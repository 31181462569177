import axios from "axios";
import { FilterComuneDto } from "components/filter/FilterComuneDto";
import { BaseComuneDto } from "models/BaseComuneDto";
import { ComuneDto } from "models/ComuneDto";
import { Response } from "models/Response";
import * as Constants from 'utils/constants';

//To Do tipizzare le response

const findByIdProvincia = async (id: number) => {
    return axios.get<{}, Response<ComuneDto[]>>(`${Constants.API_COMUNE_FIND_BY_ID_PROVINCIA}/${id}`);
}

const findAllComuni = async () => {
    return axios.get<{}, Response<ComuneDto[]>>(`${Constants.API_COMUNE_FIND_ALL_VALID}`);
}

const findByComuneDescrizione = async (descrizione: string) => {
    return axios.get<{}, Response<BaseComuneDto>>(`${Constants.API_COMUNE_FIND_BY_DESCRIZIONE}/${descrizione}`);
}

const findComuneById = async (id: number) => {
    return axios.get<{}, Response<ComuneDto>>(`${Constants.API_COMUNE_FIND_BY_ID_COMUNE}/${id}`);
}

const saveOrUpdateComune = async (comune: ComuneDto) => {
    return axios.post<{}, Response<ComuneDto>>(Constants.API_COMUNE_SAVE_UPDATE, comune);
}

const deleteComune = async (id: number) => {
    return axios.get<{}, Response<ComuneDto>>(`${Constants.API_COMUNE_DELETE}/${id}`);
}

const findComuneByFilter = async (filter: FilterComuneDto) => {
    return axios.post<{}, Response<ComuneDto[]>>(Constants.API_COMUNE_FIND_BY_COMUNE_FILTER, filter);
}

const ComuneService = {
    findByIdProvincia,
    findAllComuni,
    findByComuneDescrizione,
    findComuneById,
    saveOrUpdateComune,
    deleteComune,
    findComuneByFilter
}

export default ComuneService;