import axios from 'axios';
import * as Constants from 'utils/constants';
import { Response } from 'models/Response';
import { ApplicationDto, UtenteDto } from 'models/UtenteDto';
import { LoginDto } from 'models/LoginDto';

export const mockedLogin = async (): Promise<{token: string}> => {
    return new Promise<{token: string}>(resolve => {
        setTimeout(() => {
            resolve({token: 'dsiofjsdi8jfsd8jfsdioj'});
        }, 1000)
    });
}


const login = async (data: LoginDto) => {
    return axios.post<{}, Response<ApplicationDto>, LoginDto>(Constants.API_USER_LOGIN, data);
}

const AuthService = {
    mockedLogin,
    login,
}

export default AuthService;