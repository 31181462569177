import { useContext, useMemo } from "react";
import { AppCTX } from "contexts/AppContext";

import { Variants } from "framer-motion";

import MEASURES from "style/measures";
import { AsideContainer, AsideHeader, LinksList } from "./styles";

import { motion, AnimatePresence } from 'framer-motion'
import { useState } from "react";
import { NavLink } from "react-router-dom";

import { Typography } from "@mui/material";
import { AttachFileTwoTone, CalendarMonthTwoTone, DashboardTwoTone, LocalHospitalTwoTone, MedicationLiquidTwoTone, SickTwoTone, SupervisorAccountTwoTone } from "@mui/icons-material";
import { SidebarItem } from "./types";

import { ROUTES } from 'utils/constants';
import { useAppSelector } from "store/storeHooks";

const asideAnimationVariants: Variants = {
    full: {
        width: MEASURES.sidebar.full.width,
    },
    collapsed: {
        width: MEASURES.sidebar.collapsed.width,
    }
};

const adminMenuList: SidebarItem[] = [
    {
        path: ROUTES.admin.main,
        icon: <SupervisorAccountTwoTone />,
        label: 'Amministratori'
    },
    {
        path: ROUTES.medico.main,
        icon: <LocalHospitalTwoTone />,
        label: 'Medici'
    }
];

const medicoMenuList: SidebarItem[] = [
    {
        path: ROUTES.dashboard,
        icon: <DashboardTwoTone />,
        label: 'Dashboard'
    },
    {
        path: ROUTES.paziente.main,
        icon: <SickTwoTone />,
        label: 'Pazienti'
    },
    {
        path: ROUTES.calendar,
        icon: <CalendarMonthTwoTone />,
        label: 'Appuntamenti'
    },
    {
        path: ROUTES.materialeInformativo.main,
        icon: <AttachFileTwoTone />,
        label: 'Materiale Informativo'
    },
    {
        path: ROUTES.farmaco.main,
        icon: <MedicationLiquidTwoTone />,
        label: 'Farmaci'
    },
];


const Sidebar: React.FC = () => {
    const { isSidebarCollapsed, toggleCollapseSidebar } = useContext(AppCTX);

    const [isAnimating, setIsAnimating] = useState<boolean>(false);

    const { loggedUser } = useAppSelector(({ auth }) => auth);

    const menuList = useMemo(() => loggedUser?.codProfilo === 'A' ? adminMenuList : medicoMenuList, [loggedUser]);

    return (
        <AsideContainer
            animate={!isSidebarCollapsed ? 'full' : 'collapsed'}
            variants={asideAnimationVariants}
            transition={{ duration: 0.3 }}
            onAnimationStart={() => {
                setIsAnimating(true);
            }}
            onAnimationComplete={() => {
                setIsAnimating(false);
            }}
        >
            <AsideHeader
                onClick={toggleCollapseSidebar}
            >
                <motion.h2
                    animate={{
                        transform: isAnimating ? 'scale(0)' : 'scale(1)',
                    }}
                >
                    {!isSidebarCollapsed ? 'Heart Away' : 'HA'}
                </motion.h2>
            </AsideHeader>
            <LinksList>
                {menuList.map(({ path, label, icon }, idx) => (
                    <NavLink key={`${idx}_${label.trim().toLowerCase()}`} to={path}>
                        <AnimatePresence key={`animate_presence_${idx}_${label.trim().toLowerCase()}`}>
                            <>
                                {icon}
                                {!isSidebarCollapsed && (
                                    <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                        transition={{ duration: 0.5 }}
                                    >
                                        <Typography variant="subtitle2">{label}</Typography>
                                    </motion.div>
                                )}
                            </>
                        </AnimatePresence>
                    </NavLink>
                ))}
            </LinksList>
        </AsideContainer >
    );
}

export default Sidebar;