
import { useState } from "react";
import { InputAdornment, Button } from "@mui/material";
import { AccountCircleTwoTone } from "@mui/icons-material";
import { LoginPageContainer, SideScreenAuth, SideScreenImage } from "./styles";
import { AuthCard, CardBody, InputsContainer } from "components/authCard";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import doctorsHeartCare from 'assets/doctors_heart_care.jpg';
import AuthService from "services/AuthService";
import InputField from "components/layout/inputs/InputField";
import { LoginDto } from "models/LoginDto";
import { StatusCodes } from "utils/enums";
import { useAppDispatch } from "store/storeHooks";
import { setNewMessage } from "reducers/notification.reducer";
import { login } from "reducers/auth.reducer";
import PasswordInputField from "components/layout/inputs/PasswordInputField";

const LoginPage: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const dispatch = useAppDispatch();

    const loginSchema = Yup.object().shape({
        username: Yup.string()
            .required('Username obbligatoria'),
        password: Yup.string()
            .required('Password obbligatoria'),
    });

    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        validationSchema: loginSchema,
        onSubmit: async (values) => {
            setIsLoading(true);
            const { username, password } = values;
            const loginData = new LoginDto(username, password);
            AuthService.login(loginData).then(res => {
                const { statusCode, message, out: user } = res;

                if (statusCode === StatusCodes.KO) {
                    dispatch(setNewMessage({
                        level: 'error',
                        message
                    }));
                    return;
                }

                dispatch(login(user));
            }).catch(err => {
                console.log(err);
                dispatch(setNewMessage({
                    level: 'error',
                    message: err.message
                }));
            }).finally(() => {
                setIsLoading(false);
            });
        },
    })

    const isUsernameInvalid = formik.touched.username && formik.errors.username != null && formik.errors.username.length > 0;
    const isPasswordInvalid = formik.touched.password && formik.errors.password != null && formik.errors.password.length > 0;

    return (
        <form onSubmit={formik.handleSubmit}>
            <LoginPageContainer>
                <SideScreenAuth>
                    <AuthCard>
                        <h2>Login</h2>
                        <CardBody>
                            <InputsContainer>
                                <InputField
                                    type="text"
                                    size="small"
                                    label="Username"
                                    {...formik.getFieldProps('username')}
                                    isInvalid={isUsernameInvalid}
                                    errorMessage={formik.errors.username}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <AccountCircleTwoTone />
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                                <PasswordInputField
                                    size="small"
                                    label="Password"
                                    {...formik.getFieldProps('password')}
                                    isInvalid={isPasswordInvalid}
                                    errorMessage={formik.errors.password}
                                />

                            </InputsContainer>
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                size="large"
                                disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
                            >
                                {isLoading ? 'Loading...' : 'Continua'}
                            </Button>
                        </CardBody>
                    </AuthCard>
                </SideScreenAuth>
                <SideScreenImage>
                    <img src={doctorsHeartCare} alt="call-doctor" />
                </SideScreenImage>
            </LoginPageContainer >
        </form>
    );
}

export default LoginPage;