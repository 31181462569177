import { DeleteForeverTwoTone } from "@mui/icons-material";
import { Alert, Autocomplete, Button, TextField } from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { FilterCatalogoDto } from "components/filter/FilterCatalogoDto";
import { GenericFilter } from "components/filter/GenericFilter";
import { CardActionsContainer } from "components/layout/Card/styles";
import { it } from "date-fns/locale";
import { CatalogoDto } from "models/CatalogoDto";
import { ProfiloClinicoMasterDto } from "models/GestioneProfiloClinicoDto";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { setNewMessage } from "reducers/notification.reducer";
import CatalogoService from "services/CatalogoService";
import ProfiloClinicoService from "services/ProfiloClinicoService";
import { useAppDispatch } from "store/storeHooks";
import { ProfiloClinicoGenericListTypes, StatusCodes } from "utils/enums";
import { DateSection, ItemCardContainer, ItemContainer, ItemSection } from "./styles";
import { ProfiloClinicoGenericListProps } from "./types";

const ProfiloClinicoGenericList: React.FC<ProfiloClinicoGenericListProps> = ({ type }) => {

    const dispatch = useAppDispatch();

    const addItemLabel = useMemo<string>(() => {
        switch (type) {
            case ProfiloClinicoGenericListTypes.FATTORI_RISCHIO:
                return 'Per aggiungere un nuovo fattore di rischio bisogna sceglierlo e selezionarlo dal menù a tendina';
            case ProfiloClinicoGenericListTypes.MALATTIE_CARDIOVASCOLARI:
                return 'Per aggiungere una nuova malattia cardiovascolare bisogna sceglierla e selezionarla dal menù a tendina';
            case ProfiloClinicoGenericListTypes.MALATTIE_CONCOMITANTI:
                return 'Per aggiungere una nuova malattia concomitante bisogna sceglierla e selezionarla dal menù a tendina';
        }
    }, [type]);

    const [find, setFind] = useState<string>('');
    const [listOptions, setListOptions] = useState<CatalogoDto[]>([]);

    useEffect(() => {
        // if (!find || find.length < 3) {
        //     setListOptions([]);
        //     return;
        // };

        const timeoutId = setTimeout(() => {
            const filter = new FilterCatalogoDto();

            filter.tipo = type;
            CatalogoService.findByFilter(filter).then(res => {
                const { out } = res;

                setListOptions(out);
            });
        }, 500);

        return (() => {
            clearTimeout(timeoutId);
        });

    }, [find, type]);

    const { id: pazienteId } = useParams();

    const [list, setList] = useState<ProfiloClinicoMasterDto[]>([]);

    useEffect(() => {
        const filter = new GenericFilter();

        filter.parentId = Number(pazienteId);
        filter.tipo = type;

        ProfiloClinicoService.getListaProfiloClinicoById(filter).then(({ out }) => {
            setList(out);
        }).catch(_ => {
            setList([]);
        });
    }, [pazienteId, type]);

    const save = () => {
        ProfiloClinicoService.saveUpdateProfiloClinico(list).then(({ statusCode, message }) => {
            if (statusCode === StatusCodes.OK) {
                dispatch(setNewMessage({
                    level: 'success',
                    message,
                }));
            } else {
                dispatch(setNewMessage({
                    level: 'error',
                    message,
                }));
            }
        });
    }

    const removeItemFromList = (index: number) => {
        const terapiaList = [...list];
        const updatedList = terapiaList.filter((_, idx) => idx !== index);
        setList(updatedList);
    }

    const deleteProfilo = (id: number, index: number) => {
        ProfiloClinicoService.deleteProfilo(id).then(({ statusCode, message }) => {
            if (statusCode === StatusCodes.OK) {
                dispatch(setNewMessage({
                    level: 'success',
                    message
                }));

                removeItemFromList(index);
            } else {
                dispatch(setNewMessage({
                    level: 'error',
                    message
                }));
            }
        });
    }

    return (
        <>
            <div className="row mt-4 mb-4">
                <div className="col-md-8">
                    <Autocomplete
                        noOptionsText="Digitare almeno tre caratteri..."
                        options={listOptions}
                        getOptionLabel={({ descrizione }) => descrizione}
                        value={null}
                        onChange={(_, value) => {
                            if (value) {
                                let listShallow = [...list];

                                const newItem = new ProfiloClinicoMasterDto();
                                newItem.catalogo = value;
                                newItem.paziente.id = Number(pazienteId);
                                listShallow.push(newItem);

                                setList(listShallow);
                            }
                        }}
                        filterSelectedOptions
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Cerca farmaco per principio attivo o descrizione"
                                onChange={(evt) => {
                                    setFind(evt.target.value);
                                }}
                            />
                        )}
                    />
                </div>
                <div className="col-md-4">
                    <Alert color="warning" icon={false}>
                        {addItemLabel}
                    </Alert>
                </div>
            </div>

            {list.map((item, index) => (
                <ItemCardContainer appearence={{ isFullWidth: true }}>
                    <ItemContainer>
                        <ItemSection>
                            <p>{item.catalogo.descrizione}</p>
                        </ItemSection>
                        <DateSection>
                            <LocalizationProvider adapterLocale={it} dateAdapter={AdapterDateFns}>
                                <MobileDatePicker
                                    views={['day', 'month', 'year']}
                                    value={item.dataRilevazione}
                                    onChange={(value) => {
                                        const i = { ...item };
                                        i.dataRilevazione = value;

                                        const updatedList = [...list];
                                        updatedList[index] = i;
                                        setList(updatedList);
                                    }}
                                    renderInput={(props) => <TextField {...props} label="Data diagnosi" />}
                                />
                            </LocalizationProvider>
                        </DateSection>
                        <Button
                            color="error"
                            onClick={() => {
                                if (item.id) {
                                    deleteProfilo(item.id, index);
                                } else {
                                    removeItemFromList(index);
                                }
                            }}
                        >
                            <DeleteForeverTwoTone />
                        </Button>
                    </ItemContainer>
                </ItemCardContainer>
            ))}

            <CardActionsContainer>
                <Button variant="contained" color="success" onClick={save}>
                    Salva
                </Button>
            </CardActionsContainer>
        </>
    );
}

export default ProfiloClinicoGenericList;