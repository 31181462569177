import axios from "axios";
import { FilterVisitaDto } from "components/filter/FilterVisitaDto";
import { VisitaDto } from "models/VisitaDto";
import * as Constants from 'utils/constants';
import { Response } from "models/Response";
import { ValoriAntropometriciDto } from "models/ValoriAntropometriciDto";
import { GenericFilter } from "components/filter/GenericFilter";

//To Do tipizzare le response

const saveOrUpdatevalori = async (visita : ValoriAntropometriciDto) => {
    return axios.post<{}, Response<ValoriAntropometriciDto>>(Constants.API_VALORI_ANTROPOMETRICI_SAVE, visita);
}

const getLastValori = async (id: number) => {
    return axios.get<{}, Response<ValoriAntropometriciDto>>(`${Constants.API_VALORI_ANTROPOMETRICI_LAST_INSERT}/${id}`);
}

const getValoribyId = async (id: number) => {
    return axios.get<{}, Response<ValoriAntropometriciDto>>(`${Constants.API_VALORI_ANTROPOMETRICI_ID}/${id}`);
}

const pageByFilter = async (filter : GenericFilter) => {
    return axios.post<{}, Response<ValoriAntropometriciDto[]>>(Constants.API_VALORI_ANTROPOMETRICI_PAGE, filter);
}

const getLastSixMonthsByPaziente = async (idPaziente: number) => {
    return axios.get<{}, Response<ValoriAntropometriciDto[]>>(`${Constants.API_LAST_SIX_MONTHS}/${idPaziente}`);
}



const ValoriAntropometriciService = {
    saveOrUpdatevalori,
    getLastValori,
    getValoribyId,
    pageByFilter,
    getLastSixMonthsByPaziente
}

export default ValoriAntropometriciService;