import axios from "axios";
import { FilterUtenteDto } from "components/filter/FilterUtenteDto";
import { GenericFilter } from "components/filter/GenericFilter";
import { MedicoDto } from "models/MedicoDto";
import { TerapiaMasterDto, TerapiaSlaveDto, TerapieDto } from "models/TerapieDto";
import { Response } from "models/Response";
import * as Constants from 'utils/constants';
import { FilterTerapieDto } from "components/filter/FilterTerapieDto";

const listaTerapieMaster = async (filter: FilterTerapieDto) => {
    return axios.post<{}, Response<TerapiaMasterDto[]>>(Constants.API_TERAPIE_MASTER_FILTER, filter);
}

const deleteTerapia = async (id: number | null | undefined) => {
    return axios.get<{}, Response<TerapiaSlaveDto>>(`${Constants.API_TERAPIE_DELETE}/${id}`);
}

const deleteTerapiaSlave = async (id: number | null | undefined) => {
    return axios.get<{}, Response<TerapiaMasterDto>>(`${Constants.API_TERAPIE_DELETE_SLAVE}/${id}`);
}

const findByIdMaster = async (id: number | null | undefined) => {
    return axios.get<{}, Response<TerapiaMasterDto>>(`${Constants.API_TERAPIE_FIND_BY_ID_MASTER}/${id}`);
}

const saveUpdateTerapia = async (terapia: TerapieDto) => {
    return axios.post<{}, Response<TerapieDto>>(Constants.API_TERAPIE_SAVE_UPDATE_MASTER, terapia);
}

const saveUpdateTerapie = async (terapie: TerapiaSlaveDto[]) => {
    return axios.post<{}, Response<any>, TerapiaSlaveDto[]>(Constants.API_TERAPIE_SAVE_UPDATE_MASTER, terapie);
}

const saveUpdateTerapiaSlave = async (terapiaSlave: TerapiaSlaveDto) => {
    return axios.post<{}, Response<TerapieDto>>(Constants.API_TERAPIE_SAVE_UPDATE_SLAVE, terapiaSlave);
}

const listaTerapieSlave = async (filter: FilterTerapieDto) => {
    return axios.post<{}, Response<TerapiaMasterDto[]>>(Constants.API_TERAPIE_PAGE_SLAVE, filter);
}

const listTerapiaByPaziente = async (id: number | null | undefined) => {
    return axios.get<{}, Response<TerapieDto>>(`${Constants.API_TERAPIE_LIST_BY_PAZIENTE}/${id}`);
}

const listTerapiaByMedico = async (id: number | null | undefined) => {
    return axios.get<{}, Response<TerapieDto>>(`${Constants.API_TERAPIE_LIST_BY_MEDICO}/${id}`);
}

const listaTerapieMasterSlave = async (filter: FilterTerapieDto) => {
    return axios.post<{}, Response<TerapiaSlaveDto[]>>(Constants.API_TERAPIE_PAGE_MASTER_SLAVE, filter);
}

const terapiaMasterAndHisSlaves = async (id: number | null | undefined) => {
    return axios.get<{}, Response<TerapieDto>>(`${Constants.API_TERAPIE_MASTER_AND_HIS_SLAVES}/${id}`);
}

const TerapieService = {
    listaTerapieMaster,
    deleteTerapia,
    deleteTerapiaSlave,
    findByIdMaster,
    saveUpdateTerapia,
    saveUpdateTerapie,
    saveUpdateTerapiaSlave,
    listaTerapieSlave,
    listTerapiaByPaziente,
    listTerapiaByMedico,
    listaTerapieMasterSlave,
    terapiaMasterAndHisSlaves
}

export default TerapieService;