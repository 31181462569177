import axios from "axios";
import { FilterUtenteDto } from "components/filter/FilterUtenteDto";
import { MedicoDto } from "models/MedicoDto";
import { Response } from "models/Response";
import * as Constants from 'utils/constants';

//To Do tipizzare le response

const saveMedico = async (medico : MedicoDto) => {
    return axios.post<{}, Response<any>, MedicoDto>(Constants.API_MEDICO_CREATE, medico);
}

const getMedicoById = async (id: number) => {
    return axios.get<{}, Response<MedicoDto>>(`${Constants.API_MEDICO}/${id}`);
}

const listaMedici = async (filter: FilterUtenteDto) => {
    return axios.post<{}, Response<MedicoDto[]>, FilterUtenteDto>(Constants.API_MEDICO_FILTER_USER, filter);
}

const MedicoService = {
    getMedicoById,
    saveMedico,
    listaMedici
}

export default MedicoService;