import { Button, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, Switch, TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Card from "components/layout/Card/Card";
import InputField from "components/layout/inputs/InputField";
import { it } from "date-fns/locale";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "utils/constants";
import { PATIENT_TABS } from "./constants";
import { HeadingTabBox, HeadingTabContainer, PazienteDetailActionsContainer, TabLink } from "./styles";
import * as Yup from 'yup';
import AutocompleteInputField from "components/layout/inputs/AutocompleteInputField";
import { useFormik } from "formik";
import { useEffect, useMemo, useRef, useState } from "react";
import { CatalogoDto } from "models/CatalogoDto";
import { FilterCatalogoDto } from "components/filter/FilterCatalogoDto";
import CatalogoService from "services/CatalogoService";
import { PazienteDto } from "models/PazienteDto";
import PazienteService from "services/PazienteService";
import { StatusCodes } from "utils/enums";
import { useAppDispatch } from "store/storeHooks";
import { setNewMessage } from "reducers/notification.reducer";
import { NazioneDto } from "models/NazioneDto";
import NazioneService from "services/NazioneService";
import { ProvinciaDto } from "models/ProvinciaDto";
import ProvinciaService from "services/ProvinciaService";
import { ComuneDto } from "models/ComuneDto";
import ComuneService from "services/ComuneService";
import {RichiestaAssociazioneDto} from "../../../models/UtenteDto";
import { Response } from "models/Response";

export const PazienteDetailpage: React.FC = () => {

    const {id: pazienteId} = useParams();

    const isEdit = useMemo(() => pazienteId !== null && pazienteId !== undefined, [pazienteId]);

    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const paziente = useRef<PazienteDto>(new PazienteDto());

    const [listOspedali, setListOspedali] = useState<CatalogoDto[]>([]);

    const [listaNazioni, setListaNazioni] = useState<NazioneDto[]>([]);

    const [listaProvince, setListaProvince] = useState<ProvinciaDto[]>([]);

    const [provincia, setProvincia] = useState<ProvinciaDto>();

    const [listaComuni, setListaComuni] = useState<ComuneDto[]>([]);

    const [nazione, setNazione] = useState<NazioneDto>();

    const [start, setStart] = useState<boolean>(true);

    const [reminderBool, setReminder] = useState<boolean>(true);

    var cont: number = 0;

    // const [listaComuni, setListaComuni] = useState<ComuneDto[]>([]);


    useEffect(() => {
        paziente.current.utente.flagConferma = "M";

        if (!pazienteId) return;
        PazienteService.getPazienteById(Number(pazienteId)).then(res => {
            const {statusCode, message, out} = res;

            if (statusCode === StatusCodes.KO) {
                dispatch(setNewMessage({
                    level: 'error',
                    message
                }));

                navigate('lista-medici');
            } else {
                fillPaziente(out);
            }
        });
    }, []);


    useEffect(() => {
        const filter = new FilterCatalogoDto();
        filter.tipo = "OSP_INT";
        CatalogoService.findByFilter(filter).then(res => {
            const {out} = res;

            setListOspedali(out);
        });
    }, []);


    useEffect(() => {
        NazioneService.findAllNations().then(res => {
            const {out} = res;
            setListaNazioni(out);
        });
    }, []);

    useEffect(() => {
        ProvinciaService.findAllProvince().then(res => {
            const {out} = res;
            setListaProvince(out);
        });
    }, []);


    const fillPaziente = (p: PazienteDto) => {
        paziente.current = p;
        formik.setFieldValue('nome', p.nome);
        formik.setFieldValue('cognome', p.cognome);
        formik.setFieldValue('cf', p.cf);
        formik.setFieldValue('genere', p.sesso);
        formik.setFieldValue('fattoreEtnico', p.fattoreEtnico);
        formik.setFieldValue('dataNascita', p.dataNascita);
        formik.setFieldValue('cittaNascita', p.cittaResidenza);
        formik.setFieldValue('via', p.via);
        formik.setFieldValue('cap', p.cap);
        formik.setFieldValue('userName', p.utente.usrName);
        formik.setFieldValue('email', p.utente.usrMail);
        formik.setFieldValue('telefono', p.telefono);
        formik.setFieldValue('ospedali', p.utente.codiciIntervento ?? []);
        formik.setFieldValue('score2', p.score2);
        formik.setFieldValue('nazione', p.nazione.statoDes);
        formik.setFieldValue('provincia', p.provincia?.desPrv);
        if (p.provincia) setProvincia(p.provincia);
        formik.setFieldValue('comune', p.comune?.desCom);
        setNazione(p.nazione)
        formik.setFieldValue('reminder', p.reminder);
        p.reminder=='S'? setReminder(true) : setReminder(false);

    }

    const pazienteSchema = Yup.object().shape({
        nome: Yup.string()
            .required('Nome campo obbligatorio'),
        cognome: Yup.string()
            .required('Cognome campo obbligatorio'),
        cf: Yup.string()
            .required('Cod. identificativo campo obbligatorio')
            .min(16, 'Cod. identificativo 16 caratteri')
            .max(16, 'Cod. identificativo 16 caratteri'),
        genere: Yup.string()
            .required('Genere campo obbligatorio'),
        fattoreEtnico: Yup.string()
            .required('Fattore Etnico campo obbligatorio'),
        dataNascita: Yup.date()
            .required('Data di nascita campo obbligatorio'),
        cittaNascita: Yup.string()
            .required('Città di residenza campo obbligatorio'),
        via: Yup.string()
            .required('Via campo obbligatorio'),
        cap: Yup.string()
            .required('CAP campo obbligatorio')
            .min(5, 'CAP richiede 5 caratteri')
            .max(5, 'CAP richiede 5 caratteri'),
        userName: Yup.string()
            .required('Username campo obbligatorio'),
        email: Yup.string()
            .required('Email campo obbligatorio')
            .email('Email campo non corretto'),
        telefono: Yup.string()
            .required('Telefono campo obbligatorio'),
        ospedali: Yup.array()
            .required("Selezionare almeno un ospedale")
            .min(1, "Selezionare almeno un ospedale"),
        score2: Yup.string()
            .required('Score2 campo obbligatorio'),
    });

    const formik = useFormik({
        initialValues: {
            nome: '',
            cognome: '',
            cf: '',
            genere: '',
            fattoreEtnico: '',
            dataNascita: new Date(''),
            cittaNascita: '',
            via: '',
            cap: '',
            userName: '',
            email: '',
            telefono: '',
            ospedali: [],
            score2: 0,
            nazione: 'ITALIA',
            provincia: '',
            comune: '',
            reminder: 'S'

        },
        validationSchema: pazienteSchema,
        onSubmit: ({
                       nome,
                       cognome,
                       cf,
                       genere,
                       fattoreEtnico,
                       dataNascita,
                       cittaNascita,
                       via,
                       cap,
                       userName,
                       email,
                       telefono,
                       ospedali,
                       score2,
                       nazione,
                       provincia,
                       comune,
                       reminder
                   }) => {

            paziente.current.nome = nome;
            paziente.current.cognome = cognome;
            paziente.current.cf = cf;
            paziente.current.sesso = genere;
            paziente.current.fattoreEtnico = fattoreEtnico;
            paziente.current.dataNascita = dataNascita;
            paziente.current.cittaResidenza = cittaNascita;
            paziente.current.via = via;
            paziente.current.cap = cap;
            paziente.current.utente.usrName = userName;
            paziente.current.utente.usrMail = email;
            paziente.current.telefono = telefono;
            paziente.current.utente.codiciIntervento = ospedali;
            paziente.current.score2 = score2;
            paziente.current.nazione = getNazione(nazione);
            paziente.current.provincia = getProvincia(provincia);
            paziente.current.comune = getComune(comune);
            reminderBool== true ? reminder='S' : reminder='N'
            paziente.current.reminder = reminder;
            savePaziente();
        }
    });

    function listaRichieste(res: Response<PazienteDto>){
        let listaAssociazioneDto: RichiestaAssociazioneDto[] = [];

        paziente.current.utente.codiciIntervento.forEach(item => {
            let richiestaAssociazioneDto = new RichiestaAssociazioneDto();
            richiestaAssociazioneDto.statoEvasa = 'N';
            richiestaAssociazioneDto.ospedale = item;
            richiestaAssociazioneDto.utente = res.out.utente;
            listaAssociazioneDto.push(richiestaAssociazioneDto);
        })

        return listaAssociazioneDto;
    }
    // function salvaListaRichiesta(lista: RichiestaAssociazioneDto[]) {
    //
    //     RichiesteAssocizioneService.addRiechistaAssociazione(lista).then(response => {
    //         if (response.statusCode === StatusCodes.OK) {
    //             dispatch(setNewMessage({
    //                 level: 'success',
    //                 message: response.message
    //             }));
    //         } else {
    //             dispatch(setNewMessage({
    //                 level: 'error',
    //                 message: response.message
    //             }));
    //         }
    //     }).catch(ex => {
    //         dispatch(setNewMessage({
    //             level: 'error',
    //             message: ex
    //         }));
    //     })
    // }

    function getNazione(descrizione: string) {
        var nazione: NazioneDto = new NazioneDto();
        listaNazioni.forEach(x => {
            if (x.statoDes == descrizione) nazione = x
        })
        return nazione;
    }

    function getProvincia(descrizione: string) {
        var provincia: ProvinciaDto = new ProvinciaDto();
        listaProvince.forEach(x => {
            if (x.desPrv == descrizione) provincia = x
        })
        return provincia;
    }

    function getComune(descrizione: string) {
        var comune: ComuneDto = new ComuneDto();
        listaComuni.forEach(x => {
            if (x.desCom == descrizione) comune = x
        })
        return comune;
    }

    const savePaziente = () => {
        PazienteService.saveOrUpdatePaziente(paziente.current).then(res => {
            const { statusCode, message } = res;

            if (statusCode === StatusCodes.OK) {
                dispatch(setNewMessage({
                    level: 'success',
                    message
                }));
                // let listaAssociazioneDto: RichiestaAssociazioneDto[];

                // listaAssociazioneDto = listaRichieste(res) ;
                // console.info('listaAssociazioneDto', listaAssociazioneDto);
                // salvaListaRichiesta(listaAssociazioneDto);
                navigate(ROUTES.paziente.list);
            } else {
                dispatch(setNewMessage({
                    level: 'error',
                    message
                }));
            }
        }).catch(err => {
            dispatch(setNewMessage({
                level: 'error',
                message: err
            }));
        });
    }

    function setProvinciaScelta(event: any) {
        listaProvince.forEach(x => {
            if (x.desPrv === event) setProvincia(x)
        })
    }

    
    useEffect(() => {
        if (provincia) {
            ComuneService.findByIdProvincia(Number(provincia.id)).then(res => {
                const { out } = res;
                setListaComuni(out);
            });
        }
        else {
           setListaComuni([])
        }
    }, [provincia]);
    
    function setNazioneScelta(event: any) {
        listaNazioni.forEach(x => {
            if (x.statoDes === event) setNazione(x)
        })
    }

    useEffect(() => {
        if (nazione) setNazione(nazione)
        cont++
        if(cont==1 && nazione) setStart(false)
        if(cont>1) setStart(false);
    }, [nazione]);
    
    const isNomeInvalid = formik.touched.nome && formik.errors.nome != null && formik.errors.nome.length > 0;
    const isCognomeInvalid = formik.touched.cognome && formik.errors.cognome != null && formik.errors.cognome.length > 0;
    const isCfInvalid = formik.touched.cf && formik.errors.cf != null && formik.errors.cf.length > 0;
    const isCittaNascitaInvalid = formik.touched.cittaNascita && formik.errors.cittaNascita != null && formik.errors.cittaNascita.length > 0;
    const isViaInvalid = formik.touched.via && formik.errors.via != null && formik.errors.via.length > 0;
    const isCapInvalid = formik.touched.cap && formik.errors.cap != null && formik.errors.cap.length > 0;
    const isUserNameInvalid = formik.touched.userName && formik.errors.userName != null && formik.errors.userName.length > 0;
    const isEmailInvalid = formik.touched.email && formik.errors.email != null && formik.errors.email.length > 0;
    const isTelefonoInvalid = formik.touched.telefono && formik.errors.telefono != null && formik.errors.telefono.length > 0;
    const isOspedaliInvalid = formik.touched.ospedali && formik.errors.ospedali != null && formik.errors.ospedali.length > 0;
    const isScore2Invalid = formik.touched.score2 && formik.errors.score2 != null && formik.errors.score2.length > 0;

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="row">
                    <Card title="Scheda Paziente" appearence={{ isFullWidth: true }}>
                        <div className="mb-5">
                            <div className="row">
                                <div className="col-md-4">
                                    <InputField
                                        label="Nome"
                                        {...formik.getFieldProps('nome')}
                                        isInvalid={isNomeInvalid}
                                        errorMessage={formik.errors.nome}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <InputField
                                        label="Cognome"
                                        {...formik.getFieldProps('cognome')}
                                        isInvalid={isCognomeInvalid}
                                        errorMessage={formik.errors.cognome}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <InputField
                                        label="Cod. Identificato"
                                        {...formik.getFieldProps('cf')}
                                        isInvalid={isCfInvalid}
                                        errorMessage={formik.errors.cf}
                                    />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-2">
                                    {/* TODO: Creare un componente SelectInputField generico */}
                                    <FormControl size="small" fullWidth>
                                        <InputLabel id="genere">Genere</InputLabel>
                                        <Select
                                            labelId="genere"
                                            label="Genere"
                                            {...formik.getFieldProps('genere')}
                                            onChange={(evt) => {
                                                formik.setFieldValue('genere', evt.target.value);
                                            }}
                                        >
                                            <MenuItem value="U">Uomo</MenuItem>
                                            <MenuItem value="D">Donna</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-md-3">
                                    {/* TODO: Creare un componente SelectInputField generico */}
                                    <FormControl size="small" fullWidth>
                                        <InputLabel id="etnia">Etnia</InputLabel>
                                        <Select
                                            labelId="Etnia"
                                            label="Etnia"
                                            {...formik.getFieldProps('fattoreEtnico')}
                                            onChange={(evt) => {
                                                formik.setFieldValue('fattoreEtnico', evt.target.value);
                                            }}
                                        >
                                            <MenuItem value="C">Caucasica</MenuItem>
                                            <MenuItem value="A">Asiatica</MenuItem>
                                            <MenuItem value="N">Africana/Afro-americana</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-md-3">
                                    {/* TODO: Creare un componente DateInputField generico */}
                                    <LocalizationProvider adapterLocale={it} dateAdapter={AdapterDateFns}>
                                        <DesktopDatePicker
                                            label="Data di nascita"
                                            inputFormat="dd/MM/yyyy"
                                            value={formik.getFieldProps('dataNascita').value}
                                            onChange={(value) => {
                                                formik.setFieldValue('dataNascita', value);
                                            }}
                                            renderInput={(params) => <TextField {...params} size="small" fullWidth />}
                                        />
                                    </LocalizationProvider>
                                </div>
                                <div className="col-md-4">
                                    <InputField
                                        label="Città di residenza"
                                        {...formik.getFieldProps('cittaNascita')}
                                        isInvalid={isCittaNascitaInvalid}
                                        errorMessage={formik.errors.cittaNascita}
                                    />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-4">
                                    <FormControl size="small" fullWidth>
                                        <InputLabel id="nazione">Nazione</InputLabel>
                                        <Select
                                            labelId="nazione"
                                            label="nazione"
                                            {...formik.getFieldProps('nazione')}
                                            onChange={(evt) => {
                                                formik.setFieldValue('nazione', evt.target.value);
                                                setNazioneScelta(evt.target.value)
                                                setStart(false)
                                            }}
                                            required={true}
                                        >
                                            {listaNazioni.map(naz => (
                                                <MenuItem value={naz.statoDes}>{naz.statoDes}</MenuItem>)
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>

                                {nazione?.statoDes==="ITALIA" || start===true ? 
                                <div className="col-md-4">
                                    <FormControl size="small" fullWidth>
                                        <InputLabel id="provincia">Provincia</InputLabel>
                                        <Select
                                            labelId="provincia"
                                            label="provincia"
                                            {...formik.getFieldProps('provincia')}
                                            onChange={(evt) => {
                                                formik.setFieldValue('provincia', evt.target.value);
                                                 setProvinciaScelta(evt.target.value) 
                                            }}                                  
                                        >
                                            {listaProvince.map(pro => (
                                                <MenuItem value={pro.desPrv}>{pro.desPrv}</MenuItem>)
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                :null } 
                                {nazione?.statoDes==="ITALIA" || start===true && provincia  ? 
                                <div className="col-md-4">
                                    <FormControl size="small" fullWidth>
                                        <InputLabel id="comune">Comune</InputLabel>
                                        <Select
                                            labelId="comune"
                                            label="comune"
                                            {...formik.getFieldProps('comune')}
                                            onChange={(evt) => {
                                                formik.setFieldValue('comune', evt.target.value);
                                            }}
                                        >
                                            {listaComuni.map(com => (
                                                <MenuItem value={com.desCom}>{com.desCom}</MenuItem>)
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                :null } 
                            </div>
                            <div className="row">
                                <div className="col-md-4 mt-3">
                                    <InputField
                                        label="Via"
                                        {...formik.getFieldProps('via')}
                                        isInvalid={isViaInvalid}
                                        errorMessage={formik.errors.via}
                                    />
                                </div>
                                <div className="col-md-4 mt-3">
                                    <InputField
                                        label="CAP"
                                        type="number"
                                        {...formik.getFieldProps('cap')}
                                        isInvalid={isCapInvalid}
                                        errorMessage={formik.errors.cap}
                                    />
                                </div>
                                <div className="col-md-4 mt-3">
                                    <InputField
                                        label="Username"
                                        {...formik.getFieldProps('userName')}
                                        isInvalid={isUserNameInvalid}
                                        errorMessage={formik.errors.userName}
                                    />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-4">
                                    <InputField
                                        label="Email"
                                        {...formik.getFieldProps('email')}
                                        isInvalid={isEmailInvalid}
                                        errorMessage={formik.errors.email}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <InputField
                                        label="Telefono"
                                        {...formik.getFieldProps('telefono')}
                                        isInvalid={isTelefonoInvalid}
                                        errorMessage={formik.errors.telefono}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <InputField
                                        label="Score2"
                                        {...formik.getFieldProps('score2')}
                                        isInvalid={isScore2Invalid}
                                        errorMessage={formik.errors.score2}
                                    />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-12">
                                    <AutocompleteInputField
                                        label="Ospedali"
                                        options={listOspedali}
                                        name={formik.getFieldProps('ospedali').name}
                                        value={formik.getFieldProps('ospedali').value}
                                        onChange={(value) => {
                                            formik.setFieldValue('ospedali', value);
                                        }}
                                        isInvalid={isOspedaliInvalid}
                                        errorMessage={"Inserire almeno un ospedale"}
                                    />
                                </div>
                                <div className="row mt-3">
                                <FormGroup>
                                <FormControlLabel control={ <Switch defaultChecked />}
                                label="Abilitato alla ricezione delle notifiche" 
                                checked={reminderBool}
                                onChange={(value) => {
                                    setReminder(!reminderBool)
                                }}
                                />
                                </FormGroup>
                                </div>
                            </div>
                
                            <PazienteDetailActionsContainer>
                                <Button type="submit" variant="contained" color="success">
                                    Salva
                                </Button>
                            </PazienteDetailActionsContainer>
                        </div>
                        {isEdit && (
                            <HeadingTabContainer>
                                {PATIENT_TABS.map(({ label, path }, idx) => (
                                    <TabLink
                                        key={`${idx}_${label}`}
                                        to={path.replace(':id', pazienteId!)}
                                    >
                                        <HeadingTabBox>
                                            {label}
                                        </HeadingTabBox>
                                    </TabLink>
                                ))}
                            </HeadingTabContainer>
                        )}
                    </Card>
                </div>
            </form>

            <Outlet />
        </>
    );
}

export default PazienteDetailpage;