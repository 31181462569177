import Emitter from "services/EmitterService";
import { BACKEND_URL } from "./constants";

export const setupAxios = (axios: any) => {
      axios.defaults.baseURL = BACKEND_URL;
    //axios.defaults.baseURL = 'http://192.168.21.94:8085/hearthway-be/v1/';
    // axios.defaults.baseURL = 'http://192.168.21.152:8086/hearthway-be/v1/';

    axios.interceptors.request.use((config: any) => {
        Emitter.emit('spinner', true);
        const token = sessionStorage.getItem('hearthway-token');

        if (token) {
            config.headers['hearthway-token'] = token;
        }

        return config;
    });

    axios.interceptors.response.use((res: any) => {
        Emitter.emit('spinner', false);
        const token = res?.data?.token || res?.data?.out?.token;

        if (token) {
            sessionStorage.setItem('hearthway-token', token);
        }

        return res.data;
    }, () => {
        Emitter.emit('spinner', false);
    });
}