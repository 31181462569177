import { useEffect } from "react";
import { useAppDispatch } from "store/storeHooks";
import { logout } from "reducers/auth.reducer";
import { useNavigate } from "react-router-dom";

const LogoutPage: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(logout());
         navigate('/login');
    }, [dispatch, navigate]);

    return null;
}

export default LogoutPage;