import Card from "components/layout/Card/Card";
import { useEffect } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "utils/constants";
import { HeadingTabBox, TabLink } from "../../styles";
import { ProfiloClinicoSubtabContainer } from "./styles";
import { PROFILO_CLINICO_SUBTABS } from "./types";

export const ProfiloClinicoTab: React.FC = () => {

    const { id: idPaziente } = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        navigate(ROUTES.paziente.detail.tab.profiloClinico.valoriAntropometrici.replace(':id', `${idPaziente}`));
    }, []);

    return (
        <>
            <Card appearence={{ isFullWidth: true }} title="Porfilo Clinico" >
                <ProfiloClinicoSubtabContainer>
                    {PROFILO_CLINICO_SUBTABS.map(({ path, label }, idx) => (
                        <TabLink
                            key={`${idx}_${label}`}
                            to={path.replace(':id', idPaziente!)}
                        >
                            <HeadingTabBox>
                                {label}
                            </HeadingTabBox>
                        </TabLink>
                    ))}
                </ProfiloClinicoSubtabContainer>

                <Outlet />
            </Card>
        </>
    );
}

export default ProfiloClinicoTab;