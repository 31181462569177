import { BaseDto } from "./BaseDto";
import { PazienteDto } from "./PazienteDto";



export class ValoriAntropometriciDto extends BaseDto {

    paziente: PazienteDto;

    dataInizio: Date | null;

    peso: number | null;

    altezza: number | null;

    bmi: number | null;

    constructor() {
        super();
        this.paziente = new PazienteDto();
        this.dataInizio = null;
        this.altezza = null;
        this.bmi = null;
        this.peso = null;
    }

}    