import styled from "@emotion/styled";
import { AlertColor } from "@mui/material";
import Card from "components/layout/Card/Card";

export const ChartCard = styled(Card)`
    
`;

export const ChartHeading = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const ChartLegend = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;

    div {
        text-transform: capitalize;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
`;

export const LegendIndicator = styled.div<{color: AlertColor}>`
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: ${({theme, color}) => theme.palette[color].light};
    margin-right: 5px;
`;