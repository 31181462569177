const MEASURES = {
     sidebar: {
          full: {
               width: '225px'
          },
          collapsed: {
               width: '75px'
          }
     },
     header: {
          height: '100px',
          width: '100vw',
     },
     pageConent: {
          width: '100vw',
          minHeight: '100vh'
     }
}

export default MEASURES;