import styled from "@emotion/styled";
import { AlertColor } from "@mui/material";
import Card from "components/layout/Card/Card";

export const AlertCard = styled(Card) <{ severity: AlertColor }>`
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 3px solid ${({ theme, severity }) => theme.palette[severity].light};
    border-radius: 0.3px;
    height: 150px;
    margin-top: 0;

    strong {
        font-size: 1.3rem;
        color: ${({ theme, severity }) => theme.palette[severity].light};;
    }
`;

export const SeverityChartCard = styled(Card)`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    width: 100%;
`;