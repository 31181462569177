import { forwardRef } from "react";
import { PasswordInputFiledProps } from "./types";
import InputField from "./InputField";
import { IconButton, InputAdornment } from "@mui/material";
import { useState } from "react";
import { VisibilityOffTwoTone, VisibilityTwoTone } from "@mui/icons-material";

const PasswordInputField = forwardRef<HTMLInputElement, PasswordInputFiledProps>(({ ...props }, ref) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);

    return (
        <>
            <InputField
                type={showPassword ? 'text' : 'password'}
                {...props}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={() => {
                                    setShowPassword(p => !p);
                                }}
                            >{showPassword ? <VisibilityOffTwoTone /> : <VisibilityTwoTone />}</IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        </>
    );
});


export default PasswordInputField;