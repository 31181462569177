import React from "react";
import { useState } from "react";

export const AppCTX = React.createContext({
    isSidebarCollapsed: false,
    toggleCollapseSidebar: () => { },
});

const AppContext: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState<boolean>(false);

    return (
        <AppCTX.Provider
            value={{
                isSidebarCollapsed,
                toggleCollapseSidebar: () => {
                    setIsSidebarCollapsed(c => !c);
                }
            }}
        >
            {children}
        </AppCTX.Provider>
    );
};

export default AppContext;