import axios from "axios";
import { FilterVisitaDto } from "components/filter/FilterVisitaDto";
import { VisitaDto } from "models/VisitaDto";
import * as Constants from 'utils/constants';
import { Response } from "models/Response";

//To Do tipizzare le response

const saveUpdateVisita = async (visita: VisitaDto) => {
    return axios.post<{}, Response<VisitaDto>>(Constants.API_VISITA_SAVE_UPDATE, visita);
}

const getVisitaById = async (id: number) => {
    return axios.get<{}, Response<VisitaDto>>(`${Constants.API_VISITA}/${id}`);
}

const deleteVisita = async (id: number | null | undefined) => {
    return axios.get<{}, Response<VisitaDto>>(`${Constants.API_VISITA_DELETE}/${id}`);
}

const findVisitaPage = async (filter: FilterVisitaDto) => {
    return axios.post<{}, Response<VisitaDto[]>>(Constants.API_VISITA_FILTER_PAGE, filter);
}

const getLastMonth = async (id: number) => {
    return axios.get<{}, Response<VisitaDto[]>>(`${Constants.API_LAST_MONTH}/${id}`);
}

const VisitaService = {
    saveUpdateVisita,
    getVisitaById,
    deleteVisita,
    getLastMonth,
    findVisitaPage
}

export default VisitaService;