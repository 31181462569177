import Calendar from "components/calendar/Calendar";

const MeetingsPage: React.FC = () => {

    return (
        <>
            <Calendar />
        </>
    );
}

export default MeetingsPage;