import { CardProps } from "./types";
import { CardContainer, CardTitleContainer } from "./styles";
import React from "react";

const Card: React.FC<CardProps & React.PropsWithChildren> = ({ title, children, appearence, className }) => {

    return (
        <>
            <CardContainer {...appearence} className={className}>
                {title && (
                    <CardTitleContainer>
                        {title}
                    </CardTitleContainer>
                )}
                {children}
            </CardContainer>
        </>
    );
}

export default Card;