import * as React from 'react';


const PrivacyPage: React.FC = () => {

 return (
     <>
     <h1>
       <a href="_Toc135840203" />
       1.&nbsp; DOCUMENTO
     </h1>
     <h1>
       <a href="_Toc135840204" />
       1.1&nbsp;&nbsp;&nbsp; SCOPO
     </h1>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>
       Il presente è redatto dall’azienda <strong>EOS SRL</strong> per soddisfare
       il REGOLAMENTO (UE) 2016/679 DEL PARLAMENTO&nbsp;&nbsp;&nbsp;
       EUROPEO&nbsp;&nbsp;&nbsp; E&nbsp;&nbsp;&nbsp; DEL&nbsp;&nbsp;&nbsp;
       CONSIGLIO&nbsp;&nbsp;&nbsp; del&nbsp;&nbsp;&nbsp; 27&nbsp;&nbsp;&nbsp;
       Aprile&nbsp;&nbsp;&nbsp; 2016&nbsp;&nbsp;&nbsp; relativo&nbsp;&nbsp;&nbsp;
       alla protezione delle persone fisiche con riguardo al trattamento dei dati
       personali, nonché alla libera circolazione di tali dati e che abroga la
       direttiva 95/46/CE (regolamento generale sulla protezione dei dati)
     </p>
     <p>
       Inoltre costituisce un valido strumento per l’adozione delle misure idonee
       previste dallo stesso regolamento in accordo alla&nbsp;
       “responsabilizzazione” (accountability nell’accezione inglese) di titolari e
       responsabili, ossia, sull’ adozione di comportamenti proattivi e tali da
       dimostrare la concreta adozione di misure finalizzate ad assicurare
       l’applicazione del regolamento (si vedano artt. 23-25, in particolare, e
       l’intero Capo IV del regolamento). In sostanza rappresenta lo strumento
       affidato ai titolari per la definizione delle modalità, le garanzie e i
       limiti del trattamento dei dati personali nel rispetto delle disposizioni
       normative e alla luce di alcuni criteri specifici indicati nel regolamento.
     </p>
     <p>&nbsp;</p>
     <h1>
       <a href="_Toc135840205" />
       1.2&nbsp;&nbsp;&nbsp; CAMPO DI APPLICAZIONE
     </h1>
     <p>
       <br /> Il Documento definisce le politiche e gli standard di sicurezza in
       merito al rischio inerente al trattamento dei dati adottati dall’azienda.
     </p>
     <p>
       Quest’ultimo è da intendersi come rischio di impatti negativi sulle libertà
       e i diritti degli interessati (si vedano art. 75-77); tali impatti saranno
       analizzati attraverso un apposito processo di valutazione (si vedano artt.
       35-36) tenendo conto dei rischi noti o evidenziabili e delle misure tecniche
       e organizzative (anche di sicurezza) che il titolare ritiene di dover
       adottare per mitigare tali rischi. All’esito di questa valutazione di
       impatto il titolare potrà decidere in autonomia se iniziare il trattamento
       (avendo adottato le misure idonee a mitigare sufficientemente il rischio)
       ovvero consultare l’autorità di controllo competente per ottenere
       indicazioni su come gestire il rischio residuale; l’Autorità non avrà il
       compito di “autorizzare” il trattamento, bensì di indicare le misure
       ulteriori eventualmente da implementare a cura del titolare e potrà, ove
       necessario, adottare tutte le misure correttive ai sensi dell’art. 58:
       dall’ammonimento del titolare fino alla limitazione o al divieto di
       procedere al trattamento.
     </p>
     <p>
       Il presente documento e le relative informative in allegato si applicano:
     </p>
     <p>&nbsp;</p>
     <ul>
       <li>
         a tutti i lavoratori dipendenti e a tutti i collaboratori della{" "}
         <strong>EOS SRL</strong> a prescindere dal rapporto contrattuale con la
         stessa intrattenuto (lavoratori somministrati, collaboratori a progetto,
         agenti, stagisti, consulenti, ecc.) che si trovano ad operare sui dati
         personali di cui la <strong>EOS SRL</strong> stessa è Titolare (di seguito
         “utenti”);
       </li>
       <li>
         a tutte le attività o comportamenti comunque connessi all’utilizzo della
         rete Internet e della posta elettronica, mediante strumentazione aziendale
         o di terze parti autorizzate all’uso dell’infrastruttura aziendale.
       </li>
     </ul>
     <p>&nbsp;</p>
     <h1>
       <a href="_Toc135840206" />
       1.3&nbsp;&nbsp;&nbsp; RIFERIMENTI NORMATIVI
     </h1>
     <h1>&nbsp;</h1>
     <ol>
       <li>
         REGOLAMENTO (UE) 2016/679 DEL PARLAMENTO EUROPEO&nbsp; E&nbsp; DEL&nbsp;
         CONSIGLIO del&nbsp; 27 aprile 2016 relativo alla protezione delle persone
         fisiche con riguardo al trattamento dei dati personali, nonché alla libera
         circolazione di tali dati e che abroga la direttiva 95/46/CE (regolamento
         generale sulla protezione dei dati)
       </li>
     </ol>
     <p>&nbsp;</p>
     <ol start={2}>
       <li>
         Linee guida in materia di valutazione d'impatto sulla protezione dei dati
         e determinazione della possibilità che il trattamento "possa presentare un
         rischio elevato" ai fini del regolamento (UE) 2016/679
       </li>
     </ol>
     <p>&nbsp;</p>
     <h1>
       <a href="_Toc135840207" />
       1.4&nbsp;&nbsp;&nbsp; Definizioni
     </h1>
     <p>&nbsp;</p>
     <ol>
       <li>
         «dato personale»: qualsiasi informazione riguardante una persona fisica o
         giuridica identificata o identificabile («interessato»); come il nome, la
         ragione sociale di un’azienda, un numero di identificazione, dati relativi
         all'ubicazione, un identificativo online (e-mail)e recapiti telefoni.
       </li>
       <li>
         «trattamento»: qualsiasi operazione o insieme di operazioni, compiute con
         o senza l'ausilio di processi automatizzati e applicate a dati personali o
         insiemi di dati personali, come la raccolta, la registrazione,
         l'organizzazione, la strutturazione, la conservazione, l'adattamento o la
         modifica, l'estrazione, la consultazione, l'uso, la comunicazione mediante
         trasmissione, diffusione o qualsiasi altra forma di messa a disposizione,
         il raffronto o l'interconnessione, la limitazione, la cancellazione o la
         distruzione;
       </li>
       <li>
         «limitazione di trattamento»: il contrassegno dei dati personali
         conservati con l'obiettivo di limitarne il trattamento in futuro;
       </li>
       <li>
         «profilazione»: qualsiasi forma di trattamento automatizzato di dati
         personali consistente nell'utilizzo di tali dati personali per valutare
         determinati aspetti personali relativi a una persona fisica, in
         particolare per analizzare o prevedere aspetti riguardanti il rendimento
         professionale, la situazione economica, la salute, le preferenze
         personali, gli interessi, l'affidabilità, il comportamento, l'ubicazione o
         gli spostamenti di detta persona fisica;
       </li>
       <li>
         «pseudonimizzazione»:il trattamento dei dati personali in modo tale che i
         dati personali non possano più essere attribuiti a un interessato
         specifico senza l'utilizzo di informazioni aggiuntive, a condizione che
         tali informazioni aggiuntive siano conservate separatamente e soggette a
         misure tecniche e organizzative intese a garantire che tali dati personali
         non siano attribuiti a una persona fisica identificata o identificabile;
       </li>
       <li>
         «archivio»: qualsiasi insieme&nbsp;&nbsp; strutturato&nbsp;&nbsp;
         di&nbsp;&nbsp; dati&nbsp;&nbsp; personali accessibili&nbsp;&nbsp;
         secondo&nbsp; criteri determinati, indipendentemente dal fatto che tale
         insieme sia centralizzato, decentralizzato o ripartito in modo funzionale
         o geografico;
       </li>
       <li>
         «titolare del trattamento»: la persona fisica o giuridica, l'autorità
         pubblica, il servizio o altro organismo che, singolarmente o insieme ad
         altri, determina le finalità e i mezzi del trattamento di dati personali;
         quando le finalità e i mezzi di tale trattamento sono determinati dal
         diritto dell'Unione o degli Stati membri, il titolare del trattamento o i
         criteri specifici applicabili alla sua designazione possono essere
         stabiliti dal diritto dell'Unione o degli Stati membri;
       </li>
       <li>
         «responsabile del trattamento»: la persona fisica o giuridica, l'autorità
         pubblica, il servizio o altro organismo che tratta dati personali per
         conto del titolare del trattamento
       </li>
       <li>
         «destinatario»: la persona fisica o giuridica, l'autorità pubblica, il
         servizio o un altro organismo che riceve comunicazione di dati personali,
         che si tratti o meno di terzi. Tuttavia, le autorità pubbliche che possono
         ricevere comunicazione di dati personali nell'ambito di una specifica
         indagine conformemente al diritto dell'Unione o degli Stati membri non
         sono considerate destinatari; il trattamento di tali dati da parte di
         dette autorità pubbliche è conforme alle norme applicabili in materia di
         protezione dei dati secondo le finalità del trattamento;
       </li>
       <li>
         «terzo»: la persona fisica o giuridica, l'autorità pubblica, il servizio o
         altro organismo che non sia l'interessato, il titolare del trattamento, il
         responsabile del trattamento e le persone autorizzate al trattamento dei
         dati personali sotto l'autorità diretta del titolare o del responsabile;
       </li>
       <li>
         «consenso dell'interessato»: qualsiasi manifestazione di volontà libera,
         specifica, informata e inequivocabile dell'interessato, con la quale lo
         stesso manifesta il proprio assenso, mediante dichiarazione o azione
         positiva inequivocabile, che i dati personali che lo riguardano siano
         oggetto di trattamento;
       </li>
       <li>
         «violazione dei dati personali»: la violazione di sicurezza che comporta
         accidentalmente o in modo illecito la distruzione, la perdita, la
         modifica, la divulgazione non autorizzata o l'accesso ai dati personali
         trasmessi, conservati o comunque trattati;
       </li>
     </ol>
     <p>&nbsp;</p>
     <p>
       <strong>Dati Considerati particolari (punto 13-14-15)</strong>
     </p>
     <p>
       Art. 9 del GDPR, “Trattamento di categorie particolari di dati personali”,
       dopo avere affermato il principio per cui “1. È vietato trattare dati
       personali che rivelino l’origine razziale o etnica, le opinioni politiche,
       le convinzioni religiose o filosofiche, o l’appartenenza sindacale, nonché
       trattare dati genetici, dati biometrici intesi a identificare in modo
       univoco una persona fisica, dati relativi alla salute o alla vita sessuale o
       all’orientamento sessuale della persona”, al comma 2 il Regolamento precisa
       alcune eccezioni a tale divieto, tra le quali – alla lettera e) – annovera
       il caso del trattamento che “riguarda dati personali resi manifestamente
       pubblici dall’interessato”.
     </p>
     <p>&nbsp;</p>
     <ol start={13}>
       <li>
         «dati genetici»: i dati personali relativi alle caratteristiche genetiche
         ereditarie o acquisite di una persona fisica che forniscono informazioni
         univoche sulla fisiologia o sulla salute di detta persona fisica, e che
         risultano in particolare dall'analisi di un campione biologico della
         persona fisica in questione;
       </li>
       <li>
         «dati biometrici»: i dati personali ottenuti da un trattamento tecnico
         specifico relativi alle caratteristiche fisiche, fisiologiche o
         comportamentali di una persona fisica che ne consentono o confermano
         l'identificazione univoca, quali l'immagine facciale o i dati
         dattiloscopici;
       </li>
       <li>
         «dati relativi alla salute»: i dati personali attinenti alla salute fisica
         o mentale di una persona fisica, compresa la prestazione di servizi di
         assistenza sanitaria, che rivelano informazioni relative al suo stato di
         salute;
       </li>
       <li>
         «stabilimento principale»: a)&nbsp; per&nbsp; quanto&nbsp; riguarda&nbsp;
         un&nbsp; titolare&nbsp; del&nbsp; trattamento&nbsp; con stabilimenti in
         più di uno Stato membro, il luogo della sua amministrazione centrale
         nell'Unione, salvo che le decisioni sulle finalità e i mezzi del
         trattamento di dati personali siano adottate in un altro stabilimento del
         titolare del trattamento nell'Unione e che quest'ultimo stabilimento abbia
         facoltà di ordinare l'esecuzione di tali decisioni, nel qual caso lo
         stabilimento che ha adottato siffatte decisioni è considerato essere lo
         stabilimento principale; b) con riferimento a un responsabile del
         trattamento con stabilimenti in più di uno Stato membro, il luogo in cui
         ha sede la sua amministrazione centrale nell'Unione o, se il responsabile
         del trattamento non ha un'amministrazione centrale nell'Unione, lo
         stabilimento del responsabile del trattamento nell'Unione in cui sono
         condotte le principali attività di trattamento nel contesto delle attività
         di uno stabilimento del responsabile del trattamento nella misura in cui
         tale responsabile è soggetto a obblighi specifici ai sensi del presente
         regolamento;
       </li>
       <li>
         «rappresentante»: la persona fisica o giuridica stabilita nell'Unione che,
         designata dal titolare del trattamento o dal responsabile del trattamento
         per iscritto ai sensi dell'articolo 27, li rappresenta per quanto riguarda
         gli obblighi rispettivi a norma del presente regolamento;
       </li>
       <li>
         «impresa»: la persona fisica o giuridica, indipendentemente dalla forma
         giuridica rivestita, che eserciti un'attività economica, comprendente le
         società di persone o le associazioni che esercitano regolarmente
         un'attività economica;
       </li>
       <li>
         «gruppo imprenditoriale»: un gruppo costituito da un'impresa controllante
         e dalle imprese da questa controllate;
       </li>
       <li>
         «norme vincolanti d'impresa»: le politiche in materia di protezione dei
         dati personali applicate da un titolare del trattamento o responsabile del
         trattamento stabilito nel territorio di uno Stato membro al trasferimento
         o al complesso di trasferimenti di dati personali a un titolare del
         trattamento o responsabile del trattamento in uno o più paesi terzi,
         nell'ambito di un gruppo imprenditoriale o di un gruppo di imprese che
         svolge un'attività economica comune;
       </li>
       <li>
         «autorità di controllo»: l'autorità pubblica indipendente istituita da uno
         Stato membro ai sensi dell'articolo 51;
       </li>
       <li>
         «autorità di controllo interessata»: un'autorità di controllo interessata
         dal trattamento di dati personali in quanto: a) il titolare del
         trattamento o il responsabile del trattamento è stabilito sul territorio
         dello Stato&nbsp; membro&nbsp; di&nbsp; tale&nbsp; autorità&nbsp; di&nbsp;
         controllo;&nbsp; b)&nbsp; gli&nbsp; interessati&nbsp; che risiedono nello
         Stato membro dell'autorità di controllo sono o sono probabilmente
         influenzati in modo sostanziale dal trattamento; oppure c) un reclamo è
         stato proposto a tale autorità di controllo;
       </li>
       <li>
         «trattamento transfrontaliero»: a) trattamento di dati personali che ha
         luogo nell'ambito delle attività di stabilimenti in più di uno Stato
         membro di un titolare del trattamento o responsabile del trattamento
         nell'Unione ove il titolare del trattamento o il responsabile del
         trattamento siano stabiliti in più di uno Stato membro; oppure b)
         trattamento di dati personali che ha luogo nell'ambito delle attività di
         un unico stabilimento di un titolare del trattamento o responsabile del
         trattamento nell'Unione, ma che incide o probabilmente incide in modo
         sostanziale su interessati in più di uno Stato membro;
       </li>
       <li>
         «obiezione pertinente e motivata»: un'obiezione al progetto di decisione
         sul fatto che vi sia o meno una violazione del presente regolamento,
         oppure che l'azione prevista in relazione al titolare del trattamento o
         responsabile del trattamento sia conforme al presente regolamento, la
         quale obiezione dimostra chiaramente la rilevanza dei rischi posti dal
         progetto di decisione riguardo ai diritti e alle libertà fondamentali
         degli interessati e, ove applicabile, alla libera circolazione dei dati
         personali all'interno dell'Unione;
       </li>
       <li>
         «servizio della società dell'informazione»: il servizio definito
         all'articolo 1, paragrafo 1, lettera b), della direttiva (UE) 2015/1535
         del Parlamento europeo e del Consiglio (1);
       </li>
       <li>
         «organizzazione internazionale»: un'organizzazione e gli organismi di
         diritto internazionale pubblico a essa subordinati o qualsiasi altro
         organismo istituito da o sulla base di un accordo tra due o più Stati.
       </li>
     </ol>
     <p>&nbsp;</p>
     <h1>
       <a href="_Toc135840208" />
       2.&nbsp; PRINCIPI APPLICABILI AL TRATTAMENTO DEI DATI PERSONALI
     </h1>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>
       I dati personali di <strong>EOS SRL</strong> sono:
     </p>
     <ol>
       <li>
         trattati in modo lecito, corretto e trasparente nei confronti
         dell'interessato («liceità, correttezza e trasparenza»);
       </li>
       <li>
         raccolti per finalità determinate, esplicite e legittime, e
         successivamente trattati in modo che non sia incompatibile con tali
         finalità; un ulteriore trattamento dei dati personali a fini di
         archiviazione nel pubblico interesse, di ricerca scientifica o storica o a
         fini statistici non è, conformemente all'articolo 89, paragrafo 1,
         considerato incompatibile con le finalità iniziali («limitazione della
         finalità»);
       </li>
       <li>
         adeguati, pertinenti e limitati a quanto necessario rispetto alle finalità
         per le quali sono trattati («minimizzazione dei dati»);
       </li>
       <li>
         esatti e, se necessario, aggiornati; devono essere adottate tutte le
         misure ragionevoli per cancellare o rettificare tempestivamente i dati
         inesatti rispetto alle finalità per le quali sono trattati («esattezza»);
       </li>
       <li>
         conservati in una forma che consenta l'identificazione degli interessati
         per un arco di tempo non superiore al conseguimento delle finalità per le
         quali sono trattati; i dati personali possono essere conservati per
         periodi più lunghi a condizione che siano trattati esclusivamente a fini
         di archiviazione nel pubblico interesse, di ricerca scientifica o storica
         o a fini statistici, conformemente all'articolo 89, paragrafo 1, fatta
         salva l'attuazione di misure tecniche e organizzative adeguate richieste
         dal presente regolamento a tutela dei diritti e delle libertà
         dell'interessato («limitazione della conservazione»);
       </li>
       <li>
         trattati in maniera da garantire un'adeguata sicurezza dei dati personali,
         compresa la protezione, mediante misure tecniche e organizzative adeguate,
         da trattamenti non autorizzati o illeciti e dalla perdita, dalla
         distruzione o dal danno accidentali («integrità e riservatezza»).
       </li>
     </ol>
     <p>&nbsp;</p>
     <p>
       Le informazioni necessarie a definire i trattamenti sono richiamate
       nell’allegato 1: Registro delle attività del trattamento
     </p>
     <p>&nbsp;</p>
     <h1>
       <a href="_Toc135840209" />
       3.&nbsp; LICEITÀ DEL TRATTAMENTO
     </h1>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>
       I trattamenti di <strong>EOS SRL</strong> sono leciti in quanto ricorrono
       almeno una delle seguenti condizioni:
     </p>
     <p>
       -l'interessato ha espresso il consenso al trattamento dei propri dati
       personali per una o più specifiche finalità:
     </p>
     <p>&nbsp;</p>
     <ul>
       <li>
         il trattamento è necessario all'esecuzione di un contratto di cui
         l'interessato è parte o all'esecuzione di misure precontrattuali adottate
         su richiesta dello stesso;
       </li>
       <li>
         il trattamento è necessario per adempiere un obbligo legale al quale è
         soggetto il titolare del trattamento;
       </li>
       <li>
         il trattamento è necessario per la salvaguardia degli interessi vitali
         dell'interessato o di un'altra persona fisica;
       </li>
       <li>
         il trattamento è necessario per l'esecuzione di un compito di interesse
         pubblico o connesso all'esercizio di pubblici poteri di cui è investito il
         titolare del trattamento;
       </li>
       <li>
         il trattamento è necessario per il perseguimento del legittimo interesse
         del titolare del trattamento o di terzi, a condizione che non prevalgano
         gli interessi o i diritti e le libertà fondamentali dell'interessato che
         richiedono la protezione dei dati personali, in particolare se
         l'interessato è un minore.
       </li>
     </ul>
     <p>&nbsp;</p>
     <p>
       Il regolamento conferma che ogni trattamento deve trovare fondamento in
       un'idonea base giuridica;{" "}
       <strong>
         i fondamenti di liceità del&nbsp; trattamento&nbsp; sono&nbsp;
         indicati&nbsp; all'art.&nbsp; 6 del regolamento e coincidono, in linea di
         massima, con quelli previsti del Codice privacy - d.lgs. 196/2003
       </strong>{" "}
       (consenso, adempimento obblighi contrattuali, interessi vitali della persona
       interessata o di terzi, obblighi di legge cui è soggetto il titolare,
       interesse pubblico o esercizio di pubblici poteri, interesse legittimo
       prevalente del titolare o di terzi cui i dati vengono comunicati).
     </p>
     <p>&nbsp;</p>
     <p>
       Le specifiche di liceità sono verificate nell’allegato 1: Registro delle
       attività del trattamento
     </p>
     <p>&nbsp;</p>
     <h1>
       <a href="_Toc135840210" />
       4.&nbsp; CONDIZIONI PER IL CONSENSO
     </h1>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>
       <strong>EOS SRL</strong> basa le condizioni del consenso sui seguenti punti:
     </p>
     <p>&nbsp;</p>
     <ol>
       <li>
         Qualora il trattamento sia basato sul consenso, il titolare del
         trattamento deve essere in grado di dimostrare che l'interessato ha
         prestato il proprio consenso al trattamento dei propri dati personali.
       </li>
       <li>
         Se il consenso dell'interessato è prestato nel contesto di una
         dichiarazione scritta che riguarda anche altre questioni, la richiesta di
         consenso è presentata in modo chiaramente distinguibile dalle altre
         materie, in forma comprensibile e facilmente accessibile, utilizzando un
         linguaggio semplice e chiaro. Nessuna parte di una tale dichiarazione che
         costituisca una violazione del presente regolamento è vincolante.
       </li>
       <li>
         L'interessato ha il diritto di revocare il proprio consenso in qualsiasi
         momento. La revoca del consenso non pregiudica la liceità del trattamento
         basata sul consenso prima della revoca. Prima di esprimere il proprio
         consenso, l'interessato è informato di ciò.
         <br /> Il consenso è revocato con la stessa facilità con cui è accordato.
       </li>
       <li>
         Nel valutare se il consenso sia stato liberamente prestato, si tiene nella
         massima considerazione l'eventualità, tra le altre, che l'esecuzione di un
         contratto, compresa la prestazione di un servizio, sia condizionata alla
         prestazione del consenso al trattamento di dati personali non necessario
         all'esecuzione di tale contratto.
       </li>
     </ol>
     <p>&nbsp;</p>
     <p>
       Le specifiche sul consenso relativamente ai vari trattamenti sono indicate
       nell’allegato
     </p>
     <p>1: Registro delle attività del trattamento</p>
     <p>&nbsp;</p>
     <p>&nbsp;</p>
     <h1>
       <a href="_Toc135840211" />
       5.&nbsp; TRATTAMENTO DI DATI PARTICOLARI
     </h1>
     <p>
       <br /> Premesso che il regolamento formalizza che è vietato trattare dati
       particolari che rivelino l'origine razziale o etnica, le opinioni politiche,
       le convinzioni religiose o filosofiche, o l'appartenenza sindacale, nonché
       trattare dati genetici, dati biometrici intesi a identificare in modo
       univoco una persona fisica, dati relativi alla salute o alla vita sessuale o
       all'orientamento sessuale della persona, tale trattamento non si applica per
       EOS SRL in quanto l’azienda non tratta dati particolari o si verifica almeno
       uno dei seguenti casi:
     </p>
     <ol>
       <li>
         l'interessato ha prestato il proprio consenso esplicito al trattamento di
         tali dati particolari per una o più finalità specifiche;
       </li>
       <li>
         <strong>
           il trattamento è necessario per assolvere gli obblighi ed esercitare i
           diritti specifici del titolare del trattamento o dell'interessato in
           materia di diritto del lavoro e della sicurezza sociale e protezione
           sociale, nella misura in cui sia autorizzato dal diritto dell'Unione o
           degli Stati membri o da un contratto collettivo ai sensi del diritto
           degli Stati membri, in presenza di garanzie appropriate per i diritti
           fondamentali e gli interessi dell'interessato;
         </strong>
       </li>
       <li>
         il trattamento è necessario per tutelare un interesse vitale
         dell'interessato o di un'altra persona fisica qualora l'interessato si
         trovi nell'incapacità fisica o giuridica di prestare il proprio consenso;
       </li>
       <li>
         il trattamento è effettuato, nell'ambito delle sue legittime attività e
         con adeguate garanzie, da una fondazione, associazione o altro organismo
         senza scopo di lucro che persegua finalità politiche, filosofiche,
         religiose o sindacali, a condizione che il trattamento riguardi unicamente
         i membri, gli ex membri o le persone che hanno regolari contatti con la
         fondazione, l'associazione o l'organismo a motivo delle sue finalità e che
         i dati particolari non siano comunicati all'esterno senza il consenso
         dell'interessato;
       </li>
       <li>
         il trattamento riguarda dati particolari resi manifestamente pubblici
         dall'interessato;
       </li>
       <li>
         <strong>
           il trattamento è necessario per accertare, esercitare o difendere un
           diritto in sede giudiziaria o ogniqualvolta le autorità giurisdizionali
           esercitino le loro funzioni giurisdizionali;
         </strong>
       </li>
       <li>
         il trattamento è necessario per motivi di interesse pubblico rilevante
         sulla base del diritto dell'Unione o degli Stati membri, che deve essere
         proporzionato alla finalità perseguita, rispettare l'essenza del diritto
         alla protezione dei dati e prevedere misure appropriate e specifiche per
         tutelare i diritti fondamentali e gli interessi dell'interessato;
       </li>
       <li>
         il trattamento è necessario per finalità di medicina preventiva o di
         medicina del lavoro, valutazione della capacità lavorativa del dipendente,
         diagnosi, assistenza o terapia sanitaria o sociale ovvero gestione dei
         sistemi e servizi sanitari o sociali sulla base del diritto dell'Unione o
         degli Stati membri o conformemente al contratto con un professionista
         della sanità. In questo caso è il Medico competente dell’azienda che
         tratta i dati particolari dei dipendenti non direttamente l’azienda.
       </li>
       <li>
         il trattamento è necessario per motivi di interesse pubblico nel settore
         della sanità pubblica, quali la protezione da gravi minacce per la salute
         a carattere transfrontaliero o la garanzia di parametri elevati di qualità
         e sicurezza dell'assistenza sanitaria e dei medicinali e dei dispositivi
         medici, sulla base del diritto dell'Unione o degli Stati membri che
         prevede misure appropriate e specifiche per tutelare i diritti e le
         libertà dell'interessato, in particolare il segreto professionale;
       </li>
       <li>
         il trattamento è necessario a fini di archiviazione nel pubblico
         interesse, di ricerca scientifica o storica o a fini statistici in
         conformità dell'articolo 89, paragrafo 1, sulla base del diritto
         dell'Unione o nazionale, che è proporzionato alla finalità perseguita,
         rispetta l'essenza del diritto alla protezione dei dati e prevede misure
         appropriate e specifiche per tutelare i diritti fondamentali e gli
         interessi dell'interessato.
       </li>
     </ol>
     <p>
       Il trattamento di tali dati è indicato nell’allegato 1: Registro delle
       attività del trattamento.
     </p>
     <p>&nbsp;</p>
     <h1>
       <a href="_Toc135840212" />
       6.&nbsp; DIRITTI DELL'INTERESSATO
     </h1>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <h1>
       <a href="_Toc135840213" />
       6.1&nbsp;&nbsp;&nbsp; INFORMAZIONI DA FORNIRE QUALORA I DATI PERSONALI
       SIANO&nbsp; RACCOLTI PRESSO L'INTERESSATO
     </h1>
     <p>&nbsp;</p>
     <p>
       <strong>EOS SRL</strong> in caso di raccolta presso l'interessato di dati
       che lo riguardano, il titolare del trattamento fornisce all'interessato, nel
       momento in cui i dati personali sono ottenuti, le seguenti informazioni:
     </p>
     <p>&nbsp;</p>
     <ol>
       <li>
         l'identità e i dati di contatto del titolare del trattamento e, ove
         applicabile, del suo rappresentante;
       </li>
       <li>
         i dati di contatto del responsabile della protezione dei dati, ove
         applicabile;
       </li>
       <li>
         le finalità del trattamento cui sono destinati i dati personali nonché la
         base giuridica del trattamento;
       </li>
       <li>
         i legittimi interessi perseguiti dal titolare del trattamento o da terzi
         ove richiesto;
       </li>
       <li>
         gli eventuali destinatari o le eventuali categorie di destinatari dei dati
         personali;
       </li>
       <li>
         ove applicabile, l'intenzione del titolare del trattamento di trasferire
         dati personali a un paese terzo o a un'organizzazione internazionale e
         l'esistenza o l'assenza di una decisione di adeguatezza della Commissione
         o, il riferimento alle garanzie appropriate o opportune e i mezzi per
         ottenere una copia di tali dati o il luogo dove sono stati resi
         disponibili.
       </li>
     </ol>
     <p>&nbsp;</p>
     <p>
       In aggiunta alle informazioni di cui sopra, nel momento in cui i dati
       personali sono ottenuti, il titolare del trattamento fornisce
       all'interessato le seguenti ulteriori informazioni necessarie per garantire
       un trattamento corretto e trasparente:
     </p>
     <ol>
       <li>
         il periodo di conservazione dei dati personali oppure, se non è possibile,
         i criteri utilizzati per determinare tale periodo;
       </li>
       <li>
         l'esistenza del diritto dell'interessato di chiedere al titolare del
         trattamento l'accesso ai dati personali e la rettifica o la cancellazione
         degli stessi o la limitazione del trattamento che lo riguardano o di
         opporsi al loro trattamento, oltre al diritto alla portabilità dei dati;
       </li>
       <li>
         l'esistenza del diritto di revocare il consenso in qualsiasi momento senza
         pregiudicare la liceità del trattamento basata sul consenso prestato prima
         della revoca;
       </li>
       <li>il diritto di proporre reclamo a un'autorità di controllo;</li>
       <li>
         se la comunicazione di dati personali è un obbligo legale o contrattuale
         oppure un requisito necessario per la conclusione di un contratto, e se
         l'interessato ha l'obbligo di fornire i dati personali nonché le possibili
         conseguenze della mancata comunicazione di tali dati;
       </li>
       <li>
         l'esistenza di un processo decisionale automatizzato, compresa la
         profilazione e, almeno in tali casi, informazioni significative sulla
         logica utilizzata, nonché l'importanza e le conseguenze previste di tale
         trattamento per l'interessato
       </li>
     </ol>
     <p>&nbsp;</p>
     <h1>
       <a href="_Toc135840214" />
       6.2&nbsp;&nbsp;&nbsp; INFORMAZIONI DA FORNIRE QUALORA I DATI PERSONALI NON
       SIANO STATI OTTENUTI PRESSO L'INTERESSATO
     </h1>
     <p>&nbsp;</p>
     <p>
       Qualora i dati non siano stati ottenuti presso l'interessato, il titolare
       del trattamento fornisce all'interessato le seguenti informazioni:
     </p>
     <ol>
       <li>
         l'identità e i dati di contatto del titolare del trattamento e, ove
         applicabile, del suo rappresentante;
       </li>
       <li>
         i dati di contatto del responsabile della protezione dei dati, ove
         applicabile;
       </li>
       <li>
         le finalità del trattamento cui sono destinati i dati personali nonché la
         base giuridica del trattamento;
       </li>
       <li>le categorie di dati personali in questione;</li>
       <li>
         gli eventuali destinatari o le eventuali categorie di destinatari dei dati
         personali;
       </li>
       <li>
         ove applicabile, l'intenzione del titolare del trattamento di trasferire
         dati personali a un destinatario in un paese terzo o a un'organizzazione
         internazionale e l'esistenza o l'assenza di una decisione di adeguatezza
         della Commissione o, il riferimento alle garanzie adeguate o opportune e i
         mezzi per ottenere una copia di tali dati o il luogo dove sono stati resi
         disponibili.
       </li>
     </ol>
     <p>&nbsp;</p>
     <p>
       Oltre alle informazioni di cui sopra il titolare del trattamento dei dati di{" "}
       <strong>EOS SRL</strong> fornisce all'interessato le seguenti informazioni
       necessarie per garantire un trattamento corretto e trasparente nei confronti
       dell'interessato:
     </p>
     <p>&nbsp;</p>
     <ol>
       <li>
         il periodo di conservazione dei dati personali oppure, se non è possibile,
         i criteri utilizzati per determinare tale periodo;
       </li>
       <li>
         l'esistenza del diritto dell'interessato di chiedere al titolare del
         trattamento l'accesso ai dati personali e la rettifica o la cancellazione
         degli stessi o la limitazione del trattamento dei dati personali che lo
         riguardano e di opporsi al loro trattamento, oltre al diritto alla
         portabilità dei dati;
       </li>
       <li>
         qualora il trattamento sia relativo a dati particolari l'esistenza del
         diritto di revocare il consenso in qualsiasi momento senza pregiudicare la
         liceità del trattamento basata sul consenso prima della revoca;
       </li>
       <li>il diritto di proporre reclamo a un'autorità di controllo;</li>
       <li>
         la fonte da cui hanno origine i dati personali e, se del caso,
         l'eventualità che i dati provengano da fonti accessibili al pubblico;
       </li>
       <li>
         l'esistenza di un processo decisionale automatizzato, compresa la
         profilazione e, almeno in tali casi, informazioni significative sulla
         logica utilizzata, nonché l'importanza e le conseguenze previste di tale
         trattamento per l'interessato.
       </li>
     </ol>
     <p>Il titolare del trattamento fornisce le informazioni di cui sopra</p>
     <ol>
       <li>
         entro un termine ragionevole dall'ottenimento dei dati personali, ma al
         più tardi entro un mese, in considerazione delle specifiche circostanze in
         cui i dati personali sono trattati;
       </li>
       <li>
         nel caso in cui i dati personali siano destinati alla comunicazione con
         l'interessato, al più tardi al momento della prima comunicazione
         all'interessato;
       </li>
       <li>
         nel caso sia prevista la comunicazione ad altro destinatario, non oltre la
         prima comunicazione dei dati personali.
       </li>
     </ol>
     <p>
       Qualora il titolare del trattamento intenda trattare ulteriormente i dati
       personali per una finalità diversa da quella per cui essi sono stati
       ottenuti, prima di tale ulteriore trattamento fornisce all'interessato
       informazioni in merito a tale diversa finalità e ogni informazione
       pertinente, non fornisce informativa nella misura in cui l'interessato
       dispone già delle informazioni e negli altri casi provvede a dare
       informativa relativa a quanto sopra con ausilio di Informative (allegato
       5-6)
     </p>
     <h1>
       <a href="_Toc135840215" />
       6.3&nbsp;&nbsp;&nbsp; DIRITTO&nbsp;&nbsp; DI&nbsp;&nbsp; ACCESSO&nbsp;&nbsp;
       DELL’INTERESSATO, DIRITTO&nbsp;&nbsp; DI&nbsp;&nbsp; RETTIFICA,&nbsp;&nbsp;
       DIRITTO&nbsp;&nbsp; ALLA CANCELLAZIONE&nbsp;&nbsp; (DIRITTO&nbsp;&nbsp;
       ALL’OBLIO),&nbsp;&nbsp; DIRITTO&nbsp;&nbsp; DI&nbsp;&nbsp;
       LIMITAZIONE&nbsp;&nbsp; DI&nbsp;&nbsp; TRATTAMENTO, OBBLIGO DI NOTIFICA IN
       CASO DI RETTIFICA O CANCELLAZIONE DEI DATI PERSONALI O LIMITAZIONE DEL
       TRATTAMENTO, DIRITTO DI PORTABILITA’ DEI DATI E DIRITTO DI OPPOSIZIONE (art
       16-21 del Regolamento GDPR 679/2016)
     </h1>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>
       In relazione a quanto sopra l’interessato di <strong>EOS SRL</strong> ha
       diritto di accesso, diritto di rettifica, diritto alla cancellazione ed
       eventuale diritto di limitazione di trattamento, ovvero diritto di
       portabilità dei dati e diritto all’oblio.
     </p>
     <p>&nbsp;</p>
     <p>
       L'interessato ha il diritto di ottenere dal titolare del trattamento la
       conferma che sia o meno in corso un trattamento di dati personali che lo
       riguardano e in tal caso, di ottenere l'accesso ai dati personali e alle
       seguenti informazioni:
     </p>
     <p>&nbsp;</p>
     <ol>
       <li>le finalità del trattamento;</li>
       <li>le categorie di dati personali in questione;</li>
       <li>
         i destinatari o le categorie di destinatari a cui i dati personali sono
         stati o saranno comunicati, in particolare se destinatari di paesi terzi o
         organizzazioni internazionali;
       </li>
       <li>
         quando possibile, il periodo di conservazione dei dati personali previsto
         oppure, se non è possibile, i criteri utilizzati per determinare tale
         periodo;
       </li>
       <li>
         l'esistenza del diritto dell'interessato di chiedere al titolare del
         trattamento la rettifica o la cancellazione dei dati personali o la
         limitazione del trattamento dei dati personali che lo riguardano o di
         opporsi al loro trattamento;
       </li>
       <li>il diritto di proporre reclamo a un'autorità di controllo;</li>
       <li>
         qualora i dati non siano raccolti presso l'interessato, tutte le
         informazioni disponibili sulla loro origine;
       </li>
       <li>
         l'esistenza di un processo decisionale automatizzato, compresa la
         profilazione e, almeno in tali casi, informazioni significative sulla
         logica utilizzata, nonché l'importanza e le conseguenze previste di tale
         trattamento per l'interessato.
       </li>
     </ol>
     <p>&nbsp;</p>
     <p>
       Qualora i dati personali siano trasferiti a un paese terzo o a
       un'organizzazione internazionale, l'interessato ha il diritto di essere
       informato dell'esistenza di garanzie adeguate relative al trasferimento.
     </p>
     <p>&nbsp;</p>
     <p>
       Il titolare del trattamento fornisce una copia dei dati personali oggetto di
       trattamento. In caso di ulteriori copie richieste dall'interessato, il
       titolare del trattamento può addebitare un contributo spese ragionevole
       basato sui costi amministrativi. Se l'interessato presenta la richiesta
       mediante mezzi elettronici e salvo indicazione diversa dell'interessato, le
       informazioni sono fornite in un formato elettronico di uso comune.
     </p>
     <p>
       L'interessato ha il diritto di ottenere dal titolare del trattamento la
       rettifica dei dati personali inesatti che lo riguardano senza ingiustificato
       ritardo. Tenuto conto delle finalità del trattamento, l'interessato ha il
       diritto di ottenere l'integrazione dei dati personali incompleti, anche
       fornendo una dichiarazione integrativa
     </p>
     <p>&nbsp;</p>
     <p>
       L'interessato ha il diritto di ottenere dal titolare del trattamento la
       cancellazione dei dati personali che lo riguardano senza ingiustificato
       ritardo e il titolare del trattamento ha l'obbligo di cancellare senza
       ingiustificato ritardo i dati personali, se sussiste uno dei motivi
       seguenti:
     </p>
     <p>&nbsp;</p>
     <ol>
       <li>
         i dati personali non sono più necessari rispetto alle finalità per le
         quali sono stati raccolti o altrimenti trattati;
       </li>
       <li>
         l'interessato revoca il consenso e se non sussiste altro fondamento
         giuridico per il trattamento;
       </li>
       <li>
         l'interessato si oppone al trattamento, e non sussiste alcun motivo
         legittimo prevalente per procedere al trattamento, oppure si oppone al
         trattamento
       </li>
       <li>i dati personali sono stati trattati illecitamente;</li>
     </ol>
     <p>&nbsp;</p>
     <p>
       L'interessato ha il diritto di ottenere dal titolare del trattamento la
       limitazione del trattamento quando ricorre una delle seguenti ipotesi:
     </p>
     <p>&nbsp;</p>
     <ol>
       <li>
         l'interessato contesta l'esattezza dei dati personali, per il periodo
         necessario al titolare del trattamento per verificare l'esattezza di tali
         dati personali;
       </li>
       <li>
         il trattamento è illecito e l'interessato si oppone alla cancellazione dei
         dati personali e chiede invece che ne sia limitato l'utilizzo;
       </li>
       <li>
         benché il titolare del trattamento non ne abbia più bisogno ai fini del
         trattamento, i dati personali sono necessari all'interessato per
         l'accertamento, l'esercizio o la difesa di un diritto in sede giudiziaria;
       </li>
       <li>
         l'interessato si è opposto al trattamento, in attesa della verifica in
         merito all'eventuale prevalenza dei motivi legittimi del titolare del
         trattamento rispetto a quelli dell'interessato.
       </li>
     </ol>
     <p>&nbsp;</p>
     <p>
       Il titolare del trattamento comunica a ciascuno dei destinatari cui sono
       stati trasmessi i dati personali le eventuali rettifiche o cancellazioni o
       limitazioni del trattamento effettuate, salvo che ciò si riveli impossibile
       o implichi uno sforzo sproporzionato.
     </p>
     <p>&nbsp;</p>
     <p>
       Il titolare del trattamento comunica all'interessato tali destinatari
       qualora l'interessato lo richieda.
     </p>
     <p>&nbsp;</p>
     <p>
       L'interessato ha il diritto di <u>opporsi</u> in qualsiasi momento, per
       motivi connessi alla sua situazione particolare, al trattamento dei dati
       personali che lo riguardano compresa la profilazione sulla base di tali
       disposizioni. Il titolare del trattamento si astiene dal trattare
       ulteriormente i dati personali salvo che egli dimostri l'esistenza di motivi
       legittimi cogenti per procedere al trattamento che prevalgono sugli
       interessi, sui diritti e sulle libertà dell'interessato oppure per
       l'accertamento, l'esercizio o la difesa di un diritto in sede giudiziaria.
     </p>
     <p>&nbsp;</p>
     <p>
       Qualora i dati personali siano trattati per finalità di marketing diretto,
       l'interessato ha il diritto di opporsi in qualsiasi momento al trattamento
       dei dati personali che lo riguardano effettuato per tali finalità, compresa
       la profilazione nella misura in cui sia connessa a tale marketing diretto.
       Qualora l'interessato si opponga al trattamento per finalità di marketing
       diretto, i dati personali non sono più oggetto di trattamento per tali
       finalità.
     </p>
     <p>&nbsp;</p>
     <h1>
       <a href="_Toc135840216" />
       7.&nbsp; RUOLI, COMPITI DELLE&nbsp; FIGURE&nbsp; PREVISTE&nbsp; PER&nbsp;
       LA&nbsp; SICUREZZA&nbsp; DEI&nbsp; DATI PERSONALI
     </h1>
     <h2>
       <a href="_Toc135840217" />
       7.1&nbsp;&nbsp; TITOLARE DEL TRATTAMENTO
     </h2>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>
       Tenuto conto della natura, dell'ambito di applicazione, del contesto e delle
       finalità del trattamento, nonché dei rischi aventi probabilità e gravità
       diverse per i diritti e le libertà delle persone fisiche, il titolare del
       trattamento di <strong>EOS SRL</strong> mette in atto misure tecniche e
       organizzative adeguate per garantire, ed essere in grado di dimostrare, che
       il trattamento è effettuato conformemente al presente documento. Dette
       misure sono riesaminate e aggiornate qualora necessario ed includono
       l'attuazione di politiche adeguate in materia di protezione dei dati.
     </p>
     <p>
       Tenendo conto dello stato dell'arte e dei costi di attuazione, nonché della
       natura, dell'ambito di applicazione, del contesto e delle finalità del
       trattamento, come anche dei rischi aventi probabilità e gravità diverse per
       i diritti e le libertà delle persone fisiche costituiti dal trattamento, sia
       al momento di determinare i mezzi del trattamento sia all'atto del
       trattamento stesso il titolare del trattamento di <strong>EOS SRL</strong>{" "}
       mette in atto misure tecniche e organizzative adeguate, volte ad attuare in
       modo efficace i principi di protezione dei dati e a integrare nel
       trattamento le necessarie garanzie al fine di soddisfare i requisiti del
       presente regolamento e tutelare i diritti degli interessati.
     </p>
     <p>&nbsp;</p>
     <p>
       Il titolare del trattamento dati di <strong>EOS SRL</strong> mette in atto
       misure tecniche e organizzative adeguate per garantire che siano trattati,
       per impostazione predefinita, solo idati personali necessari per ogni
       specifica finalità del trattamento. Tale obbligo vale per la quantità dei
       dati personali raccolti, la portata del trattamento, il periodo di
       conservazione e l'accessibilità. In particolare, dette misure garantiscono
       che, per impostazione predefinita, non siano resi accessibili dati personali
       a un numero indefinito di persone fisiche senza l'intervento della persona
       fisica.
     </p>
     <p>
       <strong>Il Titolare del trattamento dei dati è EOS SRL.</strong>
     </p>
     <p>
       <strong>
         <br />{" "}
       </strong>
     </p>
     <h2>
       <a href="_Toc135840218" />
       7.2&nbsp;&nbsp; RESPONSABILE DEL TRATTAMENTO
     </h2>
     <p>
       <br /> Qualora un trattamento debba essere effettuato per conto del titolare
       del trattamento di <strong>EOS SRL</strong>, quest'ultimo ricorre unicamente
       a responsabili del trattamento che presentino garanzie sufficienti per
       mettere in atto misure tecniche e organizzative adeguate in modo tale che il
       trattamento soddisfi i requisiti del presente regolamento e garantisca la
       tutela dei diritti dell'interessato.
     </p>
     <p>
       Il responsabile del trattamento non ricorre a un altro responsabile senza
       previa autorizzazione scritta, specifica o generale, del titolare del
       trattamento.
     </p>
     <p>
       Nel caso di autorizzazione scritta generale, il responsabile del trattamento
       informa il titolare del trattamento di eventuali modifiche previste
       riguardanti l'aggiunta o la sostituzione di altri responsabili del
       trattamento, dando così al titolare del trattamento l'opportunità di opporsi
       a tali modifiche.
     </p>
     <p>
       I trattamenti da parte di un responsabile del trattamento sono disciplinati
       da un contratto (<u>delega</u>) che vincoli il responsabile del trattamento
       al titolare del trattamento e che stipuli la materia disciplinata e la
       durata del trattamento, la natura e la finalità del trattamento, il tipo di
       dati personali e le categorie di interessati, gli obblighi e i diritti del
       titolare del trattamento.
     </p>
     <p>
       Il contratto (delega) prevede, in particolare, che il responsabile del
       trattamento:
     </p>
     <ol>
       <li>
         tratti i dati personali soltanto su istruzione documentata del titolare
         del trattamento, anche in caso di trasferimento di dati personali verso un
         paese terzo o un'organizzazione internazionale, salvo che lo richieda il
         diritto dell'Unione o nazionale cui è soggetto il responsabile del
         trattamento; in tal caso, il responsabile del trattamento informa il
         titolare del trattamento circa tale obbligo giuridico prima del
         trattamento, a meno che il diritto vieti tale informazione per rilevanti
         motivi di interesse pubblico;
       </li>
       <li>
         garantisca che le persone autorizzate al trattamento dei dati personali si
         siano impegnate alla riservatezza o abbiano un adeguato obbligo legale di
         riservatezza;
       </li>
       <li>adotti tutte le misure richieste</li>
       <li>
         rispetti le condizioni previste per ricorrere a un altro responsabile del
         trattamento;
       </li>
       <li>
         tenendo conto della natura del trattamento, assista il titolare del
         trattamento con misure tecniche e organizzative adeguate, nella misura in
         cui ciò sia possibile, al fine di soddisfare l'obbligo del titolare del
         trattamento di dare seguito alle richieste per l'esercizio dei diritti
         dell’interessato;
       </li>
       <li>
         assista il titolare del trattamento nel garantire il rispetto degli
         obblighi sicurezza e consultazione preventiva, tenendo conto della natura
         del trattamento e delle informazioni a disposizione del responsabile del
         trattamento;
       </li>
       <li>
         su scelta del titolare del trattamento, cancelli o gli restituisca tutti i
         dati personali dopo che è terminata la prestazione dei servizi relativi al
         trattamento e cancelli le copie esistenti;
       </li>
       <li>
         metta a disposizione del titolare del trattamento tutte le informazioni
         necessarie per dimostrare il rispetto degli obblighi di cui al presente
         articolo e consenta e contribuisca alle attività di revisione, comprese le
         ispezioni, realizzati dal titolare del trattamento o da un altro soggetto
         da questi incaricato
       </li>
     </ol>
     <p>&nbsp;</p>
     <p>
       Si rimanda alla Allegato 2: Nomina del responsabile del trattamento dei dati
       personali
     </p>
     <p>&nbsp;</p>
     <p>&nbsp;</p>
     <h1>
       <a href="_Toc135840219" />
       8.&nbsp; REGISTRI DELLE ATTIVITÀ DI TRATTAMENTO
     </h1>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>
       Il titolare del trattamento ed ogni responsabile o gli incaricati tengono un
       registro delle attività di trattamento svolte sotto la propria
       responsabilità. Tale registro contiene tutte le seguenti informazioni:
     </p>
     <ol>
       <li>
         il nome e i dati di contatto del titolare del trattamento e, ove
         applicabile, del contitolare del trattamento, del rappresentante del
         titolare del trattamento e del responsabile della protezione dei dati DPO
         se necessario.
       </li>
       <li>le finalità del trattamento;</li>
       <li>
         una descrizione delle categorie di interessati e delle categorie di dati
         personali
       </li>
       <li>
         le categorie di destinatari a cui i dati personali sono stati o saranno
         comunicati, compresi i destinatari di paesi terzi od organizzazioni
         internazionali;
       </li>
       <li>
         ove applicabile, i trasferimenti di dati personali verso un paese terzo o
         un'organizzazione internazionale, compresa l'identificazione del paese
         terzo o dell'organizzazione internazionale
       </li>
       <li>
         ove possibile, i termini ultimi previsti per la cancellazione delle
         diverse categorie di dati;
       </li>
       <li>
         ove possibile, una descrizione generale delle misure di sicurezza tecniche
         e organizzative Si rimanda ai registri riportati nell’allegato 1 :
         Registro delle attività del trattamento
       </li>
     </ol>
     <p>&nbsp;</p>
     <p>&nbsp;</p>
     <p>&nbsp;</p>
     <h1>
       <a href="_Toc135840220" />
       9.&nbsp; SICUREZZA DEL TRATTAMENTO
     </h1>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>
       Tenendo conto dello stato dell'arte e dei costi di attuazione, nonché della
       natura, dell'oggetto, del contesto e delle finalità del trattamento, come
       anche del rischio di varia probabilità e gravità per i diritti e le libertà
       delle persone fisiche, il titolare del trattamento di
       <strong>EOS SRL</strong> ed i responsabili del trattamento mettono in atto
       misure tecniche e organizzative adeguate per garantire un livello di
       sicurezza adeguato al rischio, che comprendono, tra le altre, se del caso:
     </p>
     <ol>
       <li>
         la capacità di assicurare su base permanente la riservatezza, l'integrità,
         la disponibilità e la resilienza dei sistemi e dei servizi di trattamento;
       </li>
       <li>
         la capacità di ripristinare tempestivamente la disponibilità e l'accesso
         dei dati personali in caso di incidente fisico o tecnico;
       </li>
       <li>
         una procedura per testare, verificare e valutare regolarmente l'efficacia
         delle misure tecniche e organizzative al fine di garantire la sicurezza
         del trattamento.
       </li>
     </ol>
     <p>
       Nel valutare l'adeguato livello di sicurezza, il titolare del trattamento
       dei dati ed i responsabili del trattamento tengono conto in special modo dei
       rischi presentati dal trattamento che derivano in particolare dalla
       distruzione, dalla perdita, dalla modifica, dalla divulgazione non
       autorizzata o dall'accesso, in modo accidentale o illegale, a dati personali
       trasmessi, conservati o comunque trattati.
     </p>
     <p>
       Le misure di sicurezza dei trattamenti sono riportate nell’allegato 1 :
       Registro delle attività del trattamento.
     </p>
     <p>&nbsp;</p>
     <h1>
       <a href="_Toc135840221" />
       10.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
       NOTIFICA DI&nbsp; UNA&nbsp; VIOLAZIONE&nbsp; DEI&nbsp; DATI&nbsp;
       PERSONALI&nbsp; ALL'AUTORITÀ&nbsp; DI CONTROLLO E COMUNICAZIONE DI UNA
       VIOLAZIONE DEI DATI PERSONALI ALL'INTERESSATO
     </h1>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>
       In caso di grave violazione dei dati personali, il titolare del trattamento
       dati, notifica la violazione all'autorità di controllo competente senza
       ingiustificato ritardo e, ove possibile, entro 72 ore dal momento in cui ne
       è venuto a conoscenza, a meno che sia improbabile che la violazione dei dati
       personali presenti un rischio per i diritti e le libertà delle persone
       fisiche.
     </p>
     <p>&nbsp;</p>
     <p>
       Qualora la notifica all'autorità di controllo non sia effettuata entro 72
       ore, è corredata dei motivi del ritardo.
     </p>
     <p>&nbsp;</p>
     <p>
       Il responsabile del trattamento informa il titolare del trattamento senza
       ingiustificato ritardo dopo essere venuto a conoscenza della violazione.
     </p>
     <p>&nbsp;</p>
     <p>La notifica contiene:</p>
     <p>&nbsp;</p>
     <ol>
       <li>
         descrizione della natura della violazione dei dati personali compresi, ove
         possibile, le categorie e il numero approssimativo di interessati in
         questione nonché le categorie e il numero approssimativo di registrazioni
         dei dati personali in questione;
       </li>
       <li>
         comunicazione del nome e dei dati di contatto del responsabile della
         protezione dei dati o di altro punto di contatto presso cui ottenere più
         informazioni;
       </li>
       <li>
         descrizione delle probabili conseguenze della violazione dei dati
         personali;
       </li>
       <li>
         descrizione delle misure adottate o di cui si propone l'adozione da parte
         del titolare del trattamento per porre rimedio alla violazione dei dati
         personali e anche, se del caso, per attenuarne i possibili effetti
         negativi.
       </li>
     </ol>
     <p>&nbsp;</p>
     <p>
       Il titolare del trattamento dati, documenta qualsiasi violazione dei dati
       personali, comprese le circostanze a essa relative, le sue conseguenze e i
       provvedimenti adottati per porvi rimedio in apposito registro Data Breach.
       Tale documentazione consente all'autorità di controllo di verificare il
       rispetto del REGOLAMENTO (UE) 2016/679 DEL PARLAMENTO EUROPEO E DEL
       CONSIGLIO del 27 aprile 2016
     </p>
     <p>Si rimanda all’istruzione operativa data breach.</p>
     <p>&nbsp;</p>
     <p>
       Non è richiesta la comunicazione all'interessato se è soddisfatta una delle
       seguenti condizioni:
     </p>
     <ol>
       <li>
         il titolare del trattamento ha messo in atto le misure tecniche e
         organizzative adeguate di protezione e tali misure erano state applicate
         ai dati personali oggetto della violazione, in particolare quelle
         destinate a rendere i dati personali incomprensibili a chiunque non sia
         autorizzato ad accedervi, quali la cifratura;
       </li>
       <li>
         il titolare del trattamento ha successivamente adottato misure atte a
         scongiurare il sopraggiungere di un rischio elevato per i diritti e le
         libertà degli interessati
       </li>
       <li>
         detta comunicazione richiederebbe sforzi sproporzionati. In tal caso, si
         procede invece a una comunicazione pubblica o a una misura simile, tramite
         la quale gli interessati sono informati con analoga efficacia.
       </li>
     </ol>
     <p>&nbsp;</p>
     <p>&nbsp;</p>
     <h1>
       <a href="_Toc135840222" />
       11.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
       VALUTAZIONE D'IMPATTO SULLA PROTEZIONE DEI DATI
     </h1>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>
       Quando un tipo di trattamento, allorché prevede in particolare l'uso di
       nuove tecnologie, considerati la natura, l'oggetto, il contesto e le
       finalità del trattamento, può presentare un rischio elevato per i diritti e
       le libertà delle persone fisiche, il titolare del trattamento deve
       effettuare, prima di procedere al trattamento, una valutazione dell'impatto
       dei trattamenti previsti sulla protezione dei dati personali.
     </p>
     <p>
       La valutazione d'impatto sulla protezione è richiesta in particolare nei
       casi seguenti:
     </p>
     <ol>
       <li>
         una valutazione sistematica e globale di aspetti personali relativi a
         persone fisiche, basata su un trattamento automatizzato, compresa la
         profilazione, e sulla quale si fondano decisioni che hanno effetti
         giuridici o incidono in modo analogo significativamente su dette persone
         fisiche;
       </li>
       <li>il trattamento, su larga scala, di categorie dati particolari.</li>
       <li>
         la sorveglianza sistematica su larga scala di una zona accessibile al
         pubblico.
       </li>
     </ol>
     <p>&nbsp;</p>
     <p>
       L’Azienda anche se non rientra nelle casistiche sopracitate, ha provveduto
       redigere una valutazione dei rischi con il metodo sotto riportato.
     </p>
     <p>&nbsp;</p>
     <p>La valutazione contiene:</p>
     <ol>
       <li>
         una descrizione sistematica dei trattamenti previsti e delle finalità del
         trattamento, compreso, ove applicabile, l'interesse legittimo perseguito
         dal titolare del trattamento;
       </li>
       <li>
         una valutazione della necessità e proporzionalità dei trattamenti in
         relazione alle finalità;
       </li>
       <li>
         una valutazione dei rischi per i diritti e le libertà degli interessati;
       </li>
       <li>
         le misure previste per affrontare i rischi, includendo le garanzie, le
         misure di sicurezza e i meccanismi per garantire la protezione dei dati
         personali e dimostrare la conformità al presente regolamento, tenuto conto
         dei diritti e degli interessi legittimi degli interessati e delle altre
         persone in questione.
       </li>
     </ol>
     <p>
       Nel valutare l'impatto del trattamento effettuato dai relativi titolari o
       responsabili è tenuto in debito conto il rispetto da parte di questi ultimi
       dei codici di condotta approvati
     </p>
     <p>&nbsp;</p>
     <p>
       Il titolare del trattamento raccoglie le opinioni degli interessati o dei
       loro rappresentanti sul trattamento previsto nell’ambito della valutazione.
     </p>
     <p>&nbsp;</p>
     <p>
       Il titolare del trattamento procede a un riesame per valutare se il
       trattamento dei dati personali sia effettuato conformemente alla valutazione
       d'impatto sulla protezione dei dati almeno quando insorgono variazioni del
       rischio rappresentato dalle attività relative al trattamento.
     </p>
     <table>
       <tbody>
         <tr>
           <td width={45}>&nbsp;</td>
         </tr>
         <tr>
           <td>&nbsp;</td>
           <td>&nbsp;</td>
         </tr>
       </tbody>
     </table>
     <p>&nbsp;</p>
     <p>
       <strong>EOS SRL </strong>ha identificato tutti i rischi aziendali
       analizzando i propri trattamenti e suddividendo i rischi &nbsp;in:
     </p>
     <p>&nbsp;</p>
     <ul>
       <li>
         <strong>Rischi</strong> <strong>nei</strong> <strong>confronti</strong>{" "}
         <strong>degli</strong>
         <strong>individui</strong> (es. <u>rischi</u> <u>per</u> <u>la</u>{" "}
         <u>sicurezza</u> <u>fisica</u>,<u>rischi</u> <u>material</u>i (es. perdite
         finanziarie causate da frodi o dati inesatti i violazione della sicurezza)
         e <u>risch</u>i <u>moral</u>i (es. preoccupazione per la diffusione di una
         notizia riservata o per un’intrusione non prevista)
       </li>
       <li>
         <strong>Rischi nei confronti dell’organizzazione </strong>possono
         consistere in danni alla reputazione con conseguente perdita di business o
         in costi finanziari dovuti alla una violazione di dati;
       </li>
       <li>
         <strong>Rischi di conformità </strong>(es. al GDPR, alle indicazioni del
         garante, ) possono comportare multe o penali
       </li>
     </ul>
     <p>
       <strong>EOS SRL </strong>ha quindi effettuato tale analisi dei rischi per i
       trattamenti riportati in Allegato 1, Si riserva comunque in futuro di
       ampliare l’analisi ad altri trattamenti che dovessero essere implementati in
       azienda.
     </p>
     <p>
       Con questa fase sono identificati i rischi potenziali nei quali{" "}
       <strong>EOS SRL </strong>potrebbe incorrere.
     </p>
     <p>&nbsp;</p>
     <p>
       Un metodo semplice ed efficace per poter effettuare la stima/analisi del
       rischio e definirne l’accettabilità o meno, può essere la matrice, in cui le
       due variabili, gravità dell’impatto (G) e la probabilità (P) e la
       Proporzionalità delle misure (I); ciascun parametro viene categorizzato (in
       4 livelli), ottenendo così un prodotto dei tre parametri che riassume il
       livello di rischio di ogni situazione individuata.
     </p>
     <p>Il prodotto delle variabili</p>
     <p>R = G*P*I &nbsp;quantificherà la stima del rischio.</p>
     <p>&nbsp;</p>
     <p>Di seguito riportiamo le tabelle dei tre parametri:</p>
     <p>&nbsp;</p>
     <p>
       <strong>Tabella delle probabilità di accadimento dell’evento (P)</strong>
     </p>
     <table>
       <tbody>
         <tr>
           <td width={521}>
             <p>
               <strong>Criteri</strong>
             </p>
           </td>
           <td width={98}>
             <p>
               <strong>Livello</strong>
             </p>
           </td>
           <td width={60}>
             <p>
               <strong>Valore</strong>
             </p>
           </td>
         </tr>
         <tr>
           <td width={521}>
             <p>
               L’evento rilevato può provocare un Impatto per la concomitanza di
               più eventi poco probabili e indipendenti fra loro. Non sono noti
               episodi già verificatisi.
             </p>
           </td>
           <td width={98}>
             <p>Improbabil e</p>
           </td>
           <td width={60}>
             <p>
               <strong>&nbsp;</strong>
             </p>
             <p>
               <strong>1</strong>
             </p>
           </td>
         </tr>
         <tr>
           <td width={521}>
             <p>
               L’Evento&nbsp;&nbsp; rilevato&nbsp;&nbsp; può&nbsp;&nbsp;
               provocare&nbsp;&nbsp; un&nbsp;&nbsp; Impatto&nbsp;&nbsp;
               solo&nbsp;&nbsp; in&nbsp;&nbsp; circostanze
             </p>
             <p>
               sfortunate di eventi. Sono noti solo rarissimi episodi già
               verificatisi negli ultimi 5 anni
             </p>
           </td>
           <td width={98}>
             <p>Poco Probabile</p>
           </td>
           <td width={60}>
             <p>
               <strong>&nbsp;</strong>
             </p>
             <p>
               <strong>2</strong>
             </p>
           </td>
         </tr>
         <tr>
           <td width={521}>
             <p>
               L’Evento &nbsp;rilevato &nbsp;può &nbsp;provocare &nbsp;un
               &nbsp;Impatto &nbsp;anche &nbsp;se &nbsp;non &nbsp;in &nbsp;modo
             </p>
             <p>
               automatico o diretto. E’ noto qualche episodio già verificatosi
               negli ultimi 5 anni
             </p>
           </td>
           <td width={98}>
             <p>
               <strong>&nbsp;</strong>
             </p>
             <p>Probabile</p>
           </td>
           <td width={60}>
             <p>
               <strong>&nbsp;</strong>
             </p>
             <p>
               <strong>3</strong>
             </p>
           </td>
         </tr>
         <tr>
           <td width={521}>
             <p>
               Esiste &nbsp;una &nbsp;correlazione &nbsp;diretta &nbsp;tra
               &nbsp;evento &nbsp;ed &nbsp;il &nbsp;verificarsi &nbsp;dell’Impatto
               ipotizzato. Si sono già verificati danni per lo stesso pericolo
               rilevato negli ultimi 5 anni.
             </p>
           </td>
           <td width={98}>
             <p>Altamente Probabile</p>
           </td>
           <td width={60}>
             <p>
               <strong>&nbsp;</strong>
             </p>
             <p>
               <strong>4</strong>
             </p>
           </td>
         </tr>
       </tbody>
     </table>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>
       <strong>Tabella Gravità (G)</strong>
     </p>
     <table>
       <tbody>
         <tr>
           <td width={518}>
             <p>
               <strong>Criteri</strong>
             </p>
           </td>
           <td width={94}>
             <p>
               <strong>Livello</strong>
             </p>
           </td>
           <td width={67}>
             <p>
               <strong>Valore</strong>
             </p>
           </td>
         </tr>
         <tr>
           <td width={518}>
             <p>L’impatto risulta pressochè irrilevante</p>
           </td>
           <td width={94}>
             <p>Lieve</p>
           </td>
           <td width={67}>
             <p>
               <strong>1</strong>
             </p>
           </td>
         </tr>
         <tr>
           <td width={518}>
             <p>L’impatto comporta una perdita contenuta</p>
           </td>
           <td width={94}>
             <p>Medio</p>
           </td>
           <td width={67}>
             <p>
               <strong>2</strong>
             </p>
           </td>
         </tr>
         <tr>
           <td width={518}>
             <p>L’impatto comporta una perdita significativa</p>
           </td>
           <td width={94}>
             <p>Grave</p>
           </td>
           <td width={67}>
             <p>
               <strong>3</strong>
             </p>
           </td>
         </tr>
         <tr>
           <td width={518}>
             <p>
               L’impatto&nbsp;&nbsp; comporta&nbsp;&nbsp; una perdita con gravi
               effetti sulla stabilità
             </p>
             <p>
               economico finanziaria aziendale e sulle libertà e i diritti degli
               interessati
             </p>
           </td>
           <td width={94}>
             <p>Gravissimo</p>
           </td>
           <td width={67}>
             <p>
               <strong>4</strong>
             </p>
           </td>
         </tr>
       </tbody>
     </table>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>
       <strong>Tabella necessità e proporzionalità (I)</strong>
     </p>
     <table>
       <tbody>
         <tr>
           <td width={474}>
             <p>
               <strong>Criteri</strong>
             </p>
           </td>
           <td width={112}>
             <p>
               <strong>Livello</strong>
             </p>
           </td>
           <td width={76}>
             <p>
               <strong>Valore</strong>
             </p>
           </td>
         </tr>
         <tr>
           <td width={474}>
             <p>
               Le misure intraprese sono coerenti e proporzionali e secondo
               necessità rispetto al trattamento oggetto
             </p>
           </td>
           <td width={112}>
             <p>Conformi</p>
           </td>
           <td width={76}>
             <p>1</p>
           </td>
         </tr>
         <tr>
           <td width={474}>
             <p>
               Le misure intraprese sono lievemente superiori/inferiori e comunque
               sproporzionate e quindi oltremodo necessità rispetto al trattamento
               oggetto
             </p>
           </td>
           <td width={112}>
             <p>Non proporzionate</p>
           </td>
           <td width={76}>
             <p>2</p>
           </td>
         </tr>
         <tr>
           <td width={474}>
             <p>
               Le misure intraprese sono inferiori, comunque non proporzionali ed
               insufficienti rispetto alla necessità del l trattamento oggetto
             </p>
           </td>
           <td width={112}>
             <p>Inferiori</p>
           </td>
           <td width={76}>
             <p>3</p>
           </td>
         </tr>
       </tbody>
     </table>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>
       <strong>Classificazione dei livelli di rischio</strong>
     </p>
     <p>
       Il processo di valutazione che porta ad associare ad uno scenario talvolta
       complesso, un giudizio espresso in forma numerica, risente ovviamente di
       tutta una serie di incertezze ed approssimazioni. La molteplicità dei
       fattori che concorrono a definire una condizione di rischio, porta
       necessariamente a categorizzare le variabili del problema e a risentire
       delle incertezze nella definizione delle stesse.
     </p>
     <p>
       Per tale motivo, il livello di rischio calcolato come di seguito serve ad
       individuare una classe di rischio per ogni situazione analizzata
     </p>
     <p>&nbsp;</p>
     <p>R = G*P *I</p>
     <p>
       Il giudizio espresso quindi in&nbsp; forma lessicale è categorizzato in tre
       livelli a seconda
     </p>
     <p>dell’importanza del rischio stesso.</p>
     <table>
       <tbody>
         <tr>
           <td colSpan={3} width={635}>
             <p>
               <strong>Stima del rischio (R</strong>
               <strong>K </strong>
               <strong>= P x I)</strong>
             </p>
           </td>
         </tr>
         <tr>
           <td width={211}>
             <p>
               <strong>R 8</strong>
             </p>
           </td>
           <td width={212}>
             <p>
               <strong>8 </strong>&lt; <strong>R </strong>&lt; <strong>24</strong>
             </p>
           </td>
           <td width={212}>
             <p>
               <strong>R&nbsp; 24</strong>
             </p>
           </td>
         </tr>
         <tr>
           <td width={211}>
             <p>
               <strong>Rischio BASSO (B)</strong>
             </p>
           </td>
           <td width={212}>
             <p>
               <strong>Rischio MEDIO (M)</strong>
             </p>
           </td>
           <td width={212}>
             <p>
               <strong>Rischio ALTO (A)</strong>
             </p>
           </td>
         </tr>
         <tr>
           <td width={211}>
             <p>
               <strong>Area Accettabile</strong>
             </p>
           </td>
           <td width={212}>
             <p>
               <strong>Area medio</strong>
             </p>
           </td>
           <td width={212}>
             <p>
               <strong>A</strong>
               <strong>r</strong>
               <strong>e</strong>
               <strong>a</strong>
               <strong>I</strong>
               <strong>n</strong>
               <strong>a</strong>
               <strong>cc</strong>
               <strong>e</strong>
               <strong>tt</strong>
               <strong>a</strong>
               <strong>b</strong>
               <strong>i</strong>
               <strong>l</strong>
               <strong>e</strong>
             </p>
           </td>
         </tr>
       </tbody>
     </table>
     <p>&nbsp;</p>
     <p>
       <strong>Area Rischio basso (accettabile)</strong>
     </p>
     <p>
       In questo caso il rischio è così basso da essere trascurabile e non deve
       essere necessariamente perseguito il controllo del rischio o attivate azioni
       correttive.
     </p>
     <p>&nbsp;</p>
     <p>
       <strong>Area Rischio medio</strong>
     </p>
     <p>
       All’interno di quest’area l’azienda si prodiga per ridurre per quanto
       possibile i rischi, ponderandone il rischio residuo e la praticabilità di
       un’ulteriore riduzione, tramite:
     </p>
     <ul>
       <li>la praticabilità tecnica;</li>
       <li>la praticabilità</li>
     </ul>
     <p>
       <u>La praticabilità tecnica ed organizzativa</u> si riferisce alla capacità
       di ridurre il rischio con misure tecniche ed organizzative ,
       indipendentemente dal costo.
     </p>
     <p>
       <u>La praticabilità economica</u> fa riferimento alla capacità di ridurre il
       rischio senza rendere l’intervento economicamente inaccettabile, considerato
       lo stato dell’area generalmente accettata. Vicino all’area accettabile, si
       ritiene sufficiente il rapporto rischi/benefici.
     </p>
     <p>
       Le azioni, se ritenute necessarie, andranno inserite all’interno del piano
       di miglioramento o nella modulistica delle azioni correttive/preventive.
     </p>
     <p>&nbsp;</p>
     <p>
       <strong>Area</strong> <strong>Rischio</strong>{" "}
       <strong>alto (inaccettabile)</strong>
     </p>
     <p>
       Il rischio è così alto da non poter essere tollerabile, e dev’essere
       perseguito il suo controllo per una sua eliminazione o riduzione a livelli
       accettabili. Sono quindi prescritte azioni correttive o di miglioramento a
       breve termine.
     </p>
     <p>
       Le azioni andranno inserite all’interno del piano di miglioramento e
       documentate
     </p>
     <p>&nbsp;</p>
     <p>
       <strong>Valutazione</strong>
     </p>
     <p>
       Se il risultato dell’analisi ricade nell’area di accettabilità, verrà
       definito un obiettivo per renderlo tale.
     </p>
     <p>&nbsp;</p>
     <p>
       <strong>Azioni</strong>
     </p>
     <p>È costituito dalle fasi:</p>
     <ul>
       <li>predisposizione delle azioni correttive (interventi migliorativi);</li>
       <li>monitoraggio del rischio</li>
     </ul>
     <p>
       Le azioni correttive saranno rivolte, ove possibile, all’eliminazione del
       pericolo, ovvero alla riduzione del rischio fino ad un livello accettabile,
       tenuto sempre presente la praticabilità tecnica ed economica.
     </p>
     <p>
       I tempi di attuazione delle azioni correttive saranno individuati in base al
       livello di rischio stimato in:
     </p>
     <table>
       <tbody>
         <tr>
           <td width={184}>
             <p>
               <strong>L</strong>
               <strong>I</strong>
               <strong>V</strong>
               <strong>E</strong>
               <strong>L</strong>
               <strong>L</strong>
               <strong>O</strong>
               <strong>D</strong>
               <strong>I</strong>
               <strong>R</strong>
               <strong>I</strong>
               <strong>S</strong>
               <strong>C</strong>
               <strong>H</strong>
               <strong>I</strong>
               <strong>O</strong>
             </p>
           </td>
           <td colSpan={5} width={473}>
             <p>
               <strong>T</strong>
               <strong>E</strong>
               <strong>M</strong>
               <strong>P</strong>
               <strong>I</strong>
               <strong>D</strong>
               <strong>I</strong>
               <strong>A</strong>
               <strong>TT</strong>
               <strong>U</strong>
               <strong>A</strong>
               <strong>Z</strong>
               <strong>I</strong>
               <strong>O</strong>
               <strong>N</strong>
               <strong>E</strong>
               <strong>D</strong>
               <strong>E</strong>
               <strong>LL</strong>
               <strong>E</strong>
               <strong>A</strong>
               <strong>Z</strong>
               <strong>I</strong>
               <strong>O</strong>
               <strong>N</strong>
               <strong>I</strong>
               <strong>C</strong>
               <strong>O</strong>
               <strong>R</strong>
               <strong>R</strong>
               <strong>E</strong>
               <strong>T</strong>
               <strong>T</strong>
               <strong>I</strong>
               <strong>V</strong>
               <strong>E</strong>
             </p>
             <p>
               <strong>(misure di sicurezza)</strong>
             </p>
           </td>
         </tr>
         <tr>
           <td width={184}>
             <p>
               <strong>Rischio alto (A)</strong>
             </p>
           </td>
           <td width={209}>
             <p>
               Azioni&nbsp;&nbsp; correttive/piano&nbsp;&nbsp; di programmare con
               urgenza
             </p>
           </td>
           <td width={117}>
             <p>miglioramento</p>
           </td>
           <td width={90}>
             <p>immediate</p>
           </td>
           <td width={25}>
             <p>o</p>
           </td>
           <td width={32}>
             <p>da</p>
           </td>
         </tr>
         <tr>
           <td width={184}>
             <p>
               <strong>Rischio</strong> <strong>medio</strong> <strong>(M)</strong>
             </p>
           </td>
           <td colSpan={5} width={473}>
             <p>
               Eventuali Azioni correttive-preventive/piano di miglioramento da
               valutare o da programmare nel breve –medio periodo
             </p>
           </td>
         </tr>
         <tr>
           <td width={184}>
             <p>
               <strong>Rischio basso (B)</strong>
             </p>
           </td>
           <td colSpan={5} width={473}>
             <p>Non è richiesta alcuna azione</p>
           </td>
         </tr>
       </tbody>
     </table>
     <p>&nbsp;</p>
     <p>
       <strong>Diagramma</strong> <strong>di</strong> <strong>flusso</strong>{" "}
       <strong>del processo di Valutazione del Rischio</strong>
     </p>
     <table>
       <tbody>
         <tr>
           <td width={124}>&nbsp;</td>
         </tr>
         <tr>
           <td>&nbsp;</td>
           <td>&nbsp;</td>
         </tr>
       </tbody>
     </table>
     <p>&nbsp;</p>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>
       Sulle risultanze dei singoli fattori di rischio valutati, si individuano le
       misure correttive ritenute necessariamente immediate o programmabili.
     </p>
     <p>&nbsp;</p>
     <p>
       Gli impatti sono valutati secondo le seguenti cause riportate nell’Allegato
       3 Analisi cause:
     </p>
     <p>-Incendio</p>
     <p>-Acqua,</p>
     <p>-Incidente</p>
     <p>-Cedimento strutturale</p>
     <p>-Fenomeni metereologici</p>
     <p>-Fenomeni sismici</p>
     <p>-Alluvioni ed allagamenti</p>
     <p>-Guasti generali</p>
     <ul>
       <li>Malfunziohrefnti</li>
       <li>Radiazioni</li>
     </ul>
     <p>-Violazioni privacy</p>
     <ul>
       <li>Guasti tecnici</li>
       <li>Abuso di diritti</li>
       <li>Errori umani</li>
       <li>Dipendenza dell'azienda</li>
       <li>Danneggiamento accidentale</li>
       <li>Accesso accidentale</li>
     </ul>
     <p>&nbsp;</p>
     <p>Si rimanda all’allegato 4: Valutazione impatto protezione dati</p>
     <p>&nbsp;</p>
     <p>&nbsp;</p>
     <h1>
       <a href="_Toc135840223" />
       12.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
       DPO DATA PROTECTION OFFICER
     </h1>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>È obbligatorio nominare il DPO nei seguenti casi:</p>
     <ul>
       <li>
         Amministrazione e enti pubblici fatte eccezione per autorità giudiziaria;
       </li>
       <li>
         Soggetti la cui attività principale consiste in trattamenti che, per la
         loro natura e il loro oggetto o le loro finalità, richiedono il
         monitoraggio regolare e sistematico degli interessati su larga scala;
       </li>
       <li>
         Soggetti la cui attività principale consiste nel trattamento, su larga
         scala, di dati Particolari, relativi alla saluta o alla vita sessuale
         genetici, giudiziari e biometrici.
       </li>
     </ul>
     <p>&nbsp;</p>
     <p>Il DPO è Responsabile della Protezione dei Dati e deve:</p>
     <ul>
       <li>
         Possedere una adeguata conoscenza della normativa e della prassi di
         gestione dei dati Personali;
       </li>
       <li>
         Adempiere alle sue funzioni in piena indipendenza e in assenza di
         conflitti di interesse;
       </li>
       <li>Operare sulla Base di un contratto;</li>
     </ul>
     <p>&nbsp;</p>
     <p>Il DPO deve eseguire i seguenti compiti:</p>
     <ul>
       <li>Sorvegliare l’osservanza del regolamento;</li>
       <li>
         Supportare il titolare in ogni attività connessa al trattamento dei dati
         Personali anche con riguardo alla tenuta di un registro dell’Attività di
         Trattamento;
       </li>
       <li>Supportare nella valutazione d’impatto sulla protezione dei Dati;</li>
       <li>
         Informare e sensibilizzare il Titolare o il Responsabile nonché i
         dipendenti riguardo agli obblighi del Regolamento;
       </li>
       <li>
         Cooperare con le autorità di controllo e fungere da punto di contatto;
       </li>
     </ul>
     <p>&nbsp;</p>
     <p>
       Il titolare del trattamento e il responsabile del trattamento designano
       sistematicamente un responsabile della protezione dei dati ogniqualvolta:
     </p>
     <ol>
       <li>
         il trattamento è effettuato da un'autorità pubblica o da un organismo
         pubblico, eccettuate le autorità giurisdizionali quando esercitano le loro
         funzioni giurisdizionali;
       </li>
       <li>
         le attività principali del titolare del trattamento o del responsabile del
         trattamento consistono in trattamenti che, per loro natura, ambito di
         applicazione e/o finalità, richiedono il monitoraggio regolare e
         sistematico degli interessati su larga scala; oppure
       </li>
       <li>
         le attività principali del titolare del trattamento o del responsabile del
         trattamento consistono nel trattamento, su larga scala, di categorie
         particolari di dati personali
       </li>
     </ol>
     <p>&nbsp;</p>
     <p>
       Il responsabile della protezione dei dati è designato in funzione delle
       qualità professionali, in particolare della conoscenza specialistica della
       normativa e delle prassi in materia di protezione dei dati, e della capacità
       di assolvere i compiti.
     </p>
     <p>
       Il responsabile della protezione dei dati può essere un dipendente del
       titolare del trattamento o del responsabile del trattamento oppure assolvere
       i suoi compiti in base a un contratto di servizi.
     </p>
     <p>
       Il titolare del trattamento o il responsabile del trattamento pubblica i
       dati di contatto del responsabile della protezione dei dati e li comunica
       all'autorità di controllo.
     </p>
     <p>
       Il titolare del trattamento e il responsabile del trattamento si assicurano
       che il responsabile della protezione dei dati sia tempestivamente e
       adeguatamente coinvolto in tutte le questioni riguardanti la protezione dei
       dati personali.
     </p>
     <p>
       Il titolare e del trattamento e il responsabile del trattamento sostengono
       il responsabile della protezione dei dati nell'esecuzione dei suoi compiti
       fornendogli le risorse necessarie per assolvere tali compiti e accedere ai
       dati personali e ai trattamenti e per mantenere la propria conoscenza
       specialistica.
     </p>
     <p>
       Il responsabile della protezione dei dati riferisce direttamente al vertice
       gerarchico del titolare del trattamento o del responsabile del trattamento.
     </p>
     <p>
       Gli interessati possono contattare il responsabile della protezione dei dati
       per tutte le questioni relative al trattamento dei loro dati personali e
       all'esercizio dei loro diritti derivanti dal presente regolamento.
     </p>
     <p>
       Il responsabile della protezione dei dati è tenuto al segreto o alla
       riservatezza in merito all'adempimento dei propri compiti.
     </p>
     <p>
       Il responsabile della protezione dei dati può svolgere altri compiti e
       funzioni. Il titolare del trattamento o il responsabile del trattamento si
       assicura che tali compiti e funzioni non diano adito a un conflitto di
       interessi.
     </p>
     <p>&nbsp;</p>
     <p>
       Il responsabile della protezione dei dati è incaricato almeno dei seguenti
       compiti:
     </p>
     <ol>
       <li>
         informare e fornire consulenza al titolare del trattamento o al
         responsabile del trattamento nonché ai dipendenti che eseguono il
         trattamento in merito agli obblighi derivanti dal presente regolamento
         nonché da altre disposizioni dell'Unione o degli Stati membri relative
         alla protezione dei dati;
       </li>
       <li>
         sorvegliare l'osservanza del presente regolamento, di altre disposizioni
         dell'Unione o degli Stati membri relative alla protezione dei dati nonché
         delle politiche del titolare del trattamento o del responsabile del
         trattamento in materia di protezione dei dati personali, compresi
         l'attribuzione delle responsabilità, la sensibilizzazione e la formazione
         del personale che partecipa ai trattamenti e alle connesse attività di
         controllo;
       </li>
       <li>
         fornire, se richiesto, un parere in merito alla valutazione d'impatto
         sulla protezione dei dati e sorvegliarne lo svolgimento;
       </li>
       <li>cooperare con l'autorità di controllo;</li>
       <li>
         fungere da punto di contatto per l'autorità di controllo per questioni
         connesse al trattamento, tra cui la consultazione preventiva.
       </li>
     </ol>
     <p>
       Nell'eseguire i propri compiti il responsabile della protezione dei dati
       considera debitamente i rischi inerenti al trattamento, tenuto conto della
       natura, dell'ambito di applicazione, del contesto e delle finalità del
       medesimo.
     </p>
     <p>&nbsp;</p>
     <p>
       <strong>
         L’azienda EOS SRL non rientra tra i casi sopramenzionati, di conseguenza
         non ha provveduto a nominare un responsabile di protezione dei dati, DPO.
       </strong>
     </p>
     <p>
       <strong>
         <br />{" "}
       </strong>
     </p>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <h1>
       <a href="_Toc135840224" />
       13.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
       RESPONSABILE DEL TRATTAMENTO DEI DATI
     </h1>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>
       L’azienda anche se non rientra nell’obbligo di nominare un DPO deve nominare
       un Titolare del Trattamento dei dati che a sua volta può nominare un
       Responsabile del trattamento dati. Titolare del trattamento è la Direzione
       Aziendale o l’Amministratore.
     </p>
     <p>
       Responsabile del trattamento la persona fisica o giuridica che tratta i dati
       personali per conto del Titolare del trattamento.
     </p>
     <p>
       Il Responsabile del Trattamento può nominare a sua volta degli incaricati
       per il trattamento dei dati.
     </p>
     <p>
       Si fa riferimento all’
       <strong>organigramma funzionale per il Trattamento dei dati.</strong>
     </p>
     <p>&nbsp;</p>
     <p>&nbsp;</p>
     <h1>
       <a href="_Toc135840225" />
       14.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; PIANO&nbsp; DI&nbsp;
       FORMAZIONE DEL&nbsp; PERSONALE AUTORIZZATO AL TRATTAMENTO DEI DATI
     </h1>
     <p>&nbsp;</p>
     <p>
       Al “Titolare del trattamento” è affidato il compito di verificare ogni anno,
       entro il 31 dicembre, le necessità di formazione del personale “Incaricato
       del trattamento” dei dati con lo scopo di fornire ogni informazione
       necessaria a migliorare la sicurezza di trattamento dei dati.
     </p>
     <p>
       Per ogni utente il “Titolare del trattamento“ definisce, sulla base
       dell’esperienza e delle sue conoscenze, ed in funzione anche di eventuali
       variazioni della normativa, le necessità di formazione, i vari partecipanti
       alle formazioni saranno registrati nell’apposito{" "}
       <strong>Piano di formazione.</strong>
     </p>
     <p>&nbsp;</p>
     <h1>
       <a href="_Toc135840226" />
       15.&nbsp;&nbsp; MISURE DI SICUREZZA TECNICHE ED ORGANIZZATIVE
     </h1>
     <h2>
       <a href="_Toc135840227" />
       15.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
       SISTEMA DI AUTENTIFICAZIONE INFORMATICA, PASSWORD INTERNE AZIENDALI
     </h2>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>
       L’azienda adotta istruzioni per mantenere una sicurezza tecnica sulle
       password dei pc aziendali, ovvero:
     </p>
     <ul>
       <li>
         la credenziale di autenticazione ricevuta per il trattamento dei dati deve
         essere memorizzata con segretezza ed ad uso esclusivo;
       </li>
       <li>
         la parola chiave, prevista dal sistema di autenticazione, deve essere
         composta da otto caratteri; non deve&nbsp; contenere&nbsp;
         riferimenti&nbsp;&nbsp;&nbsp; riconducibili&nbsp; a&nbsp; persone&nbsp;
         fisiche;&nbsp; deve&nbsp; essere modificata ogni 6 mesi;
       </li>
       <li>usare sia lettere che numeri e almeno un carattere maiuscolo;</li>
       <li>
         non basare la scelta su informazioni facilmente deducibili quali, ad
         esempio, il proprio nome, il nome dei propri familiari, le date di
         nascita, i codici fiscali, ecc.,
       </li>
       <li>mantenerla riservata e non divulgarla a terzi;</li>
       <li>
         non permettere ad altri utenti di operare con il proprio identificativo
         utente;
       </li>
       <li>
         non trascriverla su supporti (es. fogli, post-it) facilmente accessibili a
         terzi, né lasciarla memorizzata sul proprio PC;
       </li>
       <li>non comunicarla mai per telefono salvo gravi necessità;</li>
       <li>
         non è consentito lasciare incustodito o accessibile l’elaboratore durante
         la sessione di trattamento dei dati; per evitare l’accesso non autorizzato
         alle banche dati elettroniche, l’elaboratore utilizzato è impostato
         prevedendo in automatico il blocco del sistema (richiesta reinserimento
         password);
       </li>
       <li>
         si deve controllare e custodire, per l’intero ciclo necessario allo
         svolgimento delle operazioni di trattamento, gli atti ed i documenti
         cartacei prelevati dagli archivi; al termine dell’utilizzo si è tenuti a
         riporre quanto prelevato nel luogo preposto alla conservazione; per le
         norme generali di prevenzioni, possibilmente in armadi chiusi a chiave.
       </li>
       <li>
         Ad ogni&nbsp;&nbsp; “Incaricato&nbsp;&nbsp; del&nbsp;&nbsp;
         Trattamento”&nbsp;&nbsp; è&nbsp;&nbsp; stata&nbsp;&nbsp;
         assegnata&nbsp;&nbsp; una&nbsp;&nbsp; credenziale&nbsp;&nbsp; per
         l’autentificazione.
       </li>
       <li>Non sono ammessi nomi identificativi di gruppo.</li>
       <li>
         La parola chiave è individuale, disattivata in caso di perdita o
         dimissioni dell’“Incaricato del Trattamento” durante la digitazione non
         può comparire in chiaro sul monitor e non può rimanere nella memoria del
         computer
       </li>
       <li>
         Le password di tutti i pc e server sono custodite dal Responsabile
         trattamento dati, all’interno di foglio excel protetto da password oppure
         sopra un foglio cartaceo chiuso all’interno di armadio con serratura.
         <br /> L’azienda <strong>EOS SRL</strong> ha installato su tutti i pc un
         software di crittografia dei dati e la visualizzazione di alcune cartelle
         come quella della contabilità e scansione documenti della contabilità è
         limitata solo ad alcuni utenti.
       </li>
     </ul>
     <p>&nbsp;</p>
     <h2>
       <a href="_Toc135840228" />
       15.2 ACCESSO AI DATI DALLA POSTAZIONE DI LAVORO
     </h2>
     <p>&nbsp;</p>
     <p>La postazione di lavoro deve essere:</p>
     <p>&nbsp;</p>
     <ul>
       <li>utilizzata solo per scopi legati alla propria attività lavorativa;</li>
       <li>utilizzata in modo esclusivo da un solo utente;</li>
       <li>
         protetta, evitando che terzi possano accedere ai dati che si sta trattando
       </li>
     </ul>
     <p>&nbsp;</p>
     <p>Occorre, inoltre, precisare che è dovere dell'Incaricato:</p>
     <ul>
       <li>
         non utilizzare in Azienda risorse informatiche private (PC, periferiche,
         token, ecc..);
       </li>
       <li>non installare alcun software;</li>
       <li>
         non lasciare sulla scrivania informazioni riservate su qualunque supporto
         esse siano archiviate (carta, chiavette USB ecc.)
       </li>
       <li>
         richiamare le funzioni di sicurezza del sistema operativo (con la sequenza
         dei tasti CTRL+ALT+CANC) ed assicurarsi della attivazione della funzione
         Lock Workstation in caso di abbandono momentaneo del proprio PC o, in
         alternativa, impostare lo screen saver con password in modo che si attivi
         dopo max.5 minuti di inattività;
       </li>
       <li>
         non lasciare il computer portatile incustodito sul posto di lavoro (al
         termine dell’orario lavorativo, durante le pause di lavoro, o durante
         riunioni lontane dalla propria postazione);
       </li>
       <li>non lasciare incustoditi cellulari e palmari;</li>
       <li>
         non utilizzare fax e/o telefono per trasmettere informazioni riservate e
         personali se non si è assolutamente certi dell’identità dell’interlocutore
         o del destinatario e se esso non è legittimato a riceverle.
       </li>
     </ul>
     <p>&nbsp;</p>
     <h2>
       <a href="_Toc135840229" />
       15.3 PROTEZIONE DEI PC PORTATILI
     </h2>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>
       Un computer portatile presenta maggiori vulnerabilità rispetto ad una
       postazione di lavoro fissa. Fatte salve tutte le disposizioni dei paragrafi
       precedenti, di seguito vengono illustrate le ulteriori precauzioni da
       adottare nell’uso dei dispositivi portatili:
     </p>
     <ul>
       <li>
         conservare lo strumento in un luogo sicuro alla fine della giornata
         lavorativa;
       </li>
       <li>
         non lasciare mai incustodito l’elaboratore in caso di utilizzo in ambito
         esterno all’azienda;
       </li>
       <li>
         avvertire tempestivamente l’Area IT, che darà le opportune indicazioni, in
         caso di furto di un PC portatile;
       </li>
       <li>
         essere sempre ben consapevole delle informazioni archiviate sul portatile
         il quale è maggiormente soggetto a furto e smarrimento rispetto alla
         postazione fissa;
       </li>
       <li>
         operare sempre nella massima riservatezza quando si utilizza il PC
         portatile in pubblico: i dati, ed in particolare le password, potrebbero
         essere intercettati da osservatori indiscreti.
       </li>
     </ul>
     <p>&nbsp;</p>
     <h2>
       <a href="_Toc135840230" />
       15.4 BACK UP
     </h2>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>
       Il back up è la replicazione su un qualunque supporto di memorizzazione di
       materiale informativo archiviato nella memoria di massa dei computer, siano
       essi personal computer, workstation o server, home computer o smartphone, al
       fine di prevenire la perdita definitiva dei dati in caso di eventi malevoli
       accidentali o intenzionali. Si tratta dunque di una misura di ridondanza
       fisica dei dati.
     </p>
     <p>
       Un secondo scopo è quello di recuperare i dati da un momento precedente,
       cioè utilizzare una versione precedente rispetto a quella attuale, di dati
       attualmente in possesso, secondo una politica di conservazione dei dati
       definiti dall'utente, tipicamente configurata all'interno di un software di
       backup.
     </p>
     <p>
       Un backup può essere fatto in diverse modalità, su degli appropriati hard
       disk esterni, chiamati per l’appunto NAS, su uno o più banali supporti
       ottici come CD, DVD e/o Blu-Ray, su delle semplici pen drive USB, su delle
       pratiche microSD, e in generale, su un qualunque altro valido supporto di
       memorizzazione. Una copia di backup, può anche essere fatta direttamente sul
       cloud, one drive ecc.
     </p>
     <p>
       Fino a quando i nuovi dati vengono creati e modificati, sarà sempre
       necessario eseguire backup con una certa frequenza. Gli individui e le
       organizzazioni, partendo da un computer fino a migliaia di sistemi
       informatici hanno tutti la necessita di proteggere i dati. Le scale possono
       essere molto diverse, ma gli obiettivi e limitazioni sono essenzialmente le
       stesse. Chi gestisce il processo di backup è quindi tenuto a valutare e
       applicare le misure necessarie a garantire un certo grado di successo per la
       copia dei dati.
     </p>
     <p>&nbsp;</p>
     <p>
       <strong>Obiettivi</strong>
     </p>
     <p>
       <strong>Recovery Point Objective (RPO)</strong>
     </p>
     <p>
       Essenzialmente è il momento in cui si effettua l'ultimo backup prima di un
       disastro. Il RPO desiderabile sarebbe il punto appena prima dell'evento di
       perdita dei dati poiché tutte le modifiche effettuate ai dati in istanti di
       tempo successivi a questo evento vengono perse. Si cerca quindi di
       realizzare operazioni di backup il più frequente possibile in modo da
       perdere il minor numero di dati possibile.
     </p>
     <p>&nbsp;</p>
     <p>
       <strong>Recovery Time Objective (RTO)</strong>
     </p>
     <p>
       La quantità di tempo trascorso tra il disastro e il ripristino delle
       funzioni aziendali ovviamente dipende dal tipo di disastro e dalla bontà del
       piano di disaster recovery.
     </p>
     <p>&nbsp;</p>
     <p>
       <strong>Sicurezza dei dati</strong>
     </p>
     <p>
       Oltre a preservare l'accesso ai dati per i suoi proprietari, bisogna mettere
       in pratica misure che riescano ad impedire accessi non autorizzati, per
       esempio tramite{" "}
       <u>crittografia dei dati e politiche di gestione dei supporti adeguati.</u>
     </p>
     <p>&nbsp;</p>
     <p>
       <strong>Periodo di conservazione dei dati</strong>
     </p>
     <p>
       Regolamenti e la politica di gestione del backup possono portare a
       situazioni in cui si prevede che le copie devono essere conservate per un
       periodo particolare, ma non oltre. Mantenere il backup dopo questo periodo
       può portare a responsabilità indesiderate e l'uso non ottimale dei supporti
       di memorizzazione.
     </p>
     <p>&nbsp;</p>
     <p>
       All’interno della sede legale di <strong>EOS SRL</strong> il back-up avviene
       all’interno di due server fisici in azienda posizionati in una stanza
       dedicata con climatizzatore e dispositivo di rilevazione fumo e temperatura.
     </p>
     <p>
       Sono presenti anche 4 NAS di archivio per i file di posta, l’archiviazione
       della posta avviene
     </p>
     <p>
       sopra sopra1 NAS con 4 dischi e in più viene replicato sopra un NAS uguale
       identico. Viene utilizzato 1 NAS per i documenti Autocad e 1 per la replica.
     </p>
     <p>
       Per effettuare le repliche si utilizzano 2 NAS esterni dove ogni venerdì si
       attaccano al server e partono in automatico. Il lunedì vengono portati
       esterhrefnte all’azienda.
     </p>
     <p>
       Sono presenti anche 4 server virtuali dedicati (server di dominio, server
       per la posta elettronica Exchange, uno per i documenti word e cad e un
       server virtuale per il software gestionale).
     </p>
     <p>&nbsp;</p>
     <h2>
       <a href="_Toc135840231" />
       15.5 PROTEZIONE DA VIRUS INFORMATICI
     </h2>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>
       Al fine di garantire l’integrità dei dati contro i rischi di distruzione o
       perdita di dati a causa di Virus Informatici, il “Titolare del trattamento”,
       stabilisce quali protezioni software adottare in relazione all’evoluzione
       tecnologica dei sistemi disponibili sul mercato.
     </p>
     <p>
       Il “Titolare del trattamento”, stabilisce inoltre la periodicità, con cui
       debbono essere effettuati gli aggiorhrefnti dei sistemi Antivirus utilizzati
       per ottenere un accettabile standard di sicurezza delle “Banche di dati”
       trattate
     </p>
     <p>&nbsp;</p>
     <p>
       Il “Titolare del trattamento”, nel caso in cui su uno o più sistemi si
       dovesse verificare perdita di informazioni&nbsp; o&nbsp; danni&nbsp; a&nbsp;
       causa&nbsp; di&nbsp; infezione&nbsp; o&nbsp; contagio&nbsp; da&nbsp;
       Virus&nbsp; Informatici&nbsp;&nbsp; deve&nbsp; inoltre provvedere a:
     </p>
     <p>&nbsp;</p>
     <ul>
       <li>Isolare il sistema.</li>
       <li>
         Verificare se ci sono altri sistemi infettati con lo stesso Virus
         Informatico.
       </li>
       <li>Identificare l’Antivirus adatto e bonificare il sistema infetto.</li>
       <li>
         Installare l’Antivirus adatto su tutti gli altri sistemi che ne sono
         sprovvisti.
       </li>
     </ul>
     <p>&nbsp;</p>
     <p>
       L’azienda utilizza antivirus centralizzato nel server che si aggiorna
       automaticamente su ogni postazione ed è impostato un firewall di rete, gli
       ospiti non possono entrare nella rete aziendale ma possono viaggiare su una
       rete separata.
     </p>
     <p>&nbsp;</p>
     <h2>
       <a href="_Toc135840232" />
       15.6 MISURE&nbsp; DI&nbsp; SICUREZZA&nbsp; CONTRO&nbsp; IL&nbsp;
       RISCHIO&nbsp; DI&nbsp; ACCESSO&nbsp; NON&nbsp; AUTORIZZATO&nbsp; E
       TRATTAMENTO NON CONSENTITO
     </h2>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>
       <strong>NORME GENERALI DI PREVENZIONE</strong>
     </p>
     <p>&nbsp;</p>
     <p>È fatto divieto a chiunque di:</p>
     <p>&nbsp;</p>
     <ul>
       <li>
         Effettuare copie su supporti magnetici o trasmissioni non autorizzate dal
         “Titolare del trattamento” di dati oggetto del trattamento.
       </li>
       <li>
         Effettuare copie fotostatiche o di qualsiasi altra natura, non autorizzate
         dal “Titolare del trattamento” , di stampe, tabulati, elenchi, rubriche e
         di ogni altro materiale riguardante i dati oggetto del trattamento.
       </li>
       <li>
         Sottrarre, cancellare, distruggere senza l’autorizzazione del “Titolare
         del trattamento”, stampe, tabulati, elenchi, rubriche e ogni altro
         materiale riguardante i dati oggetto del trattamento.
       </li>
       <li>
         Consegnare a persone non autorizzate dal “Titolare del trattamento”,
         stampe, tabulati, elenchi, rubriche e di ogni altro materiale riguardante
         i dati oggetto del trattamento.
       </li>
     </ul>
     <p>&nbsp;</p>
     <h2>
       <a href="_Toc135840233" />
       15.7 PROCEDURE PER CONTROLLARE L’ACCESSO AI LOCALI IN CUI VENGONO TRATTATI I
       DATI
     </h2>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>
       Al “Titolare del trattamento “è affidato il compito impartire idonee
       istruzioni al fine di:
     </p>
     <p>&nbsp;</p>
     <ul>
       <li>
         Definire le modalità di accesso agli uffici in cui sono presenti sistemi o
         apparecchiature di accesso ai dati trattati.
       </li>
       <li>
         Impedire l’intrusione nei locali, da parte di persone non autorizzate.
       </li>
       <li>
         Impedire il danneggiamento, la manomissione, la sottrazione o la copia di
         dati.
       </li>
       <li>
         Conservare i documenti contenenti i dati in contenitori o locali muniti di
         serratura.
       </li>
     </ul>
     <p>&nbsp;</p>
     <p>
       L’accesso ai locali aziendali non è consentito fuori dagli orari di
       chiusura, ad eccezione del personale autorizzato (rif. Lettere di incarico).
     </p>
     <p>
       Gli uffici vengono sempre chiusi a chiave e i pc hanno tutti gli screen
       sever con le relative password.
     </p>
     <p>&nbsp;</p>
     <h2>
       <a href="_Toc135840234" />
       15.8 PIANO DI VERIFICA DELLE MISURE ADOTTATE
     </h2>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>
       La bontà delle misure adottate deve essere periodicamente verificata. Nello
       specifico le operazioni di verifica riguardano i seguenti:
     </p>
     <ul>
       <li>
         controllo e manutenzione degli estintori: effettuato da personale
         competente
       </li>
       <li>
         corretto utilizzo delle credenziali di autenticazione e disattivazione
         delle password scadute:
       </li>
       <li>aggiorhrefnto antivirus periodico</li>
       <li>
         aggiorhrefnto del livello di formazione degli incaricati: annualmente e in
         relazione dell’evoluzione tecnologica e teorica avvenuta in azienda
       </li>
     </ul>
     <p>&nbsp;</p>
     <h2>
       <a href="_Toc135840235" />
       15.9 MANUTENZIONE DELLE APPARECCHIATURE E DEI SISTEMI DI TRATTAMENTO DEI
       DATI
     </h2>
     <p>&nbsp;</p>
     <p>
       Nel caso&nbsp; in&nbsp; cui&nbsp; esistano&nbsp; rischi&nbsp; evidenti&nbsp;
       ogni&nbsp; “
       <strong>Incaricato&nbsp;&nbsp; del&nbsp;&nbsp; trattamento</strong>”&nbsp;
       è&nbsp; tenuto&nbsp; ad informarne il “
       <strong>Titolare del trattamento</strong>”, perché siano presi gli opportuni
       provvedimenti allo scopo di assicurare il corretto trattamento dei dati in
       conformità alle norme in vigore.
     </p>
     <p>
       Al “<strong>Titolare del trattamento</strong>” è affidato il compito
       impartire adeguate istruzioni al fine di adottare il ripristino
       dell’apparecchiatura, tenendo conto anche dell’evoluzione tecnologica.
     </p>
     <p>
       La manutenzione straordinaria dei sistemi di elaborazione è affidata a
       professionisti esterni, i quali hanno autorizzazione all’accesso alla banca
       dati informatica, al fine di operare il ripristino delle apparecchiature
       danneggiate / procedere all’aggiorhrefnto dei sistemi informatici.
     </p>
     <p>&nbsp;</p>
     <h2>
       <a href="_Toc135840236" />
       15.10 USO DI INTERNET E GESTIONE POSTA ELETTRONICA
     </h2>
     <p>&nbsp;</p>
     <p>
       Gli&nbsp; strumenti&nbsp; di&nbsp; comunicazione&nbsp; telematica&nbsp;
       (Internet&nbsp; e&nbsp; Posta&nbsp; elettronica)&nbsp; devono&nbsp; essere
       utilizzati solo ed esclusivamente per finalità lavorative. Sono vietati
       comportamenti che possano arrecare danno all'Azienda.
     </p>
     <p>In particolare, l’utente dovrà osservare le seguenti regole:</p>
     <ul>
       <li>
         è consentita la navigazione internet solo in siti attinenti e necessari
         per lo svolgimento delle mansioni assegnate;
       </li>
       <li>
         non è consentito scaricare software gratuiti (freeware o shareware)
         prelevati da siti Internet;
       </li>
       <li>
         non è consentita la registrazione a siti internet o partecipare a Forum di
         discussione se questo non è strettamente necessario per lo svolgimento
         della propria attività lavorativa;
       </li>
       <li>
         non è consentito l’utilizzo funzioni di instant messaging a meno che
         autorizzate dall’Area IT;
       </li>
       <li>
         è vietato aprire e-mail e file allegati di origine sconosciuta o che
         presentino degli aspetti anomali (quali ad esempio, un soggetto non
         chiaro);
       </li>
       <li>
         non è consentito rispondere a messaggi provenienti da un mittente
         sconosciuto o di dubbio contenuto in quanto tale atto assicura al mittente
         l’esistenza del destinatario;
       </li>
       <li>
         è vietato l’utilizzo della posta elettronica per comunicare informazioni
         riservate, dati personali o dati critici, senza garantirne l’opportuna
         protezione;
       </li>
       <li>
         occorre sempre accertarsi che i destinatari della corrispondenza per posta
         elettronica siano autorizzati ad entrare in possesso dei dati che ci si
         appresta ad inviare;
       </li>
       <li>
         occorre sempre essere consapevoli che posta elettronica e navigazione
         Internet sono veicoli per l’introduzione sulla propria macchina (e quindi
         in azienda) di virus e altri elementi potenzialmente dannosi;
       </li>
       <li>
         è consentito solo l’utilizzo dei programmi ufficialmente installati
         dall’Area IT/ tecnico informatico/software house;
       </li>
       <li>
         è vietato installare autonomamente programmi, sussistendo infatti il grave
         pericolo di introdurre virus informatici e/o di alterare la funzionalità
         delle applicazioni software esistenti, di violare la legge sul diritto
         d’autore non disponendo delle apposite licenze d’uso acquistate
         dall’Azienda;
       </li>
       <li>
         è vietato modificare le caratteristiche impostate sulle dotazioni od
         installare dispositivi di memorizzazione, comunicazione o altro (ad
         esempio masterizzatori, modem, wi-fi o connect card),collegare alla rete
         aziendale qualsiasi apparecchiatura (ad es. switch, hub, apparati di
         memorizzazione di rete, ecc), effettuare collegamenti verso l’esterno di
         qualsiasi tipo (ad tramite modem o connect card ecc.) utilizzando un pc
         che sia contemporaneamente collegato alla rete aziendale (creando così un
         collegamento tra la rete aziendale interna e la rete esterna); al fine di
         ottimizzare le risorse a disposizione della posta elettronica aziendale e
         migliorare le prestazioni del sistema si evidenzia che la casella di posta
         deve essere “tenuta in ordine” cancellando periodicamente o comunque se
         sono superati i limiti di spazio concessi, documenti inutili o allegati
         ingombranti.
       </li>
       <li>
         va sempre prestata la massima attenzione nell’utilizzo dei supporti di
         origine esterna (per es. chiavi USB, dischi esterni ecc.), avvertendo
         immediatamente l’Amministratore di sistema nel caso in cui siano rilevati
       </li>
       <li>
         L’utente, in caso di assenza programmata (ad esempio per ferie o attività
         di lavoro fuori sede) - di almeno 5 giornate lavorative - deve attivare
         l’apposita funzionalità di sistema (cd. “Fuori Sede”) che consente di
         inviare automaticamente ai mittenti un messaggio di risposta contenente le
         “coordinate” (anche elettroniche o telefoniche) di un altro utente o altre
         modalità utili di contatto della
       </li>
       <li>
         L’azienda, in caso di assenza improvvisa o prolungata dell’utente o
         comunque non programmata e per improrogabili necessità di sicurezza o di
         operatività del sistema, si riserva, per mezzo dell’Amministratore di
         Sistema e Responsabile sistemi ICT, di accedere alla casella di posta
         elettronica dell’utente assente: per i dettagli si rimanda al paragrafo 5
         “Accesso ai dati dell’utente”.
       </li>
     </ul>
     <p>&nbsp;</p>
     <p>
       Particolari cautele nella predisposizione dei messaggi di posta elettronica.
     </p>
     <p>
       Nell’utilizzo della posta elettronica ciascun utente deve tenere in debito
       conto che i soggetti esterni possono attribuire carattere istituzionale alla
       corrispondenza ricevuta da dipendenti aziendali. Pertanto si deve prestare
       particolare attenzione agli eventuali impegni contrattuali e precontrattuali
       contenuti nei messaggi.
     </p>
     <p>
       La formulazione dei messaggi deve pertanto far uso di un linguaggio
       appropriato, corretto e rispettoso che tuteli la dignità delle persone,
       l’immagine e la reputazione dell’Azienda.
     </p>
     <p>
       L’Azienda formula inoltre le seguenti regole di comportamento a cui gli
       utenti devono attenersi:
     </p>
     <ol>
       <li>
         conservare le comunicazioni inviate o ricevute, in particolare quelle
         dalle quali si possano desumere impegni e/o indicazioni operative
         provenienti dalla Committenza pubblica;
       </li>
       <li>
         prestare attenzione ai messaggi di posta elettronica ed ai file, programmi
         e oggetti allegati, ricevuti da mittenti sconosciuti, con testo del
         messaggio non comprensibile o comunque avulso dal proprio contesto
         lavorativo. In tali casi gli utenti devono in particolare:
         <ul>
           <li>
             visualizzare preventivamente il contenuto tramite utilizzo della
             funzione “Riquadro di lettura”(o preview) e, nel caso si riscontri un
             contenuto sospetto, non aprire il messaggio,
           </li>
           <li>
             una volta aperto il messaggio, evitare di aprire gli allegati o
             cliccare sui “link” eventualmente presenti,
           </li>
           <li>cancellare il messaggio e svuotare il “cestino” della posta,</li>
           <li>segnalare l’accaduto all’Amministratore di Sistema</li>
         </ul>
       </li>
       <li>
         evitare di cliccare sui collegamenti ipertestuali dubbi presenti nei
         messaggi di posta: in caso di necessità, accedere ai siti segnalati
         digitando il nome del sito da visitare direttamente nella barra degli
         indirizzi nei consueti strumenti di navigazione;
       </li>
       <li>
         in caso di iscrizione a servizi informativi accessibili via internet
         ovvero a servizi di editoria on line, veicolati attraverso lo strumento di
         posta elettronica :
         <ul>
           <li>
             adoperare estrema cautela ed essere selettivi nella scelta della
             società che fornisce il servizio; in particolare l’adesione dovrà
             avvenire in funzione dell’attinenza del servizio con la propria
             attività lavorativa,
           </li>
           <li>
             utilizzare il servizio solo per acquisire informazioni inerenti
             finalità aziendali, facendo attenzione alle informazioni fornite a
             terzi in modo da prevenire attacchi di social engineering,
           </li>
           <li>
             in caso di appesantimento dovuto ad un eccessivo traffico di messaggi
             scambiati attraverso la lista di distribuzione, revocare l’adesione
             alla stessa. Si raccomanda, in proposito, di approfondire al momento
             dell’iscrizione le modalità per richiederne la
           </li>
         </ul>
       </li>
       <li>
         in caso di errore nella spedizione o ricezione, contattare rispettivamente
         il destinatario cui è stata trasmessa per errore la comunicazione o il
         mittente che, per errore, l’ha spedita, eliminando quanto ricevuto
         (compresi allegati) senza effettuare copia;
       </li>
       <li>
         evitare di predisporre messaggi che contengano materiali che violino la
         legge sul diritto d’autore, o altri diritti di proprietà intellettuale o
       </li>
     </ol>
     <p>&nbsp;</p>
     <h2>
       <a href="_Toc135840237" />
       15.11 TRASMISSIONE E RIPRODUZIONE DEI DOCUMENTI
     </h2>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>
       Al fine di prevenire eventuali accessi ai dati aziendali da parte di
       soggetti terzi non autorizzati, occorre adottare delle cautele nella
       trasmissione e riproduzione dei documenti contenenti dati personali.
     </p>
     <p>
       Quando le informazioni devono essere trasmesse telefonicamente occorre
       essere assolutamente certi dell’identità dell’interlocutore e verificare che
       esso sia legittimato ad ottenere quanto domandato. In particolare, nel caso
       di richieste da parte di terzi può essere necessario, a seconda della natura
       dei dati richiesti, procedere nel seguente modo:
     </p>
     <ul>
       <li>chiedere il nome del chiamante e la motivazione della richiesta;</li>
       <li>
         richiedere il numero di telefono da cui l’interlocutore sta effettuando la
         chiamata;
       </li>
       <li>
         verificare che il numero dichiarato corrisponda a quello del chiamante;
       </li>
     </ul>
     <p>
       procedere immediatamente a richiamare la persona che ha richiesto
       l’informazione, con ciò accertandosi della identità dichiarata in
       precedenza.
     </p>
     <p>
       Quando il dato deve essere inviato a mezzo fax, posta elettronica, SMS, ecc.
       e, in particolar modo, nel caso in cui vengano inviati documenti contenenti
       dati sensibili occorre:
     </p>
     <ul>
       <li>
         prestare la massima attenzione affinché il numero telefonico o l’indirizzo
         e-mail immessi siano corretti;
       </li>
       <li>
         verificare che non vi siano inceppamenti di carta o che dalla macchina non
         siano presi più fogli e attendere sempre il rapporto di trasmissione per
         un ulteriore verifica del numero del destinatario e della quantità di
         pagine inviate;
       </li>
       <li>
         nel caso di documenti inviati per posta elettronica accertarsi, prima di
         confermare l’invio, di avere allegato il file giusto;
       </li>
       <li>
         in caso di trasmissione di dati particolarmente delicati è opportuno
         anticipare l’invio chiamando il destinatario della comunicazione al fine
         di assicurare il ricevimento nelle mani del medesimo, evitando che terzi
         estranei o non autorizzati conoscano il contenuto della documentazione
         inviata.
       </li>
     </ul>
     <p>
       Tutti coloro che provvedono alla duplicazione di documenti con stampanti,
       macchine fotocopiatrici o altre apparecchiature, in caso di copia erronea o
       non leggibile correttamente, da cui potrebbero essere desunti dati
       personali, sono tenuti a distruggere il documento mediante apposita macchina
       “distruggi documenti” o con qualunque altro mezzo che ne renda impossibile
       la ricostruzione in modo da escludere qualunque possibilità da parte di
       estranei di venire a conoscenza dei dati medesimi.
     </p>
     <p>&nbsp;</p>
     <h2>
       <a href="_Toc135840238" />
       15.12 ARCHIVI CARTACEI
     </h2>
     <p>&nbsp;</p>
     <p>
       Tutto il materiale cartaceo contenente dati personali non deve essere
       lasciato incustodito sulle scrivanie e, a fine lavoro, deve essere riposto
       in un luogo sicuro, possibilmente in armadi chiusi a chiave. Inoltre,
       occorre usare la medesima perizia nello svolgimento delle normali quotidiane
       operazioni di lavoro, per evitare che il materiale risulti facilmente
       visibile a persone terze o, comunque, ai non autorizzati al trattamento.
     </p>
     <p>
       In caso di trattamento di dati particolari (condizione di salute, dati
       giudiziari, ecc.), tutta la documentazione cartacea deve essere conservata
       in armadi/cassetti chiusi a chiave o stanze chiuse a chiave in caso di
       allontahrefnto, anche temporaneo, dalla postazione di lavoro.
     </p>
     <p>
       L’accesso a tutti i locali aziendali deve essere consentito solo a personale
       preventivamente autorizzato dalla Titolarità.
     </p>
     <p>
       Gli armai della <strong>EOS SRL</strong>, sono muniti di serratura.
     </p>
     <p>&nbsp;</p>
     <p>&nbsp;</p>
     <h2>
       <a href="_Toc135840239" />
       15.13 VIDEOSORVEGLIANZA
     </h2>
     <p>
       Nell’azienda <strong>EOS SRL</strong> non è presente un sistema di
       videosorveglianza e non sono presenti videocitofoni.
     </p>
     <h2>
       <a href="_Toc135840240" />
       15.14 SISTEMI ANTINTRUSIONE
     </h2>
     <p>
       E’ presente un allarme perimetrale e interno collegato con l’istituto di
       vigilanza e con i cellulari del titolare e del responsabile del trattamento
       dei dati. Sono presenti anche sensori su porte e finestre.
     </p>
     <p>&nbsp;</p>
     <h2>
       <a href="_Toc135840241" />
       15.15 DISPOSITIVI ANTINCENDIO
     </h2>
     <p>In azienda sono presenti estintori per apparecchiature elettriche.</p>
     <p>&nbsp;</p>
     <h2>
       <a href="_Toc135840242" />
       15.16 ALTRE MISURE ADOTTATE DALL’AZIENDA
     </h2>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <ul>
       <li>Aggiorhrefnti continui dei software</li>
       <li>Chiusura degli uffici a chiave</li>
       <li>Archiviazione cartacea in armadi chiusi a chiave</li>
       <li>
         In caso di licenziamento di un dipendente che trattava dati, si esegue un
         cambio password immediato e si cancella la mail del dipendente.
       </li>
       <li>
         I documenti archiviati non sono mai portati all’esterno dell’azienda e non
         sono disponibili al di fuori dell’orario di lavoro.
       </li>
       <li>Presenza di un regolamento interno per la riservatezza dei dati.</li>
       <li>
         Le cartelle mediche dei dipendenti le custodisce il medico competente.
       </li>
     </ul>
     <p>&nbsp;</p>
     <p>
       L’azienda esternalizza solo alcuni servizi base per svolgere le sue normali
       attività lavorative come commercialista, consulente del lavoro, consulente
       paghe, RSPP, medico competente, consulenti esterni ed enti certificatori.
     </p>
     <p>
       Si è provveduto ad inviare in via telematica la delega di collaboratori dati
       in outsourcing, in allegato 14.
     </p>
     <p>&nbsp;</p>
     <h1>
       <a href="_Toc135840243" />
       16.&nbsp;&nbsp; TRASFERIMENTO DATI AI PAESI EXTRA UE
     </h1>
     <p>
       <strong>&nbsp;</strong>
     </p>
     <p>
       Il Regolamento Europeo 679/2018 prevede il divieto di trasferire dati verso
       titolari o responsabili in paesi extra UE, senza garanzie.
     </p>
     <p>
       Si devono stipulare garanzie contrattuali e utilizzare deroghe al divieto di
       trasferimento applicabili in specifiche situazioni ( es: difendere un
       diritto in sede giudiziaria).
     </p>
     <p>
       Il consenso del trattamento dei dati da mandare all’interessato deve essere
       esplicito per poter trasferire dati a Paesi fuori dall’Unione Europea.
     </p>
     <p>
       Bisogna verificare se ci sono i riconoscimenti di adeguatezza da parte della
       commissione europea e definire procedure operative sia per la verifica di
       adeguatezza che per le garanzie contrattuali.
     </p>
     <p>
       <strong>EOS SRL</strong> ha un solo fornitore di fasi in outsourcing estero,
       al quale è stata inviata informativa clienti e fornitori per paesi extra UE
       in allegato 15.
     </p>
     <p>&nbsp;</p>
     <p>&nbsp;</p>
     <p>&nbsp;</p>
     <p>&nbsp;</p>
     <h1>
       <a href="_Toc135840244" />
       17.&nbsp;&nbsp; ARCHIVIAZIONE DEI DATI
     </h1>
     <p>
       In conformità alle indicazioni della privacy e GDPR descritte in precedenza,
       il paragrafo fornisce una panoramica dettagliata sull'archiviazione dei dati
       all'interno della struttura applicativa su Azure e le misure di sicurezza
       adottate per garantire la riservatezza e l'integrità dei dati dei pazienti.
       &nbsp;
     </p>
     <p>&nbsp;</p>
     <h2>
       17.1&nbsp;&nbsp;&nbsp; &nbsp;
       <a href="_Toc135840245" />
       SPECIFICHE TECNICE
     </h2>
     <p>
       I dati dei pazienti sono archiviati all'interno del database PostgreSQL su
       Azure denominato "Heartawaydb". Questo database relazionale offre
       funzionalità avanzate per la gestione e l'elaborazione dei dati, garantendo
       la scalabilità e l'affidabilità necessarie. Il database contiene le seguenti
       tabelle:
     </p>
     <ul>
       <li>
         "Anagrafica Paziente": Raccoglie le informazioni anagrafiche dei pazienti.
       </li>
       <li>
         "Questionario Stile di Vita": Conserva i dati relativi agli stili di vita
         dei pazienti, acquisiti tramite un questionario.
       </li>
       <li>
         "Registrazione esami pazienti": Archivia i risultati degli esami ematici
         dei pazienti.
       </li>
       <li>
         "Elenco delle terapie del paziente": Memorizza le informazioni sulle
         terapie seguite dai pazienti.
       </li>
       <li>
         "Profilo clinico del paziente": Contiene dati utili per il calcolo dello
         score2, che rappresenta un profilo clinico del paziente.
       </li>
       <li>
         "Elenco dei valori antropometrici dei pazienti": Conserva i dati relativi
         alle misurazioni antropometriche dei pazienti.
       </li>
     </ul>
     <h2>
       <a href="_Toc135840246" />
       17.2&nbsp;&nbsp;&nbsp; ACCESSO AL DATABASE
     </h2>
     <p>
       L'accesso al database è strettamente controllato per garantire che solo
       l'applicazione server abbia l'autorizzazione ad accedere ai dati dei
       pazienti. A tal fine, è stata implementata una Virtual Private Network (VPN)
       che consente una connessione sicura tra l'applicazione server e il database.
       L'accesso tramite VPN implica la crittografia dei dati trasmessi e
       l'identificazione delle parti coinvolte, riducendo il rischio di accesso non
       autorizzato o intercettazione dei dati durante la trasmissione.
     </p>
     <h2>
       <a href="_Toc135840247" />
       17.3&nbsp;&nbsp;&nbsp; SICUREZZA DELLO STORAGE ACCOUNT
     </h2>
     <p>
       I documenti associati ai pazienti sono salvati all'interno di uno storage
       account su Azure. L'accesso a tale storage account è consentito solo tramite
       una VPN diretta tra l'applicazione server e lo storage account. Questa
       configurazione garantisce che solo le applicazioni autorizzate possano
       accedere ai documenti archiviati, prevenendo l'accesso non autorizzato o
       l'intercettazione dei dati durante il trasferimento.
     </p>
     <h2>
       <a href="_Toc135840248" />
       17.4&nbsp;&nbsp;&nbsp; MANUTENZIONE ED ACCESSI ESTERNI
     </h2>
     <p>
       Nel caso in cui sia necessaria la manutenzione del sistema, gli accessi
       esterni al database sono regolamentati attraverso il firewall di sicurezza.
       Solo gli indirizzi IP specificati come autorizzati possono accedere al
       database durante il periodo di manutenzione. Ciò limita l'accesso solo al
       personale autorizzato, garantendo la sicurezza dei dati dei pazienti durante
       le attività di manutenzione.
     </p>
   </>
   
 );

 
}

export default PrivacyPage;