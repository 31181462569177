import { BaseDto } from "./BaseDto";

export class DashboardReportDto extends BaseDto {

    lieve: number;
    moderato: number;
    grave: number;

  constructor() {
    super();
    this.lieve = 0;
    this.moderato = 0;
    this.grave = 0;
  }
}