import { LiquorTwoTone, SmokingRoomsTwoTone, SportsVolleyballTwoTone } from "@mui/icons-material";
import Card from "components/layout/Card/Card";
import StileDiVitaSection from "../StileDiVitaSection/StileDiVitaSection";
import { StileDiVitaCardProps } from "./types";
import { format as dateFormat } from "date-fns";
import { StileDiVitaItemCardContainer } from "./styles";

const StileDiVitaCard: React.FC<StileDiVitaCardProps> = ({ title, item }) => {

    return (
        <StileDiVitaItemCardContainer
            title={title ? title : ''}
            appearence={{ isFullWidth: true }}
        >
            
            <div><strong>Data registrazione: </strong> {dateFormat(new Date(item.dataInizio), 'dd/MM/yyyy')}</div>
            <StileDiVitaSection icon={<SportsVolleyballTwoTone />}>
                <div><strong>Pratica sport: </strong> {item.sport === 'S' ? 'Si' : 'No'}</div>
                {item.sport === 'S' && (
                    <div><strong>Pratica più di 150 minuti: </strong> {item.sportsmall === 'S' ? 'Si' : 'No'}</div>
                )}
                {item.sport === 'S' && item.sportsmall === 'S' && (
                    <div><strong>Pratica più di 300 minuti: </strong> {item.sportlarge === 'S' ? 'Si' : 'No'}</div>
                )}
                {item.sport === 'S' && (
                    <div><strong>Tipo di sport: </strong> {item.tiposport === 'A' ? 'Aerobico (jogging,bicicletta)' : 'Di potenza (pesistica)'}</div>
                )}
            </StileDiVitaSection>
            <StileDiVitaSection icon={<SmokingRoomsTwoTone />}>
                <div><strong>Fuma: </strong> {item.fumocorrente === 'S' ? 'Si' : 'No'}</div>
                {item.fumocorrente === 'N' && (
                    <div><strong>Fumava in passato: </strong> {item.fumopassato === 'S' ? 'Si' : 'No'}</div>
                )}
                {item.fumocorrente === 'S' && (
                    <div><strong>Da quanti anni: </strong> {item.anno}</div>
                )}
                {item.fumopassato === 'S' && (
                    <div><strong>Per quanti anni: </strong> {item.anno}</div>
                )}
                {(item.fumocorrente === 'S' || item.fumopassato === 'S') && (
                    <div><strong>Sigarette al giorno (pack/anno): </strong> {item.numsig}</div>
                )}
            </StileDiVitaSection>
            <StileDiVitaSection icon={<LiquorTwoTone />}>
                <div><strong>Assume alcolici abitualmente: </strong> {item.alcol === 'S' ? 'Si' : 'No'}</div>
                {item.alcol === 'S' && (
                    <div><strong>Più di 100gr a settimana: </strong> {item.tipoalcol === 'S' ? 'Si' : 'No'}</div>
                )}
            </StileDiVitaSection>


        </StileDiVitaItemCardContainer>
    );
}

export default StileDiVitaCard;