import { BaseDto } from "./BaseDto";
import { RegioneDto } from "./RegioneDto";

export class ProvinciaDto extends BaseDto {

    sigla: string;
    regione: RegioneDto;
    areaPrv: string;
    dataInizio: Date;
    dataFine: Date;
    desPrv: string;

    constructor() {
        super();
        this.sigla= '';
        this.regione= new RegioneDto();
        this.areaPrv= '';
        this.dataInizio= new Date();
        this.dataFine= new Date();
        this.desPrv= '';

    }
}