import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import Card from "components/layout/Card/Card";
import InputField from "components/layout/inputs/InputField";
import React, { useEffect, useRef, useState } from "react";
import { FilterCardProps } from "./types";

import * as Yup from 'yup';
import { useFormik, useFormikContext } from 'formik';
import { FilterUtenteDto } from "components/filter/FilterUtenteDto";
import Emitter from "services/EmitterService";
import { CardActionsContainer } from "components/layout/Card/styles";
import { FilterPazienteDto } from "components/filter/FilterPazienteDto";
import { CatalogoDto } from "models/CatalogoDto";
import { FilterCatalogoDto } from "components/filter/FilterCatalogoDto";
import CatalogoService from "services/CatalogoService";
import AutocompleteInputField from "components/layout/inputs/AutocompleteInputField";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "store/storeHooks";
let filter: FilterPazienteDto = new FilterPazienteDto();

const FilterPaziente: React.FC<FilterCardProps & React.PropsWithChildren> = ({ children, onClean, onSearch, tipo }) => {

    interface PazienteDetailForm {
        nome: string;
        cognome: string;
        ospedali: CatalogoDto[];
        cf: string;
    }

    let filter: FilterPazienteDto = new FilterPazienteDto();


    const [listOspedali, setListOspedali] = useState<CatalogoDto[]>([]);

    const { loggedUser } = useAppSelector(({ auth }) => auth);

    useEffect(() => {
        formik.resetForm();
        const filter = new FilterCatalogoDto();
        filter.tipo = "OSP_INT";
        CatalogoService.findByFilter(filter).then(res => {
            const { out } = res;

            setListOspedali(out);
        });
    }, []);



    function resettaForm() {
        formik.resetForm();
    }


    const formik = useFormik<PazienteDetailForm>({
        initialValues: {
            nome: '',
            cognome: '',
            ospedali: [],
            cf: '' ,
    
        },

        onSubmit: async (values) => {
            const { nome, cognome, ospedali, cf } = values;
            filter.nome = nome;
            filter.cognome = cognome;
            let listaIdOspedale: number[] = [];
            ospedali.map(o => listaIdOspedale.push(Number(o.id)));
            filter.listaOspedali = listaIdOspedale;
            filter.cf = cf;

            let arrayNum: number[] = [];
            loggedUser?.medicoDto.utente.codiciIntervento.forEach(item => {
                if (item.id) {
                    arrayNum.push(item.id);
                }
            });
            filter.conoVisibilita = arrayNum;
            filter.pageSize = 25;
            filter.selectPage = 0;
            Emitter.emit(tipo, filter);
        }
    })

    return (
        <form onSubmit={formik.handleSubmit}>
            <Card
                title="filtro"
                appearence={{ isFullWidth: true }}
            >
                {children}
                <div className="row col-md-12">
                <div className="col-md-4">
                        <InputField
                            type="text"
                            size="small"
                            fullWidth={true}
                            label="Cognome"
                            {...formik.getFieldProps('cognome')}
                        />
                    </div>
                <div className="col-md-4">
                        <InputField
                            type="text"
                            size="small"
                            label="Nome"
                            fullWidth={true}
                            {...formik.getFieldProps('nome')}
                        />
                    </div>
             
                    <div className="col-md-4">
                        <InputField
                            type="text"
                            size="small"
                            fullWidth={true}
                            label="Cod. Identificativo"
                            {...formik.getFieldProps('cf')}
                        />
                    </div>
                </div>
       
                <div className="row mt-3 col-md-12">
                    <div className="col-md-12">
                        <AutocompleteInputField
                            label="Ospedali"
                            options={listOspedali}
                            name={formik.getFieldProps('ospedali').name}
                            value={formik.getFieldProps('ospedali').value}
                            onChange={(value) => {
                                formik.setFieldValue('ospedali', value);
                            }}
                        />
                    </div>
                </div>
                <CardActionsContainer>
                    <Button
                        type="button"
                        color="primary"
                        size="small"
                        onClick={() => resettaForm()}
                    >
                        Pulisci
                    </Button>

                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        size="small"
                    >
                        Cerca
                    </Button>
                </CardActionsContainer>
            </Card>
        </form>
    );
}

export default FilterPaziente;
