import { GenericFilter } from "./GenericFilter";

export class FilterEsameEmaticoDto extends GenericFilter {

    paziente: number | null;
    dataEsame: Date | null | undefined;

    constructor() {
        super();
        
        this.dataEsame = null;
        this.paziente = null;

    }
}