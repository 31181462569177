import axios from "axios";
import { GenericFilter } from "components/filter/GenericFilter";
import { NazioneDto } from "models/NazioneDto";
import { Response } from "models/Response";
import * as Constants from 'utils/constants';

//To Do tipizzare le response

const findByIdNazione = async (id: number) => {
    return axios.get<{}, Response<NazioneDto>>(`${Constants.API_NAZIONE_FIND_BY_ID}/${id}`);
}

const saveOrUpdateNazione = async (nazione: NazioneDto) => {
    return axios.post<{}, Response<NazioneDto>>(Constants.API_NAZIONE_SAVE_UPDATE, nazione);
}

const deleteNazione = async (id: number) => {
    return axios.get<{}, Response<NazioneDto>>(`${Constants.API_NAZIONE_DELETE}/${id}`);
}

const findNazioneByFilter = async (filter: GenericFilter) => {
    return axios.post<{}, Response<NazioneDto[]>>(Constants.API_NAZIONE_FIND_BY_FILTER, filter);
}

const findAllNations = async () => {
    return axios.get<{}, Response<NazioneDto[]>>(`${Constants.API_NAZIONE_FIND_ALL}`);
}

const NazioneService = {
   findByIdNazione,
   saveOrUpdateNazione,
   deleteNazione,
   findNazioneByFilter,
   findAllNations
}

export default NazioneService;