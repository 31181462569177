import styled from "@emotion/styled";
import { Fab } from "@mui/material";
import ZINDEXES from "style/z-indexes";

export const ScrollTopButtonContainer = styled(Fab)`
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 25px;
    bottom: 25px;
    width: 36px;
    height: 36px;
    opacity: 0.3;
    color: white;
    /* border-radius: 0.5rem; */
    z-index: ${ZINDEXES.aside};

    &:hover {
        cursor: pointer;
        opacity: 1;
    }
`;