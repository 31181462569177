import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApplicationDto } from "models/UtenteDto";

interface AuthState {
    isAuth: boolean;
    loggedUser: ApplicationDto | null;
}

const initialState: AuthState = {
    isAuth: false,
    loggedUser: null,
}

export const authSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        login: (state, action: PayloadAction<ApplicationDto>) => {
            state.isAuth = true;
            state.loggedUser = action.payload;
        },
        hasDoneReset: (state) => {
            if(state.loggedUser) {
                state.loggedUser.resetPassword = false;
            }
        },
        logout: (state) => {
            state.isAuth = false;
            state.loggedUser = null;
        }
    }
});

export const { login, hasDoneReset, logout } = authSlice.actions;

export const authMiddleware = (store: any) => (next: any) => (action: any) => {
    if (login.match(action)) {
        const userData = action.payload;
        localStorage.setItem('loggedUser', JSON.stringify(userData));
    } else if (hasDoneReset.match(action)) {
        const lu = JSON.parse(localStorage.getItem('loggedUser')!);
        lu.resetPassword = false;
        localStorage.setItem('loggedUser', JSON.stringify(lu));
    } else if (logout.match(action)) {
        localStorage.removeItem('loggedUser');
    }
    return next(action);
};

export default authSlice.reducer;
