import { AlertCard } from "./styles";
import Chart from 'react-apexcharts';
import { ApexOptions } from "apexcharts";
import { CircularProgress, useTheme } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { DashboardReportDto } from "models/DashboardReportDto";
import PazienteService from "services/PazienteService";
import { FaceTwoTone, SentimentDissatisfiedTwoTone, SentimentVeryDissatisfiedTwoTone, SentimentVerySatisfiedTwoTone } from "@mui/icons-material";
import Card from "components/layout/Card/Card";

const DashboardPage: React.FC = () => {

    const theme = useTheme();

    // const options: ApexOptions = {
    //     chart: {
    //         redrawOnWindowResize: false,
    //         redrawOnParentResize: false,
    //     },
    //     plotOptions: {
    //         pie: {
    //             expandOnClick: false,
    //         }
    //     },
    //     colors: [theme.palette.error.light, theme.palette.warning.light, theme.palette.success.light],
    //     fill: {
    //         colors: [theme.palette.error.light, theme.palette.warning.light, theme.palette.success.light],
    //     },
    //     labels: [`$Altissimo ${counts.grave}`, 'Moderato', 'Basso']
    // };

    const [counts, setCounts] = useState<DashboardReportDto>(new DashboardReportDto());

    const options = useMemo<ApexOptions>(() => ({
        chart: {
            redrawOnWindowResize: false,
            redrawOnParentResize: false,
        },
        plotOptions: {
            pie: {
                expandOnClick: false,
            }
        },
        colors: [theme.palette.error.light, theme.palette.warning.light, theme.palette.success.light],
        fill: {
            colors: [theme.palette.error.light, theme.palette.warning.light, theme.palette.success.light],
        },
        labels: [`Altissimo: ${counts.grave}`, `Moderato: ${counts.moderato}`, `Basso: ${counts.lieve}`]
    }), [theme, counts]);

    useEffect(() => {
        PazienteService.getPazienteCounts().then(({ out }) => {
            const newCount = out;
            newCount.lieve = out.lieve || 0;
            newCount.moderato = out.moderato || 0;
            newCount.grave = out.grave || 0;
            setCounts(newCount);
        });
    }, []);

    return (
        <>
            <div className="row">
                <div className="col-md-3">
                    <AlertCard appearence={{ isFullWidth: true }} severity="info">
                        <FaceTwoTone color="primary" />
                        <p>TOT Pazienti: <strong>{counts.grave + counts.moderato + counts.lieve}</strong></p>
                    </AlertCard>
                </div>
                <div className="col-md-3">
                    <AlertCard appearence={{ isFullWidth: true }} severity="error">
                        <SentimentVeryDissatisfiedTwoTone color="error" />
                        <p>Pazienti rischio altissimo: <strong>{counts.grave}</strong></p>
                    </AlertCard>
                </div>
                <div className="col-md-3">
                    <AlertCard appearence={{ isFullWidth: true }} severity="warning">
                        <SentimentDissatisfiedTwoTone color="warning" />
                        <p>Pazienti rischio moderato: <strong>{counts.moderato}</strong></p>
                    </AlertCard>
                </div>
                <div className="col-md-3">
                    <AlertCard appearence={{ isFullWidth: true }} severity="success">
                        <SentimentVerySatisfiedTwoTone color="success" />
                        <p>Pazienti rischio basso: <strong>{counts.lieve}</strong></p>
                    </AlertCard>
                </div>
            </div>

            <div className="row mt-3">
                <Card className="" appearence={{ isFullWidth: true }} title="Report Fattori Rischio">
                    <div>
                        <Chart
                            type="pie"
                            width={'80%'}
                            height='500px'
                            options={options}
                            series={[counts.grave, counts.moderato, counts.lieve]}
                        />
                    </div>
                </Card>
            </div>
        </>
    );
};

export default DashboardPage;