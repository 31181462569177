import styled from "@emotion/styled";
import Card from "components/layout/Card/Card";

export const ItemCardContainer = styled(Card)`
    border-top: 3px solid ${({ theme }) => theme.palette.primary.light};
    border-radius: 0.3px;
    min-height: 100px;
`;

export const ItemContainer = styled.div`
    min-height: 100px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;

    .MuiButton-root {
        width: calc(10% - 10px);
        left: 2px;
    }
`;

export const ItemSection = styled.div`
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: small;
`;

export const DateSection = styled.div`
    width: 20%;
    margin-right: 15px;

     > .MuiFormControl-root {
        width: 100%;
        height: 100%;

        > .MuiOutlinedInput-root {
            width: 100%;
            height: 100%;
            border-radius: 0;
        }
    }
`;

