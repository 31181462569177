import styled from "@emotion/styled";
import MEASURES from "style/measures";
import { motion } from "framer-motion";
import ZINDEXES from "style/z-indexes";

export const PageContent = styled(motion.div)`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: ${MEASURES.pageConent.width};
    min-height: ${MEASURES.pageConent.minHeight};
    padding-top: calc(${MEASURES.header.height} + 25px);
    padding-right: 40px;
    padding-bottom: 40px;
    z-index: ${ZINDEXES.page};
`;