import { BaseDto } from "./BaseDto";


export class NazioneDto extends BaseDto {

   statoCod: string;
   statoDes: string;
   statoNaz: string;
   statoCodfis: string;
   flagUE: string;
   dataInizio: Date;
   dataFine: Date;


    constructor() {
        super();
        this.statoCod = '';
        this.statoDes = '';
        this.statoNaz = '';
        this.statoCodfis = '';
        this.flagUE= '';
        this.dataInizio = new Date();
        this.dataFine = new Date();
    }
}