import axios from "axios";
import { Response } from "models/Response";
import * as Constants from 'utils/constants';
import { FilterEsameDto } from "components/filter/FilterEsameDto";
import { EsameDto, EsameMasterDto, EsameSlaveDto } from "models/EsameDto";
import { EsameEmaticoDto } from "models/EsameEmaticoDto";
import { FilterEsameEmaticoDto } from "components/filter/FilterEsameEmaticoDto";

const listaEsami = async (filter: FilterEsameEmaticoDto) => {
    return axios.post<{}, Response<EsameEmaticoDto[]>, FilterEsameEmaticoDto>(Constants.API_ESAME_EMATICO_FILTER, filter);
}

const deleteEsame = async (id : number | null | undefined) => {
    return axios.get<{}, Response<EsameEmaticoDto>>(`${Constants.API_DELETE_ESAME_EMATICO}/${id}`);
}

const saveOrUpdateEsame = async (dto: EsameEmaticoDto) => {
    return axios.post<{}, Response<EsameEmaticoDto>, EsameEmaticoDto>(Constants.API_SAVE_OR_UPDATE_ESAME_EMATICO, dto);
}

const getEsameById = async (id : number | null | undefined) => {
    return axios.get<{}, Response<EsameEmaticoDto>>(`${Constants.API_GET_ESAME_EMATICO}/${id}`);
}

const EsameEmaticoService = {
    listaEsami,
    deleteEsame,
    saveOrUpdateEsame,
    getEsameById,
}

export default EsameEmaticoService;