import { CallMergeTwoTone, CheckBoxOutlined, DeleteTwoTone, EditTwoTone } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import Modal from "components/modal/Modal";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { setNewMessage } from "reducers/notification.reducer";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "utils/constants";
import { StatusCodes } from "utils/enums";
import { PazienteItemCardCardContainer, PazienteItemCardItemCardTitle } from "./styles";

import UtenteService from "services/UtenteService";
import { PazienteItemCardProps } from "./types";
import moment from "moment";
import { CatalogoDto } from "models/CatalogoDto";
import { useAppSelector } from "store/storeHooks";
import { useFormik } from "formik";
import RichiesteAssocizioneService from "services/RichiesteAssocizioneService";
import { PazienteDto } from "models/PazienteDto";
import * as Yup from 'yup';
import { RichiestaAssociazioneDto } from "models/UtenteDto";
import Card from "../Card/Card";
import AutocompleteInputField from "../inputs/AutocompleteInputField";

const PazienteItemCard: React.FC<PazienteItemCardProps> = ({ paziente, onPazienteDeleted }) => {

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const [isModalAssociazione, setIsModalAssociazione] = useState<boolean>(false);

    const [pazienteDto, setPazienteDto] = useState<PazienteDto>(new PazienteDto());


    const [listOspedaliRichiesta, setListOspedaliRichiesta] = useState<CatalogoDto[]>([]);

    const { loggedUser } = useAppSelector(({ auth }) => auth);



    const pazienteSchema = Yup.object().shape({
        ospedali: Yup.array()
            .required("Selezionare almeno un ospedale")
            .min(1, "Selezionare almeno un ospedale"),
    });



    useEffect(() => {
        formik.resetForm();
        loadOspedaliSessione();
    }, []);

    const formik = useFormik({
        initialValues: {
            ospedali: []
        },
        validationSchema: pazienteSchema,
        onSubmit: async (values) => {
            const { ospedali } = values;
            let listaAssociazioneDto: RichiestaAssociazioneDto[] = [];
            ospedali.forEach(item => {
                let richiestaAssociazioneDto = new RichiestaAssociazioneDto();
                richiestaAssociazioneDto.statoEvasa = 'N';
                richiestaAssociazioneDto.ospedale = item;
                richiestaAssociazioneDto.utente = pazienteDto.utente;
                listaAssociazioneDto.push(richiestaAssociazioneDto);
            })
            console.info('listaAssociazioneDto', listaAssociazioneDto);
            salvaListaRichiesta(listaAssociazioneDto);
        }
    })

    const isOspedaliInvalid = formik.touched.ospedali && formik.errors.ospedali != null && formik.errors.ospedali.length > 0;

    function loadOspedaliSessione() {
        let arrayNum: CatalogoDto[] = [];
        loggedUser?.medicoDto.utente.codiciIntervento.forEach(item => {
            if (item.id) {
                arrayNum.push(item);
            }
        });
        setListOspedaliRichiesta(arrayNum);
    }



    function openModaleRichiesta(item: PazienteDto) {
        formik.resetForm();
        setPazienteDto(item);
        setIsModalAssociazione(true);
    }


    function salvaListaRichiesta(lista: RichiestaAssociazioneDto[]) {

        RichiesteAssocizioneService.addRiechistaAssociazione(lista).then(response => {
            if (response.statusCode === StatusCodes.OK) {
                dispatch(setNewMessage({
                    level: 'success',
                    message: response.message
                }));
                setIsModalAssociazione(false);
            } else {
                dispatch(setNewMessage({
                    level: 'error',
                    message: response.message
                }));
            }
        }).catch(ex => {
            dispatch(setNewMessage({
                level: 'error',
                message: ex
            }));
        })
    }



    const deletePaziente = () => {
        UtenteService.attivaDisattiva(paziente.utente).then(res => {
            const { statusCode, message } = res;

            if (statusCode === StatusCodes.OK) {
                dispatch(setNewMessage({
                    level: 'success',
                    message: message
                }));
                setIsModalOpen(false);
                onPazienteDeleted();
            } else {
                dispatch(setNewMessage({
                    level: 'error',
                    message: message
                }));
            }
        })
    }

    const listOspedaliString = useMemo(() => paziente.utente?.codiciIntervento?.map(o => o?.descrizione).join(", "), [paziente.utente.codiciIntervento]);

    return (
        <>
            <PazienteItemCardCardContainer appearence={{ isFullWidth: true }}>
                <PazienteItemCardItemCardTitle>
                    <div className="container">
                        <p>{paziente.cf}</p>
                    </div>
                </PazienteItemCardItemCardTitle>
                {paziente != null && paziente.corrispondenze != null && paziente.corrispondenze > 0 && (
                    <div className="d-flex justify-content-end mb-2">
                        <IconButton title="Dettaglio Paziente">
                            <EditTwoTone color="primary" onClick={() => {
                                navigate(ROUTES.paziente.detail.tab.profiloClinico.main.replace(':id', `${paziente.id}`));
                            }} />
                        </IconButton>
                        <IconButton onClick={() => setIsModalOpen(true)} title="Cancella Paziente">
                            <DeleteTwoTone color="error" />
                        </IconButton>
                    </div>
                )}
                {paziente == null || paziente.corrispondenze == null || paziente.corrispondenze == 0 && (
                    <div className="d-flex justify-content-end mb-2">
                        <IconButton onClick={() => openModaleRichiesta(paziente)} title="Associa Ospedale">
                            <CallMergeTwoTone color="error" />
                        </IconButton>
                    </div>
                )}
                <div className="row">
                    <div className="col-md-4">
                        <strong>Cognome: </strong>{paziente.cognome}
                    </div>
                    <div className="col-md-4">
                        <strong>Nome: </strong>{paziente.nome}
                    </div>
                    <div className="col-md-4">
                        <strong>Data di Nascita: </strong>{paziente.dataNascita ? moment(paziente.dataNascita).format('DD/MM/YYYY') : null}
                    </div>
                    <div className="col-md-4 mt-3">
                        <strong>Indirizzo: </strong>{paziente.via}
                    </div>
                    <div className="col-md-4 mt-3">
                        <strong>Cap: </strong>{paziente.cap}
                    </div>
                    <div className="col-md-4 mt-3">
                        <strong>Città di Residenza: </strong>{paziente.cittaResidenza}
                    </div>
                </div>
                <div className="row mt-3">
                <div className="col-md-4">
                        <strong>Username: </strong>{paziente.utente.usrName}
                    </div>
                    <div className="col-md-4">
                        <strong>Email: </strong>{paziente.utente.usrMail}
                    </div>

                    <div className="col-md-4">
                        <strong>Telefono: </strong>{paziente.telefono}
                    </div>
                </div>
                <div className="row mt-2">
                <div className="col-md-4">
                        <strong>Score2: </strong>{paziente.score2}
                    </div>
                    <div className="col-md-4">
                        <strong>Richieste di associazione in pending: </strong>{paziente.richieste}
                    </div>
                    <div className="col-md-4">
                        <strong>Notifiche: </strong>{paziente.reminder=='S'? "Attivate" : "Disattivate"}
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-md-12">
                        <strong>Lista Ospedali:</strong>
                    </div>
                    <div className="mt-1 col-md-12">
                        {listOspedaliString}
                    </div>
                </div>
            </PazienteItemCardCardContainer>

            <Modal
                open={isModalOpen}
                title="ATTENZIONE"
                onClose={() => setIsModalOpen(false)}
                onConfirm={deletePaziente}
            >
                <div className="d-flex justify-content-center">
                    <p style={{ width: '40%' }}>Il paziente <strong>{paziente.nome} {paziente.cognome}</strong> verrà eliminato in maniera permanente. Confermi di voler proseguire ?</p>
                </div>
            </Modal>

            <Modal
                open={isModalAssociazione}
                title="Gestione Associzione Paziente"
                onClose={() => setIsModalAssociazione(false)}
                onConfirm={formik.handleSubmit}
            >
                <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                        <div className="mb-5">
                            <div className="row d-flex flex-column">
                                <div className="col-md-4">
                                    <label>Cognome:</label>
                                    <span>{pazienteDto.cognome}</span>
                                </div>
                                <div className="col-md-4">
                                    <label>Nome:</label>
                                    <span>{pazienteDto.nome}</span>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-12">
                                    <AutocompleteInputField
                                        label="Ospedali"
                                        options={listOspedaliRichiesta}
                                        name={formik.getFieldProps('ospedali').name}
                                        value={formik.getFieldProps('ospedali').value}
                                        onChange={(value) => {
                                            formik.setFieldValue('ospedali', value);
                                        }}
                                        isInvalid={isOspedaliInvalid}
                                        errorMessage={"Inserire almeno un ospedale"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal>
        </>
    )
}

export default PazienteItemCard;