import { BaseDto } from "./BaseDto";
import { CatalogoDto } from "./CatalogoDto";
import { UtenteDto } from "./UtenteDto";

export class MedicoDto extends BaseDto {
  cognome: string;
  nome: string;
  utente: UtenteDto;
  telefono: string;

  constructor() {
    super();
    this.cognome = "";
    this.nome = "";
    this.telefono = "";
    this.utente= new UtenteDto();
  }
}