import React, { useEffect } from "react";
import { useAppDispatch } from "store/storeHooks";
import { login } from "reducers/auth.reducer";

/*
    Questo componente renderizza solo i suoi figli ma si occupa di tenere salvato lo stato
    dell'utente autenticato ricavandolo dalla sessione ogni volta che la pgaina viene refreshata.
*/

const Authkeeper: React.FC<React.PropsWithChildren> = ({ children }) => {

    const dispatch = useAppDispatch();

    useEffect(() => {
        const loggedUser = localStorage.getItem('loggedUser');
        if(!loggedUser) return;

        dispatch(login(JSON.parse(loggedUser)));
    }, [dispatch]);


    return <>{children}</>
}

export default Authkeeper;