export class BaseDto {

    id: number | null | undefined;
    recVer: number | null | undefined;
    valid: boolean;
    usrIn: string;
  
    constructor() {
      this.id = 0;
      this.recVer = 0;
      this.valid = true;
      this.usrIn = '';
    }
  }