import { Button } from "@mui/material";
import { CardBody, InputsContainer } from "components/authCard";
import PasswordInputField from "components/layout/inputs/PasswordInputField";
import { useFormik } from "formik";
import { ChangePasswordDto } from "models/ChangePasswordDto";
import { useState } from "react";
import { setNewMessage } from "reducers/notification.reducer";
import UtenteService from "services/UtenteService";
import { useAppDispatch, useAppSelector } from "store/storeHooks";
import { StatusCodes } from "utils/enums";
import * as Yup from 'yup';
import { PasswordCriteriaBox } from "./styles";
import { CambioPasswordFormProps } from "./types";

const CambioPasswordForm: React.FC<CambioPasswordFormProps> = ({ onChangePasswordDone }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { loggedUser } = useAppSelector(({ auth }) => auth);
    const dispatch = useAppDispatch();

    Yup.addMethod(Yup.string, 'append', function append(appendStr: string) {
        return this.transform((value) => `${value}${appendStr}`);
    });

    const cambioPasswordSchema = Yup.object().shape({
        oldPassword: Yup.string()
            .required('Campo obbligatorio'),
        newPassword: Yup.string()
            .required('Campo obbligatorio')
            .min(8, 'La password deve contenere almeno 8 caratteri')
            .matches(new RegExp('(?=.*[a-z])'), 'La password deve contere almeno una lettera minuscola')
            .matches(new RegExp('(?=.*[A-Z])'), 'La password deve contere almeno una lettera maiuscola')
            .matches(new RegExp('.*\\d.*'), 'La password deve contere almeno un numero')
            .matches(new RegExp('[-+_!@#$%^&*.,?]'), 'La password deve contere almeno un carattere speciale')
            .test('pwd-different', 'password uguale alla precedente', function (value) {
                return value !== this.parent.oldPassword || this.createError();
            }),
        repeatNewPassword: Yup.string()
            .required('Campo obbligatorio')
            .test('pwd-matches', 'Le password non coincidono', function (value) {
                return value === this.parent.newPassword || this.createError();
            }),
    });

    const formik = useFormik({
        initialValues: {
            oldPassword: '',
            newPassword: '',
            repeatNewPassword: ''
        },
        validationSchema: cambioPasswordSchema,
        onSubmit: async (values) => {
            const { oldPassword, newPassword } = values;
            setIsLoading(true);

            UtenteService.changePassword(new ChangePasswordDto(loggedUser!.username, oldPassword, newPassword)).then(res => {
                const { statusCode, message, out } = res;

                if (statusCode === StatusCodes.OK) {
                    dispatch(setNewMessage({
                        level: 'success',
                        message: message
                    }));

                    onChangePasswordDone?.();
                } else {
                    dispatch(setNewMessage({
                        level: 'error',
                        message: message
                    }));
                }
            }).finally(() => {
                setIsLoading(false);
            });
        }
    });

    const isOldPasswordInvalid = formik.touched.oldPassword && formik.errors.oldPassword != null && formik.errors.oldPassword.length > 0;
    const isNewPasswordInvalid = formik.touched.newPassword && formik.errors.newPassword != null && formik.errors.newPassword.length > 0;
    const isRepeatNewPasswordInvalid = formik.touched.repeatNewPassword && formik.errors.repeatNewPassword != null && formik.errors.repeatNewPassword.length > 0;

    return (
        <CardBody>
            <form onSubmit={formik.handleSubmit}>
                <PasswordCriteriaBox
                    isValid={!isOldPasswordInvalid && !isNewPasswordInvalid && !isRepeatNewPasswordInvalid}
                >
                    <ul>
                        <li>Almeno 8 caratteri</li>
                        <li>Almeno una lettera minuscola</li>
                        <li>Almeno una lettera maiuscola</li>
                        <li>Almeno un numero</li>
                        <li>Almeno un carattere speciale (-+_!@#$%^&*.,?)</li>
                    </ul>
                </PasswordCriteriaBox>
                <InputsContainer>
                    <PasswordInputField
                        size="small"
                        label="Vecchia Password"
                        {...formik.getFieldProps('oldPassword')}
                        isInvalid={isOldPasswordInvalid}
                        errorMessage={formik.errors.oldPassword}
                    />

                    <PasswordInputField
                        size="small"
                        label="Nuova Password"
                        {...formik.getFieldProps('newPassword')}
                        isInvalid={isNewPasswordInvalid}
                        errorMessage={formik.errors.newPassword}
                    />

                    <PasswordInputField
                        size="small"
                        label="Ripeti Nuova Password"
                        {...formik.getFieldProps('repeatNewPassword')}
                        isInvalid={isRepeatNewPasswordInvalid}
                        errorMessage={formik.errors.repeatNewPassword}
                    />
                </InputsContainer>
                <div id="reset-password-form-actions-container">
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        size="large"
                        disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
                    >
                        {isLoading ? 'Loading...' : 'Continua'}
                    </Button>
                </div>
            </form>
        </CardBody>
    );
}

export default CambioPasswordForm;