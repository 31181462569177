import { BaseDto } from "./BaseDto";

export class FarmacoDto extends BaseDto {
 
  codice: string;
  descrizione: string;
  dataValidita: Date | null | undefined;

  constructor() {
    super();
    this.codice = "";
    this.descrizione = "";
    this.dataValidita = null;
  }
}