import { createPortal } from "react-dom";
import React, { useEffect, useState } from "react";
import { Backdrop, ModalActions, ModalContainer, ModalOverlay, ModalTitle } from "./styles";
import { ModalProps } from "./types";
import { Button } from "@mui/material";

const Modal: React.FC<ModalProps> = ({ open, onClose, onConfirm, title, children }) => {
    useEffect(() => {
        if (!open) return;

        const keyPressCallback = (evt: KeyboardEvent) => {
            if (evt.key === 'Escape') {
                onClose();
            }
        }

        window.addEventListener('keydown', keyPressCallback);

        return () => {
            window.removeEventListener('keypress', keyPressCallback);
        }
    }, [open, onClose]);

    if (!open) return null;
    return createPortal(
        <>
            <Backdrop />
            <ModalContainer>
                <ModalOverlay>
                    {title && <ModalTitle>{title}</ModalTitle>}
                    {children}

                    <ModalActions>
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={onClose}
                        >
                            Chiudi
                        </Button>
                        <Button variant="contained" onClick={onConfirm}>Conferma</Button>
                    </ModalActions>
                </ModalOverlay>
            </ModalContainer>
        </>
        , document.getElementById('root-modals') as HTMLElement);
}

export default Modal;