import { forwardRef, useMemo } from "react";
import { TextField } from "@mui/material";
import { ErrorParagraph } from "./styles";
import { InputFieldProps, InputColor } from "./types";

const InputField = forwardRef<HTMLInputElement, InputFieldProps>(({ isInvalid = false, errorMessage, color, size = 'small', fullWidth = true, ...props }, ref) => {

    const cssClasses = useMemo<String[]>(() => {
        let classes: String[] = [];

        if (isInvalid) {
            classes.push('isInvalid');
        } else {
            classes = [...classes].filter((c) => c !== 'isInvalid');
        }

        return classes;
    }, [isInvalid]);

    const currentColor = useMemo<InputColor>(() => {
        let c: InputColor = undefined;

        if (isInvalid) {
            c = 'error';
        } else {
            c = color;
        }

        return c;
    }, [color, isInvalid]);

    return (
        <div>
            <TextField
                ref={ref}
                className={cssClasses.join(" ")}
                color={currentColor}
                size={size}
                fullWidth={fullWidth}
                {...props}
            />
            {isInvalid && errorMessage && (<ErrorParagraph>{errorMessage}</ErrorParagraph>)}
        </div>
    );
});


export default InputField;