import styled from "@emotion/styled";
import Card from "components/layout/Card/Card";
import { CardTitleContainer } from "components/layout/Card/styles";

export const PazienteItemCardCardContainer = styled(Card)`
    border-top: 3px solid ${({theme}) => theme.palette.primary.light};
    border-radius: 0.3px;
    min-height: 100px;
`;

export const PazienteItemCardItemCardTitle = styled(CardTitleContainer)`
    min-width: 300px;
    min-height: 30px;
    width: 2px;
    top: -18px;
    left: 0;
    font-size: small;
`;