import Button from "@mui/material/Button";
import { useNavigate, useParams } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import Tooltip from "@mui/material/Tooltip";
import { useEffect, useState } from "react";
import FilterMedico from "components/filterCard/FilterMedico";
import { Alert, Autocomplete, TablePagination, TextField } from "@mui/material";
import Card from "components/layout/Card/Card";
import { pageStart, rowForPage, listForPage, ROUTES } from "utils/constants";
import Emitter from "services/EmitterService";
import { StatusCodes } from "utils/enums";
import { FilterTerapieDto } from "components/filter/FilterTerapieDto";
import TerapieService from "services/TerapieService";
import TerapieItemCard from "components/terapieItemCard/TerapieItemCard";
import { TerapiaSlaveDto, TerapieDto } from "models/TerapieDto";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { CardActionsContainer } from "components/layout/Card/styles";
import { useAppDispatch, useAppSelector } from "store/storeHooks";
import { FarmacoDto } from "models/FarmacoDto";
import { GenericFilter } from "components/filter/GenericFilter";
import FarmacoService from "services/FarmacoService";
import { setNewMessage } from "reducers/notification.reducer";




const TerapieListPage: React.FC = () => {

    let filter: FilterTerapieDto = new FilterTerapieDto();
    const [listaTerapie, setListaTerapie] = useState<TerapiaSlaveDto[]>([]);
    const { id: pazienteId } = useParams();
    const [totalElement, setTotalElement] = useState(0);
    const [page, setPage] = useState(pageStart);
    const [rowsPerPage, setRowsPerPage] = useState(rowForPage);



    useEffect(() => {
        caricaTabella(new FilterTerapieDto());
        leggiFiltro();
        return () => {
            Emitter.off("lista terapie", leggiFiltro());
        };
    }, []);

    function leggiFiltro() {
        Emitter.on('lista terapie', (newValue: FilterTerapieDto) => {
            filter = newValue;
            caricaTabella(filter);
        });
    }

    function intiDataTalbe() {
        setListaTerapie([]);
        setTotalElement(0);
    }

    function caricaTabella(filter: FilterTerapieDto) {
        intiDataTalbe();
        filter.paziente = pazienteId != null ? (Number(pazienteId)) : null;
        TerapieService.listaTerapieMasterSlave(filter).then(res => {
            if (res.statusCode === StatusCodes.OK) {
                setListaTerapie(res.out);
                setTotalElement(res.paginationInfo?.totalElements ?? 0)
                console.log("TERAPIE", res.out);
            } else {
                console.error(res.message);
            }
        }).catch(ex => {
            console.error(ex);
        })
    }

    const navigate = useNavigate();

    const { loggedUser } = useAppSelector(({ auth }) => auth);
    const dispatch = useAppDispatch();

    const [findFarmaco, setFindFarmaco] = useState<string>('');
    const [listFarmaci, setListFarmaci] = useState<FarmacoDto[]>([]);

    useEffect(() => {
        // if (!findFarmaco || findFarmaco.length < 3) {
        //     setListFarmaci([]);
        //     return;
        // };
        const timeoutId = setTimeout(() => {
            const filter = new GenericFilter();

            filter.selectPage = 0;
            filter.pageSize = 200;
            filter.descrizione = findFarmaco;
            FarmacoService.listaFarmaci(filter).then(({ out }) => {
                setListFarmaci(out);
            });
        }, 500);

        return (() => {
            clearTimeout(timeoutId);
        });

    }, [findFarmaco]);

    const removeTerapiaFromList = (terapiaIndex: number) => {
        const terapiaList = [...listaTerapie];
        const updatedList = terapiaList.filter((_, idx) => idx !== terapiaIndex);
        setListaTerapie(updatedList);
    }

    const deleteTerapia = (terapiaSlaveId: number, terapiaSlaveIndex: number) => {
        TerapieService.deleteTerapiaSlave(terapiaSlaveId).then(({ statusCode, message }) => {
            if (statusCode === StatusCodes.OK) {
                dispatch(setNewMessage({
                    level: 'success',
                    message
                }));

                caricaTabella(new FilterTerapieDto());
            } else {
                dispatch(setNewMessage({
                    level: 'error',
                    message
                }));
            }
        });
    }

    const saveTerapie = () => {
        TerapieService.saveUpdateTerapie(listaTerapie).then(({ statusCode, message }) => {

            if (statusCode === StatusCodes.OK) {
                dispatch(setNewMessage({
                    level: 'success',
                    message,
                }));
                caricaTabella(new FilterTerapieDto());
            } else {
                dispatch(setNewMessage({
                    level: 'error',
                    message,
                }));
            }
        });
    }

    return (
        <>

            <div className="d-flex justify-content-start">
                <Button onClick={() => {
                    navigate(ROUTES.paziente.list);
                }} variant="contained" style={{ width: "10px" }} color="primary" >
                    <Tooltip title="indietro" placement="top">
                        <ArrowBackIosNewIcon />
                    </Tooltip>
                </Button>
            </div>

                <Card title="Elenco Farmaci" appearence={{ isFullWidth: true }}>
                    <div className="row mt-4">
                        <div className="col-md-8">
                            <Autocomplete
                                noOptionsText="Digitare almeno tre caratteri..."
                                options={listFarmaci}
                                getOptionLabel={({ codice, descrizione }) => `${codice} - ${descrizione}`}
                                value={null}
                                onChange={(_, value) => {
                                    if (value) {
                                        let terapieList = [...listaTerapie];

                                        const newTerapia = new TerapiaSlaveDto();
                                        newTerapia.farmaco = value;
                                        newTerapia.paziente.id = Number(pazienteId);
                                        newTerapia.medico.utente.id = loggedUser?.id;
                                        terapieList.push(newTerapia);

                                        setListaTerapie(terapieList);
                                    }
                                }}
                                filterSelectedOptions
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Cerca farmaco per principio attivo o descrizione"
                                        onChange={(evt) => {
                                            setFindFarmaco(evt.target.value);
                                        }}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-md-4">
                            <Alert color="warning" icon={false}>
                                Per aggiungere una nuova terapia bisogna sceglierla e selezionarla dal menù a tendina
                            </Alert>
                        </div>
                    </div>

                    {listaTerapie.map((t, idx) => {
                        return (
                            <TerapieItemCard
                                terapia={t}
                                onTerapiaDeleted={() => {
                                    if (t.id) {
                                        deleteTerapia(t.id, idx);
                                    } else {
                                        removeTerapiaFromList(idx);
                                    }
                                }}
                                onTerapiaChange={(terapiaUpdated) => {
                                    const updatedList = [...listaTerapie];
                                    updatedList[idx] = terapiaUpdated;
                                    setListaTerapie(updatedList);
                                }}
                            />
                        );
                    })}

                    <CardActionsContainer>
                        <Button variant="contained" color="success" onClick={saveTerapie}>
                            Salva
                        </Button>
                    </CardActionsContainer>
                </Card>

        </>
    );
}

export default TerapieListPage;