import { DeleteTwoTone, EditTwoTone } from "@mui/icons-material";
import { IconButton, Table, TableCell, TableHead, TableRow } from "@mui/material";
import Modal from "components/modal/Modal";
import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { setNewMessage } from "reducers/notification.reducer";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "utils/constants";
import { StatusCodes } from "utils/enums";
import UtenteService from "services/UtenteService";
import {  ValoriAntropometriciItemCardProps } from "./types";
import moment from "moment";
import EsameService from "services/EsameEmaticoService";
import { ValoriAntropometriciCardTitle, ValoriAntropometriciItemCardContainer } from "./styles";



const  ValoriAntropometriciItemCard: React.FC< ValoriAntropometriciItemCardProps> = ({ valoriAntropometrici, onValoriAntropometriciDelete }) => {

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const [isModalOpenSlave, setIsModalOpenSlave] = useState<boolean>(false);

    const [idSlave, setIdSlave] = useState<number | null | undefined>(null);

    const deleteEsame = () => {
        // EsameService.deleteEsame(esame.esameMaster.id).then(res => {
        //     const { statusCode, message } = res;

        //     if (statusCode === StatusCodes.OK) {
        //         dispatch(setNewMessage({
        //             level: 'success',
        //             message: message
        //         }));
        //         setIsModalOpen(false);
        //         onEsameDeleted();
        //     } else {
        //         dispatch(setNewMessage({
        //             level: 'error',
        //             message: message
        //         }));
        //     }
        // })
    }

    function deleteSlave(id: number | null | undefined) {
        setIsModalOpenSlave(true);
        setIdSlave(id);
    }

    const deleteEsameSlave = () => {
        // EsameService.deleteEsameSlave(idSlave).then(res => {
        //     const { statusCode, message } = res;

        //     if (statusCode === StatusCodes.OK) {
        //         dispatch(setNewMessage({
        //             level: 'success',
        //             message: message
        //         }));
        //         setIsModalOpenSlave(false);
        //         onEsameDeleted();
        //     } else {
        //         dispatch(setNewMessage({
        //             level: 'error',
        //             message: message
        //         }));
        //     }
        // })
    }


    function decodeNumber(valore: number) {
        return valore ? valore : 'ND'
    }

    return (
        <>
             <ValoriAntropometriciItemCardContainer appearence={{ isFullWidth: true }}>
                 {/* <ValoriAntropometriciCardTitle>
                     <div className="container">
                         <p>{moment(valoriAntropometrici.dataInizio).format('DD/MM/yyyy HH:mm:ss')}</p>
                     </div>
                 </ValoriAntropometriciCardTitle>  */}
                <div className="row">
                    <div className="col-md-3">
                       <strong>Data Registrazione:</strong> {moment(valoriAntropometrici.dataInizio).format('DD/MM/yyyy HH:mm:ss')} 
                     </div>
                     <div className="col-md-3">
                       <strong>Altezza:</strong> {valoriAntropometrici.altezza ? decodeNumber(valoriAntropometrici.altezza) + " (cm)" : null} 
                     </div>
                     <div className="col-md-3">
                     <strong>Peso:</strong> {valoriAntropometrici.peso ? decodeNumber(valoriAntropometrici.peso) + " (kg)" : null} 
                     </div>
                     <div className="col-md-3">
                     <strong>Bmi:</strong> {valoriAntropometrici.bmi ? decodeNumber(valoriAntropometrici.bmi) : null} 
                     </div>
                </div>
            </ValoriAntropometriciItemCardContainer>
        </>
    )
}

export default ValoriAntropometriciItemCard;