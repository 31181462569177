import { GenericFilter } from "./GenericFilter";

export class FilterTerapieDto extends GenericFilter {

    medico: number | null;
    paziente: number | null;
    farmaco: number | null;

    constructor() {
        super();
        
        this.medico = null;
        this.paziente = null;
        this.farmaco = null;

    }
}