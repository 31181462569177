import styled from "@emotion/styled";

type alignment = 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly' | 'space-evenly';

interface FlexBoxProps {
    direction?: 'row' | 'column';
    justify?: alignment;
    align?: alignment;
}

export const FlexConfigurableContainer = styled.div<FlexBoxProps>`
    display: flex;
    flex-direction: ${({ direction }) => direction ? direction : 'row'};
    justify-content: ${({ justify }) => justify ? justify : 'center'};
    align-items: ${({ align }) => align ? align : 'center'};
`;