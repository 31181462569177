import AppContext from "contexts/AppContext";
import Layout from "components/layout";
import { Routes, Route, Navigate } from "react-router-dom";
import { ROUTES } from 'utils/constants';
import DashboardPage from "pages/dashboard/DashboardPage";
import PazienteListPage from "pages/paziente/PazienteListPage";
import MeetingsPage from "pages/meetings/MeetingsPage";
import MedicoListPage from "pages/medico/MedicoListPage/MedicoListPage";
import MedicoDetailPage from "pages/medico/MedicoDetailPage/MedicoDetailPage";
import LogoutPage from "pages/logout/LogoutPage";
import ListFarmaciPage from "pages/listFarmaci/ListFarmaciPage";
import ListDocumentiPage from "pages/listaDocumenti/ListDocumentiPage";
import PazienteDetailpage from "pages/paziente/detail/PazienteDetailPage";
import ProfiloClinicoTab from "pages/paziente/detail/tabs/ProfiloClinicoTab/ProfiloClinicoTab";

import VisiteTab from "pages/visita/VisiteTab";
import TerapieListPage from "pages/terapia/TerapieListPage";
import ValoriAntropometriciSubTab from "pages/paziente/detail/tabs/ProfiloClinicoTab/subtabs/valoriAntropometrici/ValoriAntropometriciSubTab";
import VisitaItemCard from "pages/visita/VisitaItemCard";
import EsameEdit from "pages/esame/EsameEdit";
import EsamiTab from "pages/esame/EsamiTab";
import { PrivateRoutesProps } from "./types";
import ProfiloClinicoGenericList from "pages/paziente/detail/tabs/ProfiloClinicoTab/subtabs/profiloClinicoGenericList/ProfiloClinicoGenericList";
import { ProfiloClinicoGenericListTypes } from "utils/enums";
import StileDiVitaSubTab from "pages/paziente/detail/tabs/ProfiloClinicoTab/subtabs/stileVita/StileDiVitaSubTab";
import MedicoSelfEdit from "pages/medico/MedicoSelfEdit/MedicoSelfEdit";
import TrendTab from "pages/paziente/detail/tabs/TrendTab/TrendTab";
import SettingsPage from "pages/settings/SettingsPage";



const PrivateRoutes: React.FC<PrivateRoutesProps> = ({ role = 'M' }) => {
    return (
        <AppContext>
            <Layout>
                <Routes>
                    {role.toUpperCase() === 'A' ? (
                        <>
                            {/* Admin */}
                            <Route path={ROUTES.admin.main}>
                                <Route path={ROUTES.admin.list} element={<MedicoListPage codProfilo="A" />} />
                                <Route path={ROUTES.admin.new} element={<MedicoDetailPage codProfilo="A" />} />
                                <Route path={ROUTES.admin.detail} element={<MedicoDetailPage codProfilo="A" />} />

                                <Route index element={<Navigate to={ROUTES.admin.list} />} />
                            </Route>

                            {/* Medico */}
                            <Route path={ROUTES.medico.main}>
                                <Route path={ROUTES.medico.list} element={<MedicoListPage codProfilo="M" />} />
                                <Route path={ROUTES.medico.new} element={<MedicoDetailPage codProfilo="M" />} />
                                <Route path={ROUTES.medico.detail} element={<MedicoDetailPage codProfilo="M" />} />

                                <Route index element={<Navigate to={ROUTES.medico.list} />} />
                            </Route>

                            <Route path={ROUTES.logout} element={<LogoutPage />} />
                            <Route path={ROUTES.cambioPassword} element={<SettingsPage />} />

                            <Route path="*" element={<Navigate to={ROUTES.admin.main} />} />
                        </>
                    ) : (
                        <>
                            {/* Dashboard */}
                            <Route path={ROUTES.dashboard} element={<DashboardPage />} />
                            <Route path={ROUTES.profilo} element={<MedicoSelfEdit />} />

                            {/* Paziente */}
                            <Route path={ROUTES.paziente.main}>
                                <Route path={ROUTES.paziente.list} element={<PazienteListPage />} />
                                <Route path={ROUTES.paziente.new} element={<PazienteDetailpage />} />
                                <Route path={ROUTES.paziente.detail.main} element={<PazienteDetailpage />}>


                                    {/* Trend */}
                                    <Route path={ROUTES.paziente.detail.tab.trend} element={<TrendTab />} />


                                    {/* Profilo Clinico */}
                                    <Route path={ROUTES.paziente.detail.tab.profiloClinico.main} element={<ProfiloClinicoTab />} >
                                        <Route path={ROUTES.paziente.detail.tab.profiloClinico.valoriAntropometrici} element={<ValoriAntropometriciSubTab />} />
                                        <Route path={ROUTES.paziente.detail.tab.profiloClinico.sileVita} element={<StileDiVitaSubTab />} />
                                        <Route path={ROUTES.paziente.detail.tab.profiloClinico.fattoriRischioCardiovascolare} element={<ProfiloClinicoGenericList type={ProfiloClinicoGenericListTypes.FATTORI_RISCHIO} />} />
                                        <Route path={ROUTES.paziente.detail.tab.profiloClinico.malattieCardiovascolari} element={<ProfiloClinicoGenericList type={ProfiloClinicoGenericListTypes.MALATTIE_CARDIOVASCOLARI} />} />
                                        <Route path={ROUTES.paziente.detail.tab.profiloClinico.malattieConcomitanti} element={<ProfiloClinicoGenericList type={ProfiloClinicoGenericListTypes.MALATTIE_CONCOMITANTI} />} />
                                    </Route>

                                    {/* Visite */}
                                    <Route path={ROUTES.paziente.detail.tab.visite.main} element={<VisiteTab />} />
                                    <Route path={ROUTES.paziente.detail.tab.visite.visualizza} element={<VisitaItemCard />} />


                                    {/* Terapie */}
                                    <Route path={ROUTES.paziente.detail.tab.terapie} element={<TerapieListPage />} />


                                    {/* Esami */}
                                    <Route path={ROUTES.paziente.detail.tab.esami.main} element={<EsamiTab />} />
                                    <Route path={ROUTES.paziente.detail.tab.esami.new} element={<EsameEdit />} />
                                    <Route path={ROUTES.paziente.detail.tab.esami.update} element={<EsameEdit />} />

                                    {/* <Route index element={<Navigate to={ROUTES.paziente.detail.tab.profiloClinico} />} /> */}
                                </Route>

                                <Route index element={<Navigate to={ROUTES.paziente.list} />} />
                            </Route>

                            <Route path={ROUTES.calendar} element={<MeetingsPage />} />

                            <Route path={ROUTES.farmaco.main} >
                                <Route path={ROUTES.farmaco.list} element={<ListFarmaciPage />} />

                                <Route index element={<Navigate to={ROUTES.farmaco.list} />} />
                            </Route>

                            <Route path={ROUTES.farmaco.main} >
                                <Route path={ROUTES.farmaco.list} element={<ListFarmaciPage />} />

                                <Route index element={<Navigate to={ROUTES.farmaco.list} />} />
                            </Route>

                            <Route path={ROUTES.materialeInformativo.main} >
                                <Route path={ROUTES.materialeInformativo.list} element={<ListDocumentiPage />} />

                                <Route index element={<Navigate to={ROUTES.materialeInformativo.list} />} />
                            </Route>

                            <Route path={ROUTES.logout} element={<LogoutPage />} />
                            <Route path={ROUTES.cambioPassword} element={<SettingsPage />} />

                            <Route path="*" element={<Navigate to='/dashboard' />} />
                        </>
                    )}
                </Routes>
            </Layout>
        </AppContext>
    );
}

export default PrivateRoutes;