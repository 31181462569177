import { createTheme } from "@mui/material";

export const appTheme = createTheme({
    palette: {
        primary: {
            main: '#42a5f4',
            light: '#42a5f4',
            contrastText: '#ffff'
        },
        info: {
            main: '#42a5f4',
            light: '#42a5f4',
            contrastText: '#ffff'
        },
        warning: {
            main: '#ffb74d',
            light: '#ffb74d',
            contrastText: '#ffff'
        },
        error: {
            main: '#ef5350',
            light: '#ef5350',
            contrastText: '#ffff'
        },
        success: {
            main: '#9ccc65',
            light: '#9ccc65',
            contrastText: '#ffff'
        },
    }
});