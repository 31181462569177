import { Button, FormControl, InputLabel, MenuItem, Select, TextField, Tooltip } from "@mui/material";
import Card from "components/layout/Card/Card";
import { CardActionsContainer } from "components/layout/Card/styles";
import InputField from "components/layout/inputs/InputField";
import { useFormik } from "formik";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { setNewMessage } from "reducers/notification.reducer";
import { useAppDispatch } from "store/storeHooks";
import { StatusCodes } from "utils/enums";
import { ROUTES } from "utils/constants";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import PazienteService from "services/PazienteService";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { it } from "date-fns/locale";
import { EsameEmaticoDto } from "models/EsameEmaticoDto";
import EsameEmaticoService from "services/EsameEmaticoService";
import { CatalogoDto } from "models/CatalogoDto";
import { FilterCatalogoDto } from "components/filter/FilterCatalogoDto";
import CatalogoService from "services/CatalogoService";
import AutocompleteInputField from "components/layout/inputs/AutocompleteInputField";

export const EsameEdit: React.FC = () => {

    const { idEsame: esameId } = useParams();
    const { id: pazienteId } = useParams();
    const esame = useRef<EsameEmaticoDto>(new EsameEmaticoDto());
    const [listaUnitaMisura, setListaUnitaMisura] = useState<CatalogoDto[]>([]);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!esameId) fillPaziente();
        EsameEmaticoService.getEsameById(Number(esameId)).then(res => {
            const { statusCode, message, out } = res;

            if (statusCode === StatusCodes.KO) {
                dispatch(setNewMessage({
                    level: 'error',
                    message
                }));

                navigate(ROUTES.paziente.detail.tab.visite.main);
            } else {
                fillEsame(out);
            }
        });
    }, []);

    useEffect(() => {
        const filter = new FilterCatalogoDto();
        filter.tipo = "UNI_MIS";
        CatalogoService.findByFilter(filter).then(res => {
            const { out } = res;

            setListaUnitaMisura(out);
        });
    }, []);

    const fillPaziente = () => {
        let id: number = pazienteId != null ? (Number(pazienteId)) : 0;
        PazienteService.getPazienteById(id).then(res => {
            if (res.statusCode === StatusCodes.OK) {
                esame.current.paziente = res.out
                fillEsame(esame.current);
            } else {
                console.error(res.message);
            }
        }).catch(ex => {
            console.error(ex);
        });

    }

    const fillEsame = (e: EsameEmaticoDto) => {
        esame.current = e;

        formik.setFieldValue('dataEsame', e.dataEsame);
        formik.setFieldValue('descrizione', e.descrizione);
        formik.setFieldValue('glicemiaDigiuno', e.glicemiaDigiuno);
        formik.setFieldValue('colesteroloTotale', e.colesteroloTotale);
        formik.setFieldValue('colesteroloLDL', e.colesteroloLDL);
        formik.setFieldValue('colesteroloHDL', e.colesteroloHDL);
        formik.setFieldValue('creatinina', e.creatinina);
        formik.setFieldValue('egfr', e.egfr);
        formik.setFieldValue('emoglobina', e.emoglobina);
        formik.setFieldValue('piastrine', e.piastrine);
        formik.setFieldValue('trigliceridi', e.trigliceridi);
        formik.setFieldValue('hb1Ac', e.hb1Ac);
        formik.setFieldValue('ast', e.ast);
        formik.setFieldValue('alt', e.alt);
        formik.setFieldValue('acidoUrico', e.acidoUrico);
        formik.setFieldValue('ck', e.ck);
        formik.setFieldValue('cpk', e.cpk);
        formik.setFieldValue('tsh', e.tsh);
        formik.setFieldValue('ft3', e.ft3);
        formik.setFieldValue('ft4', e.ft4);
        formik.setFieldValue('ferritina', e.ferritina);
        formik.setFieldValue('transferrina', e.transferrina);
        formik.setFieldValue('sideremia', e.sideremia);
        formik.setFieldValue('lpa', e.lpa);
        formik.setFieldValue('omocisteina', e.omocisteina);
        formik.setFieldValue('amilasi', e.amilasi);
        formik.setFieldValue('lipasi', e.lipasi);
        formik.setFieldValue('ldh', e.ldh);
        formik.setFieldValue('sodio', e.sodio);
        formik.setFieldValue('potassio', e.potassio);
        formik.setFieldValue('azotemia', e.azotemia);
        formik.setFieldValue('bilirubinaTotale', e.bilirubinaTotale);
        formik.setFieldValue('ntProBNP', e.ntProBNP);
        formik.setFieldValue('bnp', e.bnp);
        formik.setFieldValue('troponinaI', e.troponinaI);
        formik.setFieldValue('refColesteroloTotaleUM', e.refColesteroloTotaleUM.descrizione);
        formik.setFieldValue('refColesteroloLDLUM', e.refColesteroloLDLUM.descrizione);
        formik.setFieldValue('refColesteroloHDLUM', e.refColesteroloHDLUM.descrizione);
        formik.setFieldValue('refTrigliceridiUM', e.refTrigliceridiUM.descrizione);
        formik.setFieldValue('refTroponinaIUM', e.refTroponinaIUM.descrizione);


    }

    const formik = useFormik({
        initialValues: {
            nome: '',
            cognome: '',
            dataEsame: new Date(),
            glicemiaDigiuno: '',
            colesteroloTotale: '',
            colesteroloLDL: '',
            colesteroloHDL: '',
            creatinina: '',
            egfr: '',
            emoglobina: '',
            piastrine: '',
            trigliceridi: '',
            hb1Ac: '',
            ast: '',
            alt: '',
            acidoUrico: '',
            ck: '',
            cpk: '',
            tsh: '',
            ft3: '',
            ft4: '',
            ferritina: '',
            transferrina: '',
            sideremia: '',
            lpa: '',
            omocisteina: '',
            amilasi: '',
            lipasi: '',
            ldh: '',
            sodio: '',
            potassio: '',
            azotemia: '',
            bilirubinaTotale: '',
            ntProBNP: '',
            bnp: '',
            troponinaI: '',
            refColesteroloTotaleUM: '',
            refColesteroloLDLUM: '',
            refColesteroloHDLUM: '',
            refTrigliceridiUM: '',
            refTroponinaIUM: '',
        },
        onSubmit: async (values) => {
            const { nome, cognome, dataEsame, glicemiaDigiuno, colesteroloTotale, colesteroloLDL,
                colesteroloHDL, creatinina, egfr, emoglobina, piastrine, trigliceridi, hb1Ac, ast, alt, acidoUrico,
                ck, cpk, tsh, ft3, ft4, ferritina, transferrina, sideremia, lpa, omocisteina, amilasi, lipasi, ldh,
                sodio, potassio, azotemia, bilirubinaTotale, ntProBNP, bnp, troponinaI, refColesteroloTotaleUM, refColesteroloLDLUM, refColesteroloHDLUM, refTrigliceridiUM, refTroponinaIUM } = values;

            esame.current.paziente.nome = nome;
            esame.current.paziente.cognome = cognome;
            esame.current.dataEsame = dataEsame;
            esame.current.glicemiaDigiuno = Number(glicemiaDigiuno);
            esame.current.colesteroloTotale = Number(colesteroloTotale);
            esame.current.colesteroloLDL = Number(colesteroloLDL);
            esame.current.colesteroloHDL = Number(colesteroloHDL);
            esame.current.creatinina = Number(creatinina);
            esame.current.egfr = Number(egfr);
            esame.current.emoglobina = Number(emoglobina);
            esame.current.piastrine = Number(piastrine);
            esame.current.trigliceridi = Number(trigliceridi);
            esame.current.hb1Ac = Number(hb1Ac);
            esame.current.ast = Number(ast);
            esame.current.alt = Number(alt);
            esame.current.acidoUrico = Number(acidoUrico);
            esame.current.ck = Number(ck);
            esame.current.cpk = Number(cpk);
            esame.current.tsh = Number(tsh);
            esame.current.ft3 = Number(ft3);
            esame.current.ft4 = Number(ft4);
            esame.current.ferritina = Number(ferritina);
            esame.current.transferrina = Number(transferrina);
            esame.current.sideremia = Number(sideremia);
            esame.current.lpa = Number(lpa);
            esame.current.omocisteina = Number(omocisteina);
            esame.current.amilasi = Number(amilasi);
            esame.current.lipasi = Number(lipasi);
            esame.current.ldh = Number(ldh);
            esame.current.sodio = Number(sodio);
            esame.current.potassio = Number(potassio);
            esame.current.azotemia = Number(azotemia);
            esame.current.bilirubinaTotale = Number(bilirubinaTotale);
            esame.current.ntProBNP = Number(ntProBNP);
            esame.current.bnp = Number(bnp);
            esame.current.troponinaI = Number(troponinaI);
            esame.current.refColesteroloTotaleUM = getCatalogo(refColesteroloTotaleUM);
            esame.current.refColesteroloLDLUM = getCatalogo(refColesteroloLDLUM);
            esame.current.refColesteroloHDLUM = getCatalogo(refColesteroloHDLUM);
            esame.current.refTrigliceridiUM = getCatalogo(refTrigliceridiUM);
            esame.current.refTroponinaIUM = getCatalogo(refTroponinaIUM);

            saveEsame(esame.current);
        }
    });

    function getCatalogo(descrizione: string) {
        var prova: CatalogoDto = new CatalogoDto();
        listaUnitaMisura.forEach(x => {
            if (x.descrizione == descrizione) prova = x
        })
        return prova;
    }

    function saveEsame(esame: EsameEmaticoDto) {
        EsameEmaticoService.saveOrUpdateEsame(esame).then(res => {
            const { statusCode, message } = res;

            if (statusCode === StatusCodes.OK) {
                dispatch(setNewMessage({
                    level: "success",
                    message
                }));

                navigate(ROUTES.paziente.detail.tab.esami.main.replace(':id', `${pazienteId}`));
            } else {
                dispatch(setNewMessage({
                    level: "error",
                    message
                }));
            }
        }).catch(err => {
            dispatch(setNewMessage({
                level: "error",
                message: err
            }));
        })
    };

    const listUnoDue = useMemo(() => listaUnitaMisura?.filter(um => um.codice === '001' || um.codice === '002'),[listaUnitaMisura]);
    const listTreQuattro = useMemo(() => listaUnitaMisura?.filter(um => um.codice === '003' || um.codice === '004'),[listaUnitaMisura]);

    return (
        <>
            <div className="d-flex justify-content-start">
                <Button onClick={() => {
                    navigate(ROUTES.paziente.detail.tab.esami.main.replace(':id', `${pazienteId}`));
                }} variant="contained" style={{ width: "10px" }} color="primary" >
                    <Tooltip title="indietro" placement="top">
                        <ArrowBackIosNewIcon />
                    </Tooltip>
                </Button>
            </div>

            <Card
                appearence={{
                    isFullWidth: true,
                }}
            >
                <form onSubmit={formik.handleSubmit}>
                    <div className="row col-md-12 mt-3">
                        <div className="col-md-3">
                            <LocalizationProvider adapterLocale={it} dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    label="Data Esame"
                                    inputFormat="dd/MM/yyyy"
                                    value={formik.getFieldProps('dataEsame').value}
                                    onChange={(value) => {
                                        formik.setFieldValue('dataEsame', value);
                                    }}
                                    renderInput={(params) => <TextField {...params} size="small" fullWidth />}
                                />
                            </LocalizationProvider>
                        </div>
                    </div>
                    <div className="row col-md-12 mt-5">
                        <div className="col-md-3">
                            <InputField
                                type="number"
                                size="small"
                                label="Glicemia A Digiuno mg/dl"
                                {...formik.getFieldProps('glicemiaDigiuno')}
                            />
                        </div>
                        <div className="col-md-3">
                            <InputField
                                type="number"
                                size="small"
                                fullWidth={true}
                                label="Creatinina mg/dl;"
                                {...formik.getFieldProps('creatinina')}
                            />
                        </div>
                        <div className="col-md-3">
                            <InputField
                                type="number"
                                size="small"
                                fullWidth={true}
                                label="Emoglobina gr/L"
                                {...formik.getFieldProps('emoglobina')}
                            />
                        </div>
                        <div className="col-md-3">
                            <InputField
                                type="number"
                                size="small"
                                fullWidth={true}
                                label="Piastrine 10^3/μl"
                                {...formik.getFieldProps('piastrine')}
                            />
                        </div>
                    </div>
                    <div className="row col-md-12 mt-3">
                        <div className="col-md-3">
                            <InputField
                                type="number"
                                size="small"
                                fullWidth={true}
                                label="Hb1Ac % - mmol/mol"
                                {...formik.getFieldProps('hb1Ac')}
                            />
                        </div>
                        <div className="col-md-3">
                            <InputField
                                type="number"
                                size="small"
                                fullWidth={true}
                                label="AST mg/dl"
                                {...formik.getFieldProps('ast')}
                            />
                        </div>
                        <div className="col-md-3">
                            <InputField
                                type="number"
                                size="small"
                                fullWidth={true}
                                label="ALT mg/dl"
                                {...formik.getFieldProps('alt')}
                            />
                        </div>
                        <div className="col-md-3">
                            <InputField
                                type="number"
                                size="small"
                                fullWidth={true}
                                label="Acido Urico mg/dl"
                                {...formik.getFieldProps('acidoUrico')}
                            />
                        </div>
                    </div>
                    <div className="row col-md-12 mt-3">
                        <div className="col-md-3">
                            <InputField
                                type="number"
                                size="small"
                                fullWidth={true}
                                label="CK mg/dl"
                                {...formik.getFieldProps('ck')}
                            />
                        </div>
                        <div className="col-md-3">
                            <InputField
                                type="number"
                                size="small"
                                fullWidth={true}
                                label="CPK mg/dl"
                                {...formik.getFieldProps('cpk')}
                            />
                        </div>
                        <div className="col-md-3">
                            <InputField
                                type="number"
                                size="small"
                                fullWidth={true}
                                label="TSH µU/ml"
                                {...formik.getFieldProps('tsh')}
                            />
                        </div>
                        <div className="col-md-3">
                            <InputField
                                type="number"
                                size="small"
                                fullWidth={true}
                                label="FT3 pmol/ml"
                                {...formik.getFieldProps('ft3')}
                            />
                        </div>
                    </div>
                    <div className="row col-md-12 mt-3">
                        <div className="col-md-3">
                            <InputField
                                type="number"
                                size="small"
                                fullWidth={true}
                                label="FT4 ng/dl"
                                {...formik.getFieldProps('ft4')}
                            />
                        </div>
                        <div className="col-md-3">
                            <InputField
                                type="number"
                                size="small"
                                fullWidth={true}
                                label="Ferritina ng/mL"
                                {...formik.getFieldProps('ferritina')}
                            />
                        </div>
                        <div className="col-md-3">
                            <InputField
                                type="number"
                                size="small"
                                fullWidth={true}
                                label="Transferrina g/dl"
                                {...formik.getFieldProps('transferrina')}
                            />
                        </div>
                        <div className="col-md-3">
                            <InputField
                                type="number"
                                size="small"
                                fullWidth={true}
                                label="Sideremia mcg/dl"
                                {...formik.getFieldProps('sideremia')}
                            />
                        </div>
                    </div>
                    <div className="row col-md-12 mt-3">
                        <div className="col-md-3">
                            <InputField
                                type="number"
                                size="small"
                                fullWidth={true}
                                label="LPA mg/dl"
                                {...formik.getFieldProps('lpa')}
                            />
                        </div>
                        <div className="col-md-3">
                            <InputField
                                type="number"
                                size="small"
                                fullWidth={true}
                                label="Omocisteina mg/dl"
                                {...formik.getFieldProps('omocisteina')}
                            />
                        </div>
                        <div className="col-md-3">
                            <InputField
                                type="number"
                                size="small"
                                fullWidth={true}
                                label="Amilasi mg/dl"
                                {...formik.getFieldProps('amilasi')}
                            />
                        </div>
                        <div className="col-md-3">
                            <InputField
                                type="number"
                                size="small"
                                fullWidth={true}
                                label="Lipasi mg/dl"
                                {...formik.getFieldProps('lipasi')}
                            />
                        </div>
                    </div>
                    <div className="row col-md-12 mt-3">
                        <div className="col-md-3">
                            <InputField
                                type="number"
                                size="small"
                                fullWidth={true}
                                label="LDH mg/dl"
                                {...formik.getFieldProps('ldh')}
                            />
                        </div>
                        <div className="col-md-3">
                            <InputField
                                type="number"
                                size="small"
                                fullWidth={true}
                                label="Sodio mmol/lit"
                                {...formik.getFieldProps('sodio')}
                            />
                        </div>
                        <div className="col-md-3">
                            <InputField
                                type="number"
                                size="small"
                                fullWidth={true}
                                label="Potassio mmol/lit"
                                {...formik.getFieldProps('potassio')}
                            />
                        </div>
                        <div className="col-md-3">
                            <InputField
                                type="number"
                                size="small"
                                fullWidth={true}
                                label="Azotemia mg/dl"
                                {...formik.getFieldProps('azotemia')}
                            />
                        </div>
                    </div>
                    <div className="row col-md-12 mt-3">
                        <div className="col-md-3">
                            <InputField
                                type="number"
                                size="small"
                                fullWidth={true}
                                label="Bilirubina Totale mg/dl"
                                {...formik.getFieldProps('bilirubinaTotale')}
                            />
                        </div>
                        <div className="col-md-3">
                            <InputField
                                type="number"
                                size="small"
                                fullWidth={true}
                                label="NT Pro BNP pg/ml"
                                {...formik.getFieldProps('ntProBNP')}
                            />
                        </div>
                        <div className="col-md-3">
                            <InputField
                                type="number"
                                size="small"
                                fullWidth={true}
                                label="BNP pg/ml"
                                {...formik.getFieldProps('bnp')}
                            />
                        </div>
                        {esameId ?
                            // <div className="row col-md-12 mt-3">
                            <div className="col-md-3">
                                <InputField
                                    disabled
                                    type="number"
                                    size="small"
                                    fullWidth={true}
                                    label="eGFR mL/min/1.73 m2"
                                    {...formik.getFieldProps('egfr')}
                                />
                            </div>
                            // </div>
                            : null}
                    </div>
                    <div className="row col-md-12 mt-3">
                        <div className="col-md-4">
                            <InputField
                                type="number"
                                size="small"
                                fullWidth={true}
                                label="Colesterolo Totale mg/dl - mmol/L"
                                {...formik.getFieldProps('colesteroloTotale')}
                            />
                        </div>
                        <div className="col-md-2">
                            <FormControl size="small" fullWidth>
                                <InputLabel id="refColesteroloTotaleUM">Unità di Misura</InputLabel>
                                <Select
                                    labelId="Unità di Misura"
                                    label="Unità di Misura"
                                    {...formik.getFieldProps('refColesteroloTotaleUM')}
                                    onChange={(evt) => {
                                        formik.setFieldValue('refColesteroloTotaleUM', evt.target.value);
                                    }}
                                >
                                    {listUnoDue.map(um => (
                                        <MenuItem value={um.descrizione}>{um.descrizione}</MenuItem>)
                                    )}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-md-4">
                            <InputField
                                type="number"
                                size="small"
                                fullWidth={true}
                                label="Colesterolo LDL mg/dl - mmol/L"
                                {...formik.getFieldProps('colesteroloLDL')}
                            />
                        </div>
                        <div className="col-md-2">
                            <FormControl size="small" fullWidth>
                                <InputLabel id="refColesteroloLDLUM">Unità di Misura</InputLabel>
                                <Select
                                    labelId="Unità di Misura"
                                    label="Unità di Misura"
                                    {...formik.getFieldProps('refColesteroloLDLUM')}
                                    onChange={(evt) => {
                                        formik.setFieldValue('refColesteroloLDLUM', evt.target.value);
                                    }}
                                >
                                    {listUnoDue.map(um => (
                                        <MenuItem value={um.descrizione}>{um.descrizione}</MenuItem>)
                                    )}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className="row col-md-12 mt-3">
                        <div className="col-md-4">
                            <InputField
                                type="number"
                                size="small"
                                fullWidth={true}
                                label="Colesterolo HDL mg/dl - mmol/L"
                                {...formik.getFieldProps('colesteroloHDL')}
                            />
                        </div>
                        <div className="col-md-2">
                            <FormControl size="small" fullWidth>
                                <InputLabel id="refColesteroloHDLUM">Unità di Misura</InputLabel>
                                <Select
                                    labelId="Unità di Misura"
                                    label="Unità di Misura"
                                    {...formik.getFieldProps('refColesteroloHDLUM')}
                                    onChange={(evt) => {
                                        formik.setFieldValue('refColesteroloHDLUM', evt.target.value);
                                    }}
                                >
                                    {listUnoDue.map(um => (
                                        <MenuItem value={um.descrizione}>{um.descrizione}</MenuItem>)
                                    )}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-md-4">
                            <InputField
                                type="number"
                                size="small"
                                fullWidth={true}
                                label="Trigliceridi mg/dl - mmol/L"
                                {...formik.getFieldProps('trigliceridi')}
                            />
                        </div>
                        <div className="col-md-2">
                            <FormControl size="small" fullWidth>
                                <InputLabel id="refTrigliceridiUM">Unità di Misura</InputLabel>
                                <Select
                                    labelId="Unità di Misura"
                                    label="Unità di Misura"
                                    {...formik.getFieldProps('refTrigliceridiUM')}
                                    onChange={(evt) => {
                                        formik.setFieldValue('refTrigliceridiUM', evt.target.value);
                                    }}
                                >
                                    {listUnoDue.map(um => (
                                        <MenuItem value={um.descrizione}>{um.descrizione}</MenuItem>)
                                    )}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className="row col-md-12 mt-3">
                        <div className="col-md-4">
                            <InputField
                                type="number"
                                size="small"
                                fullWidth={true}
                                label="Troponina I ng/ml - pg/ml"
                                {...formik.getFieldProps('troponinaI')}
                            />
                        </div>
                        <div className="col-md-2">
                            <FormControl size="small" fullWidth>
                                <InputLabel id="refTroponinaIUM">Unità di Misura</InputLabel>
                                <Select
                                    labelId="Unità di Misura"
                                    label="Unità di Misura"
                                    {...formik.getFieldProps('refTroponinaIUM')}
                                    onChange={(evt) => {
                                        formik.setFieldValue('refTroponinaIUM', evt.target.value);
                                    }}
                                >
                                    {listTreQuattro.map(um => (
                                        <MenuItem value={um.descrizione}>{um.descrizione}</MenuItem>)
                                    )}
                                </Select>
                            </FormControl>
                        </div>


                    </div>
                    {/* {esameId ?
                        <div className="row col-md-12 mt-3">
                            <div className="col-md-3">
                                <InputField
                                    disabled
                                    type="number"
                                    size="small"
                                    fullWidth={true}
                                    label="eGFR mL/min/1.73 m2"
                                    {...formik.getFieldProps('egfr')}
                                />
                            </div>
                        </div>
                        : null} */}


                    <CardActionsContainer>
                        <Button type="submit" variant="contained" color="success" onClick={() => {
                            console.log("SUBMIT VALUE");
                        }}>
                            Salva
                        </Button>
                    </CardActionsContainer>
                </form>
            </Card>
        </>
    );
}

export default EsameEdit;