import styled from "@emotion/styled";
import { CardActionsContainer } from "components/layout/Card/styles";
import { NavLink } from "react-router-dom";

export const StatusIndicator = styled.div<{ score: number }>`
    width: 30px;
    height: 30px;
    display : flex;
    border-radius: 50%;
    background-color: ${({ theme, score }) => {
        if (score < 5) {
            return theme.palette.success.light;
        }

        if (score < 8) {
            return theme.palette.warning.light;
        }

        return theme.palette.error.light;
    }};
`;

export const PazienteDetailActionsContainer = styled(CardActionsContainer)`
    margin-bottom: 50px;
`;

export const HeadingTabContainer = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    height: 50px;
    width: 100%;
    padding: 0 1.5rem;
    display: flex;
    justify-content: flex-start;
`;

export const HeadingTabBox = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-bottom-width: 3px;
    border-bottom-style: solid;
    border-bottom-color: #ffff;
    margin-right: 2rem;
`;

export const TabLink = styled(NavLink)`
        text-decoration: none;
        font-weight: 600;
        font-size: 1.2rem;
        color: #a1a5b6;

        @media (max-width: 500px) {
                font-size: 0.6rem;
        }

        &:hover, &.active {
            color: ${({ theme }) => theme.palette.primary.light};
        

        > div {
            border-bottom-color: ${({ theme }) => theme.palette.primary.light};
        }
    }
`;