import { BaseDto } from "./BaseDto";
import { CatalogoDto } from "./CatalogoDto";
import { UtenteDto } from "./UtenteDto";

export class DocumentiUtiliDto extends BaseDto {
 
  nomeFile: string;
  path: string;
  tipo: string;
  tag: string;
  data: string;
  dataCaricamento: Date | null | undefined;

  constructor() {
    super();
    this.nomeFile = "";
    this.path = "";
    this.tag = "";
    this.tipo = "";
    this.data = "";
    this.dataCaricamento = null;
  }
}


export const PARAMETERSYSTEM = {
  ESTENSIONI: ['doc', 'docx', 'xls', 'xlsx', 'pdf', 'jpeg', 'jpg', 'png', 'odt'],
  ESTENSIONI_IMG: ['jpeg', 'jpg', 'png'],
  ESTENSIONI_ZIP: ['zip'],
  ESTENSIONI_CSV: ['text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'],
};


