import { Button, TablePagination, Tooltip } from "@mui/material";
import { FilterPazienteDto } from "components/filter/FilterPazienteDto";
import { PazienteDto } from "models/PazienteDto";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Emitter from "services/EmitterService";
import PazienteService from "services/PazienteService";
import { listForPage, pageStart, ROUTES, rowForPage } from "utils/constants";
import { StatusCodes } from "utils/enums";
import AddIcon from '@mui/icons-material/Add';
import Card from "components/layout/Card/Card";
import FilterPaziente from "components/filterCard/FilterPaziente";
import PazienteItemCard from "components/layout/pazienteItemCard/PazienteItemCard";
import { useAppSelector } from "store/storeHooks";

const PazienteListPage: React.FC = () => {


    const { loggedUser } = useAppSelector(({ auth }) => auth);

    let filter: FilterPazienteDto = new FilterPazienteDto();

    const [listaPaziente, setListaPaziente] = useState<PazienteDto[]>([]);

    const [totalElement, setTotalElement] = useState(0);
    const [page, setPage] = useState(pageStart);
    const [rowsPerPage, setRowsPerPage] = useState(rowForPage);


    const [elementiPerPAgine, setElementiPerPAgine] = useState(listForPage);

    useEffect(() => {
        caricaTabella(inizializzaFiltro(new FilterPazienteDto()));
        leggiFiltro();
        return () => {
            Emitter.off("paziente", leggiFiltro());
        };
    }, []);

    function leggiFiltro() {
        Emitter.on('paziente', (newValue: FilterPazienteDto) => {
            filter = newValue;
            setRowsPerPage(25);
            setPage(0);
            caricaTabella(filter);
        });
    }


    function inizializzaFiltro(filter: FilterPazienteDto) {
        
        let arrayNum: number[] = [];
        loggedUser?.medicoDto.utente.codiciIntervento.forEach(item => {
            if (item.id) {
                arrayNum.push(item.id);
            }
        });
        filter.conoVisibilita = arrayNum;

        return filter;
    }


    function intiDataTalbe() {
        setListaPaziente([]);
        setTotalElement(0);
    }

    function caricaTabella(filter: FilterPazienteDto) {
        intiDataTalbe();
        PazienteService.listPazienti(filter).then(res => {
            if (res.statusCode === StatusCodes.OK) {
                setListaPaziente(res.out);
                setTotalElement(res.paginationInfo?.totalElements ?? 0)
            } else {
                console.error(res.message);
            }
        }).catch(ex => {
            console.error(ex);
        })
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        filter.selectPage = newPage;
        filter.pageSize = rowsPerPage;
        caricaTabella(filter);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        filter.selectPage = page;
        filter.pageSize = parseInt(event.target.value, 10);
        caricaTabella(filter);
    };

    const navigate = useNavigate();
 

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={() => {
                    navigate(ROUTES.paziente.new);
                }} variant="contained" style={{ width: "10px" }} color="success" >
                    <Tooltip title="Aggiungi Paziente" placement="top">
                        <AddIcon />
                    </Tooltip>
                </Button>
            </div>

            <FilterPaziente tipo="paziente" />
            <Card title="Elenco Pazienti" appearence={{ isFullWidth: true }}>

                {listaPaziente.length> 0 ? 
                        listaPaziente.map(m => <PazienteItemCard paziente={m} onPazienteDeleted={() =>  caricaTabella(inizializzaFiltro(new FilterPazienteDto()))} />
                        ) : <h3>La ricerca non ha prodotto risutati</h3>}
              
                {listaPaziente.length > 0 && (
                    <TablePagination
                        rowsPerPageOptions={elementiPerPAgine}
                        component="div"
                        count={totalElement}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                )}
                
            </Card>
        </>
    );
}

export default PazienteListPage;