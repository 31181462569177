import { useAppSelector } from "store/storeHooks";
import LoginPage from "pages/login/LoginPage";
import PrivateRoutes from "./PrivateRoutes";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ROUTES } from "utils/constants";
import ResetPasswordPage from "pages/resetPassword/ResetPasswordPage";
import PrivacyPage from "pages/privacy/PrivacyPage";

const AppRoutes: React.FC = () => {
    const isUserAuth = localStorage.getItem('loggedUser');

    const { loggedUser } = useAppSelector(({ auth }) => auth);

    if (!isUserAuth) {
        return (
            <BrowserRouter  basename={process.env.REACT_APP_ROUTER_BASE || ''}>
                <Routes>
                    <Route path={ROUTES.login} element={<LoginPage />} />

                    <Route index element={<Navigate to={ROUTES.login} />} />
                    <Route path="/*" element={<Navigate to={ROUTES.login} />} />
                    <Route path={ROUTES.privacy} element={<PrivacyPage />} />
                </Routes>
            </BrowserRouter>
        );
    }

    if(loggedUser?.resetPassword) {
        return (
            <BrowserRouter  basename={process.env.REACT_APP_ROUTER_BASE || ''}>
                <Routes>
                    <Route path="/cambio-password" element={<ResetPasswordPage />} />
                    <Route path="/*" element={<Navigate to={'/cambio-password'} />} />
                </Routes>
            </BrowserRouter>
        );
    }

    return (
        <BrowserRouter  basename={process.env.REACT_APP_ROUTER_BASE || ''}>
            <Routes>
                <Route path="/*" element={<PrivateRoutes role={loggedUser?.codProfilo} />} />
            </Routes>
        </BrowserRouter>
    );
}

export default AppRoutes;