import { GenericFilter } from "./GenericFilter";

export class FilterUtenteDto extends GenericFilter {

    nome: string;
    cognome: string;
    profilo: number | null;
    codiceProfilo: string;
    ruolo: number | null;
    attivo: string;
    ospedale: number | null;
    listaIdOspedali: number[]; 

    constructor(codiceProfilo?: string) {
        super();
        this.nome = "";
        this.cognome = "";
        this.profilo = null;
        this.codiceProfilo = codiceProfilo ? codiceProfilo : "";
        this.ruolo = null;
        this.attivo = "";
        this.ospedale = null;
        this.listaIdOspedali = [];
    }

}