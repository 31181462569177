import { BaseDto } from "./BaseDto";
import { CatalogoDto } from "./CatalogoDto";
import { MedicoDto } from "./MedicoDto";
import { ProfiloDto } from "./ProfiloDto";

export class UtenteDto extends BaseDto {
  usrName: string;
  nome: string;
  cognome: string;
  usrMail: string;
  dataScadenzaPassword: Date;
  login: boolean;
  profilo: ProfiloDto;
  flagConferma: string;
  usrPhone: string;
  usrPwd: string;
  primoLogin: string;
  codiciIntervento: CatalogoDto[];

  constructor() {
    super();
    this.usrName = '';
    this.nome = '';
    this.cognome = '';
    this.usrMail = '';
    this.dataScadenzaPassword = new Date();
    this.login = false;
    this.profilo = new ProfiloDto();
    this.flagConferma = '';
    this.usrPhone = '';
    this.usrPwd = '';
    this.primoLogin = '';
    this.codiciIntervento = [];
  }
}


export class ApplicationDto {
  
  etag: string;
  token: string;
  username: string;
  cognome: string;
  codProfilo: string;
  descrizioneProfilo: string;
  id: number | null;
  primoLogin: string;
  usrPhone: string;
  usrPwd: string;
	idPaziente: number | null;
	idMedico: number | null;
	idOspedale: number | null;
	medicoDto: MedicoDto;
  resetPassword: boolean;

  constructor() {
    this.etag = "";
    this.token = "";
    this.username = "";
    this.cognome = "";
    this.codProfilo = "";
    this.descrizioneProfilo = "";
    this.id= null;
    this.primoLogin = "";
    this.usrPhone = "";
    this.usrPwd = "";
    this.primoLogin = "";
    this.idPaziente= null;
    this.idMedico=  null;
    this.idOspedale =  null;
    this.medicoDto = new  MedicoDto();
    this.resetPassword = true;
  }
}


export class RichiestaAssociazioneDto extends BaseDto {

  utente: UtenteDto;
  ospedale: CatalogoDto;
  statoEvasa : string;

  constructor() {
    super();
    this.utente = new UtenteDto();
    this.ospedale = new CatalogoDto();
    this.statoEvasa = "N";
  }


}