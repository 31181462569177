import { DeleteTwoTone, EditTwoTone } from "@mui/icons-material";
import { AlertColor, Button, IconButton, Tooltip } from "@mui/material";
import Modal from "components/modal/Modal";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setNewMessage } from "reducers/notification.reducer";
import { ROUTES } from "utils/constants";
import { StatusCodes } from "utils/enums";
import UtenteService from "services/UtenteService";
import { VisitaItemCardProps } from "./types";
import { VisitaItemCardContainer, VisitaItemCardTitle } from "./styles";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { VisitaDto } from "models/VisitaDto";
import VisitaService from "services/VisitaService";
import { useAppDispatch } from "store/storeHooks";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Card from "components/layout/Card/Card";
import { StatusIndicator } from "pages/paziente/detail/styles";



const VisitaItemCard: React.FC<VisitaItemCardProps> = () => {

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const { idVisita: visitaId } = useParams();
    const { id: pazienteId } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [visita, setVisita] = useState<VisitaDto>(new VisitaDto());

    const formatYesOrNo = (value: string) => value.toUpperCase() === 'S' ? 'SI' : 'NO';

    const labelCriticita = useMemo(() => {
        if (visita.punteggioTotale < 5) {
            return 'Lieve';
        }

        if (visita.punteggioTotale < 8) {
            return 'Moderata';
        }

        return 'Grave';
    }, [visita.punteggioTotale]);


    useEffect(() => {
        VisitaService.getVisitaById(Number(visitaId)).then(res => {
            const { statusCode, message, out } = res;

            if (statusCode === StatusCodes.OK) {
                setVisita(out);

            } else {
                dispatch(setNewMessage({
                    level: 'error',
                    message
                }));
                navigate(ROUTES.paziente.detail.tab.visite.main);
            }
        });
    }, []);


    return (
        <>
            <div className="d-flex justify-content-start mb-2">
                <Button onClick={() => {
                    navigate(ROUTES.paziente.detail.tab.visite.main.replace(':id', `${pazienteId}`));
                }} variant="contained" style={{ width: "10px" }} color="primary" >
                    <Tooltip title="Torna ad Elenco Visite" placement="top">
                        <ArrowBackIosNewIcon />
                    </Tooltip>
                </Button>
            </div>

            <Card
                appearence={{
                    isFullWidth: true,
                }}
            >
                <div className="d-flex align-items-center justify-content-start">
                    <StatusIndicator score={visita.punteggioTotale} />
                    <h3 style={{ marginLeft: '8px', marginRight: '3px' }}>Sintomatologia: </h3> <p>{labelCriticita}</p>
                </div>

                <div className="row mt-3">
                    <div className="col-md-4">
                        <strong>Data Visita: </strong>{moment(visita.dataVisita).format('DD/MM/yyyy')}
                    </div>
                    <div className="col-md-4">
                        <strong>Variazione Peso In Una Settimana: </strong>{visita.pesoCorporeo}
                    </div>

                    <div className="col-md-4">
                        <strong>Frequenza Cardiaca: </strong>{visita.frequenzaCardiaca}
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-md-4">
                        <strong>Pressione Arteriosa Sistolica: </strong>{visita.pressioneArteriosaSistolica}
                    </div>
                    <div className="col-md-4">
                        <strong>Pressione Arteriosa Diastolica: </strong>{visita.pressioneArteriosaDiastolica}

                    </div>

                    <div className="col-md-4">
                        <strong>Saturazione: </strong>{visita.saturazione}
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-md-4">
                        <strong>Gonfiore Gambe: </strong>{visita.gonfioreGambe}
                    </div>
                    {/* <div className="col-md-4">
                        <strong>Astenia: </strong>{visita.astenia}
                    </div> */}

                    <div className="col-md-4">
                        <strong>Dolore Toracico: </strong>{visita.doloreToracico}
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-md-4">
                        <strong>Episodio Sincopale: </strong>{formatYesOrNo(visita.episodioSincopale)}
                    </div>
                    <div className="col-md-4">
                        <strong>Cardiopalmo: </strong>{formatYesOrNo(visita.cardiopalmo)}
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-md-4">
                        <strong>Percentuale Aderenza Terapia Farmaco: </strong>{visita.percentAderenzaTerapiaPerFarmaco}
                    </div>

                    <div className="col-md-4">
                        <strong>Aderenza Terapia: </strong>{visita.aderenzaTerapia}
                    </div>
                </div>

                <div className="row mt-3">
                    {/* <div className="col-md-4">
                        <strong>Aggiunta Cuscino: </strong>{visita.aggiuntaCuscino}
                    </div> */}
                    <div className="col-md-4">
                        <strong>Respiro: </strong>{visita.respiro}
                    </div>

                </div>

            </Card>
        </>
    )
}

export default VisitaItemCard;