import { CalendarioVisiteDto } from "models/CalendarioVisiteDto";

export interface CalendarBodyProps {
    currentPeriod: Date;
    selectedCell: number | null;
    meetingsList: CalendarioVisiteDto[];
    onCellSelected: (selectedCell: number | null) => void;
}

export interface TodayNumbers {
    todayDayNumber: number;
    todayMonthNumber: number;
    todayYearNumber: number;
}

export class Day {
    day: number | null;
    isToday: boolean;

    constructor() {
        this.day = null;
        this.isToday = false;
    }
}

export class Week {
    days: Day[];

    constructor() {
        this.days = [];
    }
}

export class Month {
    weeks: Week[];

    constructor() {
        
        this.weeks = [];
    }
}