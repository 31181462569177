import styled from "@emotion/styled/macro";
import { Chip } from "@mui/material";
import { CALENDAR_HORIZONTAL_PADDING } from "components/calendar/styles";

export const DayHeadingCell = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    border-top: 1px solid #c4c4c4;
    border-bottom: 1px solid #c4c4c4;
    border-left: 1px solid #c4c4c4;
    font-weight: bold;
`;

// export const CalendarDay = styled.p`
//     position: absolute;
//     top: 10px;
//     right: 10px;
//     margin: 0;
//     color: #c4c4c4;
//     color: black;
// `;

export const CalendarDay = styled(Chip)`
    position: absolute;
    top: 10px;
    right: 10px;
    margin: 0;
    color: #c4c4c4;
    color: black;
    background: none;
`;

export const CalendarCell = styled.div<{
    isToday?: boolean;
    isCurrentMonth?: boolean;
}>`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: end;
    padding: 1rem 0;
    width: 100%;
    height: 100px;
    border-left: 1px solid #c4c4c4;
    border-bottom: 1px solid #c4c4c4;
    background-color: ${({ isCurrentMonth }) => !isCurrentMonth ? '#f2f2f2' : 'none'};
    cursor: ${({ isCurrentMonth }) => isCurrentMonth ? 'pointer' : 'not-allowed'};
    transition: all ease 0.3s;

    &:hover, &.selected {
        background-color: ${({ isCurrentMonth }) => isCurrentMonth ? 'aliceblue' : 'none'};
    }

    .MuiChip-root {
        cursor: pointer;
    }

    ${CalendarDay} {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${({ isToday, theme }) => isToday ? theme.palette.primary.light : 'none'};
        color: ${({ isToday }) => isToday ? 'white' : 'black'};
    }

`;

export const CalendarRow = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 ${CALENDAR_HORIZONTAL_PADDING};
    
    ${DayHeadingCell} {
        &:first-child {
            border-top-left-radius: 4px;
        }
        
        &:last-child {
            border-top-right-radius: 4px;
            border-right: 1px solid #c4c4c4;
        }
    }

    ${CalendarCell} {
        &:last-child {
            border-right: 1px solid #c4c4c4;
        }
    }
`;