import styled from "@emotion/styled";

export const AuthCard = styled.div<{ width?: string, height?: string }>`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 300px;
    min-height: 400px;
    width: ${({ width }) => width ? width : '300px'};
    height: ${({ height }) => height ? height : '400px'};
    border-radius: 0.2rem;
    background-color: #ffff;
    box-shadow: 0 0.1rem 1rem 0.25rem rgb(0 0 0 / 30%);
    z-index: 2;
    border-top: 6px solid ${({ theme }) => theme.palette.primary.light};
    padding: 1rem 2rem;
    /* @media (max-width: 1060px) {
        width: 200%;
    } */
    color: ${({ theme }) => theme.palette.primary.light};

    h2 {
        display: flex;
        justify-content: center;
    }

    button {
        margin: 0 2rem;
        width: calc(100% - 64px);
    }

`;

export const CardBody = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;

export const InputsContainer = styled.div`
    .MuiTextField-root {
        width:100%;
        margin: 10px 0;
    }
`;