import { AlertColor } from "@mui/material";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface NotificationState {
    level: AlertColor,
    message: string;
}

const initialState: NotificationState = {
    level: 'info',
    message: '',
}

export const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        setNewMessage: (state, action: PayloadAction<NotificationState>) => {
            const {level, message} = action.payload;

            state.level = level;
            state.message = message;
        },
        clearNotification: (state) => {
            state.message = '';
        }
    }
});

export const { setNewMessage, clearNotification} = notificationSlice.actions;

export default notificationSlice.reducer;
