import { BaseDto } from "./BaseDto";


export class CatalogoDto extends BaseDto {

    codice: string;
    descrizione: string;
    tipo: string;
    flagNonModificabile: string;
    flagInListino: string;
    flagInteraGiornata: string;
    percentuale: number;
    numerico: number;
    catalogo: number;
    codiceCatalogoPadre: string;
    descrizioneCatalogoPadre: string;

    constructor() {

        super();
        this.codice = '';
        this.descrizione = '';
        this.tipo = '';
        this.flagNonModificabile = '';
        this.flagInListino = '';
        this.flagInteraGiornata = '';
        this.percentuale = 0;
        this.numerico = 0;
        this.catalogo = 0;
        this.codiceCatalogoPadre = '';
        this.descrizioneCatalogoPadre = '';

    }
    
}