import { BaseDto } from "./BaseDto";
import { MedicoDto } from "./MedicoDto";
import { PazienteDto } from "./PazienteDto";

export class CalendarioVisiteDto extends BaseDto {
  dataVisita: Date;
  paziente: PazienteDto;
  medico: MedicoDto;

  constructor() {
    super();
    this.dataVisita = new Date();
    this.paziente = new PazienteDto();
    this.medico= new MedicoDto();
  }
}