import { BaseDto } from "./BaseDto";
import { ProvinciaDto } from "./ProvinciaDto";


export class ComuneDto extends BaseDto {

    codCatasCom: string;
    sigla: string;
    desCom: string;
    comuneCap: string;
    dataInizioValidita: Date;
    dataFineValidita: Date;
    provincia: ProvinciaDto;


    constructor() {
        super();
        this.codCatasCom = '';
        this.sigla = '';
        this.desCom = '';
        this.comuneCap = '';
        this.dataInizioValidita = new Date();
        this.dataFineValidita = new Date();
        this.provincia = new ProvinciaDto();
    }
}