import styled from "@emotion/styled";
import { Button } from "@mui/material";

export const CALENDAR_HORIZONTAL_PADDING = '1.2rem';

export const CalendarTitle = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 60px;
    border-bottom: 1px solid #eff2f5;
    padding: 0 ${CALENDAR_HORIZONTAL_PADDING};

    button {
        display: flex;
        align-items: center;
        text-transform: capitalize;
    };
`;

export const PeriodSelectorContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.3rem ${CALENDAR_HORIZONTAL_PADDING};

    > div {
        width: 33.3%
    }

    h3 {
        color: #3f4254;
        text-transform: capitalize;
    }

    .MuiFormControl-root {
        width: 200px;
        * {
            text-transform: capitalize;
        }
    }

    .MuiButtonGroup-root {
        margin-left: 0;
        color: #c4c4c4;
        
        svg {
            fill: #757575;
        }
    }
`;

export const CurrentMonthButton = styled(Button)`
    margin-left: 5px;
`;

export const SelectYearContainer = styled.div`
    display: flex;
    justify-content: center;

    .MuiSelect-select {
        display: flex;
        justify-content: center;
        width: 100px;
    }
`;

export const CalendarContainer = styled.div`
    width: 100%;
`;

export const MonthMeetingCountParagraph = styled.p`
    strong {
        color: ${({ theme }) => theme.palette.primary.light}
    }
`;

// export const CalendarRow = styled.div`
//     display: flex;
//     justify-content: space-between;
//     border-bottom: 1px solid #1e1e2d;

//     .isHoliday {
//             color: red;
//     }
// `;

// export const CalendarCell = styled.div`
//     border: 1px solid red;
//     width: 100%;
// `;