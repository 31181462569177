import { AppCTX } from "contexts/AppContext";
import { useContext, useMemo } from "react";
import { HalfHeader, HeaderContainer } from "./styles";
import MEASURES from "style/measures";

import { Variants } from "framer-motion";
import BottomHeader from "./BottomHeader";
import TopHeader from "./TopHeader";

const additionalPadding = '25px';

const headerAnimationVariants: Variants = {
    sideFull: {
        paddingLeft: `calc(${MEASURES.sidebar.full.width} + ${additionalPadding})`,
    },
    sideCollapsed: {
        paddingLeft: `calc(${MEASURES.sidebar.collapsed.width} + ${additionalPadding})`,
    }
};

const Header: React.FC = () => {
    const { isSidebarCollapsed } = useContext(AppCTX);

    const currentVariant = useMemo(() => !isSidebarCollapsed ? 'sideFull' : 'sideCollapsed', [isSidebarCollapsed]);

    return (
        <HeaderContainer>
            <HalfHeader
                hasBottomSeparator={true}
                animate={currentVariant}
                variants={headerAnimationVariants}
            >
                <TopHeader />
            </HalfHeader>
            <HalfHeader
                animate={currentVariant}
                variants={headerAnimationVariants}
            >
                <BottomHeader />
            </HalfHeader>
        </HeaderContainer>
    );
};

export default Header;