import axios from "axios";
import { FilterUtenteDto } from "components/filter/FilterUtenteDto";
import { MedicoDto } from "models/MedicoDto";
import { Response } from "models/Response";
import { RichiestaAssociazioneDto } from "models/UtenteDto";
import * as Constants from 'utils/constants';

//To Do tipizzare le response

 
const addRiechistaAssociazione = async (lista: RichiestaAssociazioneDto[]) => {
    return axios.post<{}, Response<RichiestaAssociazioneDto[]>, RichiestaAssociazioneDto[]>(Constants.API_RICHIESTE_ADD, lista);
}

const RichiesteAssocizioneService = {
    addRiechistaAssociazione
}

export default RichiesteAssocizioneService;