import styled from "@emotion/styled";

export const SelectedCellDetailConatiner = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ul {
        list-style-type: none;
    }
`;