import axios from "axios";
import { FilterCatalogoDto } from "components/filter/FilterCatalogoDto";
import { CatalogoDto } from "models/CatalogoDto";
import { Response } from "models/Response";
import { API_CATALOGO_LIST_BY_FILTER } from "utils/constants";

export const findByFilter = async (filter: FilterCatalogoDto) => {
    return axios.post<{}, Response<CatalogoDto[]>, FilterCatalogoDto>(API_CATALOGO_LIST_BY_FILTER, filter);
}

const CatalogoService = {
    findByFilter
}

export default CatalogoService;