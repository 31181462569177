import { AlertColor } from "@mui/material";
import { BaseDto } from "./BaseDto";
import { PazienteDto } from "./PazienteDto";

export class VisitaDto extends BaseDto {
  paziente: PazienteDto;
  dataVisita: Date;
  pesoCorporeo: number;
  pressioneArteriosaSistolica: number;
  pressioneArteriosaDiastolica: number;
  frequenzaCardiaca: number
  saturazione: number;
  affannoDaSforzo: string;
  affannoARiposo: string;
  affannoDaSdraiato: string;
  aggiuntaCuscino: string;
  gonfioreGambe: string;
  astenia: string;
  doloreToracico: string;
  episodioSincopale: string;
  cardiopalmo: string;
  durataAttivitaFisica: string;
  intensitaAttivitaFisica: string;
  introitoAlcoolSettimana: string;
  sigaretteGiorno: number;
  percentAderenzaTerapiaPerFarmaco: number;
  descrizione: string;
  aderenzaTerapia: string;
  respiro: string;
  punteggioTotale: number;

  constructor() {
    super();
    this.paziente = new PazienteDto();
    this.dataVisita = new Date();
    this.pesoCorporeo = 0;
    this.pressioneArteriosaSistolica = 0;
    this.pressioneArteriosaDiastolica = 0;
    this.frequenzaCardiaca = 0;
    this.saturazione = 0;
    this.affannoDaSforzo = '';
    this.affannoARiposo = '';
    this.affannoDaSdraiato = '';
    this.aggiuntaCuscino = '';
    this.gonfioreGambe = '';
    this.astenia = '';
    this.doloreToracico = '';
    this.episodioSincopale = '';
    this.cardiopalmo = '';
    this.durataAttivitaFisica = '';
    this.intensitaAttivitaFisica = '';
    this.introitoAlcoolSettimana = '';
    this.sigaretteGiorno = 0;
    this.percentAderenzaTerapiaPerFarmaco = 0;
    this.descrizione = '';
    this.aderenzaTerapia = '';
    this.respiro = '';
    this.punteggioTotale = 0;
  }
}