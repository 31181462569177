import styled from "@emotion/styled";

export const SpinnerOverlay = styled.div`
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: black;
    z-index: 1000;
    opacity: 0.6;

    .MuiCircularProgress-root {
        width: 100px !important;
        height: 100px !important;
    }

    body {
        overflow-x: hidden;
        overflow-y: hidden;
    }
`;