import { Md5 } from 'ts-md5/dist/md5';
export class ChangePasswordDto {
    userName: string;
    oldPassword: string;
    newPassword: string;

    constructor(username: string, oldPassword: string, newPassword: string) {
        this.userName = username;
        this.oldPassword = Md5.hashStr(oldPassword).toString();
        this.newPassword = Md5.hashStr(newPassword).toString();
    }
}