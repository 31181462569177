import styled from "@emotion/styled";

export const Section = styled.div`
    display: flex;
    flex-direction: column;
`;

export const SectionHeading = styled.div`
    display: flex;
    /* color: ${({theme}) => theme.palette.secondary.light}; */
    color: lightgray;
    color: ${({theme}) => theme.palette.primary.light};

    
    .MuiSvgIcon-root {
        position: relative;
        margin: 0 15px;
        top: 3px; 
    }
`;

export const SectionLine = styled.div`
    width: 100%;
    position: relative;
    margin: 15px 0;
    border-bottom: 3px solid;
`;
