import { GenericFilter } from "./GenericFilter";

export class FilterCatalogoDto extends GenericFilter {

    codice: string;
    tipo: string;
    descrizione: string;
    flagInListino: string;
    flagContrattiManutenzione: string;
    unitaLocale: number | null;

    constructor() {
        super();
        this.codice = '';
        this.tipo = '';
        this.descrizione = '';
        this.flagInListino = '';
        this.flagContrattiManutenzione = '';
        this.unitaLocale = null;
    }
}
