import { PazienteDto } from "models/PazienteDto";
import { GenericFilter } from "./GenericFilter";

export class FilterVisitaDto extends GenericFilter {

    paziente: number |null;
    DataVisita: Date | null;
    pesoCorporeo: number ;
    pressioneArteriosaSistolica: number;
    pressioneArteriosaDiastolica: number;
    frequenzaCardiaca:  number;
    saturazione: number;
    affannoDaSforzo: string;
    affannoARiposo: string;
    affannoDaSdraiato: string;
    aggiuntaCuscino: string;
    gonfioreGambe: string;
    astenia: string;
    doloreToracico: string;
    episodioSincopale: string;
    cardiopalmo: string;
    durataAttivitaFisica: string;
    intensitaAttivitaFisica: string;
    introitoAlcoolSettimana: string;
    sigaretteGiorno: number;
    percentAderenzaTerapiaPerFarmaco: number;
    descrizione: string;
    aderenzaTerapia: string;
    respiro: string;

    constructor() {
        super();
        this.paziente = null;
        this.DataVisita = null;
        this.pesoCorporeo = 0;
        this.pressioneArteriosaSistolica = 0;
        this.pressioneArteriosaDiastolica= 0;
        this.frequenzaCardiaca = 0;
        this.saturazione = 0;
        this.affannoDaSforzo = '';
        this.affannoARiposo= '';
        this.affannoDaSdraiato= '';
        this.aggiuntaCuscino= '';
        this.gonfioreGambe= '';
        this.astenia= '';
        this.doloreToracico= '';
        this.episodioSincopale= '';
        this.cardiopalmo= '';
        this.durataAttivitaFisica= '';
        this.intensitaAttivitaFisica= '';
        this.introitoAlcoolSettimana= '';
        this.sigaretteGiorno= 0;
        this.percentAderenzaTerapiaPerFarmaco= 0;
        this.descrizione= '';
        this.aderenzaTerapia= '';
        this.respiro= '';
    }

}