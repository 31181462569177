import { GenericFilter } from "./GenericFilter";

export class FilterPazienteDto extends GenericFilter{
    nome: string;
    cognome: string;
    conoVisibilita: number[];
    dataNascitaDa: Date | null | undefined;
    dataNascitaA: Date | null | undefined;
    listaOspedali: number[];
    cf : string;
    constructor() {
        super();
        this.nome = "";
        this.cognome = "";
        this.conoVisibilita = [];
        this.dataNascitaA = null;
        this.dataNascitaDa = null;
        this.listaOspedali = [];
        this.cf = '';

    }

}