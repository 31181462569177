import CambioPasswordForm from "components/cambioPasswordForm/CambioPasswordForm";
import Card from "components/layout/Card/Card";
import { useNavigate } from "react-router-dom";
import { ChangePasswordCardContainer } from "./styles";

const SettingsPage: React.FC = () => {

    const navigate = useNavigate();

    return (
        <ChangePasswordCardContainer>
            <Card
                title="Cambio Password"
                appearence={{ isFullWidth: true }}
            >
                <CambioPasswordForm
                    onChangePasswordDone={() => {
                        navigate(-1);
                    }}
                />
            </Card>
        </ChangePasswordCardContainer>
    );
}

export default SettingsPage;

