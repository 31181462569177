import { BaseDto } from "./BaseDto";
import { CatalogoDto } from "./CatalogoDto";
import { PazienteDto } from "./PazienteDto";

export class ProfiloClinicoMasterDto extends BaseDto {

    paziente: PazienteDto;
    dataRilevazione: Date | null;
    catalogo : CatalogoDto;
    

    constructor() {

      super();
      this.paziente = new PazienteDto();
      this.dataRilevazione = null;
      this.catalogo= new CatalogoDto();  
    }
}
export class ProfiloClinicoSlaveDto extends BaseDto {

    profiloClinicoMaster :ProfiloClinicoMasterDto;
    catalogo: CatalogoDto;
    valore: string;

    constructor() {

      super();
      this.profiloClinicoMaster = new ProfiloClinicoMasterDto();
      this.catalogo = new CatalogoDto();
      this.valore = '';

    }
}

export class ProfiloClinicoDto extends BaseDto {

    profiloClinicoMaster: ProfiloClinicoMasterDto;
    profiloClinicoSlave: ProfiloClinicoSlaveDto[];

    constructor() {

      super();
      this.profiloClinicoMaster = new ProfiloClinicoMasterDto();
      this.profiloClinicoSlave = [];

    }
}