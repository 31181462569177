import styled from "@emotion/styled";

export const CardContainer = styled.div<{ isInteractive?: boolean, isFullWidth?: boolean, color?: string }>`
    position: relative;
    display: flex;
    flex-direction: column;
    width: ${({ isFullWidth }) => isFullWidth ? '100%' : '30%'};
    border-radius: 0.5rem;
    background-color: #ffff;
    padding: 1.5rem;
    padding-top: 2rem;
    margin-top: 30px;
    margin-bottom: 30px;
    box-shadow: 0 0.1rem 1rem 0.25rem rgb(0 0 0 / 5%);
    border: ${({ isInteractive, color }) => {
        return isInteractive ? `2px solid ${color}` : 'none';
    }};

    cursor: ${({ isInteractive }) => isInteractive ? 'pointer' : 'default'};

    

    &:hover {
        box-shadow: ${({ isInteractive, color }) => isInteractive ? `0 0 5px ${color ?? 'grey'}` : ''};
        transform: ${({ isInteractive }) => isInteractive ? 'scale(1.06)' : 'none'};
        transition: ${({ isInteractive }) => isInteractive ? 'all 0.3s ease' : 'none'};
    }
`;

export const CardTitleContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: -12px;
    left: 1.5rem;
    min-width: 200px;
    min-height: 30px;
    word-spacing: 100;
    background-color: ${({ theme }) => theme.palette.primary.light};
    color: #fff;
    text-transform: uppercase;

    .container {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
    }

    p {
        word-spacing: 100%;
        margin: 4px;
    }
`;

export const CardActionsContainer = styled.div`
    width: 100%;
    height: 30%;
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button {
        margin-left: 10px;
    }
`;