import axios from "axios";
import { FilterUtenteDto } from "components/filter/FilterUtenteDto";
import { GenericFilter } from "components/filter/GenericFilter";
import { DocumentiUtiliDto, PARAMETERSYSTEM } from "models/DocumentiUtiliDto";
 
import * as Constants from 'utils/constants';

//To Do tipizzare le response

 

const listaDocumenti = async (filter: GenericFilter) => {
    return axios.post<{}, any>(Constants.API_DOCUMENTI_UTILI_FILTER, filter);
}

const downloadDocumenti = async (id: number) => {
    return axios.get<{}, any>(Constants.API_DOCUMENTI_FIND_ID + "/" + id);
}



const deleteDocumenti = async (id: number) => {
    return axios.get<{}, any>(Constants.API_DOCUMENTI_DELETE + "/" + id);
}

const saveDocumenti = async (dto: DocumentiUtiliDto) => {
    return axios.post<{}, any>(Constants.API_DOCUMENTI_CREATE , dto);
}




const DocumentiUtliService = {
    saveDocumenti, 
    deleteDocumenti, 
    downloadDocumenti, 
    listaDocumenti
}



export default DocumentiUtliService;