import { Md5 } from 'ts-md5/dist/md5';

export class LoginDto {
    userName: string;
    password: string;

    constructor(userName: string, password: string) {
        this.userName = userName;
        this.password = Md5.hashStr(password).toString();
    }
}