import axios from "axios";
import { GenericFilter } from "components/filter/GenericFilter";
import { ProvinciaDto } from "models/ProvinciaDto";
import { Response } from "models/Response";
import * as Constants from 'utils/constants';

//To Do tipizzare le response

const findByIdProvincia = async (id: number) => {
    return axios.get<{}, Response<ProvinciaDto>>(`${Constants.API_PROVINCIA_FIND_BY_ID}/${id}`);
}

const findProvinciaByIdRegione = async (id: number) => {
    return axios.get<{}, Response<ProvinciaDto[]>>(`${Constants.API_PROVINCIA_FIND_BY_ID_REGIONE}/${id}`);
}

const findAllProvince = async () => {
    return axios.get<{}, Response<ProvinciaDto[]>>(`${Constants.API_PROVINCIA_FIND_ALL}`);
}

const saveOrUpdateProvincia = async (provincia: ProvinciaDto) => {
    return axios.post<{}, Response<ProvinciaDto>>(Constants.API_PROVINCIA_SAVE_UPDATE, provincia);
}

const deleteProvincia = async (id: number) => {
    return axios.get<{}, Response<ProvinciaDto>>(`${Constants.API_PROVINCIA_DELETE}/${id}`);
}

const findProvinciaByFilter = async (filter: GenericFilter) => {
    return axios.post<{}, Response<ProvinciaDto[]>>(Constants.API_PROVINCIA_FIND_BY_FILTER, filter);
}

const ProvinciaService = {
    findByIdProvincia,
    findProvinciaByIdRegione,
    findAllProvince,
    saveOrUpdateProvincia,
    deleteProvincia,
    findProvinciaByFilter
}

export default ProvinciaService;