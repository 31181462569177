import { DeleteForeverTwoTone, DeleteTwoTone, EditTwoTone } from "@mui/icons-material";
import { Button, IconButton, TextField } from "@mui/material";
import Modal from "components/modal/Modal";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setNewMessage } from "reducers/notification.reducer";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "utils/constants";
import { StatusCodes } from "utils/enums";
import { FarmacoItemContainer, FarmacoItemSection, NoteItemSection, TerapieItemCardContainer, YearPickerSection } from "./styles";
import TerapieService from "services/TerapieService";
import { TerapieItemCardProps } from "./types";
import { DesktopDatePicker, LocalizationProvider, MobileDatePicker, YearPicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { it } from "date-fns/locale";


const TerapieItemCard: React.FC<TerapieItemCardProps> = ({ terapia, onTerapiaDeleted, onTerapiaChange }) => {

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const deleteTerapia = () => {
        TerapieService.deleteTerapia(terapia.id).then(res => {
            const { statusCode, message } = res;

            if (statusCode === StatusCodes.OK) {
                dispatch(setNewMessage({
                    level: 'success',
                    message: message
                }));
                setIsModalOpen(false);
                onTerapiaDeleted();
            } else {
                dispatch(setNewMessage({
                    level: 'error',
                    message: message
                }));
            }
        })
    }

    return (
        <>
            <TerapieItemCardContainer appearence={{ isFullWidth: true }}>
                {/* <div className="d-flex justify-content-end mb-2">
                    <IconButton>
                        <EditTwoTone color="primary" onClick={() => {
                            navigate(ROUTES.paziente.detail.tab.terapieUpdate.replace(':id', `${terapia.terapiaMaster.paziente.id}`).replace(':idTerapia', `${terapia.terapiaMaster.id}`));
                        }} />
                    </IconButton>
                    <IconButton onClick={() => setIsModalOpen(true)}>
                        <DeleteTwoTone color="error" />
                    </IconButton>
                </div> */}

                <FarmacoItemContainer>
                    <FarmacoItemSection>
                        <strong>{terapia.farmaco.codice}</strong>
                        <p>{terapia.farmaco.descrizione}</p>
                    </FarmacoItemSection>
                    <YearPickerSection>
                        <LocalizationProvider adapterLocale={it} dateAdapter={AdapterDateFns}>
                            <MobileDatePicker
                                views={['day', 'month', 'year']}
                                value={terapia.dataInizio}
                                onChange={(value) => {
                                    const t = { ...terapia };
                                    t.dataInizio = value;
                                    onTerapiaChange(t);
                                }}
                                renderInput={(props) => <TextField {...props} label="Data inizio" />}
                            />
                        </LocalizationProvider>
                    </YearPickerSection>
                    <YearPickerSection>
                        <LocalizationProvider adapterLocale={it} dateAdapter={AdapterDateFns}>
                            <MobileDatePicker
                                views={['day', 'month', 'year']}
                                value={terapia.dataFine}
                                onChange={(value) => {
                                    const t = { ...terapia };
                                    t.dataFine = value;
                                    onTerapiaChange(t);
                                }}
                                renderInput={(props) => <TextField {...props} label="Data Fine" />}
                            />
                        </LocalizationProvider>
                    </YearPickerSection>
                    <NoteItemSection>
                        <TextField
                            placeholder="Nota"
                            label="Nota"
                            multiline
                            defaultValue={terapia.notaSomm}
                            onChange={(evt) => {
                                const t = { ...terapia };
                                t.notaSomm = evt.target.value;
                                onTerapiaChange(t);
                            }}
                        />
                    </NoteItemSection>
                    <NoteItemSection>
                        {terapia.notaSommMod && (
                            <TextField
                                placeholder="Nota"
                                label="Nota paziente"
                                disabled
                                multiline
                                defaultValue={terapia.notaSommMod}
                            />
                        )}
                    </NoteItemSection>
                    <Button
                        color="error"
                        onClick={() => {
                            onTerapiaDeleted();
                        }}
                    >
                        <DeleteForeverTwoTone />
                    </Button>
                </FarmacoItemContainer>

            </TerapieItemCardContainer>

            {/* <Modal
                open={isModalOpen}
                title="ATTENZIONE"
                onClose={() => setIsModalOpen(false)}
                onConfirm={deleteTerapia}
            >
                <div className="d-flex justify-content-center">
                    <p style={{ width: '40%' }}>La terapia <strong>{terapia.terapiaMaster.nota}</strong> verrà eliminato in maniera permanente. Confermi di voler proseguire ?</p>
                </div>
            </Modal> */}
        </>
    )
}

export default TerapieItemCard;