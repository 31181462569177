import axios from "axios";
import { FilterProfiloClinico } from "components/filter/FilterProfiloClinico";
import { GenericFilter } from "components/filter/GenericFilter";
import { ProfiloClinicoDto, ProfiloClinicoMasterDto } from "models/GestioneProfiloClinicoDto";
import { Response } from "models/Response";
import * as Constants from 'utils/constants';

//To Do tipizzare le response

const findByById = async (id: number) => {
    return axios.get<{}, ProfiloClinicoDto>(`${Constants.API_PROFILO_CLINICO_FIND_BY_ID}/${id}`);
}

const saveUpdateProfiloClinico = async (profiliCliniciList : ProfiloClinicoMasterDto[]) => {
    return axios.post<{}, Response<any>, ProfiloClinicoMasterDto[]>(Constants.API_PROFILO_CLINICO_SAVE_UPDATE, profiliCliniciList);
}

const profiloClinicoPage = async (filter : FilterProfiloClinico) => {
    return axios.post<{}, ProfiloClinicoMasterDto>(Constants.API_PROFILO_CLINICO_PAGE_BY_FILTER, filter);
}

const deleteProfilo = async (id: number) => {
    return axios.get<{}, Response<ProfiloClinicoMasterDto>>(`${Constants.API_PROFILO_CLINICO_DELETE}/${id}`);
}

const getListaProfiloClinicoById = async (filter: GenericFilter) => {
    return axios.post<{}, Response<ProfiloClinicoMasterDto[]>, GenericFilter>(Constants.API_PROFILO_CLINICO_LISTA_BY_PAZIENTE, filter);
}

const ProfiloClinicoService = {
   findByById,
   saveUpdateProfiloClinico,
   profiloClinicoPage,
   deleteProfilo,
   getListaProfiloClinicoById
}

export default ProfiloClinicoService;