import { Button } from "@mui/material";
import Card from "components/layout/Card/Card";
import { CardActionsContainer } from "components/layout/Card/styles";
import InputField from "components/layout/inputs/InputField";
import { useFormik } from "formik";
import { CatalogoDto } from "models/CatalogoDto";
import { MedicoDto } from "models/MedicoDto";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { setNewMessage } from "reducers/notification.reducer";
import MedicoService from "services/MedicoService";
import { useAppDispatch } from "store/storeHooks";
import { StatusCodes } from "utils/enums";
import * as Yup from 'yup';

import AutocompleteInputField from 'components/layout/inputs/AutocompleteInputField';
import CatalogoService from "services/CatalogoService";
import { FilterCatalogoDto } from "components/filter/FilterCatalogoDto";
import { ROUTES } from "utils/constants";
import { CommonUserProps } from "../types";

export const MedicoDetailPage: React.FC<CommonUserProps> = ({ codProfilo }) => {

    const { id: medicoId } = useParams();

    const medico = useRef<MedicoDto>(new MedicoDto());

    const [listOspedali, setListOspedali] = useState<CatalogoDto[]>([]);

    const navigate = useNavigate();

    const isAdmin = useMemo(() => codProfilo === 'A', [codProfilo]);

    const dispatch = useAppDispatch();

    useEffect(() => {
        medico.current.utente.flagConferma = 'M';
        medico.current.utente.profilo.codProfilo = codProfilo;

        if (!medicoId) return;

        MedicoService.getMedicoById(Number(medicoId)).then(res => {
            const { statusCode, message, out } = res;

            if (statusCode === StatusCodes.KO) {
                dispatch(setNewMessage({
                    level: 'error',
                    message
                }));

                navigate('lista-medici');
            } else {
                fillMedico(out);
            }
        });
    }, []);

    useEffect(() => {
        const filter = new FilterCatalogoDto();
        filter.tipo = "OSP_INT";
        CatalogoService.findByFilter(filter).then(res => {
            const { out } = res;

            setListOspedali(out);
        });
    }, []);

    const fillMedico = (m: MedicoDto) => {

        medico.current = m;
        formik.setFieldValue('nome', m.nome);
        formik.setFieldValue('cognome', m.cognome);
        formik.setFieldValue('userName', m.utente.usrName);
        formik.setFieldValue('email', m.utente.usrMail);
        formik.setFieldValue('telefono', m.telefono);
        formik.setFieldValue('ospedali', m.utente.codiciIntervento);
    }

    const adminSchema = Yup.object().shape({
        nome: Yup.string()
            .required('Nome campo obbligatorio'),
        cognome: Yup.string()
            .required('Cognome campo obbligatorio'),
        userName: Yup.string()
            .required('UserName campo obbligatorio'),
        email: Yup.string()
            .required('Email campo obbligatorio')
            .email('Email campo non corretto'),
    });

    const medicoSchema = Yup.object().shape({
        nome: Yup.string()
            .required('Nome campo obbligatorio'),
        cognome: Yup.string()
            .required('Cognome campo obbligatorio'),
        userName: Yup.string()
            .required('UserName campo obbligatorio'),
        email: Yup.string()
            .required('Email campo obbligatorio')
            .email('Email campo non corretto'),
        ospedali: Yup.array()
            .required("Selezionare almeno un ospedale")
            .min(1, "Selezionare almeno un ospedale"),
    });

    const formik = useFormik({
        initialValues: {
            nome: '',
            cognome: '',
            userName: '',
            telefono: '',
            email: '',
            ospedali: [],
        },
        validationSchema: isAdmin ? adminSchema : medicoSchema,
        onSubmit: async (values) => {
            const { nome, cognome, email, telefono, userName, ospedali } = values;
            medico.current.nome = nome;
            medico.current.cognome = cognome;
            medico.current.utente.codiciIntervento = ospedali;
            medico.current.utente.usrName = userName;
            medico.current.utente.usrMail = email;
            medico.current.telefono = telefono;
            saveMedico();
        }
    });

    function saveMedico() {
        MedicoService.saveMedico(medico.current).then(res => {
            const { statusCode, message } = res;

            if (statusCode === StatusCodes.OK) {
                dispatch(setNewMessage({
                    level: "success",
                    message
                }));

                const route = codProfilo === 'A' ? ROUTES.admin.list : ROUTES.medico.list;

                navigate(route);
            } else {
                dispatch(setNewMessage({
                    level: "error",
                    message
                }));
            }
        }).catch(err => {
            dispatch(setNewMessage({
                level: "error",
                message: err
            }));
        })
    };

    const isNomeInvalid = formik.touched.nome && formik.errors.nome != null && formik.errors.nome.length > 0;
    const isCognomeInvalid = formik.touched.cognome && formik.errors.cognome != null && formik.errors.cognome.length > 0;
    const isUserNameInvalid = formik.touched.userName && formik.errors.userName != null && formik.errors.userName.length > 0;
    const isemailInvalid = formik.touched.email && formik.errors.email != null && formik.errors.email.length > 0;
    const isOspedaliInvalid = formik.touched.ospedali && formik.errors.ospedali != null && formik.errors.ospedali.length > 0;

    return (
        <>
            <Card
                appearence={{
                    isFullWidth: true,
                }}
            >
                <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                        <div className="col-md-6">
                            <InputField
                                type="text"
                                size="small"
                                fullWidth={true}
                                label="Nome"
                                {...formik.getFieldProps('nome')}
                                isInvalid={isNomeInvalid}
                                errorMessage={formik.errors.nome}
                            />
                        </div>
                        <div className="col-md-6">
                            <InputField
                                type="text"
                                size="small"
                                fullWidth={true}
                                label="Cognome"
                                {...formik.getFieldProps('cognome')}
                                isInvalid={isCognomeInvalid}
                                errorMessage={formik.errors.cognome}
                            />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-6">
                            <InputField
                                type="text"
                                size="small"
                                fullWidth={true}
                                label="Username"
                                isInvalid={isUserNameInvalid}
                                errorMessage={formik.errors.userName}
                                {...formik.getFieldProps('userName')}
                            />
                        </div>

                        <div className="col-md-6">
                            <InputField
                                type="text"
                                size="small"
                                fullWidth={true}
                                label="Email"
                                {...formik.getFieldProps('email')}
                                isInvalid={isemailInvalid}
                                errorMessage={formik.errors.email}
                            />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-6">
                            <InputField
                                type="text"
                                size="small"
                                fullWidth={true}
                                label="Telefono"
                                {...formik.getFieldProps('telefono')}
                            />
                        </div>
                    </div>

                    {!isAdmin && (
                        <div className="row mt-3">
                            <div className="col-md-12">
                                <AutocompleteInputField
                                    label="Ospedali"
                                    options={listOspedali}
                                    name={formik.getFieldProps('ospedali').name}
                                    value={formik.getFieldProps('ospedali').value}
                                    onChange={(value) => {
                                        formik.setFieldValue('ospedali', value);
                                    }}
                                    isInvalid={isOspedaliInvalid}
                                />
                            </div>
                        </div>
                    )}

                    <CardActionsContainer>
                        <Button type="submit" variant="contained" color="success" onClick={() => {
                            console.log("SUBMIT VALUE", formik.errors.ospedali);
                        }}>
                            Salva
                        </Button>
                    </CardActionsContainer>
                </form>
            </Card>
        </>
    );
}

export default MedicoDetailPage;