//API
export const API_USER = '/user';
export const API_USER_LOGIN = `${API_USER}/login`;
export const API_USER_VERSIONE_APPLICAZIONE = `${API_USER}/versioneApplicazione`;
export const API_USER_SAVE_UPDATE = `${API_USER}/saveuser`;
export const API_USER_GET_USERS = `${API_USER}/getusers`;
export const API_USER_UPDATE_EMAIL = `${API_USER}/updateEmail`;
export const API_USER_CHANGE_PASSWORD = `${API_USER}/changePassword`;
export const API_USER_RESET_PASSWORD = `${API_USER}/resetPassword`;
export const API_USER_ATTIVA_DISATTIVA = `${API_USER}/attivaDisattiva`;


export const API_MEDICO = '/medico';
export const API_MEDICO_CREATE = `${API_MEDICO}/saveuser`; //Medico DTO POST
export const API_MEDICO_ATTIVA_DISATTIVA = `${API_MEDICO}/attivaDisattiva`; //MEDICO DTO POST
export const API_MEDICO_FILTER_USER = `${API_MEDICO}/getusers`; //FILTER USER DTO

export const API_PAZIENTE = '/paziente';
export const API_PAZIENTE_GET_COUNTS = `${API_PAZIENTE}/getCounts`;
export const API_PAZIENTE_SAVE_UPDATE = `${API_PAZIENTE}/saveuser`;
export const API_PAZIENTE_FILTER_USERS = `${API_PAZIENTE}/getusers`;
export const API_PAZIENTE_ATTIVA_DISATTIVA = `${API_PAZIENTE}/attivaDisattiva`;


export const API_STILE_VITA = '/stiledivita';
export const API_STILE_VITA_LAST = `${API_STILE_VITA}/getLastValori`;
export const API_STILE_VITA_STORICO = `${API_STILE_VITA}/pageByFilter`;

export const API_FARMACO = '/farmaco';
export const API_FARMACO_FILTER_USER = `${API_FARMACO}/findByFilter`; //FILTER USER DTO

export const API_TERAPIE = '/terapia';
export const API_TERAPIE_MASTER_FILTER = `${API_TERAPIE}/pageTerapiaM`;
export const API_TERAPIE_DELETE = `${API_TERAPIE}/delete`;
export const API_TERAPIE_DELETE_SLAVE = `${API_TERAPIE}/deleteSlave`;
export const API_TERAPIE_FIND_BY_ID_MASTER = `${API_TERAPIE}/findbyid`;
export const API_TERAPIE_SAVE_UPDATE_MASTER = `${API_TERAPIE}/saveOrUpdateTerapia`;
export const API_TERAPIE_SAVE_UPDATE_SLAVE = `${API_TERAPIE}/saveOrUpdateTerapiaSlave`;
export const API_TERAPIE_PAGE_SLAVE = `${API_TERAPIE}/pageTerapiaS`;
export const API_TERAPIE_LIST_BY_PAZIENTE = `${API_TERAPIE}/getListTerapiaByPaziente`;
export const API_TERAPIE_LIST_BY_MEDICO = `${API_TERAPIE}/getListTerapiaByMedico`;
export const API_TERAPIE_PAGE_MASTER_SLAVE = `${API_TERAPIE}/pageMasterAndSlave`;
export const API_TERAPIE_MASTER_AND_HIS_SLAVES = `${API_TERAPIE}/getSlavesByIdMaster`;


export const API_DOCUMENTI_UTILI = '/documenti';
export const API_DOCUMENTI_UTILI_FILTER = `${API_DOCUMENTI_UTILI}/findByFilter`; //FILTER USER DTO
export const API_DOCUMENTI_CREATE = `${API_DOCUMENTI_UTILI}/save`; //Medico DTO POST
export const API_DOCUMENTI_DELETE = `${API_DOCUMENTI_UTILI}/delete`; //Medico DTO POST
export const API_DOCUMENTI_FIND_ID = `${API_DOCUMENTI_UTILI}/findbyid`; //Medico DTO POST

export const API_VISITA = '/visita';
export const API_VISITA_SAVE_UPDATE = `${API_VISITA}/saveOrUpdateVisita`;
export const API_VISITA_FILTER_PAGE = `${API_VISITA}/findVisitaPag`;
export const API_LAST_MONTH = `${API_VISITA}/lastMonthVisite`;
export const API_VISITA_DELETE = `${API_VISITA}/delete`;

export const API_ESAME_EMATICO = '/esameEmatico';
export const API_SAVE_OR_UPDATE_ESAME_EMATICO = `${API_ESAME_EMATICO}/saveOrUpdate`;
export const API_ESAME_EMATICO_FILTER = `${API_ESAME_EMATICO}/findEsameEmaticoPag`;
export const API_DELETE_ESAME_EMATICO = `${API_ESAME_EMATICO}/delete`;
export const API_GET_ESAME_EMATICO = `${API_ESAME_EMATICO}/findById`;

export const API_CALENDARIO_VISITA = '/CalendarioVisite';
export const API_CALENDARIO_VISITE_SAVE_UPDATE = `${API_CALENDARIO_VISITA}/savecalendario`;
export const API_CALENDARIO_VISITE_GET_CALENDARIO_PAGE = `${API_CALENDARIO_VISITA}/getcalendario`;
export const API_CALENDARIO_VISITE_MENSILI = `${API_CALENDARIO_VISITA}/visiteMensiliByMedico`;
export const API_CALENDARIO_VISITE_DISATTIVA = `${API_CALENDARIO_VISITA}/disattiva`;

export const API_PROFILO_CLINICO = '/profiloClinico';
export const API_PROFILO_CLINICO_FIND_BY_ID = `${API_PROFILO_CLINICO}/findbyid`;
export const API_PROFILO_CLINICO_SAVE_UPDATE = `${API_PROFILO_CLINICO}/saveOrUpdateProfiloClinico`;
export const API_PROFILO_CLINICO_PAGE_BY_FILTER = `${API_PROFILO_CLINICO}/pageProfiloClinico`;
export const API_PROFILO_CLINICO_DELETE = `${API_PROFILO_CLINICO}/delete`;
export const API_PROFILO_CLINICO_LISTA_BY_PAZIENTE = `${API_PROFILO_CLINICO}/getListProfiloClinicoByPaziente`;

export const API_CATALOGO = '/catalogo';
export const API_CATALOGO_LIST_BY_FILTER = `${API_CATALOGO}/listByFilter`;



export const API_RICHIESTE = '/richiesteassociazione';
export const API_RICHIESTE_ADD = `${API_RICHIESTE}/addRiechistaAssociazione`; //Medico DTO POST

export const API_COMUNE = '/comune';
export const API_COMUNE_FIND_BY_ID_PROVINCIA = `${API_COMUNE}/findbyidprovincia`;
export const API_COMUNE_FIND_ALL_VALID = `${API_COMUNE}/findallvalid`;
export const API_COMUNE_FIND_BY_SIGLA_DESCRIZIONE = `${API_COMUNE}/findBaseComByCodesigla`;
export const API_COMUNE_FIND_BY_DESCRIZIONE = `${API_COMUNE}/findBaseComByDescrizione`;
export const API_COMUNE_FIND_BY_ID_COMUNE = `${API_COMUNE}/findbyid`;
export const API_COMUNE_SAVE_UPDATE = `${API_COMUNE}/saveorupdate`;
export const API_COMUNE_DELETE = `${API_COMUNE}/delete`;
export const API_COMUNE_FIND_BY_COMUNE_FILTER = `${API_COMUNE}/findByFilter`;

export const API_PROVINCIA = '/provincia';
export const API_PROVINCIA_FIND_BY_ID = `${API_PROVINCIA}/findbyid`;
export const API_PROVINCIA_FIND_BY_ID_REGIONE = `${API_PROVINCIA}/findbyidRegione`;
export const API_PROVINCIA_FIND_ALL = `${API_PROVINCIA}/findall`;
export const API_PROVINCIA_SAVE_UPDATE = `${API_PROVINCIA}/saveorupdate`;
export const API_PROVINCIA_DELETE = `${API_PROVINCIA}/delete`;
export const API_PROVINCIA_FIND_BY_FILTER = `${API_PROVINCIA}/findByFilter`;

export const API_REGIONE = '/regione';
export const API_REGIONE_SAVE_UPDATE = `${API_REGIONE}/saveorupdate`;
export const API_REGIONE_DELETE = `${API_REGIONE}/delete`;
export const API_REGIONE_FIND_BY_FILTER = `${API_REGIONE}/finbyRegionefilter`;
export const API_REGIONE_FIND_BY_ID = `${API_REGIONE}/findbyid`;
export const API_REGIONE_FIND_ALL = `${API_REGIONE}/findAllValid`;

export const API_NAZIONE = '/nazione';
export const API_NAZIONE_FIND_BY_ID = `${API_NAZIONE}/findbyid`;
export const API_NAZIONE_SAVE_UPDATE= `${API_NAZIONE}/saveorupdate`;
export const API_NAZIONE_DELETE = `${API_NAZIONE}/delete`;
export const API_NAZIONE_FIND_BY_FILTER = `${API_NAZIONE}/findByFilter`;
export const API_NAZIONE_FIND_ALL = `${API_NAZIONE}/findallvalid`;


export const API_VALORI_ANTROPOMETRICI = '/valoriantropometrici';
export const API_VALORI_ANTROPOMETRICI_LAST_INSERT = `${API_VALORI_ANTROPOMETRICI}/getLastValori`;
export const API_VALORI_ANTROPOMETRICI_ID = `${API_VALORI_ANTROPOMETRICI}/getValoribyId`;
export const API_VALORI_ANTROPOMETRICI_SAVE = `${API_VALORI_ANTROPOMETRICI}/saveOrUpdatevalori`;
export const API_VALORI_ANTROPOMETRICI_PAGE = `${API_VALORI_ANTROPOMETRICI}/pageByFilter`;
export const API_LAST_SIX_MONTHS = `${API_VALORI_ANTROPOMETRICI}/lastSixMonthValori`;


//PAGINATION
export const pageStart = 0;
export const rowForPage = 25;
export const listForPage = [5, 10, 25, 50];


export const BACKEND_URL   =  process.env.REACT_APP_BASE_URL   ;


//ROUTES
export const ROUTES = {
    login: '/login',
    logout: '/logout',
    privacy :'/privacy',
    cambioPassword: '/cambio-password',
    dashboard: '/dashboard',
    editVisita: '/editVisita/:id',
    calendar: '/calendar',
    profilo: '/profilo',
    farmaco: {
        main: '/farmaco',
        list: '/farmaco/list',
    },
    materialeInformativo: {
        main: '/materiale-informativo',
        list: '/materiale-informativo/list',
    },
    admin: {
        main: '/admin',
        list: '/admin/list',
        new: '/admin/new',
        detail: '/admin/:id'
    },
    medico: {
        main: '/medico',
        list: '/medico/list',
        new: '/medico/new',
        detail: '/medico/:id'
    },
    paziente: {
        main: '/paziente',
        list: '/paziente/list',
        new: '/paziente/new',
        detail: {
            main: '/paziente/:id',
            tab: {
                trend: '/paziente/:id/trend',
                profiloClinico: {
                    main: '/paziente/:id/profilo-clinico',
                    valoriAntropometrici: '/paziente/:id/profilo-clinico/valori-antropometrici',
                    sileVita: '/paziente/:id/profilo-clinico/stile-vita',
                    fattoriRischioCardiovascolare: '/paziente/:id/profilo-clinico/fattori-rischio-cardiovascolare',
                    malattieCardiovascolari: '/paziente/:id/profilo-clinico/malattie-cardiovascolari',
                    malattieConcomitanti: '/paziente/:id/profilo-clinico/malattie-concomitanti',
                },
                visite: {
                    main: '/paziente/:id/visite',
                    visualizza: '/paziente/:id/visite/visualizza/:idVisita',
                },
                terapie: '/paziente/:id/terapie',
                esami: {
                    main: '/paziente/:id/esami',
                    new: '/paziente/:id/esami/new',
                    update: '/paziente/:id/esami/:idEsame',
                },
            }
        },
    },
}
















