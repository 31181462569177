import { BaseDto } from "./BaseDto";
import { PazienteDto } from "./PazienteDto";

export class StileDiVitaDto extends BaseDto {

    paziente: PazienteDto;

    dataInizio: Date;

    anno: number | null;

    numsig: number;

    fumocorrente: string;

    fumopassato: string;

    sport: string;

    sportsmall: string;

    sportlarge: string;

    tiposport: string;

    alcol: string;

    tipoalcol: string;

    constructor() {
        super();
        this.paziente = new PazienteDto();
        this.dataInizio = new Date();
        this.anno = 0
        this.numsig = 0;
        this.fumocorrente = 'N';
        this.fumopassato = 'N';
        this.sport = 'N';
        this.sportsmall = 'N';
        this.sportlarge = 'N';
        this.tiposport = 'A';
        this.alcol = 'N';
        this.tipoalcol = 'N';

    }


}    