import Button from "@mui/material/Button";
import FilterCard from "components/filterCard/FilterCard";
import { MedicoDto } from "models/MedicoDto";
import { useNavigate } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import Tooltip from "@mui/material/Tooltip";
import Modal from "components/modal/Modal";
import { useEffect, useState } from "react";
import FilterMedico from "components/filterCard/FilterMedico";
import MedicoService from "services/MedicoService";
import { AlertColor, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import { AddIcCallTwoTone, AddShoppingCart, Alarm, AttachFileTwoTone, CloudDownloadTwoTone, Delete, DeleteTwoTone, EditOffTwoTone, EditTwoTone, TableRows } from "@mui/icons-material";
import Card from "components/layout/Card/Card";
import { pageStart, rowForPage, listForPage } from "utils/constants";
import { FilterUtenteDto } from "components/filter/FilterUtenteDto";
import Emitter from "services/EmitterService";
import { useFormik } from "formik";
import * as Yup from 'yup';
import InputField from "components/layout/inputs/InputField";
import { useAppDispatch, useAppSelector } from "store/storeHooks";
import { NOMEM } from "dns";
import { CatalogoDto } from "models/CatalogoDto";
import { setNewMessage } from "reducers/notification.reducer";
import { GenericFilter } from "components/filter/GenericFilter";
import { DocumentiUtiliDto, PARAMETERSYSTEM } from "models/DocumentiUtiliDto";
import FarmacoService from "services/FarmacoService";
import FilterFarmaco from "components/filterCard/FilterFarmaco";

import moment from 'moment';
import DocumentiUtliService from "services/DocumentiUtiliService";
import FilterDocumenti from "components/filterCard/FilterDocumenti";
import UtilityFile from "services/UtilityFile";

const ListDocumentiPage: React.FC = () => {


    let fileName: string = "";


    let filter: GenericFilter = new GenericFilter();

    const [openModal, setOpenModal] = useState<boolean>(false);
    const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);

    const [idDelete, setIdDelete] = useState<any>(0);

    const [listaDocumenti, setListaDocumenti] = useState<DocumentiUtiliDto[]>([]);
    const dispatch = useAppDispatch();
    const [totalElement, setTotalElement] = useState(0);
    const [page, setPage] = useState(pageStart);
    const [dense, setDense] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(rowForPage);

    const [allegato, setAllegato] = useState("");
    const [tipoFile, setTipoFile] = useState("");

    const [fileCaricato, setFileCaricato] = useState<boolean>(false);


    const [elementiPerPAgine, setElementiPerPAgine] = useState(listForPage);

    const app = useAppSelector((app) => app.auth.loggedUser);




    useEffect(() => {
        caricaTabella(new GenericFilter());
        leggiFiltro();
        return () => {
            Emitter.off("documenti", leggiFiltro());
        };
    }, []);

    function leggiFiltro() {
        Emitter.on('documenti', (newValue: GenericFilter) => {
            filter = newValue;
            setRowsPerPage(25);
            setPage(0);
            caricaTabella(filter);
        });
    }




    function intiDataTalbe() {
        setListaDocumenti([]);
        setTotalElement(0);
    }

    function caricaTabella(filter: GenericFilter) {
        intiDataTalbe();
        DocumentiUtliService.listaDocumenti(filter).then(res => {
            if (res.statusCode === 'OK') {
                setListaDocumenti(res.out);
                setTotalElement(res.paginationInfo.totalElements)
            } else {
                console.error(res.message);
            }
        }).catch(ex => {
            console.error(ex);
        })
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        filter.selectPage = newPage;
        filter.pageSize = rowsPerPage;
        caricaTabella(filter);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        filter.selectPage = page;
        filter.pageSize = parseInt(event.target.value, 10);
        caricaTabella(filter);
    };





    const uploadFile = async (event: any) => {
        const file = event.target.files[0]
        const base64 = await UtilityFile.convertBase64(file);
        let appo = "" + base64;
        setAllegato(appo.split(',')[1]);
        setTipoFile(file.type);
        setFileCaricato(true);
    }


    function salvaDocumento(medico: DocumentiUtiliDto) {

        DocumentiUtliService.saveDocumenti(medico).then(res => {
            if (res.statusCode === 'OK') {
                notifica('success', res.message);
                setOpenModal(false);
                caricaTabella(filter);
            } else {
                notifica('error', res.message);
            }
        }).catch(ex => {
            notifica('error', ex);
        })
    }



    const loginSchema = Yup.object().shape({
        descrizione: Yup.string()
            .required('Descrizione obbligatorio')
    });


    function notifica(level: AlertColor, message: string) {
        dispatch(setNewMessage({
            level: level,
            message: message
        }));
    }

    const formik = useFormik({
        initialValues: {
            descrizione: ''
        },
        validationSchema: loginSchema,
        onSubmit: async (values) => {
            const { descrizione } = values;

            if (!fileCaricato) {
                dispatch(setNewMessage({
                    level: 'error',
                    message: 'Devi caricare un file'
                }));
            } else {

                let documenti: DocumentiUtiliDto = new DocumentiUtiliDto();
                documenti.nomeFile = descrizione;
                documenti.tipo = tipoFile;
                documenti.data = allegato;
                documenti.dataCaricamento = new Date();
                documenti.path = "documenti/materialeUtile";
                documenti.tag = "MATERIALE_INFORMATIVO";
                salvaDocumento(documenti);

            }
        }
    })

    function downloadFile(id: number) {
        DocumentiUtliService.downloadDocumenti(id).then(res => {
            if (res.statusCode === 'OK') {
                let documenti: DocumentiUtiliDto = res.out;


                UtilityFile.downloadBrowserFile(UtilityFile.blobBase64ToFile(
                    documenti.data, documenti.tipo, 0), documenti.nomeFile);


            } else {
                notifica('error', res.message);
            }

        }).catch (ex => {
            notifica('error', ex)
        });
    }


    function cancellaMateriale(id: number) {
        DocumentiUtliService.deleteDocumenti(id).then(res => {
            if (res.statusCode === 'OK') {
                setOpenModalDelete(false);
                caricaTabella(filter);
            } else {
                notifica('error', res.message);
            }

        }).catch (ex => {
            notifica('error', ex)
        });
    }


    const isDescrizioneInvalid = formik.touched.descrizione && formik.errors.descrizione != null && formik.errors.descrizione.length > 0;


    const navigate = useNavigate();



    return (
        <>


            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={() => {
                    formik.resetForm();
                    setFileCaricato(false);
                    setOpenModal(true);
                }} variant="contained" style={{ width: "10px" }} color="success" >
                    <Tooltip title="Carica Materiale" placement="top">
                        <AddIcon />
                    </Tooltip>
                </Button>
            </div>

            <FilterDocumenti tipo="documenti" />
            {/* {listaDocumenti.map((row) => (
                                <div className="row col-md-12">
                                {row.codice}
                                </div>    
                            ))} */}


            <Modal
                title="Carica Documenti"
                open={openModal}
                onClose={() => {
                    setOpenModal(false);
                }}
                onConfirm={() => {
                    formik.handleSubmit();
                }}
            >
                <form>
                    <div className="row col-md-12">
                        <div className="col-md-8 mt-3">
                            <InputField
                                type="text"
                                size="small"
                                fullWidth={true}
                                label="Descrizione"
                                {...formik.getFieldProps('descrizione')}
                                isInvalid={isDescrizioneInvalid}
                                errorMessage={formik.errors.descrizione}

                            />
                        </div>

                        <div className="col-md-4 mt-3">
                            <IconButton component="label" color="primary" aria-label="Modifica" onChange={uploadFile}>
                                {" "}
                                <AttachFileTwoTone />
                                <input type="file" hidden />
                            </IconButton>
                        </div>

                    </div>
                </form>

                {/* Inserire qui quello che si vuole far vedere nel corpo della modale */}
            </Modal>


            <Modal
                title="Attenzione"
                open={openModalDelete}
                onClose={() => {
                    setOpenModalDelete(false);
                }}
                onConfirm={() => {
                    cancellaMateriale(idDelete);
                }}
            >
                    <div className="row col-md-12">
                        <div className="col-md-12">
                         <label>Sei sicuro di voler procedere con l'operazione richiesta?</label>
                        </div>
                    </div>
                {/* Inserire qui quello che si vuole far vedere nel corpo della modale */}
            </Modal>


            <Card title="Elenco Documenti" className="mt-3" appearence={{ isFullWidth: true }}>
                
                { listaDocumenti.length> 0 ?
                <> 
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Data Caricamento</TableCell>
                                    <TableCell>Descrizione</TableCell>
                                    <TableCell>Azioni</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {listaDocumenti.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell component="th" scope="row">{row.dataCaricamento ? moment(row.dataCaricamento).format('DD/MM/YYYY') : null}</TableCell>
                                        <TableCell component="th" scope="row">{row.nomeFile}</TableCell>
                                        <TableCell component="th" scope="row">
                                            <IconButton color="primary" aria-label="Download" 
                                                onClick={() => row.id ? downloadFile(row.id) : null}>
                                                <CloudDownloadTwoTone />
                                            </IconButton>
                                            <IconButton aria-label="Cancella Materiale" color="error" 
                                                onClick={() => { setIdDelete(row.id); setOpenModal(false); setOpenModalDelete(true)}} >
                                                <DeleteTwoTone />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={elementiPerPAgine}
                        component="div"
                        labelRowsPerPage="Elementi per pagina"
                        count={totalElement}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </>
                 : <h1>Nessun Elemento</h1> }               
            </Card>
        </>
    );
}

export default ListDocumentiPage;