import { BaseDto } from "./BaseDto";
import { CatalogoDto } from "./CatalogoDto";
import { PazienteDto } from "./PazienteDto";

export class EsameEmaticoDto extends BaseDto {
    
    paziente: PazienteDto;
    dataEsame: Date | null;
    descrizione: string;
    glicemiaDigiuno: number | null;
    colesteroloTotale: number | null;
    colesteroloLDL: number | null;
    colesteroloHDL: number | null;
    creatinina: number | null;
    egfr: number | null;
    emoglobina: number | null;
    piastrine: number | null;
    trigliceridi: number | null;
    hb1Ac: number | null;
    ast: number | null;
    alt: number | null;
    acidoUrico: number | null;
    ck: number | null;
    cpk: number | null;
    tsh: number | null;
    ft3: number | null;
    ft4: number | null;
    ferritina: number | null;
    transferrina: number | null;
    sideremia: number | null;
    lpa: number | null;
    omocisteina: number | null;
    amilasi: number | null;
    lipasi: number | null;
    ldh: number | null;
    sodio: number | null;
    potassio: number | null;
    azotemia: number | null;
    bilirubinaTotale: number | null;
    ntProBNP: number | null;
    bnp: number | null;
    troponinaI: number | null;
    refColesteroloTotaleUM: CatalogoDto;
    refColesteroloLDLUM: CatalogoDto;
    refColesteroloHDLUM: CatalogoDto;
    refTrigliceridiUM: CatalogoDto;
    refTroponinaIUM: CatalogoDto;


    constructor() {
       super();
       
       this.paziente = new PazienteDto();
       this.dataEsame = null;
       this.descrizione = '';
       this.glicemiaDigiuno = null;
       this.colesteroloTotale = null;
       this.colesteroloLDL = null;
       this.colesteroloHDL = null;
       this.creatinina = null;
       this.egfr = null;
       this.emoglobina = null;
        this.piastrine = null;
        this.trigliceridi = null;
        this.hb1Ac = null;
        this.ast = null;
        this.alt = null;
        this.acidoUrico = null;
        this.ck = null;
        this.cpk = null;
        this.tsh = null;
        this.ft3 = null;
        this.ft4 = null;
        this.ferritina = null;
        this.transferrina = null;
        this.sideremia = null;
        this.lpa = null;
        this.omocisteina = null;
        this.amilasi = null;
        this.lipasi = null;
        this.ldh = null;
        this.sodio = null;
        this.potassio = null;
        this.azotemia = null;
        this.bilirubinaTotale = null;
        this.ntProBNP = null;
        this.bnp = null;
        this.troponinaI = null;
        this.refColesteroloTotaleUM= new CatalogoDto();
        this.refColesteroloLDLUM= new CatalogoDto();
        this.refColesteroloHDLUM= new CatalogoDto();
        this.refTrigliceridiUM= new CatalogoDto();
        this.refTroponinaIUM= new CatalogoDto();
              
    }
    
}