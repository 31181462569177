import { ROUTES } from "utils/constants";

export const PROFILO_CLINICO_SUBTABS = [
    {
        label: 'Valori antropometrici',
        path: ROUTES.paziente.detail.tab.profiloClinico.valoriAntropometrici
    },
    {
        label: 'Stile di vita',
        path: ROUTES.paziente.detail.tab.profiloClinico.sileVita
    },
    {
        label: 'Fattori rischio',
        path: ROUTES.paziente.detail.tab.profiloClinico.fattoriRischioCardiovascolare
    },
    {
        label: 'Malattie Cardiovascolari',
        path: ROUTES.paziente.detail.tab.profiloClinico.malattieCardiovascolari
    },
    {
        label: 'Malattie Concomitanti',
        path: ROUTES.paziente.detail.tab.profiloClinico.malattieConcomitanti
    },
];