import React from "react";
import { Section, SectionHeading, SectionLine } from "./styles";
import { StileDiVitaSectionProps } from "./types";

export const StileDiVitaSection: React.FC<StileDiVitaSectionProps & React.PropsWithChildren> = ({ icon, children }) => {
    return (
        <Section>
            <SectionHeading>
                <SectionLine />
                <div>
                    {icon}
                </div>
                <SectionLine />
            </SectionHeading>
            {children}
        </Section>
    );
}

export default StileDiVitaSection;