import { useEffect, useState } from "react";
import { CalendarTitle, CurrentMonthButton, MonthMeetingCountParagraph, PeriodSelectorContainer } from "./styles";
import { Button, ButtonGroup } from '@mui/material';

import Card from "components/layout/Card/Card";
import { AddTwoTone, ArrowBackTwoTone, ArrowForwardTwoTone } from "@mui/icons-material";
import { FlexConfigurableContainer } from "style/elements";
import YearMonthPicker from "./elements/YearMonthPicker/YearMonthPicker";
import CalendarBody from "./elements/CalendarBody/CalendarBody";
import SelectedCellDetail from "./elements/SelectedCellDetail/SelectedCellDetail";
import CalendarioVisiteService from "services/CalendarioVisiteService";
import { CalendarioVisiteDto } from "models/CalendarioVisiteDto";
import { format as dateFormat } from "date-fns";
import { useAppSelector } from "store/storeHooks";


const Calendar: React.FC = () => {
    const [selectedPeriod, setSelectedPeriod] = useState<Date>(new Date());
    const [selectedCell, setSelectedCell] = useState<number | null>(null);

    const { loggedUser } = useAppSelector(({ auth }) => auth);

    const handleChangeMonth = (direction: 'prev' | 'next') => {
        switch (direction) {
            case 'prev':
                setSelectedPeriod(sy => new Date(sy.setMonth(sy.getMonth() - 1)));
                break;
            case 'next':
                setSelectedPeriod(sy => new Date(sy.setMonth(sy.getMonth() + 1)));
                break;
        }
    }


    const [meetingsList, setMeetingsList] = useState<CalendarioVisiteDto[]>([]);

    useEffect(() => {
        const period = new CalendarioVisiteDto();
        period.dataVisita = selectedPeriod;
        period.medico.id = Number(loggedUser?.medicoDto?.id);
        CalendarioVisiteService.getAppuntamentiMensili(period).then(({ out }) => {
            setMeetingsList(out);
        });
    }, [selectedPeriod]);


    return (
        <>
            <Card appearence={{ isFullWidth: true }}>
                {/* <CalendarTitle>
                    <Button variant="contained" color="secondary">
                        <AddTwoTone /> Nuovo
                    </Button>
                </CalendarTitle> */}

                <PeriodSelectorContainer>
                    <FlexConfigurableContainer
                        justify="flex-start"
                    >
                        <ButtonGroup variant="outlined" color="inherit">
                            <Button onClick={() => handleChangeMonth('prev')}>
                                <ArrowBackTwoTone />
                            </Button>
                            <Button onClick={() => handleChangeMonth('next')}>
                                <ArrowForwardTwoTone />
                            </Button>
                        </ButtonGroup>

                        <CurrentMonthButton
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                                setSelectedPeriod(new Date());
                            }}
                        >
                            Mese Corrente
                        </CurrentMonthButton>
                    </FlexConfigurableContainer>

                    <FlexConfigurableContainer>
                        <YearMonthPicker
                            currentPeriod={selectedPeriod}
                            onCurrentPeriodChange={setSelectedPeriod}
                        />
                    </FlexConfigurableContainer>

                    <FlexConfigurableContainer
                        justify="flex-end"
                    >
                        <MonthMeetingCountParagraph>
                            <strong>{meetingsList.length || 0}</strong> appuntamenti questo mese
                        </MonthMeetingCountParagraph>
                    </FlexConfigurableContainer>
                </PeriodSelectorContainer>

                <CalendarBody
                    currentPeriod={selectedPeriod}
                    selectedCell={selectedCell}
                    meetingsList={meetingsList}
                    onCellSelected={(newSelectedCell) => {
                        setSelectedCell(newSelectedCell);
                    }}
                />

                <SelectedCellDetail
                    currentMonth={selectedPeriod}
                    selectedCell={selectedCell}
                    meetingsList={meetingsList.filter((m) => parseInt(dateFormat(new Date(m.dataVisita), 'd')) === selectedCell)}
                />
            </Card>
        </>
    );
}

export default Calendar;