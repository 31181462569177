import axios from "axios";
import { Response } from "models/Response";
import * as Constants from 'utils/constants';
import { StileDiVitaDto } from "models/StileDiVitaDto";
import { GenericFilter } from "components/filter/GenericFilter";

const getLastValori = async (idPaziente: number) => {
    return axios.get<{}, Response<StileDiVitaDto>>(`${Constants.API_STILE_VITA_LAST}/${idPaziente}`);
}

const getStorico = async (filter: GenericFilter) => {
    return axios.post<{}, Response<StileDiVitaDto[]>, GenericFilter>(Constants.API_STILE_VITA_STORICO, filter);
}


const StileDiVitaService = {
    getLastValori,
    getStorico
}

export default StileDiVitaService;