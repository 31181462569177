import { BaseDto } from "./BaseDto";

export class ProfiloDto extends BaseDto {
  codProfilo: string;
  profilo: string;


  constructor() {
    super();
    this.codProfilo = "";
    this.profilo = "";
  }
}