import { useState } from "react";
import { TopHeaderContainer, LetterAvatar, UserMenuItem } from "./styles";
import { motion } from 'framer-motion';
import { Menu } from "@mui/material";
import { Link } from "react-router-dom";
import { LogoutTwoTone, SettingsTwoTone } from "@mui/icons-material";
import { useAppSelector } from "store/storeHooks";
import AccountBoxIconTwoTone from '@mui/icons-material/AccountBox';

const USER_MENU_LIST = [
    {
        path: '/profilo',
        icon: <AccountBoxIconTwoTone />,
        label: 'Profilo'
    },
    {
        path: '/cambio-password',
        icon: <SettingsTwoTone />,
        label: 'Cambio Password'
    },
    {
        path: '/logout',
        icon: <LogoutTwoTone />,
        label: 'Logout'
    },
   
];

const TopHeader: React.FC = () => {

    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const { loggedUser } = useAppSelector(({ auth }) => auth);

    return (
        <TopHeaderContainer>
            <motion.div
                whileTap={{
                    scale: 0.9
                }}
                onClick={handleOpenUserMenu}
            >
                <LetterAvatar>
                    {loggedUser?.medicoDto?.nome[0]}
                    {loggedUser?.medicoDto?.cognome[0]}
                </LetterAvatar>
            </motion.div>
            <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                {USER_MENU_LIST.map(({ path, icon, label }) => (
                    <UserMenuItem key={path} onClick={handleCloseUserMenu}>
                        {icon} <Link to={path}>{label}</Link>
                    </UserMenuItem>
                ))}
            </Menu>
        </TopHeaderContainer>
    );
}

export default TopHeader;