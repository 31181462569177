import { ROUTES } from "utils/constants";

export const PATIENT_TABS = [
    {
        label: 'Trend valori',
        path: ROUTES.paziente.detail.tab.trend
    },
    {
        label: 'Profilo Clinico',
        path: ROUTES.paziente.detail.tab.profiloClinico.main
    },
    {
        label: 'Visite Telematiche',
        path: ROUTES.paziente.detail.tab.visite.main
    },
    {
        label: 'Terapie',
        path: ROUTES.paziente.detail.tab.terapie
    },
    {
        label: 'Esami Ematici',
        path: ROUTES.paziente.detail.tab.esami.main
    },
];