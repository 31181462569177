import styled from "@emotion/styled";
import { motion } from "framer-motion";
import ZINDEXES from "style/z-indexes";

export const AsideContainer = styled(motion.div)`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: ${ZINDEXES.aside};
    background-color: #1e1e2d;
    color: #ffff;
`;

export const AsideHeader = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1a1a27;
    cursor: pointer;
`;

export const LinksList = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem 0;

    a {
        min-height: 62px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 9px 25px;
        color: #5f6070;
        text-decoration: none;

        h6 {
            margin-left: 10px;
        }
    }

    a:hover, a.active {
        background-color: #1a1a27;
        color: #fff;

        svg {
            fill: ${({theme}) => theme.palette.primary.light};
        }
    }
`;