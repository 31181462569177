import React, { useEffect, useState } from "react";
import { AlertTitle } from "@mui/material";
import { NotificationToast } from "./types";
import { useAppSelector } from "store/storeHooks";
import { useAppDispatch } from "store/storeHooks";
import { clearNotification } from "reducers/notification.reducer";
import { motion, Variants } from "framer-motion";

const animationVariants: Variants = {
    show: {
        opacity: 1
    },
    hide: {
        opacity: 0
    }
};

const NotificationToastProvider: React.FC<React.PropsWithChildren> = ({ children }) => {

    const notification = useAppSelector(({ notification }) => notification);
    const dispatch = useAppDispatch();

    const [showToast, setShowToast] = useState<boolean>(false);

    useEffect(() => {
        if (!notification.message) return;

        setShowToast(true);

        const tmId = setTimeout(() => {
            setShowToast(false);
            dispatch(clearNotification());
        }, 5000)

        return (() => {
            clearTimeout(tmId);
        });
    }, [notification, dispatch]);

    const closeToastImp = () => {
        dispatch(clearNotification());
    }

    return (
        <>
            {children}

            <motion.div
                initial={'hide'}
                animate={showToast && notification.message ? 'show' : 'hide'}
                variants={animationVariants}
            >
                <NotificationToast severity={notification.level} onClose={closeToastImp}>
                    <AlertTitle>{notification.level.toUpperCase()}</AlertTitle>
                    {notification.message.toUpperCase()}
                </NotificationToast>
            </motion.div>
        </>
    );
}

export default NotificationToastProvider;