import { FilterVisitaDto } from "components/filter/FilterVisitaDto";
import { VisitaDto } from "models/VisitaDto";
import { useEffect, useState } from "react";
import Emitter from "services/EmitterService";
import VisitaService from "services/VisitaService";
import { StatusCodes } from "utils/enums";
import { pageStart, rowForPage, listForPage, ROUTES } from "utils/constants";
import FilterVisita from "components/filterCard/FilterVisita";
import Card from "components/layout/Card/Card";
import { AlertColor, Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip } from "@mui/material";
import moment from "moment";
import Modal from "components/modal/Modal";
import { useNavigate, useParams } from "react-router-dom";
import { setNewMessage } from "reducers/notification.reducer";
import { useAppDispatch } from "store/storeHooks";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { StatusIndicator } from "pages/paziente/detail/styles";





export const VisiteTab: React.FC = () => {

    const dispatch = useAppDispatch();

    const [listaVisite, setListaVisite] = useState<VisitaDto[]>([]);
    const [totalElement, setTotalElement] = useState(0);
    const [page, setPage] = useState(pageStart);
    const [rowsPerPage, setRowsPerPage] = useState(rowForPage);
    const [elementiPerPAgine, setElementiPerPAgine] = useState(listForPage);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [visitaModale, setVisitaModale] = useState<VisitaDto>(new VisitaDto());
    const { id: pazienteId } = useParams();

    const deleteVisita = (id: number | null | undefined) => {
        VisitaService.deleteVisita(id).then(res => {
            const { statusCode, message } = res;
            if (statusCode === StatusCodes.OK) {
                dispatch(setNewMessage({
                    level: 'success',
                    message: message
                }));
                setIsModalOpen(false);
                caricaTabella(new FilterVisitaDto)
                // onMedicoDeleted();
            } else {
                dispatch(setNewMessage({
                    level: 'error',
                    message: message
                }));
            }
        })
    }


    let filter: FilterVisitaDto = new FilterVisitaDto();



    useEffect(() => {
        caricaTabella(new FilterVisitaDto());
        leggiFiltro();
        return () => {
            Emitter.off("visita", leggiFiltro());
        };
    }, []);


    function leggiFiltro() {
        Emitter.on('visita', (newValue: FilterVisitaDto) => {
            filter = newValue;
            caricaTabella(filter);
        });
    }

    function intiDataTable() {
        setListaVisite([]);
        setTotalElement(0);
    }

    function caricaTabella(filter: FilterVisitaDto) {
        intiDataTable();
        filter.paziente = pazienteId != null ? (Number(pazienteId)) : null;
        VisitaService.findVisitaPage(filter).then(res => {
            if (res.statusCode === StatusCodes.OK) {
                setListaVisite(res.out);
                setTotalElement(res.paginationInfo?.totalElements ?? 0)
            } else {
                console.error(res.message);
            }
        }).catch(ex => {
            console.error(ex);
        })
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        filter.selectPage = newPage;
        filter.pageSize = rowsPerPage;
        caricaTabella(filter);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        filter.selectPage = page;
        filter.pageSize = parseInt(event.target.value, 10);
        caricaTabella(filter);
    };



    const navigate = useNavigate();

    return (
        <>

            <div className="d-flex justify-content-start mb-2">
                <Button onClick={() => {
                    navigate(ROUTES.paziente.list);
                }} variant="contained" style={{ width: "10px" }} color="primary" >
                    <Tooltip title="Elenco Pazienti" placement="top">
                        <ArrowBackIosNewIcon />
                    </Tooltip>
                </Button>
            </div>

            {/* <FilterVisita tipo="visita" /> */}

            {!listaVisite || listaVisite.length === 0 ? (
                <Card appearence={{ isFullWidth: true }}>
                    <h2 className="centered">Nessuna visita trovata con i criteri selezionati</h2>
                </Card>
            ) : (
                <Card title="Elenco Visite" className="mt-3" appearence={{ isFullWidth: true }}>
                    {/* <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={() => {
                            navigate(ROUTES.paziente.detail.tab.visite.new.replace(':id', `${pazienteId}`));
                        }} variant="contained" style={{ width: "10px" }} color="success" >
                            <Tooltip title="Aggiungi Visita" placement="top">
                                <AddIcon />
                            </Tooltip>
                        </Button>
                    </div> */}

                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <strong>Data Visita</strong>
                                    </TableCell>
                                    <TableCell className="d-flex justify-content-center">
                                        <strong>Punteggio</strong>
                                    </TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {listaVisite.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell component="th" scope="row">{row.dataVisita ? moment(row.dataVisita).format('DD/MM/YYYY') : null}</TableCell>
                                        <TableCell component="th" scope="row">
                                            <div className="d-flex justify-content-center mt-3 mb-3">
                                                <StatusIndicator score={row.punteggioTotale} />
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <Tooltip title="Visualizza Visita" placement="top">
                                                <IconButton>
                                                    <VisibilityIcon color="primary" onClick={() => {
                                                        navigate(ROUTES.paziente.detail.tab.visite.visualizza.replace(':idVisita', `${row.id}`).replace(':id', `${pazienteId}`));
                                                    }} />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={elementiPerPAgine}
                        component="div"
                        count={totalElement}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />

                </Card>
            )}


            <Modal

                open={isModalOpen}
                title="ATTENZIONE"
                onClose={() => setIsModalOpen(false)}
                onConfirm={() => deleteVisita(visitaModale.id)}
            >
                <div className="d-flex justify-content-center">
                    <p style={{ width: '40%' }}>La visita  del paziente<strong>{visitaModale.paziente.nome} {visitaModale.paziente.cognome}</strong> verrà eliminato in maniera permanente. Confermi di voler proseguire ?</p>
                </div>
            </Modal>

        </>
    );
}

export default VisiteTab;
