import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { useEffect, useState } from 'react';
import { ScrollTopButtonContainer } from "./styles";

const ScrollTopButton: React.FC = () => {

    const [isScrolling, setIsScrolling] = useState<boolean>(false);
    const [showButton, setShowButton] = useState<boolean>(false);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            setIsScrolling(true);
        });
    }, []);

    //Debounce
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            const show = window.scrollY !== 0;
            setShowButton(show);
            setIsScrolling(false);
        }, 300);

        return () => {
            clearTimeout(timeoutId);
        }
    }, [isScrolling]);

    const animateTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    if(!showButton) {
        return null;
    }

    return (
        <ScrollTopButtonContainer
            color='primary'
            onClick={animateTop}
        >
            <ArrowUpwardIcon />
        </ScrollTopButtonContainer>
    );
}

export default ScrollTopButton;