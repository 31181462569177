import axios from "axios";
import { FilterPazienteDto } from "components/filter/FilterPazienteDto";
import { DashboardReportDto } from "models/DashboardReportDto";
import { PazienteDto } from "models/PazienteDto";
import { Response } from "models/Response";
import * as Constants from 'utils/constants';

//To Do tipizzare le response

const getPazienteCounts = async () => {
    return axios.get<{}, Response<DashboardReportDto>>(Constants.API_PAZIENTE_GET_COUNTS);
}

const getPazienteById = async (id: number) => {
    return axios.get<{}, Response<PazienteDto>>(`${Constants.API_PAZIENTE}/${id}`);
}

const saveOrUpdatePaziente = async (paziente: PazienteDto) => {
    return axios.post<{}, Response<PazienteDto>, PazienteDto>(Constants.API_PAZIENTE_SAVE_UPDATE, paziente);
}

const listPazienti = async (filtro: FilterPazienteDto) => {
    return axios.post<{}, Response<PazienteDto[]>, FilterPazienteDto>(Constants.API_PAZIENTE_FILTER_USERS, filtro);
}

const attivaDisattiva = async (paziente: PazienteDto) => {
    return axios.post<{}, PazienteDto>(Constants.API_PAZIENTE_ATTIVA_DISATTIVA, paziente);
}


const PazienteService = {
    getPazienteCounts,
    getPazienteById,
    saveOrUpdatePaziente,
    listPazienti,
    attivaDisattiva,

}

export default PazienteService;