import AppTheme from "components/layout/AppTheme";
import GlobalSpinner from "components/layout/GlobalSpinner/GlobalSpinner";
import Authkeeper from "components/utils/AuthKeeper/AuthKeeper";
import NotificationToastProvider from "components/utils/NotificationToastProvider/NotificationToastProvider";
import AppRoutes from "routes/Routes";

function App() {
  return (
    <Authkeeper>
      <GlobalSpinner>
        <AppTheme>
          <NotificationToastProvider>
            <AppRoutes />
          </NotificationToastProvider>
        </AppTheme>
      </GlobalSpinner>
    </Authkeeper>
  );
}

export default App;
