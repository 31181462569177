import { NightlightTwoTone, WbSunnyTwoTone } from "@mui/icons-material";
import { useMemo } from "react";
import { SelectedCellDetailConatiner } from "./styles";
import { SelectedCellDetailProps } from "./types";
import { format as dateFormat } from "date-fns";
import { it } from "date-fns/locale";

const SelectedCellDetail: React.FC<SelectedCellDetailProps> = ({ currentMonth, selectedCell, meetingsList }) => {

    const period = useMemo(() => dateFormat(currentMonth, 'MMMM yyyy', { locale: it }), [currentMonth]);

    const { morningMeetingsList, eveningMeetingsList } = useMemo(() => ({
        morningMeetingsList: meetingsList.filter((m) => parseInt(dateFormat(new Date(m.dataVisita), 'HH')) <= 12),
        eveningMeetingsList: meetingsList.filter((m) => parseInt(dateFormat(new Date(m.dataVisita), 'HH')) > 12),
    }), [meetingsList]);

    if (!selectedCell) {
        return <h3 className="d-flex justify-content-center">Nessun giorno selezionato</h3>
    }

    if(!meetingsList || meetingsList.length === 0) {
        return <h3 className="d-flex justify-content-center">Nessun appuntamento per il giorno selezionato</h3>
    }

    return (
        <SelectedCellDetailConatiner>
            <h3>Appuntamenti del {selectedCell} {period}</h3>

            <div className="d-flex">

                {morningMeetingsList && morningMeetingsList.length > 0 && (
                    <div className="d-flex flex-column align-items-center">
                        <WbSunnyTwoTone color="warning" />

                        <ul>
                            {morningMeetingsList.map((m, idx) => (
                                <li key={`morning_${idx}`}><strong>{dateFormat(new Date(m.dataVisita), 'HH:mm')}</strong> - {m.paziente.nome} {m.paziente.cognome}</li>
                            ))}
                        </ul>
                    </div>
                )}

                {eveningMeetingsList && eveningMeetingsList.length > 0 && (
                    <div className="d-flex flex-column align-items-center">
                        <NightlightTwoTone color="secondary" />

                        <ul>
                            {eveningMeetingsList.map((m, idx) => (
                                <li key={`morning_${idx}`}><strong>{dateFormat(new Date(m.dataVisita), 'HH:mm')}</strong> - {m.paziente.nome} {m.paziente.cognome}</li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>

        </SelectedCellDetailConatiner>
    );
}

export default SelectedCellDetail;