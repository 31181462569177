import { Button, TextField } from "@mui/material";
import Card from "components/layout/Card/Card";
import InputField from "components/layout/inputs/InputField";
import React, { useRef, useState } from "react";
import { FilterCardProps } from "./types";
import moment from "moment";
import * as Yup from 'yup';
import { useFormik, useFormikContext } from 'formik';
import { FilterUtenteDto } from "components/filter/FilterUtenteDto";
import Emitter from "services/EmitterService";
import { CardActionsContainer } from "components/layout/Card/styles";
import { FilterEsameDto } from "components/filter/FilterEsameDto";

const FilterEsame: React.FC<FilterCardProps & React.PropsWithChildren> = ({ children, onClean, onSearch, tipo }) => {

    let filter: FilterEsameDto = new FilterEsameDto();

    const formik = useFormik({
        initialValues: {
            dataDa: '',
            dataA: '',
        },
        onSubmit: async (values) => {
            const { dataDa, dataA } = values;
            filter.dataDa = dataDa ? moment(dataDa, 'YYYY-MM-DD').toDate() : null;
            filter.dataA = dataA ? moment(dataA, 'YYYY-MM-DD').toDate() : null;
            filter.pageSize = 25;
            filter.selectPage = 0;
            Emitter.emit(tipo, filter);
        }
    })

    return (
        <form onSubmit={formik.handleSubmit}>
            <Card
                title="filtro"
                appearence={{ isFullWidth: true }}
            >
                {children}

                <div className="row mt-3 col-md-12">
                    <div className="col-md-6">
                        <TextField
                            label="Data Da"
                            id="dataDa"
                            type="date"
                            size="small"
                            fullWidth={true}
                            {...formik.getFieldProps('dataDa')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                    <div className="col-md-6">
                        <TextField
                            label="Data A"
                            id="dataA"
                            type="date"
                            size="small"
                            fullWidth={true}
                            {...formik.getFieldProps('dataA')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                </div>

                <CardActionsContainer>
                    <Button
                        type="button"
                        color="primary"
                        size="small"
                        onClick={() => formik.resetForm()}
                    >
                        Pulisci
                    </Button>

                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        size="small"
                    >
                        Cerca
                    </Button>
                </CardActionsContainer>
            </Card>
        </form>
    );
}

export default FilterEsame;
