import { useMemo } from "react";
import { useLocation } from "react-router-dom";

import { CurrentPageHeading } from "./styles";

const BottomHeader: React.FC = () => {

    const location = useLocation();

    const currentPath = useMemo(() => {
        if (!location.pathname) {
            return '';
        }

        return location.pathname.split('/');
    }, [location?.pathname]);

    return (
        <>
            <CurrentPageHeading variant="h6">
                {currentPath[1].replaceAll('-', ' ')}
            </CurrentPageHeading>
        </>
    );
}

export default BottomHeader;