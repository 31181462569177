import { AutocompleteInputFieldProps } from './types';
import { Autocomplete, TextField } from '@mui/material';
import InputField from './InputField';

const AutocompleteInputField = ({ label, value, onChange, onSelected, options, name, isInvalid, errorMessage }: AutocompleteInputFieldProps) => {

    if (value instanceof Array) {
        return (
            <Autocomplete
                multiple
                id="tags-outlined"
                options={options}
                getOptionLabel={({ descrizione }) => descrizione}
                filterSelectedOptions
                value={value}
                onChange={(_, value) => onChange?.(value)}
                renderInput={(params) => (
                    <InputField
                        {...params}
                        label={label}
                        name={name}
                        isInvalid={isInvalid}
                        errorMessage={errorMessage}
                    />
                )}
            />
        )
    }

    return (
        <Autocomplete
            id="tags-outlined"
            options={options}
            getOptionLabel={({ descrizione }) => descrizione}
            // defaultValue={value}
            value={value}
            onChange={(_, value) => onSelected?.(value)}
            filterSelectedOptions
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                />
            )}
        />
    );
}

export default AutocompleteInputField;