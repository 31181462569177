import { BaseDto } from "./BaseDto";
import { FarmacoDto } from "./FarmacoDto";
import { MedicoDto } from "./MedicoDto";
import { PazienteDto } from "./PazienteDto";

export class TerapiaMasterDto extends BaseDto {

  medico: MedicoDto;
  paziente: PazienteDto;
  dataInizio: Date | null;
  dataFine: Date | null;
  nota: string;

  constructor() {
    super();

    this.medico = new MedicoDto();
    this.paziente = new PazienteDto();
    this.dataInizio = null;
    this.dataFine = null;
    this.nota = '';

  }

}


export class TerapiaSlaveDto extends BaseDto {

  terapiaMaster: TerapiaMasterDto;
  medico: MedicoDto;
  paziente: PazienteDto;
  dataInizio: Date | null;
  dataFine: Date | null;
  farmaco: FarmacoDto;
  notaSomm: string;
  notaSommMod: string;

  constructor() {
    super();
    this.terapiaMaster = new TerapiaMasterDto();
    this.medico = new MedicoDto();
    this.paziente = new PazienteDto();
    this.dataInizio = null;
    this.dataFine = null;
    this.farmaco = new FarmacoDto();
    this.notaSomm = '';
    this.notaSommMod = '';

  }

}


export class TerapieDto extends BaseDto {

  terapiaMaster: TerapiaMasterDto;
  terapiaSlave: TerapiaSlaveDto[];

  constructor() {
    super();

    this.terapiaMaster = new TerapiaMasterDto();
    this.terapiaSlave = [];

  }

}