

function downloadBrowserFile (blob: any, fileName: string) {
    const link = document.createElement('a');
    document.body.appendChild(link);
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.target = '_blank';
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
    window.URL.revokeObjectURL(link.href);
    link.remove();
  }


const convertBase64 = (file: File) => {
    return new Promise<any>((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
            resolve(fileReader.result);
        }
        fileReader.onerror = (error) => {
            reject(error);
        }
    })
}


function blobBase64ToFile(base64: string, _contentType: string, _sliceSize: number) {
    const contentType = _contentType ? _contentType : '';
    const byteCharacters = atob(base64);
    const byteArrays = [];

    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
}




  const UtilityFile = {
    downloadBrowserFile,
    convertBase64,
    blobBase64ToFile
}

export default UtilityFile;