import { GenericFilter } from "./GenericFilter";

export class FilterEsameDto extends GenericFilter {

    dataEsame: Date | null | undefined;
    medico: number | null;
    paziente: number | null;

    constructor() {
        super();
        
        this.dataEsame = null;
        this.medico = null;
        this.paziente = null;

    }
}