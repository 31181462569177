import axios from "axios";
import { FilterCalendarioVisite } from "components/filter/FilterCalendarioVisite";
import { CalendarioVisiteDto } from "models/CalendarioVisiteDto";
import { Response } from "models/Response";
import * as Constants from 'utils/constants';

//To Do tipizzare le response

const getCalendarioVisiteById = async (id: number) => {
    return axios.get<{}, CalendarioVisiteDto>(`${Constants.API_CALENDARIO_VISITA}/${id}`);
}

const saveUpdateCalendarioVisite = async (calendarioVisite : CalendarioVisiteDto) => {
    return axios.post<{}, CalendarioVisiteDto>(Constants.API_CALENDARIO_VISITE_SAVE_UPDATE, calendarioVisite);
}

const listaAppuntamentiPage= async (filter : FilterCalendarioVisite) => {
    return axios.post<{}, CalendarioVisiteDto[]>(Constants.API_CALENDARIO_VISITE_GET_CALENDARIO_PAGE, filter);
}

const getAppuntamentiMensili= async (period : CalendarioVisiteDto) => {
    return axios.post<{}, Response<CalendarioVisiteDto[]>, CalendarioVisiteDto>(Constants.API_CALENDARIO_VISITE_MENSILI, period);
}

const disattiva = async (id: number) => {
    return axios.get<{}, CalendarioVisiteDto>(`${Constants.API_CALENDARIO_VISITE_DISATTIVA}/${id}`);
}

const CalendarioVisiteService = {
    getCalendarioVisiteById,
    saveUpdateCalendarioVisite,
    listaAppuntamentiPage,
    getAppuntamentiMensili,
    disattiva
}

export default CalendarioVisiteService;