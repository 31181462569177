import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { setNewMessage } from "reducers/notification.reducer";
import ValoriAntropometriciService from "services/ValoriAntropometriciService";
import { StatusCodes } from "utils/enums";
import { useAppDispatch, useAppSelector } from "store/storeHooks";
import { ValoriAntropometriciDto } from "models/ValoriAntropometriciDto";
import { listForPage, pageStart, rowForPage } from "utils/constants";
import { GenericFilter } from "components/filter/GenericFilter";
import Card from "components/layout/Card/Card";
import { Button, TablePagination } from "@mui/material";
import ValoriAntropometriciItemCard from "components/layout/valoriAntropometriciCard/ValoriAntropometriciItemCard";
import moment from "moment";
import * as Yup from 'yup';
import { useFormik } from "formik";
import InputField from "components/layout/inputs/InputField";
import { CardActionsContainer } from "components/layout/Card/styles";

const ValoriAntropometriciSubTab: React.FC = () => {
    
    const { loggedUser } = useAppSelector(({ auth }) => auth);

    let filter: GenericFilter = new GenericFilter();
    
    const { id: pazienteId } = useParams();

    const dispatch = useAppDispatch();

    const [lastValue, setLastValue] = useState<ValoriAntropometriciDto>();

    const [elencoValue, setElencoValue] = useState<ValoriAntropometriciDto[]>([]);

    const [elencoValueLast, setElencoValueLast] = useState<ValoriAntropometriciDto[]>([]);

    const [totalElement, setTotalElement] = useState(0);
    const [page, setPage] = useState(pageStart);
    const [rowsPerPage, setRowsPerPage] = useState(rowForPage);
    const [elementiPerPAgine, setElementiPerPAgine] = useState(listForPage);



    const valoriAntropometriciSchema = Yup.object().shape({
        altezza: Yup.number().integer().max(999,'Valore massimo digitabile è 999')
            .typeError('Il dato inserito non è un numero valido')  
            .required('Altezza campo obbligatorio'),
        peso: Yup.number().max(9999.99,'Valore massimo digitabile è 9999,99').transform((_, value) => {
            if (value.includes('.')) {
              return null;
            }
            return +value.replace(/,/, '.');
          })
            .typeError('Il dato inserito non è un numero valido')  
            .required('Peso campo obbligatorio')
    });

    const formik = useFormik({
        initialValues: {
            altezza: '',
            peso: '',
        },
        validationSchema: valoriAntropometriciSchema,
        onSubmit: ({
            altezza,peso
                   }) => {
             let valoriAntropometrici = new ValoriAntropometriciDto();
             valoriAntropometrici.paziente.id = Number(pazienteId);
             valoriAntropometrici.altezza = Number(altezza);
             valoriAntropometrici.peso = Number(peso.replace(/,/, '.'));
             saveValori(valoriAntropometrici);
        }
    });

    function saveValori(dto: ValoriAntropometriciDto) {
        ValoriAntropometriciService.saveOrUpdatevalori(dto).then( res => {
            if (res.statusCode === StatusCodes.OK) {
                dispatch(setNewMessage({
                    level: 'success',
                    message: res.message
                }));
                formik.resetForm();
                intiDataTalbe();
                lastValoriAntropometrici(Number(pazienteId));
                caricaTabella(inizializzaFiltro(new GenericFilter()));
            } else {
                dispatch(setNewMessage({
                    level: 'error',
                    message: res.message
                }));
            }
        })
    }


    useEffect(() => {
        intiDataTalbe();
        lastValoriAntropometrici(Number(pazienteId));
        caricaTabella(inizializzaFiltro(new GenericFilter()));
       
    }, []);

    function inizializzaFiltro(filter: GenericFilter) {
        filter.parentId = Number(pazienteId);
        return filter;
    }


    function lastValoriAntropometrici(id: number) {

        ValoriAntropometriciService.getLastValori(id).then( res => {
            if (res.statusCode === StatusCodes.OK) {
                let array : ValoriAntropometriciDto[] = [];
                if (res.out && res.out.id){
                    array.push(res.out);
                    setLastValue(res.out)
                }
                setElencoValueLast(array);
            } else {
                dispatch(setNewMessage({
                    level: 'error',
                    message: res.message
                }));
            }
        })

    }


    function intiDataTalbe() {
        setElencoValue([]);
        setElencoValueLast([]);
        setTotalElement(0);
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        filter.selectPage = newPage;
        filter.pageSize = rowsPerPage;
        caricaTabella(filter);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        filter.selectPage = page;
        filter.pageSize = parseInt(event.target.value, 10);
        caricaTabella(filter);
    };

    const navigate = useNavigate();


    function caricaTabella(filter: GenericFilter) {
        ValoriAntropometriciService.pageByFilter(filter).then( res => {
            if (res.statusCode === StatusCodes.OK) {
                setElencoValue(res.out);
                setTotalElement(res.paginationInfo?.totalElements ?? 0)
            } else {
                dispatch(setNewMessage({
                    level: 'error',
                    message: res.message
                }));
            }
        })
    }

    function decodeNumber(valore: number) {
        return valore ? valore : 'ND'
    }


    const isAltezzaInvalid = formik.touched.altezza && formik.errors.altezza != null && formik.errors.altezza.length > 0;
    const isPesoInvalid = formik.touched.peso && formik.errors.peso != null && formik.errors.peso.length > 0;
    
    return(
        <>

    {lastValue && lastValue.id ?
            <Card title="Valori Attuali" appearence={{ isFullWidth: true }}>
            <div className="row">
                    <div className="col-md-3">
                       <strong>Data Registrazione:</strong> {moment(lastValue.dataInizio).format('DD/MM/yyyy HH:mm:ss')} 
                     </div>
                     <div className="col-md-3">
                       <strong>Altezza:</strong> {lastValue.altezza ? decodeNumber(lastValue.altezza) + " (cm)" : null} 
                     </div>
                     <div className="col-md-3">
                     <strong>Peso:</strong> {lastValue.peso ? decodeNumber(lastValue.peso) + " (kg)" : null} 
                     </div>
                     <div className="col-md-3">
                     <strong>Bmi:</strong> {lastValue.bmi ? decodeNumber(lastValue.bmi) : null} 
                     </div>
                </div>
            </Card>
            : <h3>Non sono presenti registrazioni</h3>} 
        
        <form onSubmit={formik.handleSubmit}>
                <div className="row">
                    <Card title="Registra Valori Antropometrici" appearence={{ isFullWidth: true }}>
                        <div className="mb-5">
                            <div className="row">
                                <div className="col-md-6">
                                    <InputField
                                        label="Altezza (cm)"
                                        {...formik.getFieldProps('altezza')}
                                        isInvalid={isAltezzaInvalid}
                                        errorMessage={formik.errors.altezza}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <InputField
                                        label="Peso (kg)"
                                        {...formik.getFieldProps('peso')}
                                        isInvalid={isPesoInvalid}
                                        errorMessage={formik.errors.peso}
                                    />
                                </div>
                              
                            </div>
                            <CardActionsContainer>
                                <Button type="submit" variant="contained" color="success">
                                    Salva
                                </Button>
                            </CardActionsContainer>
                        </div>
                    </Card>
                </div>
            </form>
        


            <Card title="Storico" appearence={{ isFullWidth: true }}>

                {elencoValue.length> 0 ? 
                        elencoValue.map(m => <ValoriAntropometriciItemCard valoriAntropometrici={m} onValoriAntropometriciDelete={() =>  caricaTabella(inizializzaFiltro(new GenericFilter()))} />
                        ) : <h3>La ricerca non ha prodotto risutati</h3>}
              
                {elencoValue.length > 0 && (
                    <TablePagination
                        rowsPerPageOptions={elementiPerPAgine}
                        component="div"
                        count={totalElement}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                )}
                
            </Card>
        </>
    );
}

export default ValoriAntropometriciSubTab;