import { Button, TextField } from "@mui/material";
import { MobileDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format as dateFormat } from 'date-fns';
import { it } from "date-fns/locale";
import { useState } from "react";
import { PickerContainer } from "./styles";
import { YearMonthPickerProps } from "./types";

const YearMonthPicker: React.FC<YearMonthPickerProps> = ({ currentPeriod, onCurrentPeriodChange }) => {

    const [isPickerDialogOpen, setIsPickerDialogOpen] = useState(false);

    return (
        <PickerContainer >
            <LocalizationProvider
                adapterLocale={it}
                dateAdapter={AdapterDateFns}
            >
                <MobileDatePicker
                    open={isPickerDialogOpen}
                    onClose={() => setIsPickerDialogOpen(false)}
                    views={['year', 'month']}
                    value={currentPeriod}
                    closeOnSelect={true}
                    onChange={(newPeriod) => {
                        if (!newPeriod) return;

                        onCurrentPeriodChange(newPeriod);
                    }}
                    onMonthChange={() => {
                        setIsPickerDialogOpen(false);
                    }}
                    renderInput={() => {
                        return (
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                onClick={() => setIsPickerDialogOpen(true)}
                            >
                                {dateFormat(currentPeriod, 'MMMM yyyy', { locale: it })}
                            </Button>
                        );
                    }}
                />
            </LocalizationProvider>
        </PickerContainer>
    );
}

export default YearMonthPicker;