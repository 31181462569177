import { rowForPage, pageStart } from "utils/constants";

export class GenericFilter {
    
    descrizione: string;
    parentId: number | null;
    selectPage: number | null;
    pageSize: number | null;
    tipo: string;
    dataA: Date | null;
    dataDa: Date | null;

    constructor() {
        this.descrizione = "";
        this.parentId = null;
        this.selectPage = pageStart;
        this.pageSize = rowForPage;
        this.tipo = '';
        this.dataA = null;
        this.dataDa = null;

    }
}