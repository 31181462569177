import { TablePagination } from "@mui/material";
import { GenericFilter } from "components/filter/GenericFilter";
import Card from "components/layout/Card/Card";
import { StileDiVitaDto } from "models/StileDiVitaDto";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import StileDiVitaService from "services/StileDiVitaService";
import StileDiVitaCard from "./components/StileDiVitaCard/StileDiVitaCard";
import { rowForPage, listForPage } from "utils/constants";

export const StileDiVitaSubTab: React.FC = () => {

    const { id: pazienteId } = useParams();


    const [lastStileDiVita, setLastStileDiVita] = useState<StileDiVitaDto>(new StileDiVitaDto());
    const [listStileDiVitaStorici, setListStileDiVitaStorici] = useState<StileDiVitaDto[]>([]);
    
    const [totalElement, setTotalElement] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(rowForPage);
    const [page, setPage] = useState<number>(0);

    useEffect(() => {
        StileDiVitaService.getLastValori(Number(pazienteId)).then(({ out }) => {
            setLastStileDiVita(out);
        });
    }, [pazienteId]);

    useEffect(() => {
        const filter = new GenericFilter();
        filter.parentId = Number(pazienteId);
        filter.selectPage = page;
        filter.pageSize = rowsPerPage;

        StileDiVitaService.getStorico(filter).then(({ out, paginationInfo }) => {
            setListStileDiVitaStorici(out);
            setTotalElement(paginationInfo?.totalElements ?? 0);
        });
    }, [pazienteId, page, rowsPerPage]);

    return (
        <>
            {lastStileDiVita ? (
                <>
                    <StileDiVitaCard title="Valori Attuali" item={lastStileDiVita} />

                    <Card title="Storico" appearence={{ isFullWidth: true }}>
                        {listStileDiVitaStorici && listStileDiVitaStorici.length > 0 ?
                            (listStileDiVitaStorici.map((elem, idx) => <StileDiVitaCard key={`Stile_Di_Vita_Item_${idx}`} item={elem} />))

                            : <h3>Nessuno stile di vita storico trovato</h3>}
                    </Card>

                    {listStileDiVitaStorici && listStileDiVitaStorici.length > 0 && (
                        <TablePagination
                            rowsPerPageOptions={listForPage}
                            component="div"
                            count={totalElement}
                            rowsPerPage={rowForPage}
                            page={page}
                            onPageChange={(_, newPage) => {
                                setPage(newPage);
                            }}
                            onRowsPerPageChange={(event) => {
                                setRowsPerPage(parseInt(event.target.value));
                            }}
                        />
                    )}
                </>
            ) : <h3>Stile di vita non registrato</h3>}

        </>
    )
}

export default StileDiVitaSubTab;