import { useCallback, useEffect, useMemo } from "react";
import { CalendarCell, CalendarDay, CalendarRow, DayHeadingCell } from "./styles";
import { CalendarBodyProps, Day, Month, TodayNumbers, Week } from "./types";
import { format as dateFormat } from "date-fns";
import { Chip } from "@mui/material";

const CalendarBody: React.FC<CalendarBodyProps> = ({ currentPeriod, selectedCell, meetingsList, onCellSelected }) => {

    const { todayDayNumber, todayMonthNumber, todayYearNumber } = useMemo<TodayNumbers>(() => {
        const today = new Date();

        return {
            todayDayNumber: parseInt(dateFormat(today, 'd')),
            todayMonthNumber: parseInt(dateFormat(today, 'M')),
            todayYearNumber: parseInt(dateFormat(today, 'yyyy'))
        }
    }, []);

    const calendar = useMemo<Month>(() => {


        //Informazioni giorno corrente

        const monthNumber = parseInt(dateFormat(currentPeriod, 'M'));
        const yearNumber = parseInt(dateFormat(currentPeriod, 'yyyy'));

        //Il primo giorno della settimana del primo giorno del mese selezionato [Da 1 a 7].
        const startingDayOfWeek = parseInt(dateFormat(new Date(currentPeriod.getFullYear(), currentPeriod.getMonth(), 1), 'i'));
        //L'ultimo giorno del mese selezionato [Da 28 a 31].
        const lastDayOfMonth = parseInt(dateFormat(new Date(currentPeriod.getFullYear(), currentPeriod.getMonth() + 1, 0), 'd'));

        const month = new Month();

        const firstWeek = new Week();
        const secondWeek = new Week();
        const thirdWeek = new Week();
        const fourthWeek = new Week();
        const fifthWeek = new Week();
        const sixthWeek = new Week();

        let dayOfMonth = 0;
        for (let c = 0; c < 42; c++) {
            const day = new Day();

            if (c < 7) { //Prima settimana
                if ((c + 1) < startingDayOfWeek) {
                    day.day = null;
                } else {
                    dayOfMonth++;
                    day.day = dayOfMonth;
                    day.isToday = dayOfMonth === todayDayNumber && monthNumber === todayMonthNumber && yearNumber === todayYearNumber;
                }
                firstWeek.days.push(day);
            } else if (c < 14) { //Seconda settimana
                dayOfMonth++;
                day.day = dayOfMonth;
                day.isToday = dayOfMonth === todayDayNumber && monthNumber === todayMonthNumber && yearNumber === todayYearNumber;
                secondWeek.days.push(day);
            } else if (c < 21) { //Terza settimana
                dayOfMonth++;
                day.day = dayOfMonth;
                day.isToday = dayOfMonth === todayDayNumber && monthNumber === todayMonthNumber && yearNumber === todayYearNumber;
                thirdWeek.days.push(day);
            } else if (c < 28) { //Quarta settimana
                dayOfMonth++;
                day.day = dayOfMonth;
                day.isToday = dayOfMonth === todayDayNumber && monthNumber === todayMonthNumber && yearNumber === todayYearNumber;
                fourthWeek.days.push(day);
            } else if (c < 35) { //Quinta settimana
                dayOfMonth++;
                if (dayOfMonth > lastDayOfMonth) {
                    day.day = null;
                } else {
                    day.day = dayOfMonth;
                    day.isToday = dayOfMonth === todayDayNumber && monthNumber === todayMonthNumber && yearNumber === todayYearNumber
                }
                fifthWeek.days.push(day);
            } else if (c < 42) { //Sesta settimana
                dayOfMonth++;
                if (dayOfMonth > lastDayOfMonth) {
                    day.day = null;
                } else {
                    day.day = dayOfMonth;
                    day.isToday = dayOfMonth === todayDayNumber && monthNumber === todayMonthNumber && yearNumber === todayYearNumber
                }
                sixthWeek.days.push(day);
            }
        }

        //Aggiungo le settimane al mese
        month.weeks.push(firstWeek);
        month.weeks.push(secondWeek);
        month.weeks.push(thirdWeek);
        month.weeks.push(fourthWeek);
        month.weeks.push(fifthWeek);
        month.weeks.push(sixthWeek);

        return month;
    }, [currentPeriod, todayDayNumber, todayMonthNumber, todayYearNumber]);

    const dayCountMap = useMemo<Map<number, number>>(() => {
        if (!calendar || !meetingsList || meetingsList.length === 0) {
            return new Map<number, number>();
        }

        const dayCountMap = new Map<number, number>();

        meetingsList.forEach((m) => {
            const giorno = parseInt(dateFormat(new Date(m.dataVisita), 'd'));
            const newValue = dayCountMap.has(giorno) ? (dayCountMap.get(giorno)! + 1) : 1;
            dayCountMap.set(giorno, newValue);
        });

        return dayCountMap;

    }, [calendar, meetingsList]);

    const animateBottom = useCallback(() => {
        window.scrollTo({
            top: document.body.scrollHeight,
            behavior: 'smooth',
        });
    }, []);

    useEffect(() => {

        onCellSelected(null);

    }, [currentPeriod]);

    return (
        <>
            <CalendarRow>
                <DayHeadingCell>Lun</DayHeadingCell>
                <DayHeadingCell>Mar</DayHeadingCell>
                <DayHeadingCell>Mer</DayHeadingCell>
                <DayHeadingCell>Gio</DayHeadingCell>
                <DayHeadingCell>Ven</DayHeadingCell>
                <DayHeadingCell>Sab</DayHeadingCell>
                <DayHeadingCell>Dom</DayHeadingCell>
            </CalendarRow>

            {calendar.weeks.map(({ days }) => (
                <CalendarRow>
                    {days.map(({ isToday, day }) => {
                        const isCurrentMonth = Boolean(day);
                        const isSelected = selectedCell === day;
                        const meetingsCount = day != null && dayCountMap.has(day) ? dayCountMap.get(day) : 0;
                        return (
                            <CalendarCell
                                className={`${isSelected ? 'selected' : ''}`}
                                isToday={isToday}
                                isCurrentMonth={isCurrentMonth}
                                onClick={() => {
                                    if (!isCurrentMonth) {
                                        return;
                                    }

                                    onCellSelected(day);

                                    if (meetingsCount) {
                                        animateBottom();
                                    }
                                }}
                            >
                                <CalendarDay label={day} />

                                {(isCurrentMonth && meetingsCount && meetingsCount > 0) ? (
                                    <Chip label={`Appuntamenti: ${meetingsCount}`} />
                                ) : null}
                            </CalendarCell>
                        )
                    })}
                </CalendarRow>
            ))}
        </>
    );
}

export default CalendarBody;