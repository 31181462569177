import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap-grid.css';
import './index.css';
import App from './App';
import { setupAxios } from 'utils/utility';
import axios from 'axios';
import { Provider } from 'react-redux';
import { store } from 'store/store';

setupAxios(axios);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);