import Button from "@mui/material/Button";
import { MedicoDto } from "models/MedicoDto";
import { useNavigate } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import Tooltip from "@mui/material/Tooltip";
import { useEffect, useMemo, useState } from "react";
import FilterMedico from "components/filterCard/FilterMedico";
import MedicoService from "services/MedicoService";
import { TablePagination } from "@mui/material";
import Card from "components/layout/Card/Card";
import { pageStart, rowForPage, listForPage, ROUTES } from "utils/constants";
import { FilterUtenteDto } from "components/filter/FilterUtenteDto";
import Emitter from "services/EmitterService";
import MedicoItemCard from "components/medicoItemCard/MedicoItemCard";
import { StatusCodes } from "utils/enums";
import { CommonUserProps } from "../types";



const MedicoListPage: React.FC<CommonUserProps> = ({ codProfilo }) => {

    let filter: FilterUtenteDto = new FilterUtenteDto(codProfilo);

    const [listaMedici, setListaMedico] = useState<MedicoDto[]>([]);

    const [totalElement, setTotalElement] = useState(0);
    const [page, setPage] = useState(pageStart);
    const [rowsPerPage, setRowsPerPage] = useState(rowForPage);

    const elencoTitle = useMemo(() => codProfilo === 'A' ? 'Elenco Admin' : 'Elenco Medici',[codProfilo]);


    const [elementiPerPAgine, setElementiPerPAgine] = useState(listForPage);

    useEffect(() => {
        caricaTabella(new FilterUtenteDto(codProfilo));
        leggiFiltro();
        return () => {
            Emitter.off("medico", leggiFiltro());
        };
    }, []);

    function leggiFiltro() {
        Emitter.on('medico', (newValue: FilterUtenteDto) => {
            filter = newValue;
            setRowsPerPage(25);
            setPage(0);
            caricaTabella(filter);
        });
    }

    function intiDataTalbe() {
        setListaMedico([]);
        setTotalElement(0);
    }

    function caricaTabella(filter: FilterUtenteDto) {
        intiDataTalbe();
        MedicoService.listaMedici(filter).then(res => {
            if (res.statusCode === StatusCodes.OK) {
                setListaMedico(res.out);
                setTotalElement(res.paginationInfo?.totalElements ?? 0)
            } else {
                console.error(res.message);
            }
        }).catch(ex => {
            console.error(ex);
        })
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        filter.selectPage = newPage;
        filter.pageSize = rowsPerPage;
        caricaTabella(filter);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        filter.selectPage = page;
        filter.pageSize = parseInt(event.target.value, 10);
        caricaTabella(filter);
    };

    const navigate = useNavigate();

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={() => {
                    const route = codProfilo === 'A' ? ROUTES.admin.new : ROUTES.medico.new
                    navigate(route);
                }} variant="contained" style={{ width: "10px" }} color="success" >
                    <Tooltip title="Aggiungi Medico" placement="top">
                        <AddIcon />
                    </Tooltip>
                </Button>
            </div>

            <FilterMedico tipo="medico" />
            <Card title={elencoTitle} appearence={{ isFullWidth: true }}>

                {listaMedici.map(m => <MedicoItemCard codProfilo={codProfilo} medico={m} onMedicoDeleted={() => caricaTabella(new FilterUtenteDto(codProfilo))} />)}
                <TablePagination
                    rowsPerPageOptions={elementiPerPAgine}
                    component="div"
                    count={totalElement}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Card>
        </>
    );
}

export default MedicoListPage;