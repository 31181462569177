import { MedicoDto } from "models/MedicoDto";
import { useEffect, useRef, useState } from "react";
import MedicoService from "services/MedicoService";
import { useAppSelector, useAppDispatch } from "store/storeHooks";
import { StatusCodes } from "utils/enums";
import { useFormik } from "formik";
import Card from "components/layout/Card/Card";
import InputField from "components/layout/inputs/InputField";
import { CardActionsContainer } from "components/layout/Card/styles";
import { Button } from "@mui/material";
import { setNewMessage } from "reducers/notification.reducer";


export const MedicoSelfEdit: React.FC = () => {

    const { loggedUser } = useAppSelector(({ auth }) => auth);


     const [medico, setMedico] = useState<MedicoDto>();
     const doctor = useRef<MedicoDto>(new MedicoDto);
     const dispatch = useAppDispatch();

    
     useEffect(() => {
        MedicoService.getMedicoById(Number(loggedUser?.medicoDto?.id)).then(res => {
            if (res.statusCode === StatusCodes.OK) {
                setMedico(res.out);
                fillMedico(res.out);
            } else {
                console.error(res.message);
            }
        }).catch(ex => {
            console.error(ex);
        })
    }, []);

    const fillMedico = (m: MedicoDto) => {
        doctor.current = m;
        formik.setFieldValue('nome', m.nome);
        formik.setFieldValue('cognome', m.cognome);
        formik.setFieldValue('email', m.utente.usrMail);
    }

    const formik = useFormik({
        initialValues: {
            nome: '',
            cognome: '',
            email: '',
            
        },
       // validationSchema: pazienteSchema,
        onSubmit: ({
                       nome,
                       cognome,
                       email,
                       
                   }) => {

            doctor.current.nome = nome;
            doctor.current.cognome = cognome;
            doctor.current.utente.usrMail = email;
            saveMedico();
        }
    });

    function saveMedico() {
        MedicoService.saveMedico(doctor.current).then(res => {
            const { statusCode, message } = res;

            if (statusCode === StatusCodes.OK) {
                dispatch(setNewMessage({
                    level: "success",
                    message
                }));

              
            } else {
                dispatch(setNewMessage({
                    level: "error",
                    message
                }));
            }
        }).catch(err => {
            dispatch(setNewMessage({
                level: "error",
                message: err
            }));
        })
    };

    return (
        <>
           <form onSubmit={formik.handleSubmit}>
                <div className="row">
                    <Card title="Dati Personali" appearence={{ isFullWidth: true }}>
                        <div className="mb-5">
                            <div className="row">
                                <div className="col-md-4">
                                    <InputField
                                        label="Nome"
                                        {...formik.getFieldProps('nome')}
                                        // isInvalid={isNomeInvalid}
                                        errorMessage={formik.errors.nome}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <InputField
                                        label="Cognome"
                                        {...formik.getFieldProps('cognome')}
                                        // isInvalid={isCognomeInvalid}
                                        errorMessage={formik.errors.cognome}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <InputField
                                        label="E-Mail"
                                        {...formik.getFieldProps('email')}
                                        // isInvalid={isNomeInvalid}
                                        errorMessage={formik.errors.email}
                                    />
                                </div>
                            </div>
                           
                            <CardActionsContainer>
                                <Button type="submit" variant="contained" color="success">
                                    Salva
                                </Button>
                            </CardActionsContainer>
                        </div>
                    </Card>
                </div>
            </form>
        </>
    );
}

export default MedicoSelfEdit;


