import { CircularProgress } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { SpinnerOverlay } from "./styles";
import Emitter from "services/EmitterService";

const GlobalSpinner: React.FC<React.PropsWithChildren> = ({ children }) => {

    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const contatoreChiamateRef = useRef<number>(0);

    const spinnerOff = () => {
        setShowSpinner(false);
    }

    useEffect(() => {
        let timeoutId: any = null;

        const switchSpinnerEvent = () => {
            Emitter.on('spinner', (newValue: boolean) => {
                if (newValue) {
                    contatoreChiamateRef.current = contatoreChiamateRef.current + 1;
                    setShowSpinner(true);
                    return;
                }

                contatoreChiamateRef.current = contatoreChiamateRef.current - 1;

                if (contatoreChiamateRef.current <= 0) {
                    timeoutId = setTimeout(spinnerOff, 500);
                }
            });
        }

        switchSpinnerEvent();

        return () => {
            Emitter.off("spinner", switchSpinnerEvent());
            clearTimeout(timeoutId);
        };
    }, []);

    return (
        <>
            {showSpinner &&
                (<SpinnerOverlay>
                    <CircularProgress></CircularProgress>
                </SpinnerOverlay>)
            }

            {children}
        </>)
}

export default GlobalSpinner;