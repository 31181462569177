import { useEffect, useState } from "react";
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import Card from "components/layout/Card/Card";
import { pageStart, rowForPage, listForPage, ROUTES } from "utils/constants";
import Emitter from "services/EmitterService";
import Modal from "components/modal/Modal";
import { useDispatch } from "react-redux";
import { setNewMessage } from "reducers/notification.reducer";
import { StatusCodes } from "utils/enums";
import { FilterEsameDto } from "components/filter/FilterEsameDto";
import { EsameDto, EsameMasterDto } from "models/EsameDto";
import EsameService from "services/EsameEmaticoService";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import AddIcon from '@mui/icons-material/Add';
import Tooltip from "@mui/material/Tooltip";
import FilterEsame from "components/filterCard/FilterEsame";
import EsameItemCard from "components/esameItemCard/EsameItemCard";
import { useParams } from "react-router-dom";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { EditTwoTone, DeleteTwoTone } from "@mui/icons-material";
import moment from "moment";
import { FilterEsameEmaticoDto } from "components/filter/FilterEsameEmaticoDto";
import { EsameEmaticoDto } from "models/EsameEmaticoDto";
import EsameEmaticoService from "services/EsameEmaticoService";

export const EsamiTab: React.FC = () => {

    let filter: FilterEsameEmaticoDto = new FilterEsameEmaticoDto();

    const { id: idPaziente } = useParams();
    const [listaEsami, setListaEsami] = useState<EsameEmaticoDto[]>([]);

    const [totalElement, setTotalElement] = useState(0);
    const [page, setPage] = useState(pageStart);
    const [rowsPerPage, setRowsPerPage] = useState(rowForPage);

    const dispatch = useDispatch();

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [esameModal, setEsameModal] = useState<EsameEmaticoDto>(new EsameEmaticoDto());

    const [elementiPerPAgine, setElementiPerPAgine] = useState(listForPage);

    useEffect(() => {
        caricaTabella(new FilterEsameDto());
        leggiFiltro();
        return () => {
            Emitter.off("esame", leggiFiltro());
        };
    }, []);

    function leggiFiltro() {
        Emitter.on('esame', (newValue: FilterEsameEmaticoDto) => {
            filter = newValue;
            caricaTabella(filter);
        });
    }

    function intiDataTalbe() {
        setListaEsami([]);
        setTotalElement(0);
    }

    function caricaTabella(filter: FilterEsameEmaticoDto) {
        intiDataTalbe();
        filter.paziente = idPaziente != null ? (Number(idPaziente)) : null;
        EsameEmaticoService.listaEsami(filter).then(res => {
            if (res.statusCode === 'OK') {
                setListaEsami(res.out);
                setTotalElement(res.paginationInfo?.totalElements ?? 0);
                console.info(listaEsami);
            } else {
                console.error(res.message);
            }
        }).catch(ex => {
            console.error(ex);
        })
    }

    const deleteEsame = (id: number | null | undefined) => {
        EsameEmaticoService.deleteEsame(id).then(res => {
            const { statusCode, message } = res;

            if (statusCode === StatusCodes.OK) {
                dispatch(setNewMessage({
                    level: 'success',
                    message: message
                }));
                setIsModalOpen(false);
                caricaTabella(new FilterEsameEmaticoDto());
            } else {
                dispatch(setNewMessage({
                    level: 'error',
                    message: message
                }));
            }
        })
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        filter.selectPage = newPage;
        filter.pageSize = rowsPerPage;
        caricaTabella(filter);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        filter.selectPage = page;
        filter.pageSize = parseInt(event.target.value, 10);
        caricaTabella(filter);
    };

    function setForDelete(esame: EsameEmaticoDto) {
        setIsModalOpen(true);
        setEsameModal(esame);
    }

    const navigate = useNavigate();

    return (
        <>
            <div className="d-flex justify-content-end col-md-12">
                <Button onClick={() => {
                    navigate(ROUTES.paziente.detail.tab.esami.new.replace(':id', `${idPaziente}`));
                }} variant="contained" style={{ width: "10px" }} color="success" >
                    <Tooltip title="Aggiungi Esame Ematico" placement="top">
                        <AddIcon />
                    </Tooltip>
                </Button>
            </div>

            <div className="d-flex justify-content-start col-md-6">
                <Button onClick={() => {
                    navigate(ROUTES.paziente.list);
                }} variant="contained" style={{ width: "10px" }} color="primary" >
                    <Tooltip title="indietro" placement="top">
                        <ArrowBackIosNewIcon />
                    </Tooltip>
                </Button>
            </div>

            <FilterEsame tipo="esame" />

            {!listaEsami || listaEsami.length === 0 ? (
                <Card appearence={{ isFullWidth: true }}>
                    <h2 className="centered">Nessun esame trovato con i criteri selezionati</h2>
                </Card>
            ) : (
                <Card title="Elenco Esami Ematici" appearence={{ isFullWidth: true }}>

                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Data Esame</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {listaEsami.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell component="th" scope="row">{row.dataEsame ? moment(row.dataEsame).format('DD/MM/YYYY') : null}</TableCell>
                                        <TableCell component="th" align="right" scope="row">
                                            <Tooltip title="Visualizza Visita" placement="top">
                                                <IconButton>
                                                    <EditTwoTone color="primary" onClick={() => {
                                                        navigate(ROUTES.paziente.detail.tab.esami.update.replace(':idEsame', `${row.id}`).replace(':id', `${idPaziente}`));
                                                    }} />
                                                </IconButton>
                                            </Tooltip>

                                            <Tooltip title="Cancella Visita" placement="top">
                                                <IconButton onClick={() => setForDelete(row)} >
                                                    <DeleteTwoTone color="error" />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TablePagination
                        rowsPerPageOptions={elementiPerPAgine}
                        component="div"
                        count={totalElement}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            )}

            <Modal
                open={isModalOpen}
                title="ATTENZIONE"
                onClose={() => setIsModalOpen(false)}
                onConfirm={() => deleteEsame(esameModal.id)}
            >
                <div className="d-flex justify-content-center">
                    <p style={{ width: '40%' }}>L'esame del paziente <strong>{esameModal.paziente.cognome} {esameModal.paziente.nome}</strong> verrà eliminato in maniera permanente. Confermi di voler proseguire ?</p>
                </div>
            </Modal>
        </>
    );

}

export default EsamiTab;