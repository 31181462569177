import { Button } from "@mui/material";
import Card from "components/layout/Card/Card";
import InputField from "components/layout/inputs/InputField";
import React, { useRef, useState } from "react";
import { FilterCardProps } from "./types";

import * as Yup from 'yup';
import { useFormik, useFormikContext } from 'formik';
import { FilterUtenteDto } from "components/filter/FilterUtenteDto";
import Emitter from "services/EmitterService";
import { CardActionsContainer } from "components/layout/Card/styles";
let filter: FilterUtenteDto = new FilterUtenteDto();

const FilterMedico: React.FC<FilterCardProps & React.PropsWithChildren> = ({ children, onClean, onSearch, tipo }) => {

    let filter: FilterUtenteDto = new FilterUtenteDto();

    const formik = useFormik({
        initialValues: {
            nominativo: '',
        },
        onSubmit: async (values) => {
            const { nominativo } = values;
            filter.descrizione = nominativo;
            filter.pageSize = 25;
            filter.selectPage = 0;
            Emitter.emit(tipo, filter);
        }
    })

    return (
        <form onSubmit={formik.handleSubmit}>
            <Card
                title="filtro"
                appearence={{ isFullWidth: true }}
            >
                {children}

                <InputField
                    type="text"
                    size="small"
                    label="Nominativo"
                    {...formik.getFieldProps('nominativo')}
                />

                <CardActionsContainer>
                    <Button
                        type="button"
                        color="primary"
                        size="small"
                        onClick={() => formik.resetForm()}
                    >
                        Pulisci
                    </Button>

                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        size="small"
                    >
                        Cerca
                    </Button>
                </CardActionsContainer>
            </Card>
        </form>
    );
}

export default FilterMedico;
