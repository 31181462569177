import Button from "@mui/material/Button";
import FilterCard from "components/filterCard/FilterCard";
import { MedicoDto } from "models/MedicoDto";
import { useNavigate } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import Tooltip from "@mui/material/Tooltip";
import Modal from "components/modal/Modal";
import { useEffect, useState } from "react";
import FilterMedico from "components/filterCard/FilterMedico";
import MedicoService from "services/MedicoService";
import { AlertColor, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import { AddShoppingCart, Alarm, Delete, DeleteTwoTone, EditOffTwoTone, EditTwoTone, TableRows } from "@mui/icons-material";
import Card from "components/layout/Card/Card";
import { pageStart, rowForPage, listForPage } from "utils/constants";
import { FilterUtenteDto } from "components/filter/FilterUtenteDto";
import Emitter from "services/EmitterService";
import { useFormik } from "formik";
import * as Yup from 'yup';
import InputField from "components/layout/inputs/InputField";
import { useAppDispatch, useAppSelector } from "store/storeHooks";
import { NOMEM } from "dns";
import { CatalogoDto } from "models/CatalogoDto";
import { setNewMessage } from "reducers/notification.reducer";
import { GenericFilter } from "components/filter/GenericFilter";
import { FarmacoDto } from "models/FarmacoDto";
import FarmacoService from "services/FarmacoService";
import FilterFarmaco from "components/filterCard/FilterFarmaco";

import moment from 'moment';

const ListFarmaciPage: React.FC = () => {

    let filter: GenericFilter = new GenericFilter();



    const [listaFarmaci, setListaFarmaci] = useState<FarmacoDto[]>([]);

    const [totalElement, setTotalElement] = useState(0);
    const [page, setPage] = useState(pageStart);
    const [dense, setDense] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(rowForPage);


    const [elementiPerPAgine, setElementiPerPAgine] = useState(listForPage);

    const app = useAppSelector((app) => app.auth.loggedUser);

    const appNotification = useAppDispatch();


    useEffect(()=>{
        caricaTabella(new GenericFilter());
        leggiFiltro();
        return () => {
            Emitter.off("farmaco", leggiFiltro());
          };
    },[]); 

    function leggiFiltro() {
        Emitter.on('farmaco', (newValue:GenericFilter) => { 
        filter = newValue;
        setRowsPerPage(25);
        setPage(0);
        caricaTabella(filter);
        });
    }



    function intiDataTalbe() {
        setListaFarmaci([]);
        setTotalElement(0);
    }

    function caricaTabella(filter: GenericFilter) {
        intiDataTalbe();
        FarmacoService.listaFarmaci(filter).then(res =>{
                if (res.statusCode === 'OK') {
                    setListaFarmaci(res.out);
                    setTotalElement(res.paginationInfo?.totalElements ?? 0)
                } else {
                    console.error(res.message);
                }
        }).catch( ex => {
            console.error(ex);
        })
    }
    
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        filter.selectPage = newPage;
        filter.pageSize = rowsPerPage;
        caricaTabella(filter);
      };
    
      const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        filter.selectPage = page;
        filter.pageSize = parseInt(event.target.value, 10);
        caricaTabella(filter);
      };

    const navigate = useNavigate();
    

    return (
        <>
            <FilterFarmaco tipo="farmaco" />
            {/* {listaFarmaci.map((row) => (
                                <div className="row col-md-12">
                                {row.codice}
                                </div>    
                            ))} */}
            <Card title="Elenco Farmaci" className="mt-3" appearence={ {isFullWidth : true}}> 
                    <TableContainer component={Paper}>
                        <Table  aria-label="simple table">
                            <TableHead>
                            <TableRow>
                                <TableCell>Descrizione</TableCell>
                                <TableCell>Principio Attivo</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {listaFarmaci.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell component="th" scope="row">
                                        {row.descrizione}
                                    </TableCell>
                                    <TableCell component="th" scope="row">{row.codice}</TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={elementiPerPAgine}
                        component="div"
                        count={totalElement}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                 
            </Card> 
        </>
    );
}

export default ListFarmaciPage;