import axios from "axios";
import { ChangePasswordDto } from "models/ChangePasswordDto";
import { LoginDto } from "models/LoginDto";
import { Response } from "models/Response";
import { UtenteDto } from "models/UtenteDto";
import * as Constants from 'utils/constants';

//To Do tipizzare le response

const getUserById = async (id: number) => {
    return axios.get<{}, UtenteDto>(`${Constants.API_USER}/${id}`);
}

const versioneApplicazione = async () => {
    return axios.get<{}, string>(`${Constants.API_USER}`);
}

const saveOrUpdateUtente = async (utente : UtenteDto) => {
    return axios.post<{}, UtenteDto>(Constants.API_USER_SAVE_UPDATE, utente);
}

const updateEmail = async (id : number, email : string) => {
    return axios.get<{}, UtenteDto>(`${Constants.API_USER_UPDATE_EMAIL}/${id}/${email}`);
}

const login = async ( loginUtente : LoginDto) => {
    return axios.post(Constants.API_USER_LOGIN, loginUtente);
}

const changePassword = async ( datiAccesso : ChangePasswordDto) => {
    return axios.post<{}, Response<UtenteDto>>(Constants.API_USER_CHANGE_PASSWORD, datiAccesso);
}

const resetPassword = async (username : string) => {
    return axios.get<{}, Response<UtenteDto>>(`${Constants.API_USER_RESET_PASSWORD}/${username}`);
}

const attivaDisattiva = async ( utente : UtenteDto) => {
    return axios.post<{}, Response<UtenteDto>, UtenteDto>(Constants.API_USER_ATTIVA_DISATTIVA, utente);
}


const UtenteService = {
    getUserById,
    versioneApplicazione,
    saveOrUpdateUtente,
    updateEmail,
    login,
    changePassword,
    resetPassword,
    attivaDisattiva

}

export default UtenteService;