import styled from "@emotion/styled";

export const LoginPageContainer = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    background-color: #a3c3f2;
`;

const SideScreenContainer = styled.div`
    display: flex;
    height: 100%;
`;

export const SideScreenAuth = styled(SideScreenContainer)`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%;
`;

export const SideScreenImage = styled(SideScreenContainer)`
    flex-direction: column;
    justify-content: flex-end;
    width: 60%;

    image {
        width: 100%;
        height: 20%;
    }
`;