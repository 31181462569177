import axios from "axios";
import { GenericFilter } from "components/filter/GenericFilter";
import { FarmacoDto } from "models/FarmacoDto";
import { Response } from "models/Response";
import * as Constants from 'utils/constants';

//To Do tipizzare le response

 

const listaFarmaci = async (filter: GenericFilter) => {
    return axios.post<{}, Response<FarmacoDto[]>>(Constants.API_FARMACO_FILTER_USER, filter);
}


const FarmacoService = {
    listaFarmaci
}

export default FarmacoService;