import styled from "@emotion/styled";
import { Avatar, MenuItem, Typography } from "@mui/material";
import { motion } from "framer-motion";
import MEASURES from "style/measures";
import ZINDEXES from "style/z-indexes";

export const HeaderContainer = styled.div`
    position: fixed;
    top: 0;
    height: ${MEASURES.header.height};
    width: ${MEASURES.header.width};
    z-index: ${ZINDEXES.header};
    background-color: #ffff;
`;

export const HalfHeader = styled(motion.div) <{ hasBottomSeparator?: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50%;
    padding-right: 40px;
    border-width: ${({ hasBottomSeparator }) => hasBottomSeparator ? '1px' : 0};
    border-bottom-color: #eff2f5;
    border-bottom-style: solid;
`;

export const LetterAvatar = styled(Avatar)`
    background-color: #fd7e97;
    cursor: pointer;
`;

export const UserMenuItem = styled(MenuItem)`
    display: flex;
    justify-content: flex-start;
    width: 100%;
    
    a {
        text-decoration: none;
        color: #5f6070;
    }

    svg {
        fill: #5f6070;
        margin-right: 10px;
    }

    &:hover {
        a {
            color: ${({ theme }) => theme.palette.primary.light};
        }

        svg {
            fill: ${({ theme }) => theme.palette.primary.light}
        }
    }
`;

export const CurrentPageHeading = styled(Typography)`
    text-transform: capitalize;
    color: #181c32;
    font-weight: bold;
`;

export const TopHeaderContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`;

