import { useTheme } from "@mui/material";
import Card from "components/layout/Card/Card";
import { useCallback, useEffect, useMemo, useState } from "react";
import VisitaService from "services/VisitaService";
import { DomainTuple, LineSegment, VictoryAxis, VictoryChart, VictoryLabel, VictoryLegend, VictoryLine, VictoryScatter, VictoryTooltip, VictoryVoronoiContainer } from "victory";
import { Axis } from "./types";
import { format as dateFormat } from "date-fns";
import { ChartCard, ChartHeading, ChartLegend, LegendIndicator } from "./styles";
import { useParams } from "react-router-dom";

import bilanciaImg from 'assets/bilancia.svg';
import ValoriAntropometriciService from "services/ValoriAntropometriciService";
import { DateRange } from "@mui/icons-material";


const TrendTab: React.FC = () => {

    const theme = useTheme();

    const { id: idPaziente } = useParams();

    const [listPresSist, setListPresSist] = useState<Axis<Date, number>[]>([]);
    const [listPresDiast, setListPresDiast] = useState<Axis<Date, number>[]>([]);
    const [listPeso, setListPeso] = useState<Axis<Date, number>[]>([]);

    const [pressioneDateHighlighted, setPressioneDateHighlighted] = useState<Date | null>(null);
    const [pesoDateHighlighted, setPesoDateHighlighted] = useState<Date | null>(null);

    const fetchVisiteLastMonth = useCallback(() => {
        VisitaService.getLastMonth(Number(idPaziente)).then(({ out }) => {
            const listSistTemp: Axis<Date, number>[] = [];
            const listDiastTemp: Axis<Date, number>[] = [];

            out.forEach((v) => {
                listSistTemp.push({
                    x: v.dataVisita,
                    y: v.pressioneArteriosaSistolica,
                });

                listDiastTemp.push({
                    x: v.dataVisita,
                    y: v.pressioneArteriosaDiastolica,
                });

            });

            setListPresSist(listSistTemp);
            setListPresDiast(listDiastTemp);
        });
    }, [idPaziente]);

    const fetchValoriLastSixMonths = useCallback(() => {
        ValoriAntropometriciService.getLastSixMonthsByPaziente(Number(idPaziente)).then(({ out }) => {
            const listPesoTemp: Axis<Date, number>[] = [];

            out.forEach((v) => {
                listPesoTemp.push({
                    x: v.dataInizio!,
                    y: v.peso!
                });
            });

            setListPeso(listPesoTemp);
        });
    }, [idPaziente]);

    useEffect(() => {
        fetchVisiteLastMonth();
        fetchValoriLastSixMonths();
    }, [fetchVisiteLastMonth, fetchValoriLastSixMonths]);

    return (
        <div className="row">
            <div className="col-md-12">
                <ChartCard
                    appearence={{ isFullWidth: true }}
                >
                    <ChartHeading>
                        <h3>Trend della pressione nell'ultimo mese</h3>
                        <ChartLegend>
                            <div><LegendIndicator color="info" /> <p>sistolica</p></div>
                            <div><LegendIndicator color="error" /> <p>diastolica</p></div>
                        </ChartLegend>
                    </ChartHeading>

                    <VictoryChart
                        width={1300}
                        height={350}
                        events={[{
                            target: "parent",
                            eventHandlers: {
                                onMouseLeave: () => {
                                    setPressioneDateHighlighted(null);
                                }
                            }
                        }]}
                    >
                        <VictoryAxis
                            dependentAxis
                            domain={[30, 270]}
                            tickValues={[30, 60, 90, 120, 150, 180, 210, 240, 270]}
                            tickLabelComponent={<VictoryLabel dy={-5} />}
                            style={{
                                tickLabels: {
                                    fontSize: 15,
                                }
                            }}
                        />
                        <VictoryAxis
                            crossAxis
                            tickFormat={(tick) => dateFormat(new Date(tick), 'dd/MM')}
                            tickCount={6}
                            tickLabelComponent={<VictoryLabel dx={25} />}
                            style={{
                                tickLabels: {
                                    fontSize: 15,
                                }
                            }}
                        />

                        {/* Pressione Sistolica */}
                        <VictoryScatter
                            style={{ data: { fill: theme.palette.error.light } }}
                            size={7}
                            data={listPresSist}
                            labels={({ datum }) => {
                                const xDay = dateFormat(new Date(datum.x), 'dd/MM/yyyy HH:mm');
                                const hightlightedDay = dateFormat(new Date(pressioneDateHighlighted!), 'dd/MM/yyyy HH:mm');

                                if (xDay !== hightlightedDay) {
                                    return '';
                                }

                                return `${datum.y} mmHg`;
                            }}
                            labelComponent={<VictoryLabel renderInPortal dy={-20} />}
                            events={[{
                                target: 'data',
                                eventHandlers: {
                                    onMouseOver: () => [{
                                        mutation({ datum }) {
                                            setPressioneDateHighlighted(datum.x);
                                        },
                                    }]
                                }
                            }]}
                        />
                        <VictoryLine
                            interpolation="natural"
                            style={{
                                data: { stroke: theme.palette.error.light },
                                parent: { border: "1px solid #ccc" }
                            }}
                            data={listPresSist}
                        />


                        {/* Pressione Diastolica */}
                        <VictoryScatter
                            style={{ data: { fill: theme.palette.primary.light } }}
                            size={7}
                            data={listPresDiast}
                            labels={({ datum }) => {
                                const xDay = dateFormat(new Date(datum.x), 'dd/MM/yyyy HH:mm:ss');
                                const hightlightedDaywithSeconds = dateFormat(new Date(pressioneDateHighlighted!), 'dd/MM/yyyy HH:mm:ss');
                                const hightlightedDay = dateFormat(new Date(pressioneDateHighlighted!), 'dd/MM/yyyy HH:mm');

                                if (xDay !== hightlightedDaywithSeconds) {
                                    return '';
                                }

                                return [`${datum.y} mmHg`, hightlightedDay];
                            }}
                            labelComponent={<VictoryLabel renderInPortal dy={-20} />}
                            events={[{
                                target: 'data',
                                eventHandlers: {
                                    onMouseOver: () => [{
                                        mutation({ datum }) {
                                            setPressioneDateHighlighted(datum.x);
                                        },
                                    }]
                                }
                            }]}
                        />
                        <VictoryLine
                            interpolation="natural"
                            style={{
                                data: { stroke: theme.palette.primary.light },
                                parent: { border: "1px solid #ccc" }
                            }}
                            data={listPresDiast}
                        />
                    </VictoryChart>
                </ChartCard>
            </div>

            <div className="col-md-12">
                <ChartCard
                    appearence={{ isFullWidth: true }}
                >
                    <ChartHeading>
                        <h3>Trend del peso negli utlimi sei mesi</h3>
                    </ChartHeading>

                    <VictoryChart
                        width={1300}
                        height={350}
                        events={[{
                            target: "parent",
                            eventHandlers: {
                                onMouseLeave: () => {
                                    setPesoDateHighlighted(null);
                                }
                            }
                        }]}
                    >
                        <VictoryAxis
                            dependentAxis
                            domain={[30, 150]}
                            tickValues={[30, 60, 90, 120, 150, 180, 210, 240, 270]}
                            tickLabelComponent={<VictoryLabel dy={-5} />}
                            style={{
                                tickLabels: {
                                    fontSize: 15,
                                }
                            }}
                        />
                        <VictoryAxis
                            crossAxis
                            tickFormat={(tick) => dateFormat(new Date(tick), 'dd/MM HH:mm')}
                            tickCount={6}
                            tickLabelComponent={<VictoryLabel dx={25} />}
                            style={{
                                tickLabels: {
                                    fontSize: 15,
                                }
                            }}
                        />

                        {/* Peso */}
                        <VictoryScatter
                            style={{ data: { fill: theme.palette.primary.light } }}
                            size={7}
                            data={listPeso}
                            labels={({ datum }) => {
                                const xDay = dateFormat(new Date(datum.x), 'dd/MM/yyyy HH:mm:ss');
                                const hightlightedDaywithSeconds = dateFormat(new Date(pesoDateHighlighted!), 'dd/MM/yyyy HH:mm:ss');
                                const hightlightedDay = dateFormat(new Date(pesoDateHighlighted!), 'dd/MM/yyyy HH:mm');

                                if (xDay !== hightlightedDaywithSeconds) {
                                    return '';
                                }

                                return [`${datum.y} kg`, hightlightedDay];
                            }}
                            labelComponent={<VictoryLabel renderInPortal dy={-20} />}
                            events={[{
                                target: 'data',
                                eventHandlers: {
                                    onMouseOver: () => [{
                                        mutation({ datum }) {
                                            setPesoDateHighlighted(datum.x);
                                        },
                                    }]
                                }
                            }]}
                        />

                        <VictoryLine
                            interpolation="natural"
                            style={{
                                data: { stroke: theme.palette.primary.light },
                                parent: { border: "1px solid #ccc" }
                            }}
                            data={listPeso}
                        />

                    </VictoryChart>
                </ChartCard>
            </div>
        </div>
    );
}

export default TrendTab;
