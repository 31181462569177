import { DeleteTwoTone, EditTwoTone } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import Modal from "components/modal/Modal";
import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { setNewMessage } from "reducers/notification.reducer";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "utils/constants";
import { StatusCodes } from "utils/enums";
import { MedicoItemCardContainer, MedicoItemCardTitle } from "./styles";
import { MedicoItemCardProps } from "./types";
import UtenteService from "services/UtenteService";

const MedicoItemCard: React.FC<MedicoItemCardProps> = ({ codProfilo, medico, onMedicoDeleted }) => {

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const deleteMedico = () => {
        UtenteService.attivaDisattiva(medico.utente).then(res => {
            const { statusCode, message } = res;

            if (statusCode === StatusCodes.OK) {
                dispatch(setNewMessage({
                    level: 'success',
                    message: message
                }));
                setIsModalOpen(false);
                onMedicoDeleted();
            } else {
                dispatch(setNewMessage({
                    level: 'error',
                    message: message
                }));
            }
        })
    }

    const listOspedaliString = useMemo(() => medico.utente?.codiciIntervento?.map(o => o?.descrizione).join(", "), [medico.utente.codiciIntervento]);

    return (
        <>
            <MedicoItemCardContainer appearence={{ isFullWidth: true }}>
                <MedicoItemCardTitle>
                    <div className="container">
                        <p>{medico.nome} {medico.cognome}</p>
                    </div>
                </MedicoItemCardTitle>
                <div className="d-flex justify-content-end mb-2">
                    <IconButton>
                        <EditTwoTone color="primary" onClick={() => {
                            const route = codProfilo === 'A' ? ROUTES.admin.detail : ROUTES.medico.detail
                            navigate(route.replace(':id', `${medico.id}`));
                        }} />
                    </IconButton>
                    <IconButton onClick={() => setIsModalOpen(true)}>
                        <DeleteTwoTone color="error" />
                    </IconButton>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <strong>Username: </strong>{medico.utente.usrName}
                    </div>
                    <div className="col-md-6">
                        <strong>Email: </strong>{medico.utente.usrMail}
                    </div>

                    <div className="col-md-6">
                        <strong>Telefono: </strong>{medico.telefono}
                    </div>
                </div>
                {codProfilo === 'M' && (
                    <div className="row mt-2">
                        <div className="col-md-12">
                            <strong>Lista Ospedali:</strong>
                        </div>
                        <div className="col-md-8">
                            {listOspedaliString}
                        </div>
                    </div>
                )}
            </MedicoItemCardContainer>

            <Modal
                open={isModalOpen}
                title="ATTENZIONE"
                onClose={() => setIsModalOpen(false)}
                onConfirm={deleteMedico}
            >
                <div className="d-flex justify-content-center">
                    <p style={{ width: '40%' }}>{codProfilo === 'A' ? "L'Amministratore " : "Il Medico "}<strong>{medico.nome} {medico.cognome}</strong> verrà eliminato in maniera permanente. Confermi di voler proseguire ?</p>
                </div>
            </Modal>
        </>
    )
}

export default MedicoItemCard;