import CambioPasswordForm from "components/cambioPasswordForm/CambioPasswordForm";
import { AuthCard } from "components/authCard";
import { ResetPasswordPageContainer } from "./styles";
import { useAppDispatch } from "store/storeHooks";
import { hasDoneReset } from "reducers/auth.reducer";

const ResetPasswordPage: React.FC = () => {

    const dispatch = useAppDispatch();

    return (
        <ResetPasswordPageContainer>
            <AuthCard width="60%" height="70%">
                <h2>Cambio Password</h2>
                <CambioPasswordForm onChangePasswordDone={() => {
                    dispatch(hasDoneReset());
                }} />
            </AuthCard>
        </ResetPasswordPageContainer>
    );
}

export default ResetPasswordPage;