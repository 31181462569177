import styled from "@emotion/styled";
import { style } from "@mui/system";
import ZINDEXES from "style/z-indexes";

const CenteredAllElementPage = styled.div`
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: ${ZINDEXES.backdrop};
`;

export const Backdrop = styled(CenteredAllElementPage)`
    background-color: black;
    opacity: 0.4;
`;

export const ModalContainer = styled(CenteredAllElementPage)`
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ModalOverlay = styled.div`
  position: relative;
  background-color: #ffff;
  min-width: 450px;
  min-height: 400px;
  z-index: ${ZINDEXES.modal};
  box-shadow: 0 0 8px ${({ theme }) => theme.palette.primary.light};
  border-radius: 3px;
  padding: 0.3rem 2rem;
`;

export const ModalTitle = styled.h2`
    display: flex;
    justify-content: center;
    color: ${({ theme }) => theme.palette.primary.light};
    margin-bottom: 4rem;
`;

export const ModalActions = styled.div`
    position: absolute;
    bottom: 1rem;
    right: 1rem;

    button {
        margin: 0 5px;
    }
`;