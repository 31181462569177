import { BaseDto } from "./BaseDto";
import { ProvinciaDto } from "./ProvinciaDto";

export class RegioneDto extends BaseDto {
    codice: string;
    descrizione: string;
    provincias: ProvinciaDto[]

    constructor() {
        super();
        this.codice = '';
        this.descrizione = '';
        this.provincias = [];
    }
}