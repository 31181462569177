import styled from "@emotion/styled";

export const PasswordCriteriaBox = styled.div<{isValid: boolean}>`
    padding: 1rem;
    border: 1px dashed ${({isValid, theme}) => isValid ? theme.palette.primary.light : theme.palette.error.light};
    color: ${({isValid, theme}) => isValid ? theme.palette.primary.light : theme.palette.error.light};

    ul {
        margin: 0;
        margin-block-start: 0;
        margin-block-end: 0;
        padding-inline-start: 1rem;
    }
`;