import { css, Global, ThemeProvider as EmotionThemeProvider } from "@emotion/react";
import { ThemeProvider as MaterialThemeProvider } from "@mui/system";
import { appTheme } from "style/theme";

const AppTheme: React.FC<React.PropsWithChildren> = ({ children }) => {
    return (
        <EmotionThemeProvider theme={appTheme}>
            <MaterialThemeProvider theme={appTheme}>
                <Global styles={css`
                   /* Material Override */
                    .MuiInputBase-root.Mui-focused {
                        input {
                            color: ${appTheme.palette.primary.light};
                        }

                        svg {
                            fill: ${appTheme.palette.primary.light};
                        }
                    }

                    .MuiInputAdornment-root {
                    display: flex;
                    justify-content: flex-end;
                    }

                    .MuiInputAdornment-root .MuiIconButton-root {
                    padding: 5px 13px !important;
                    margin: 0 !important;
                    }

                    .MuiButton-root.Mui-disabled {
                        opacity: 0.6;
                        color: unset !important;
                        background-color: unset !important;
                        border-style: solid;
                        border-width: 1px;

                        .MuiButton-containedPrimary {
                            border-color: ${appTheme.palette.primary.light};
                            border-color: unset !important;
                        }
                    }

                    .MuiFormControl-root.isInvalid {
                          
                          .MuiInputLabel-root {
                              color: ${appTheme.palette.error.light};
                          }
  
                          .MuiInputBase-root {
                              input {
                                  color: ${appTheme.palette.error.light};
                              }
                          }
  
                          svg {
                              fill: ${appTheme.palette.error.light};
                          }
                      }
                `} />
                {children}
            </MaterialThemeProvider>
        </EmotionThemeProvider>
    );
}

export default AppTheme;