import { BaseDto } from "./BaseDto";
import { ComuneDto } from "./ComuneDto";
import { NazioneDto } from "./NazioneDto";
import { ProvinciaDto } from "./ProvinciaDto";
import { UtenteDto } from "./UtenteDto";

export type FattoreEtnico = 'B' | 'N' | null;

export class PazienteDto extends BaseDto {
  nome: string;
  cognome: string;
  cittaResidenza: string;
  via: string;
  cap: string;
  email: string;
  telefono: string;
  dataNascita: Date;
  utente: UtenteDto;
  reminder: string;
  cf: string;
  sesso: string;
  fattoreEtnico: string;
  corrispondenze: number | null | undefined;
  richieste:  number | null | undefined;
  score2: number;
  nazione: NazioneDto;
  provincia: ProvinciaDto;
  comune: ComuneDto;

  constructor() {
    super();
    this.nome = '';
    this.cognome = '';
    this.cittaResidenza = '';
    this.via = '';
    this.cap = '';
    this.email = '';
    this.telefono = '';
    this.reminder = 'N';
    this.dataNascita = new Date();
    this.utente = new UtenteDto();
    this.sesso = '';
    this.fattoreEtnico = '';
    this.cf = '';
    this.corrispondenze = null;
    this.richieste = null;
    this.score2=0;
    this.nazione= new NazioneDto();
    this.provincia= new ProvinciaDto();
    this.comune= new ComuneDto();
  }
}