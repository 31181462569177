import React, { useContext } from "react";
import Sidebar from "./sidebar/Sidebar";
import Header from "./header/Header";
import { PageContent } from "./styles";
import { AppCTX } from "contexts/AppContext";

import { Variants } from "framer-motion";
import MEASURES from "style/measures";
import ScrollTopButton from "components/scrollTopButton/ScrollTopButton";

const additionalPadding = '25px';

const pageContentAnimationVariants: Variants = {
    sideFull: {
        paddingLeft: `calc(${MEASURES.sidebar.full.width} + ${additionalPadding})`,
    },
    sideCollapsed: {
        paddingLeft: `calc(${MEASURES.sidebar.collapsed.width} + ${additionalPadding})`,
    }
};

const Layout: React.FC<React.PropsWithChildren> = ({ children }) => {
    const { isSidebarCollapsed } = useContext(AppCTX);

    return (
        <>
            <Sidebar />
            <Header />
            <ScrollTopButton />
            <PageContent
                animate={!isSidebarCollapsed ? 'sideFull' : 'sideCollapsed'}
                variants={pageContentAnimationVariants}
            >
                {children}
            </PageContent>
        </>
    );
};

export default Layout;